<template>
  <div
    v-if="$store.state.useWidgetGreeting && showGreeting && !isGreetingClosed"
    class="widget-introduction"
  >
    <i
      class="widget-introduction-x"
      :style="{content: `url(${closeImagePath})`}"
      @click="$emit('closeGreeting')"
    />
    <div class="widget-introduction-message ease-in-animation">
      {{ isUserSignedIn
        ? $store.getters.getBotLanguages.translations.greeting.introductionMessage
        : $store.getters.getBotLanguages.translations.greeting.signedInUserIntroductionMessage }}
    </div>
  </div>
</template>

<script>
import './styles/GreetingBubble.less'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'

export default {
  name: 'GreetingBubble',
  mixins: [
    ImageUtilityMixin
  ],
  props: {
    isGreetingClosed: {
      type: Boolean
    }
  },
  data () {
    return {
      showGreeting: false,
      closeImagePath: this.getImagePath('close.png'),
      isUserSignedIn: false // TODO: find a way to check
    }
  },
  computed: {
    // TODO: somehow get the message from the kcb_wellness_snapshot, or kcb_greeting intent
    // their answer will differ depending on whether the user is signed in or not.

    // TODO: check how it looks in RTL and arabic version
  },
  watch: {
    isGreetingClosed (newValue) {
      if (newValue) {
        this.startGreeting()
      }
    }
  },
  mounted () {
    this.startGreeting()
  },
  methods: {
    startGreeting () {
      if ($store.state.useWidgetGreeting && $store.state.widgetGreetingStartupDelay) {
        setTimeout(() => {
          this.showGreeting = true
        }, $store.state.widgetGreetingStartupDelay * 1000)
      }
    }
  }
}
</script>
