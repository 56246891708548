<template>
  <div
    class="kai-user-wrapper kai-row clear"
    :class="[{ 'show-icon' : useStaticProfileImage}]"
  >
    <div class="kai-chat-wrapper">
      <!-- Chat Message Icon -->
      <MessageProfileImage v-if="useStaticProfileImage" />
      <div
        class="kai-typing-indicator-wrapper"
        :class="{ 'img-space' : useStaticProfileImage !== false}"
      >
        <div class="left-border" />
        <div class="kai-typing-indicator">
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageProfileImage from './MessageProfileImage'

import './styles/TypingIndicator.less'

export default {
  name: 'TypingIndicator',
  components: {
    MessageProfileImage
  },
  computed: {
    useStaticProfileImage () {
      return !$store.state.useAvatar || $store.getters.isLiveAgentConnected
    },
    Position () {
      if (this.payload && this.payload.position && this.payload.position.length > 0) {
        return this.payload.position
      } else {
        // `this` points to the vm instance
        return this.$slots.default.position
      }
    },
    ShowIcon () {
      if (this.payload) {
        if (this.payload.showIcon) {
          return true
        } else {
          return false
        }
      } else {
        // `this` points to the vm instance
        if (this.$slots.default && this.$slots.default.showIcon) {
          return this.$slots.default.showIcon
        } else {
          return false
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
