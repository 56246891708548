<template>
  <div
    :ref="'top_k_card_item_' + index"
    :aria-label="ScreenReaderLabel"
    navLvl="0"
    navDir="horizontal"
    class="top-k-kai-card-item"
  >
    <div>
      <div
        :ref="'top-k-kai-card-text-container-' + index"
        navDir="horizontal"
        class="top-k-kai-card-text-container"
      >
        <div>
          <div
            v-if="containsButtons"
            class="top-k-kai-card-subtitle"
            :style="displayFixed ? 'max-height:' + mainElementMaxHeight + 'px;': ''"
            :class="{fixed: displayFixed && showExpandButton, gauge:showGauge || showBar}"
            @click="sendPostBack()"
          >
            <div
              :ref="'top-k-kai-card-text-' + index"
              class="top-k-kai-card-text"
            >
              {{ titleToDisplay }}
            </div>
          </div>
          <div
            v-else
            class="top-k-kai-card-subtitle"
            :style="displayFixed ? 'max-height:' + mainElementMaxHeight + 'px;': ''"
            :class="{fixed: displayFixed && showExpandButton, gauge:showGauge || showBar}"
          >
            <div
              :ref="'top-k-kai-card-text-' + index"
              class="top-k-kai-card-text"
            >
              <a
                class="source-display-url"
                :href="item.payload.data.document_url"
              >
                {{ titleToDisplay }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardMixin from './Mixin/CardMixin'
import TextMixin from './Mixin/TextMixin'
import MediumPieChartMixin from './Mixin/MediumPieChartMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import {
  store
} from '../../store/index'

export default {
  name: 'TopKCardItem',
  mixins: [
    CardMixin,
    TextMixin,
    MediumPieChartMixin,
    ImageUtilityMixin
  ],
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isSelect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cardMainElement: 'kai-card-subtitle',
      // card elements that enter in consideration to calculate the main element maximum Height
      cardElements: [
        {
          className: 'kai-card-medium',
          substract: true
        },
        // the legend container is now part of the cardMainElement and shouldn't be included directly in the calculation
        // {
        //   className: 'kai-medium-legend-container',
        //   substract: false
        // },
        {
          className: 'kai-card-title',
          substract: true
        },
        {
          className: 'kai-card-button-container',
          substract: true
        }
      ],
      fallbackButtonText: store.getters.getBotLanguages.translations.selectTemplate.fallbackListSubmitButton,
      showGauge: false,
      showBar: false,
      maxCharLength: 90,
      minCharLength: 23,
      firstSubstrLength: 72,
      lastSubstrLength: 25
    }
  },
  computed: {
    // a computed getter
    titleToDisplay () {
      const str = this.item.payload.data.document_title

      if (str.length >= this.maxCharLength) { // If we exceed the maxCharLength
        return str.substr(0, this.firstSubstrLength) + '...' + str.substr(str.length - this.lastSubstrLength, str.length)
      }
      return str
    },
    ScreenReaderLabel () {
      var screenReader = $store.getters.getBotLanguages.translations.screenReader
      return screenReader.card + ' ' + (this.index + 1) + ': ' + this.item.payload.title
    },
    expandButtonLabel () {
      if (this.isExpanded) {
        return $store.getters.getBotLanguages.translations.screenReader.collapse
      } else {
        return $store.getters.getBotLanguages.translations.screenReader.expand
      }
    },
    displaySource () {
      return $store.state.displaySource
    },
    containsButtons () {
      return this.item.payload && this.item.payload.buttons && this.item.payload.buttons.length > 0
    }
  },
  methods: {
    convertHtmlData (elString, index) {
      const htmlObject = document.createElement('div')
      htmlObject.innerHTML = elString
    },
    sendPostBack () {
      if (this.containsButtons) {
        kserver.CoreAPI.sendMessage(this.item.payload.buttons[0].payload, 'POSTBACK')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
   /* the 300px is the min width of the .kai-card-title,
    which gave the min-width to the card. This way the min-width is kept. */
  .kai-card-item {
    min-width: var(--card-item-width);
  }
</style>
