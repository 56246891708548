<template>
  <section class="quickreplies">
    <div class="kai-card-carousel-wrapper">
      <div
        v-if="!atHeadOfList"
        ref="leftFade"
        class="bottom-bar-left-fade"
      />
      <div
        ref="navLeft"
        class="kai-card-carousel-nav-left-container bottom-bar-arrow-pos-left kai-mouse-pointer"
        navDir="horizontal"
        tabindex="0"
        role="button"
        :aria-label="screenReaderTranslations.quickReplyArrowLeft"
        :disabled="enableRTL ? atEndOfList : atHeadOfList"
        :class="{hover: hoverLeft && !mobile}"
        @click="moveCarouselToItem(currentIndex + scrollDirection)"
        @mouseover="hoverLeft = true"
        @mouseleave="hoverLeft = false"
      >
        <img
          :src="arrowIcon"
          class="kai-card-carousel-nav-left left-arrow"
          aria-hidden="true"
          draggable="false"
          :disabled="enableRTL ? atEndOfList : atHeadOfList"
        >
      </div>
      <div
        ref="quickRepliesCarousel"
        v-pan="onQuickRepliesPan"
        v-panend="onQuickRepliesPanend"
        class="kai-card-carousel"
      >
        <div class="kai-card-carousel-overflow-container">
          <div
            ref="carousel"
            class="kai-card-wraper quick-reply kai-card-carousel-transform"
            :aria-label="ScreenReaderLabel"
            role="region"
            :style="transformCarousel"
            :class="{transition: !onPanStarted, isFinishAnimate: isScrollFreeEase, scrollDisabled: disableCarouselScroll}"
          >
            <div
              v-for="(item, index) in response.default"
              :key="index"
              ref="item"
              class="kai-quick-reply-item"
              :class="{dynamic: item.isDynamic}"
              navLvl="1"
              navDir="horizontal"
              :aria-label="getItemAriaLabel(item)"
              role="button"
              @click="(e) => quickRepliesButton(item)"
            >
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="item.label"
                aria-hidden="true"
                class="kai-quick-reply-label"
                v-html="item.label"
              />
              <div
                v-else-if="item.display_text"
                class="kai-quick-reply-label"
                v-html="item.display_text"
              />
              <!-- eslint-enable vue/no-v-html -->
              <div
                v-if="item.image_url && item.image_url !== ''"
                ref="clipImage"
                class="kai-quick-reply-image"
              >
                <img
                  v-if="item.image_url"
                  class="backup_picture"
                  ondragstart="return false;"
                  :src="item.image_url"
                  alt=""
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        ref="navRight"
        class="kai-card-carousel-nav-right-container"
        navDir="horizontal"
        tabindex="0"
        role="button"
        :aria-label="screenReaderTranslations.quickReplyArrowRight"
        :disabled="enableRTL ? atHeadOfList : atEndOfList"
        :class="{hover: hoverRight && !mobile}"
        @click="moveCarouselToItem(currentIndex - scrollDirection)"
        @mouseover="hoverRight = true"
        @mouseleave="hoverRight = false"
      >
        <img
          :src="arrowIcon"
          class="kai-card-carousel-nav-right"
          aria-hidden="true"
          draggable="false"
          :disabled="enableRTL ? atHeadOfList : atEndOfList"
        >
      </div>
      <div
        v-if="!atEndOfList"
        ref="rightFade"
        class="bottom-bar-right-fade"
      />
    </div>
    <!-- <div class="kai-card-carousel-pager">
        <div v-for="(item, index) in this.response.default" :key="index" aria-hidden="”true" class="kai-card-carousel-pager-item" @click="moveCarouselToItem(index)" :class="{active:index == currentIndex}"><a tabindex="-1" href="" data-slide-index="0" class="bx-pager-link">index</a></div>
    </div> -->
  </section>
</template>
<script>
import Vue from 'vue'
import {
  isMobile
} from 'mobile-device-detect'
import Kai from '../../kai'
import CarouselMixin from './Mixin/CarouselMixin'
import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import './styles/QuickReplies.less'
import {
  store
} from '../../store/index'

Vue.directive('pan', {
  bind: function (el, binding) {
    if (typeof binding.value === 'function') {
      const mc = new Hammer(el)
      mc.get('pan').set({
        direction: Hammer.DIRECTION_HORIZONTAL
      })
      mc.on('pan', binding.value)
    }
  }
})

Vue.directive('panend', {
  bind: function (el, binding) {
    if (typeof binding.value === 'function') {
      const mc = new Hammer(el)
      mc.get('pan').set({
        direction: Hammer.DIRECTION_HORIZONTAL
      })
      mc.on('panend', binding.value)
    }
  }
})

Vue.directive('tap', {
  bind: function (el, binding) {
    if (typeof binding.value === 'function') {
      const mc = new Hammer(el)
      mc.on('tap', binding.value)
    }
  }
})

export default {
  name: 'QuickReplies',
  filters: {
    // mediumContent filter contained in MediumMixin
  },
  mixins: [
    CarouselMixin,
    KeyboardNavigationMixin,
    ImageUtilityMixin
  ],
  data () {
    return {
      response: this.$slots,
      carouselCounter: store.getters.getCarouselCounter,
      currentOffset: 0,
      currentIndex: 0,
      hoverLeft: false,
      hoverRight: false,
      mobile: isMobile,
      forceUpdate: false,
      currentCardMode: '',
      referenceOffset: 0,
      onPanStarted: false, // flag used to disable css animation during the pan gesture.
      isScrollAnimate: true,
      isScrollFreeEase: true,
      quickReplyImageCSSHeight: 32, // kai-quick-reply-image CSS height used to clip image
      leftArrowSrc: this.getImagePath('panel-left-arrow-blk.png'),
      rigthArrowSrc: this.getImagePath('panel-right-arrow-blk.png'),
      screenReaderTranslations: $store.getters.getBotLanguages.translations.screenReader,
      arrowIcon: this.getImagePath('svg/Arrow_carousel.svg'),
      enableRTL: $store.state.enableRTL,
      disableCarouselScroll: false,
      navLeftInitialWidth: 0,
      navRightInitialWidth: 0

    }
  },
  computed: {
    cardItems () {
      return this.response.default
    },
    ScreenReaderLabel () {
      var screenReaderCurrentDate = moment().format('hh:mm A')
      var screenReader = $store.getters.getBotLanguages.translations.screenReader
      var sender = screenReader.virtualAssistantDesignation + ' ' + screenReader.displayed
      var quickReplyLabel = this.response.default.length + ' ' + screenReader.quickReply
      if (this.response.default.length > 1) {
        quickReplyLabel = this.response.default.length + ' ' + screenReader.quickReplies
      }
      return screenReader.timeSendAt + ' ' + screenReaderCurrentDate + ',  ' + sender + ': ' + quickReplyLabel
    }
  },
  mounted () {
    // this.getImagesUrl
    this.clipQuickReplyImages()
    this.$nextTick(() => {
      this.navLeftInitialWidth = this.$refs.navLeft.clientWidth
      this.navRightInitialWidth = this.$refs.navRight.clientWidth
      this.observeWebviewContainerWidth()
    })
  },
  methods: {
    quickRepliesButton (quickReply) {
      Kai.Core.buttonPressed(quickReply)
    },
    clipQuickReplyImages () {
      if (this.$refs.clipImage && this.$refs.clipImage.length > 0) {
        for (var i = 0; i < this.$refs.clipImage.length; i++) {
          if (this.$refs.clipImage[i].children[0]) {
            var element = this.$refs.clipImage[i].children[0]
            var finalHeight = this.quickReplyImageCSSHeight

            var clipRatio = element.naturalHeight / finalHeight
            var finalWidth = element.naturalWidth / clipRatio
            var marginWidthCorrection = (finalWidth - finalHeight) / 2

            element.style.marginLeft = '-' + marginWidthCorrection + 'px'
          }
        }
      }
    },
    getItemAriaLabel (quickReply) {
      // check if quick reply label contains a thumb up or thumb down emoji image, (see kai.js function getThumbsImage() for more info)

      if (quickReply.label && quickReply.label.includes('thumb-icon') && quickReply.label.includes('thumb-up')) {
        return 'Thumb up'
      }
      if (quickReply.label && quickReply.label.includes('thumb-icon') && quickReply.label.includes('thumb-down')) {
        return 'Thumb down'
      }
      return quickReply.label ? quickReply.label : quickReply.display_text
    },
    checkCarouselArrows () {
      const availableWidth = this.$refs.quickRepliesCarousel.clientWidth - this.navLeftInitialWidth - this.navRightInitialWidth
      var quickrepliesTotalWidth = this.$refs.carousel.offsetWidth
      if (quickrepliesTotalWidth <= availableWidth) {
        this.disableCarouselScroll = true
        this.currentOffset = 0
        this.currentIndex = 0
        this.$refs.navLeft.style.display = 'none'
        this.$refs.navRight.style.display = 'none'
        if (this.$refs.leftFade) {
          this.$refs.leftFade.style.display = 'none'
        }
        if (this.$refs.RightFade) {
          this.$refs.rightFade.style.display = 'none'
        }
      } else {
        this.disableCarouselScroll = false
        this.$refs.navLeft.style.display = 'block'
        this.$refs.navRight.style.display = 'block'
        if (this.$refs.leftFade) {
          this.$refs.leftFade.style.display = 'block'
        }
        if (this.$refs.RightFade) {
          this.$refs.rightFade.style.display = 'block'
        }
      }
    },
    observeWebviewContainerWidth () {
      const element = document.querySelector('#webview-container')

      if (!element) {
        console.error('Element #webview-container not found')
        return
      }

      const resizeObserver = new ResizeObserver(async () => {
        try {
          this.checkCarouselArrows()
        } catch (error) {
          console.error('observeQuickReplyHeight - An error occurred:', error)
          // Retry the operation
          if (this.retryCount < 3) {
            this.retryCount++
            this.checkCarouselArrows()
          }
        }
      })

      this.$nextTick(() => {
        resizeObserver.observe(element)
      })

      // Cleanup
      this.$once('hook:beforeDestroy', () => {
        resizeObserver.unobserve(element)
      })
    },
    onQuickRepliesPan (event) {
      if (this.disableCarouselScroll) {
        return
      }
      this.onPan(event)
    },
    onQuickRepliesPanend (event) {
      if (this.disableCarouselScroll) {
        return
      }
      this.onPanEnd(event)
    }
  }
}
</script>

<style scoped>
.kai-card-carousel-transform {
    transform: var(--carousel_translate);
}
.kai-quick-reply-item:focus {
    outline: #00a0af solid 0px;
}
.kai-card-carousel-nav-left-container {
  padding: 0px;
}
.kai-card-carousel-nav-left-container, .kai-card-carousel-nav-right-container {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left:8px;
  margin-right:8px;
}

.kai-card-carousel-nav-left {
  left: 19.33%;
  right: 19.33%;
  top: 22.73%;
  width: 14px;
  height: 14px;
}

.kai-card-carousel-nav-right {
  left: 29.33%;
  right: 29.33%;
  top: 22.73%;
  width: 14px;
  height: 14px;
}
.kai-card-carousel-nav-right-container input, .kai-card-carousel-nav-left-container input {
    width: 8px;
    margin: auto;
}
.bottom-bar-right-fade {
    height: 55px;
    width: 100px;
    position: absolute;
    pointer-events: none;
    right: 0;
    background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%,  rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */

}
.bottom-bar-left-fade {
    height: 55px;
    width: 100px;
    position: absolute;
    pointer-events: none;
    left: 0;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
    background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
    z-index: 1;
}
input[type=image]:disabled
{
    opacity:0.5;
}
.bottom-bar-arrow-pos-right input{
    position: relative;
    left: 8px;
    top: 7px;
}

.bottom-bar-arrow-pos-left input{
    position: relative;
    left: 5px;
    top: 7px;
}

.kai-mouse-pointer {
    cursor: pointer;
    cursor: hand;
}
</style>
