<template>
  <div
    v-if="showAnimatedWidget"
    class="kai-animated-widget kai-avatar"
  >
    <div
      class="lottie lottie-isdefault"
      aria-hidden="true"
      data-bm-renderer="svg"
    />
  </div>
</template>

<script>

import './styles/Avatar.less'
import animationChatWithMeJsonData from '../../debug/animation_hd_chat_with_me.json'
import animationChatWithMeEndJsonData from '../../debug/animation_hd_chat_with_me_end.json'
import AvatarAnimationMixin from './Mixin/AvatarAnimationMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import Kai from '../../kai'

import Lottie from 'lottie-web'

export default {
  name: 'StartupWidgetAnimation',
  mixins: [
    AvatarAnimationMixin,
    ImageUtilityMixin
  ],
  data () {
    return {
      response: this.$slots,
      isLoopTimerDone: false,
      defaultAnimation: {
        state_name: 'chat_with_me_start_bodymovin',
        loop: false
      },
      currentAnimation: {
        state_name: 'chat_with_me_start_bodymovin',
        loop: false
      },
      previousAnimation: false,
      extraAnimation: false,
      anim: undefined,
      runningAnimation: false,
      isFirstAnimation: true
    }
  },
  computed: { // Listen to see if store state change
    showAnimatedWidget () {
      return $store.state.useWidgetAnimation && !$store.state.showWebviewWidget
    }
  },
  watch: {
    showAnimatedWidget () {
      return $store.state.useWidgetAnimation && !$store.state.showWebviewWidget
    }
  },
  created () {
    // If Animation are enable in the WV config, initialize with the following Animation name
    this.$nextTick(() => {
      this.loadAnimation()
    })
  },
  methods: {
    loadAnimation () {
      if (this.showAnimatedWidget) {
        this.currentAnimation = this.defaultAnimation
        this.loadWidgetAnimation(this.currentAnimation)
      }
    },
    parseAnimationPayload (messageContentAnimation) {
      const animation = {
        ...messageContentAnimation.data ? messageContentAnimation.data : messageContentAnimation.payload ? messageContentAnimation.payload.data : {}
      }
      animation.state_name = animation.name ? animation.name : animation.state_name ? animation.state_name : false
      animation.loop = !!animation.loop
      return JSON.parse(JSON.stringify(animation))
    },
    async loadWidgetAnimation (animation) {
      const _this = this
      if (animation.state_name) {
        animation.state_name = _this.mapNameToFile(animation.state_name)
        animation = _this.checkForExtraAnimations(animation)
      }

      // Object
      const animationObj = {
        container: document.getElementsByClassName('lottie')[0], // the dom element that will contain the animation
        renderer: 'svg',
        loop: animation.loop,
        autoplay: !_this.isFirstAnimation
      }

      if ($store.getters.isInlineAvatarEnabled) {
        animationObj.animationData = await Kai.API.getAvatarFile(animation.state_name)
      } else {
        animationObj.path = await Kai.API.getAvatarFile(animation.state_name)
      }

      await _this.prepareAssets(animationObj)

      // Attach object
      _this.anim = Lottie.loadAnimation(animationObj)

      const startDelay =
        _this.isFirstAnimation &&
        _this.$store.state.widgetAnimationSettings.startDelay
          ? _this.$store.state.widgetAnimationSettings.startDelay
          : null
      _this.loadLogoImage(_this, startDelay)

      if (animationObj.loop && !_this.isLoopTimerDone) {
        setTimeout(() => {
          _this.isLoopTimerDone = true
        }, _this.$store.state.widgetAnimationSettings.loopDurationInSeconds * 1000)
      }

      _this.anim.addEventListener('complete', () => {
        _this.completeAnimation()
      })
      _this.anim.addEventListener('loopComplete', () => {
        if (_this.isLoopTimerDone) {
          _this.completeAnimation()
        }
      })
    },
    completeAnimation () {
      const _this = this
      this.runningAnimation = false
      this.isLoopTimerDone = false
      this.isFirstAnimation = false
      if (this.extraAnimation) {
        this.dispatchExtraAnimation()
      } else {
        setTimeout(() => _this.loadAnimation(),
          _this.$store.state.widgetAnimationSettings.secondsBetweenLoops * 1000)
      }
    },
    removePreviousAnimation () {
      if (this.showAnimatedWidget) {
        const container = document.getElementsByClassName('lottie')[0]
        // remove the previous animation svg child (if any) only once the new animation start playing
        this.$nextTick(() => {
          while (container.childNodes !== undefined && container.childNodes.length > 1) {
            container.removeChild(container.childNodes[0])
          }
        })
      }
    },
    mapNameToFile: (name) => {
      switch (name.toUpperCase()) {
        case 'CHAT_WITH_ME_AVATAR_STATE': {
          return 'chat_with_me_start_bodymovin'
        }
        default:
          return name
      }
    },
    checkForExtraAnimations (animation) {
      // animations sequences:
      //  - chat starting: intro + chat + outro
      this.isLoopTimerDone = false
      if (animation.state_name === 'chat_with_me_end_bodymovin') {
        this.extraAnimation = false
        animation.state_name = 'chat_with_me_end_bodymovin'
        animation.loop = false
        animation.autoplay = true
      } else if (animation.state_name === 'chat_with_me_start_bodymovin') {
        // place the "queue waiting" animation in the queue so it can be played as soon as the "agent start" animation is done.
        this.extraAnimation = this.parseAnimationPayload(JSON.parse(JSON.stringify(animationChatWithMeJsonData.message_contents[0])))
        animation.state_name = 'chat_with_me_start_bodymovin'
        animation.loop = false
      } else if (animation.state_name === 'chat_with_me_bodymovin') {
        // place the agent end animation in the queue so it can be played as soon as the "queue waiting" animation is done.
        this.extraAnimation = this.parseAnimationPayload(JSON.parse(JSON.stringify(animationChatWithMeEndJsonData.message_contents[0])))
        animation.state_name = 'chat_with_me_bodymovin'
        animation.loop = true
        animation.autoplay = true
      }
      return animation
    },
    dispatchExtraAnimation () {
      if (this.showAnimatedWidget) {
        this.previousAnimation = JSON.parse(JSON.stringify(this.currentAnimation))
        this.currentAnimation = JSON.parse(JSON.stringify(this.extraAnimation))
        this.extraAnimation = false
        this.loadWidgetAnimation(this.currentAnimation)
      }
    }
  }
}
</script>
