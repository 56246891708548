<template>
  <section>
    <div
      class="kai-row kai-multi-select-container"
      tabindex="0"
      navDir="horizontal"
    >
      <div class="row">
        <div class="col-sm-12">
          <div class="multi-select-container">
            <div class="desktop-view">
              <div
                v-for="(item, i) in response.default.payload.options"
                :key="i"
                tabindex="-1"
                navLvl="1"
                navDir="vertical"
              >
                <div class="select-bground col-sm-10">
                  <label>
                    <input
                      ref="cardInput"
                      class="filled-in checkmark"
                      :data-id="i"
                      type="checkbox"
                      :value="item.value"
                      :disabled="isDisable"
                      @click="selectBtn($event, item, i)"
                    >
                    <span
                      v-if="item.label"
                      class="checkbox-space "
                      :class="{disabled: isDisable}"
                    >{{ item.label }}</span>
                    <span
                      v-else
                      class="checkbox-space "
                      :class="{disabled: isDisable}"
                    >{{ item.value }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TextBubble from './TextBubble'
import SelectMixin from './Mixin/SelectMixin'
import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import './styles/SelectChecklist.less'
import {
  store
} from '../../store/index'

export default {
  name: 'SelectChecklist',
  mixins: [
    SelectMixin,
    KeyboardNavigationMixin
  ],
  props: {
    isDisable: {
      type: Boolean
    }
  },
  data () {
    return {
      selectedItemsIndex: this.$parent.selectedItemsIndex,
      validationMessage: '',
      textBubble: TextBubble,
      ishideSubmitContent: false,
      response: store.getters.getSlots
    }
  },
  created () {
    this.hideSubmitContent() // Mixin
    this.$root.$refs.SelectChecklistComp = this
  },
  methods: {
    uncheckSelected (index) {
      var getIndexValue = this.selectedItemsIndex[index]
      var inputs = document.querySelectorAll('.checkmark')

      // console.log('uncheckSelected => getIndexValue', getIndexValue)

      for (var i = 0; i < inputs.length; i++) {
        inputs[getIndexValue].checked = false
      }
    }
  }
}
</script>
