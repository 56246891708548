<template>
  <section>
    <!-- For custom templates added as a Single CARD -->
    <template v-if="isSingle && isSupported">
      <div class="kai-row" />
      <div class="kai-card-wraper">
        <section>
          <CustomKIMCard
            v-if="item.type === 'KIM_CARD'"
            :item="item"
          />
          <CustomConciergeCard
            v-if="item.type === 'CONCIERGE_CARD'"
            :item="item"
          />
          <TopKCardCarousel
            v-if="item.type === 'TOP_K'"
            :item="item"
          />
        </section>
      </div>
    </template>
    <!-- For custom templates added as a multiple CARDs -->
    <template v-else>
      <CustomKIMCard
        v-if="item.type === 'KIM_CARD'"
        :item="item"
      />
      <CustomConciergeCard
        v-if="item.type === 'CONCIERGE_CARD'"
        :item="item"
      />
      <TopKCardCarousel
        v-if="item.type === 'TOP_K'"
        :item="item"
      />
    </template>
    <!-- For other custom templates that are not following the CARD approach -->
    <Search
      v-if="item.type === 'WEB_SEARCH' || item.type === 'WEB_SEARCH_GOOGLE' || item.type === 'WEB_SEARCH_SILVERCLOUD'"
      :item="item"
    />
    <!-- For Charts -->
    <Chart
      v-if="item.type === 'CHART'"
      :item="item"
    />
  </section>
</template>

<script>
import CustomKIMCard from './CustomKIMCard'
import CustomConciergeCard from './CustomConciergeCard'
import Search from './Search'
import Chart from './Chart'
import TopKCardCarousel from './TopKCard.vue'

export default {
  name: 'Custom',
  components: {
    CustomKIMCard,
    CustomConciergeCard,
    Search,
    Chart,
    TopKCardCarousel
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isSingle: Boolean
  },
  computed: {
    isSupported () {
      if (this.item.type === 'KIM_CARD' ||
      this.item.type === 'CONCIERGE_CARD' ||
      this.item.type === 'WEB_SEARCH' ||
      this.item.type === 'WEB_SEARCH_GOOGLE' ||
      this.item.type === 'WEB_SEARCH_SILVERCLOUD' ||
      this.item.type === 'CHART') {
        return true
      }

      return false
    }
  }
}
</script>
