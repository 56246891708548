import { store } from './store/index'
import kserver from './kserver/kai.coreapi'
import kbaseserver from './kserver/kai.server.autocomplete'
import Vue from 'vue'
import App from './App'
import DatePicker from './core/components/DatePicker'
import TextBubble from './core/components/TextBubble'
import QuickReplies from './core/components/QuickReplies'
import ListViewBase from './core/components/ListViewBase'
import IntroScreen from './core/components/IntroScreen'
import Carousel from './core/components/Carousel'
import TopKCarousel from './core/components/TopKCarousel'
import CardSingle from './core/components/CardSingle'
import MediumSingle from './core/components/MediumSingle'
import Select from './core/components/Select'
import CustomSingle from './core/components/CustomSingle'
import LocationMap from './core/components/LocationMap'
import ContentButton from './core/components/ContentButton'
import ContentLink from './core/components/Link'
import CustomSearch from './core/components/Search'
import CustomSearchDetails from './core/components/SilverCloudSearchDetails'
import LiveChatNotification from './core/components/LiveChatNotification'
import RelatedQuestionsUserText from './core/components/RelatedQuestionsUserText'

import NBest from './core/components/NBest'
import RelatedQuestions from './core/components/RelatedQuestions'
import TimeStamp from './core/components/TimeStamp'
import moment from 'moment'
import { isMobile } from 'mobile-device-detect'

import Cookies from 'js-cookie'

// Debug Data
import DebugMode from './core/components/DebugMode'
import cardSmallJsonData from './debug/card_small'
import cardGaugeJsonData from './debug/card_gauge'
import cardBarChartJsonData from './debug/card_bar_chart.json'
import carouselJsonData from './debug/carousel.json'
import carouselMediumJsonData from './debug/carousel_medium.json'
import cardJsonData from './debug/card.json'
import mediumPieChartJsonData from './debug/medium_piechart.json'
import mediumLineChartJsonData from './debug/medium_linechart_carousel.json'

// arabic
import carouselArabicJsonData from './debug/carousel_arabic.json'
import mediumPieChartArabicJsonData from './debug/medium_piechart_arabic.json'
import mediumLineChartArabicJsonData from './debug/medium_linechart_carousel_arabic.json'
import cardBarChartArabicJsonData from './debug/card_bar_chart_arabic.json'

import listJsonData from './debug/list.json'
import listGroupedJsonData from './debug/list_grouped.json'
import quickrepliesJsonData from './debug/quick-replies.json'
import textResponseJsonData from './debug/text-response.json'
import greetingsJsonData from './debug/greetings.json'
import debugDocJsonData from './debug/debug.json'

import multipleResponsesJsonData1 from './debug/multiple_responses_1.json'
import multipleResponsesJsonData2 from './debug/multiple_responses_2.json'
import multipleResponsesJsonData3 from './debug/multiple_responses_3.json'
import multipleResponsesJsonData4 from './debug/multiple_responses_4.json'
import multipleResponsesJsonData5 from './debug/multiple_responses_5.json'

import selectCarouselJsonData from './debug/select-carousel.json'
import selectListJsonData from './debug/select-list.json'
import selectChecklistJsonData from './debug/select-checklist.json'

import datepickerJsonData from './debug/datepicker.json'

import customKIMMediumJsonData from './debug/customKIMCard_medium.json'
import customKIMCardChartJsonData from './debug/customKIMCard_chart.json'
import customKIMCarouselChartJsonData from './debug/customKIMCarousel_chart.json'
import customConciergeCardJsonData from './debug/customConciergeCard.json'
import customConciergeCarouselJsonData from './debug/customConciergeCarousel.json'
import customKaiAnswersJsonData from './debug/customKaiAnswers.json'

import nbestJsonData from './debug/nbest.json'
import nbestBottomJsonData from './debug/nbest_bottom.json'
import nbestRelatedQuestions from './debug/nbest_related_questions.json'
import liveChatJsonData from './debug/LiveChat.json'
import liveChatWebRTCJsonData from './debug/livechat_webrtc.json'
import liveChatNotificationsJsonData from './debug/LiveChatNotifications.json'
import ratingJsonData from './debug/rating-binary.json'
import ratingFiveJsonData from './debug/rating-five.json'
import ratingTenJsonData from './debug/rating-ten.json'

import linkJsonData from './debug/link.json'
import searchCustomJsonData from './debug/search.json'
import searchQuickReplyJsonData from './debug/searchQuickReply.json'
import shortcutPanelJsonData from './debug/shortcut.json'
import buttonJsonData from './debug/button.json'
import noMessageContentJsonData from './debug/no-message-content.json'

// popup
import popupIdleJsonData from './debug/popup_idle.json'
import popupEndLivechatJsonData from './debug/popup_end_livechat.json'
import popupMinimizeJsonData from './debug/popup_minimize.json'

import transcriptData from './debug/transcript.json'

// Avatar
import avatarActiveJsonData from './debug/avatar_hd_active.json'
import avatarChatIconJsonData from './debug/avatar_hd_chat_icon.json'
import avatarCheckmarkJsonData from './debug/avatar_hd_checkmark.json'
import avatarQuestionJsonData from './debug/avatar_hd_question.json'
import avatarTransactionJsonData from './debug/avatar_hd_transaction.json'
import avatarPlaygroundJsonData from './debug/avatar_hd_playground.json'
import avatarIdleJsonData from './debug/avatar_hd_idle.json'
import avatarSplash2JsonData from './debug/avatar_hd_splash2.json'
import avatarLeavingJsonData from './debug/avatar_hd_leaving.json'
import avatarNotificationJsonData from './debug/avatar_hd_notification.json'
import avatarSorryJsonData from './debug/avatar_hd_sorry.json'
import avatarThankYouJsonData from './debug/avatar_hd_thank_you.json'
import avatarVoiceStartJsonData from './debug/avatar_hd_voice1start.json'
import avatarVoiceLoopJsonData from './debug/avatar_hd_voice2loop.json'
import avatarVoiceEndJsonData from './debug/avatar_hd_voice3end.json'
import avatarAgentStartJsonData from './debug/avatar_hd_agent_start.json'
import avatarQueueWaitingLoopJsonData from './debug/avatar_hd_queue_waiting.json'
import avatarAgentEndJsonData from './debug/avatar_hd_agent_end.json'
import avatarAgentConnectingLoopJsonData from './debug/avatar_hd_agent_connecting.json'
import avatarAgentConnectedLoopJsonData from './debug/avatar_hd_agent_connected.json'
import avatarAgentDisconnectedLoopJsonData from './debug/avatar_hd_agent_disconnected.json'

// Webview version
import webviewLibraryVersion from '../build/webview-version.json'

// Testcases multi medium
import testcaseMultiMedium from './debug/testcase_multi_medium.json'
import list2JsonData from './debug/list2.json'

// Text stream
import textStreamEvent from './debug/text-stream-event.json'

// // SINGLE MODE
// import splash1Bodymovin from '@/avatars/splash1_bodymovin.json'
// import splash2Bodymovin from '@/avatars/splash2_bodymovin.json'
// import activeBodymovin from '@/avatars/active_bodymovin.json'
// import checkmarkBodymovin from '@/avatars/checkmark_bodymovin.json'
// import idleBodymovin from '@/avatars/idle_bodymovin.json'
// import playgroundBodymovin from '@/avatars/playground_bodymovin.json'
// import questionBodymovin from '@/avatars/question_bodymovin.json'
// import sorryBodymovin from '@/avatars/sorry_bodymovin.json'
// import thankYouBodymovin from '@/avatars/thank_you_bodymovin.json'
// import transactionBodymovin from '@/avatars/transaction_bodymovin.json'
// import voice1startBodymovin from '@/avatars/voice_1start_bodymovin.json'
// import voice2LoopBodymovin from '@/avatars/voice_2loop_bodymovin.json'
// import voice3endBodymovin from '@/avatars/voice_3end_bodymovin.json'
// import leavingBodymovin from '@/avatars/leaving_bodymovin.json'
// import notificationBodymovin from '@/avatars/notification_bodymovin.json'
// import agentStartBodymovin from '@/avatars/agent_start_bodymovin.json'
// import queueWaitingLoopBodymovin from '@/avatars/waiting_bodymovin.json'
// import agentEndBodymovin from '@/avatars/agent_end_bodymovin.json'
// import agentConnectingLoopBodymovin from '@/avatars/agent_connecting_bodymovin.json'
// import agentConnectedLoopBodymovin from '@/avatars/agent_connected_bodymovin.json'
// import agentDisconnectedLoopBodymovin from '@/avatars/agent_disconnected_bodymovin.json'
// import chatWithMeBodymovin from '@/avatars/chat_with_me_bodymovin.json'
// import chatWithMeStartBodymovin from '@/avatars/chat_with_me_start_bodymovin.json'
// import chatWithMeEndBodymovin from '@/avatars/chat_with_me_end_bodymovin.json'
// // SINGLE MODE

import extraSettings from '@/json/settings.json'

/****************************************************/

/* Global Values from 1.0  */

/****************************************************/

// window not defined error to fix - JB
// var currentY = $jq(window).scrollTop();
var typingIndicatorTimerHolder = false
let typingIndicatorPendingUserMessageResponse = false
let typingIndicatorPendingKeywordsResponse = false
var inactivityTimerHolder = false

let timestampTimerHolder = false

// CAPI Status code
const STATUS_OK = '200'
const STATUS_OK_NO_CONTENTS = '204'
const STATUS_INVALID_REQUEST = '400'
const STATUS_AUTHENTICATION_FAILED = '401'
const STATUS_ACCESS_DENIED = '403'
const STATUS_LOGICAL_ERRORS = '422'
const STATUS_INVALID_SESSION_ID = '440'
const STATUS_OTP_REQUIRED = '450'
const STATUS_INVALID_OTP_RETRY = '451'
const STATUS_EXPIRED_OTP = '452'
const STATUS_TOO_MANY_OTP_FAILURES = '453'
const STATUS_INTERNAL_SERVER_ERROR = '500'
const STATUS_SERVICE_UNAVAILABLE = '503'

var invalidSessionCounter = 0
var invalidSessionAfterStartSessionCounter = 0

var cookieAutoRefreshTimeout = null

let vueApp = null

const Core = (function () {
  var dispatchWebviewEvent = (eventName, detail) => {
    document.dispatchEvent(new CustomEvent(eventName, {
      detail: detail
    }))
  }

  /****************************************************/

  /*  passToNativeMethod
        Used to pass JS code -> iOS + Android
    */

  /****************************************************/

  var passToNativeMethod = (nativeFunctionName, params) => {
    // console.log('passToNativeMethod: ' + nativeFunctionName, params)
    dispatchWebviewEvent(nativeFunctionName, params)
    // For iOS
    try {
      execNativeMethod(nativeFunctionName, params)
    } catch (err) {
      if (err instanceof ReferenceError) {
        console.info(`info - passToNativeMethod - ${err.message}`)
      } else {
        console.error(`error in passToNativeMethod - ${err.message}`)
      }
    }

    // For Android
    if (typeof injectedJS !== 'undefined') {
      injectedJS.execNativeMethod(nativeFunctionName, JSON.stringify(params))
    }
  }

  /****************************************************/

  /*  passToNativeWebviewCAPIResponseMethod
        Used to pass CAPI response from JS code -> iOS + Android
    */

  /****************************************************/

  var passToNativeWebviewCAPIResponseMethod = (params) => {
    // console.log('passToNativeWebviewCAPIResponseMethod', params)
    dispatchWebviewEvent('webviewCAPIResponse', params)
    // For iOS
    try {
      execNativeMethod('webviewCAPIResponse', params)
    } catch (err) {
      if (err instanceof ReferenceError) {
        console.info(`info - passToNativeWebviewCAPIResponseMethod - ${err.message}`)
      } else {
        console.error(`error in passToNativeWebviewCAPIResponseMethod - ${err.message}`)
      }
    }

    // For Android
    if (typeof injectedJS !== 'undefined') {
      injectedJS.execNativeMethod('webviewCAPIResponse', JSON.stringify(params))
    }
  }

  /****************************************************/

  /*  passToNativeWebviewCAPIRequestMethod
        Used to pass CAPI request from JS code -> iOS + Android
    */

  /****************************************************/

  var passToNativeWebviewCAPIRequestMethod = (params) => {
    // console.log('passToNativeWebviewCAPIRequestMethod', params)
    dispatchWebviewEvent('webviewCAPIRequest', params)
    // For iOS
    try {
      execNativeMethod('webviewCAPIRequest', params)
    } catch (err) {
      if (err instanceof ReferenceError) {
        console.info(`info - webviewCAPIRequest - ${err.message}`)
      } else {
        console.error(`error in webviewCAPIRequest - ${err.message}`)
      }
    }

    // For Android
    if (typeof injectedJS !== 'undefined') {
      injectedJS.execNativeMethod('webviewCAPIRequest', JSON.stringify(params))
    }
  }

  /****************************************************/

  /*  passToNativeWebviewCAPIErrorMethod
        Used to pass CAPI Errors from JS code -> iOS + Android
    */

  /****************************************************/

  var passToNativeWebviewCAPIErrorMethod = (params) => {
    // console.log('passToNativeWebviewCAPIErrorMethod', params)
    dispatchWebviewEvent('webviewCAPIError', params)
    // For iOS
    try {
      execNativeMethod('webviewCAPIError', params)
    } catch (err) {
      if (err instanceof ReferenceError) {
        console.info(`info - webviewCAPIError - ${err.message}`)
      } else {
        console.error(`error in webviewCAPIError - ${err.message}`)
      }
    }

    // For Android
    if (typeof injectedJS !== 'undefined') {
      injectedJS.execNativeMethod('webviewCAPIError', JSON.stringify(params))
    }
  }

  /****************************************************/

  /* Kai Init  */

  /****************************************************/

  var kaiInit = async function (e) {
    if (typeof window.ENV !== 'undefined') {
      const serverConfigs = extractServerConfigsFromData(window.ENV)
      updateServerConfigs(serverConfigs)
    }

    var selectedLanguageCode = Cookies.get('k_selected_language')
    if (selectedLanguageCode) {
      selectLanguageInternal(selectedLanguageCode, true)
    }

    const initMode = findGetParameter('initMode')

    // Check for kai_session_id cookie
    if (($store.getters.isSessionCookieEnabled || $store.getters.getStartSession === true) && !(initMode && initMode === 'capi_redirect')) {
      const kaiSessionId = Cookies.get('k_session_id')
      if (kaiSessionId) {
        $store.state.kaiSessionId = kaiSessionId
      }
      const pullServiceState = Cookies.get('k_pull_service_state')
      if (pullServiceState) {
        $store.state.pullServiceState = pullServiceState
      }
    }

    if (store.state.loadThemeFromBackend) {
      var config = Cookies.get('k_config')
      var style = Cookies.get('k_style')
      if (config || style) {
        // if previous style was stored in cookie, we apply this style first instead of waiting for the server response
        var response = {
          content: {}
        }
        if (config) {
          response.content.config = config
        }
        if (style) {
          response.content.style = style
        }
        updateConfigAndThemeVariables(response)
        generateMainContainerClass()
        store.state.webviewReady = true
        kserver.CoreAPI.getThemeVariables()
      } else {
        kserver.CoreAPI.getThemeVariables((response) => {
          updateConfigAndThemeVariables(response)
          generateMainContainerClass()
          store.state.webviewReady = true
        })
      }
    } else if (store.state.loadThemeAndAssistantPropertyFromQueryString) {
      var settings = findGetParameter('settings')

      if (settings) {
        try {
          settings = decodeURIComponent(settings)
          settings = JSON.parse(settings)
        } catch (err) {
          console.log('Decode and parse settings failed.')
        }
      }

      if (extraSettings !== undefined) {
        settings = $jq.extend(true, extraSettings, settings)
      }

      if (settings) {
        updateConfigAndThemeVariables(settings)
      }

      generateMainContainerClass()
      store.state.webviewReady = true
    } else {
      generateMainContainerClass()
      store.state.webviewReady = true
    }

    // mount Vue instance only if el exists in document
    if (document.getElementById(store.state.el)) {
      if (vueApp !== null) {
        vueApp.$destroy()
      }
      vueApp = new Vue({
        el: '#' + store.state.el,
        store: store,
        components: { App },
        template: '<App/>'
      })
    }

    updateAssistantHeader()

    passToNativeMethod('webviewReady')
    loadHistory()

    if ($store.getters.getKaiSessionId === '' && $store.getters.getStartSession === true) {
      if ($store.state.useWidgetMode !== true || ($store.state.useWidgetMode === true && $store.state.showWebviewWidget === true)) {
        kserver.CoreAPI.startSession()
      }
    }

    const serverConfig = Kai.API.getCurrentServerConfig()
    if ($store.getters.getStartPullService || (serverConfig.START_PULL_SERVICE)) {
      startPullService()
    }

    // load default payload message JSON file
    // Kai.Core.handleResponse(payloadMessage);
    // Kai.Core.handleResponse(nbestJsonData);
    if ($store.getters.getShowOnLoadTypingIcon === true) {
      store.dispatch('actionTypingIndicator', true)
    }

    // On Init load bottom bar
    setTimeout(function () {
      appendComponent({}, NBest, 'n-best')
      if (store.getters.getIntroScreen === true) {
        appendComponent({}, IntroScreen, 'kai-component-top')
      }
    }, 0)

    inactivityWatcher()
  }

  // Extend components $mount
  const mount = Vue.prototype.$mount
  Vue.prototype.$mount = function (el, hydrating) {
    const options = this.$options

    // Check to see if component templates override
    if (options.templateOverride && typeof options.templateOverride === 'string' && options.templateOverride.charAt(0) === '#' && document.querySelector(options.templateOverride)) {
      const renderFunctions = Vue.compile(document.querySelector(options.templateOverride).innerHTML)
      Object.assign(options, renderFunctions)
    }

    return mount.call(this, el, hydrating)
  }

  /****************************************************/

  function ready (fn) {
    if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
      // run in another thread to make sure the $store variable is created.
      setTimeout(() => fn())
    } else {
      window.addEventListener('load', fn)
    }
  }

  // use the "ready" function in case the webview library script finished to be download in the DOM after the load event was triggered.
  ready(kaiInit)

  /****************************************************/

  setTimeout(() => {
    var testCafeRunning = $jq('html').attr('data-hammerhead-hovered')
    if (typeof testCafeRunning !== typeof undefined && testCafeRunning !== false) {
      require('./styles/testCafe.css')
    }
  }, 1000)

  /****************************************************/

  /* Get Webview Version  */

  /****************************************************/

  var getVersion = function (callback = () => { if (!isMobile) { document.getElementById('kai-input-prompt-area').focus() } }) {
    var buildVersion = '<b>Webview Version:</b><br>' + webviewLibraryVersion.version + ' - build ' + webviewLibraryVersion.build
    if (store.getters.getKaiApiVersion.length) {
      buildVersion += '<br><br><b>Kai API Version:</b><br>' + store.getters.getKaiApiVersion
    }
    if (store.getters.getKaiAppVersion.length) {
      buildVersion += '<br><br><b>Kai APP Version:</b><br>' + store.getters.getKaiAppVersion
    }
    if (store.getters.getKaiDataVersion.length) {
      buildVersion += '<br><br><b>Data Version:</b><br>' + store.getters.getKaiDataVersion
    }
    if (store.getters.getKaiModelVersion.length) {
      buildVersion += '<br><br><b>Model Version:</b><br>' + store.getters.getKaiModelVersion
    }
    if (store.getters.getRequestHeaders && store.getters.getRequestHeaders.assistant_name) {
      buildVersion += '<br><br><b>Assistant Name:</b><br>' + store.getters.getRequestHeaders.assistant_name
    }
    if (store.getters.getRequestHeaders && store.getters.getRequestHeaders.assistant_target) {
      buildVersion += '<br><br><b>Assistant Target:</b><br>' + store.getters.getRequestHeaders.assistant_target
    }
    addMessageContent('kai-core-components', buildVersion, TextBubble, 'kai-left', true)

    if (callback) {
      callback()
    }
  }

  /****************************************************/

  /* Get Session ID  */

  /****************************************************/

  var getSessionId = function (callback = () => { if (!isMobile) { document.getElementById('kai-input-prompt-area').focus() } }) {
    this.addMessageContent('kai-core-components', store.getters.getKaiSessionId, TextBubble, 'kai-left', true)

    if (callback) {
      callback()
    }
  }

  /****************************************************/

  /* Debug Submit Message Handler  */

  /****************************************************/
  var debugSubmitMessageHandler = function (msg) {
    if (typeof msg === 'string') {
      msg = msg.toLowerCase()
    }
    switch (msg) {
      case '__button': {
        typingIndicator(true)
        store.dispatch('actionResponseData', buttonJsonData)
        return true
      }
      case '__card': {
        typingIndicator(true)
        store.dispatch('actionResponseData', cardJsonData)
        return true
      }
      case '__card_small': {
        typingIndicator(true)
        store.dispatch('actionResponseData', cardSmallJsonData)
        return true
      }
      case '__card_gauge': {
        typingIndicator(true)
        store.dispatch('actionResponseData', cardGaugeJsonData)
        return true
      }
      case '__card_bar_chart': {
        typingIndicator(true)
        store.dispatch('actionResponseData', cardBarChartJsonData)
        return true
      }
      case '__card_bar_chart_arabic': {
        typingIndicator(true)
        store.dispatch('actionResponseData', cardBarChartArabicJsonData)
        return true
      }
      case '__piechart': {
        typingIndicator(true)
        store.dispatch('actionResponseData', mediumPieChartJsonData)
        return true
      }
      case '__piechart_arabic': {
        typingIndicator(true)
        store.dispatch('actionResponseData', mediumPieChartArabicJsonData)
        return true
      }
      case '__linechart': {
        typingIndicator(true)
        store.dispatch('actionResponseData', mediumLineChartJsonData)
        return true
      }
      case '__linechart_arabic': {
        typingIndicator(true)
        store.dispatch('actionResponseData', mediumLineChartArabicJsonData)
        return true
      }
      case '__carousel': {
        const tempCarouselData1 = JSON.parse(JSON.stringify(carouselJsonData))
        // When webview is deployed on Reporting portal need to add the BaseImagePath to hardcoded image path
        for (let n = 0; n < tempCarouselData1.message_contents[2].payload.contents.length; n++) {
          if (tempCarouselData1.message_contents[2].payload.contents[n].payload.medium.medium_url.indexOf('http') === -1) {
            if ($store.getters.isInlineImagesEnabled) {
              tempCarouselData1.message_contents[2].payload.contents[n].payload.medium.medium_url = require('@/assets/img/' + tempCarouselData1.message_contents[2].payload.contents[n].payload.medium.medium_url)
            } else {
              tempCarouselData1.message_contents[2].payload.contents[n].payload.medium.medium_url = window.$store.getters.getBaseImagePath + tempCarouselData1.message_contents[2].payload.contents[n].payload.medium.medium_url
            }
          }
        }
        typingIndicator(true)
        store.dispatch('actionResponseData', tempCarouselData1)
        return true
      }
      case '__carousel_arabic': {
        const tempCarouselData2 = JSON.parse(JSON.stringify(carouselArabicJsonData))
        // When webview is deployed on Reporting portal need to add the BaseImagePath to hardcoded image path
        for (let n = 0; n < tempCarouselData2.message_contents[2].payload.contents.length; n++) {
          if (tempCarouselData2.message_contents[2].payload.contents[n].payload.medium.medium_url.indexOf('http') === -1) {
            if ($store.getters.isInlineImagesEnabled) {
              tempCarouselData2.message_contents[2].payload.contents[n].payload.medium.medium_url = require('@/assets/img/' + tempCarouselData2.message_contents[2].payload.contents[n].payload.medium.medium_url)
            } else {
              tempCarouselData2.message_contents[2].payload.contents[n].payload.medium.medium_url = window.$store.getters.getBaseImagePath + tempCarouselData2.message_contents[2].payload.contents[n].payload.medium.medium_url
            }
          }
        }
        typingIndicator(true)
        store.dispatch('actionResponseData', tempCarouselData2)
        return true
      }
      case '__carouselmedium': {
        typingIndicator(true)
        store.dispatch('actionResponseData', carouselMediumJsonData)
        return true
      }
      case '__list': {
        const tempListData = JSON.parse(JSON.stringify(listJsonData))
        // When webview is deployed on Reporting portal need to add the BaseImagePath to hardcoded image path
        for (let o = 0; o < tempListData.message_contents[0].payload.contents.length; o++) {
          if (tempListData.message_contents[0].payload.contents[o].payload.medium !== undefined) {
            if (tempListData.message_contents[0].payload.contents[o].payload.medium && tempListData.message_contents[0].payload.contents[o].payload.medium.medium_url.indexOf('http') === -1) {
              if ($store.getters.isInlineImagesEnabled) {
                tempListData.message_contents[0].payload.contents[o].payload.medium.medium_url = require('@/assets/img/' + tempListData.message_contents[0].payload.contents[o].payload.medium.medium_url)
              } else {
                tempListData.message_contents[0].payload.contents[o].payload.medium.medium_url = window.$store.getters.getBaseImagePath + tempListData.message_contents[0].payload.contents[o].payload.medium.medium_url
              }
            }
          }
        }
        typingIndicator(true)
        store.dispatch('actionResponseData', tempListData)
        return true
      }
      case '__listgrouped': {
        const currentListMode = store.getters.getGroupedListMode
        store.state.groupedListMode = true
        typingIndicator(true)
        store.dispatch('actionResponseData', listGroupedJsonData)
        setTimeout(() => { store.state.groupedListMode = currentListMode }, 5000)
        return true
      }
      case '__quickreplies': {
        typingIndicator(true)
        store.dispatch('actionResponseData', quickrepliesJsonData)
        return true
      }
      case '__themechanger': {
        store.state.showThemeChanger = true
        return true
      }
      case '__widget': {
        store.state.useWidgetMode = !store.state.useWidgetMode
        return true
      }
      case '__typing=true': {
        typingIndicator(true)
        store.dispatch('actionTypingIndicator', true)
        return true
      }
      case '__typing=false': {
        typingIndicator(true)
        store.dispatch('actionTypingIndicator', false)
        return true
      }
      case '__textresponse': {
        typingIndicator(true)
        store.dispatch('actionResponseData', textResponseJsonData)
        return true
      }
      case '__greetings': {
        typingIndicator(true)
        store.dispatch('actionResponseData', greetingsJsonData)
        return true
      }
      case '__textstreamevent': {
        typingIndicator(true)
        for (let m = 0; m <= textStreamEvent.length - 1; m++) {
          (function (x) {
            setTimeout(() => {
              store.dispatch('actionResponseData', textStreamEvent[x])
            }, x * 1 * 1000)
          })(m)
        }
        return true
      }
      case '__multiple_responses': {
        $store.dispatch('actionInputMessage', 'Text Stream')
        setTimeout(() =>
          store.dispatch('actionResponseData', multipleResponsesJsonData1)
        , 1)
        setTimeout(() =>
          store.dispatch('actionResponseData', multipleResponsesJsonData2)
        , 2)
        setTimeout(() =>
          store.dispatch('actionResponseData', multipleResponsesJsonData3)
        , 3)
        setTimeout(() =>
          store.dispatch('actionResponseData', multipleResponsesJsonData4)
        , 4)
        setTimeout(() =>
          store.dispatch('actionResponseData', multipleResponsesJsonData5)
        , 5)
        return true
      }
      case '__map': {
        mapLocation()
        return true
      }
      case '__popup_idle': {
        let parsedPayload = JSON.parse(JSON.stringify(popupIdleJsonData))
        parsedPayload = (parsedPayload.message_contents[0].payload.data)
          ? parsedPayload.message_contents[0].payload.data
          : parsedPayload.message_contents[0].payload.payload
        inlinePopup(parsedPayload, 'INACTIVITY')
        return true
      }
      case '__popup_end_livechat': {
        inlinePopup(JSON.parse(JSON.stringify(popupEndLivechatJsonData)).message_contents[0].payload.payload, 'LIVECHAT_END_SESSION_CONFIRMATION')
        return true
      }
      case '__popup_minimize': {
        let parsedPayload = JSON.parse(JSON.stringify(popupMinimizeJsonData))
        parsedPayload = (parsedPayload.message_contents[0].payload.data)
          ? parsedPayload.message_contents[0].payload.data
          : parsedPayload.message_contents[0].payload.payload
        inlinePopup(parsedPayload, 'MINIMIZE')
        return true
      }
      case '__datepicker': {
        const tempDatepickerData = JSON.parse(JSON.stringify(datepickerJsonData))
        // When webview is deployed on Reporting portal need to add the BaseImagePath to hardcoded image path
        if ($store.getters.isInlineImagesEnabled) {
          tempDatepickerData.quick_replies[0].image_url = require('@/assets/img/' + tempDatepickerData.quick_replies[0].image_url)
        } else {
          tempDatepickerData.quick_replies[0].image_url = window.$store.getters.getBaseImagePath + tempDatepickerData.quick_replies[0].image_url
        }
        typingIndicator(true)
        store.dispatch('actionResponseData', tempDatepickerData)
        return true
      }
      case '__kaianswers':
      case '__kaianswer':
      case '__customkaianswers':
      case '__customkaianswer': {
        typingIndicator(true)
        store.dispatch('actionResponseData', customKaiAnswersJsonData)
        return true
      }
      case '__customkim': {
        const customKIMArray = [customKIMMediumJsonData, customKIMCardChartJsonData, customKIMCarouselChartJsonData]

        for (let i = 0; i <= customKIMArray.length - 1; i++) {
          (function (x) {
            setTimeout(() => {
              store.dispatch('actionResponseData', customKIMArray[x])
            }, x * 500)
          })(i)
        }
        return true
      }
      case '__customconcierge': {
        typingIndicator(true)
        store.dispatch('actionResponseData', customConciergeCardJsonData)
        return true
      }
      case '__customconciergecarousel': {
        typingIndicator(true)
        store.dispatch('actionResponseData', customConciergeCarouselJsonData)
        return true
      }
      case '__debug=false': {
        debugMode(false)
        $jq('.debug-end-line').remove()
        $jq('.debug-content-container').fadeOut(1000, () => {
          $jq(this).remove()
        })
        return true
      }

      case '__selectcarousel': {
        const tempCarouselData = JSON.parse(JSON.stringify(selectCarouselJsonData))
        // When webview is deployed on Reporting portal need to add the BaseImagePath to hardcoded image path
        for (let k = 0; k < tempCarouselData.message_contents[0].payload.options.length; k++) {
          if (tempCarouselData.message_contents[0].payload.options[k].payload.medium.medium_url.indexOf('http') === -1) {
            if ($store.getters.isInlineImagesEnabled) {
              tempCarouselData.message_contents[0].payload.options[k].payload.medium.medium_url = require('@/assets/img/' + tempCarouselData.message_contents[0].payload.options[k].payload.medium.medium_url)
            } else {
              tempCarouselData.message_contents[0].payload.options[k].payload.medium.medium_url = window.$store.getters.getBaseImagePath + tempCarouselData.message_contents[0].payload.options[k].payload.medium.medium_url
            }
          }
        }
        typingIndicator(true)
        store.dispatch('actionResponseData', tempCarouselData)
        return true
      }
      case '__selectlist': {
        const tempSelectListData = JSON.parse(JSON.stringify(selectListJsonData))
        // When webview is deployed on Reporting portal need to add the BaseImagePath to hardcoded image path
        for (let p = 0; p < tempSelectListData.message_contents[0].payload.options.length; p++) {
          if (tempSelectListData.message_contents[0].payload.options[p].payload.medium.medium_url.indexOf('http') === -1) {
            if ($store.getters.isInlineImagesEnabled) {
              tempSelectListData.message_contents[0].payload.options[p].payload.medium.medium_url = require('@/assets/img/' + tempSelectListData.message_contents[0].payload.options[p].payload.medium.medium_url)
            } else {
              tempSelectListData.message_contents[0].payload.options[p].payload.medium.medium_url = window.$store.getters.getBaseImagePath + tempSelectListData.message_contents[0].payload.options[p].payload.medium.medium_url
            }
          }
        }
        typingIndicator(true)
        store.dispatch('actionResponseData', tempSelectListData)
        return true
      }
      case '__selectchecklist': {
        typingIndicator(true)
        store.dispatch('actionResponseData', selectChecklistJsonData)
        return true
      }
      case '__livechat': {
        for (let m = 0; m <= liveChatJsonData.length - 1; m++) {
          (function (x) {
            setTimeout(() => {
              store.dispatch('actionResponseData', liveChatJsonData[x])
            }, x * 8 * 1000)
          })(m)
        }
        return true
      }
      case '__webrtc': {
        for (let m = 0; m <= liveChatWebRTCJsonData.length - 1; m++) {
          (function (x) {
            setTimeout(() => {
              store.dispatch('actionResponseData', JSON.parse(JSON.stringify(liveChatWebRTCJsonData[x])))
            }, x * 1 * 1000)
          })(m)
        }
        return true
      }
      case '__livechat_notifications': {
        // liveChatNotificationsJsonData.forEach(data => {
        //   setTimeout(() => {
        //     store.dispatch('actionResponseData', data)
        //   }, 3000)
        // })
        for (let m = 0; m <= liveChatNotificationsJsonData.length - 1; m++) {
          (function (x) {
            setTimeout(() => {
              store.dispatch('actionResponseData', liveChatNotificationsJsonData[x])
            }, 3000)
          })(m)
        }
        return true
      }
      case '__nbest': {
        typingIndicator(true)
        store.dispatch('actionResponseData', nbestJsonData)
        return true
      }
      case '__nbest_bottom': {
        typingIndicator(true)
        store.dispatch('actionResponseData', nbestBottomJsonData)
        return true
      }
      case '__related_question':
      case '__nbest_related_questions':
      case '__nbest_rq':
      case '__related_questions': {
        typingIndicator(true)
        store.dispatch('actionResponseData', nbestRelatedQuestions)
        return true
      }
      case '__rating_binary': {
        typingIndicator(true)
        store.dispatch('actionResponseData', ratingJsonData)
        return true
      }
      case '__rating_five': {
        typingIndicator(true)
        store.dispatch('actionResponseData', ratingFiveJsonData)
        return true
      }
      case '__rating_ten': {
        typingIndicator(true)
        store.dispatch('actionResponseData', ratingTenJsonData)
        return true
      }
      case '__link': {
        typingIndicator(true)
        store.dispatch('actionResponseData', linkJsonData)
        return true
      }
      case '__search': {
        typingIndicator(true)
        store.dispatch('actionResponseData', searchQuickReplyJsonData)
        return true
      }
      case '__search_custom': {
        typingIndicator(true)
        store.dispatch('actionResponseData', searchCustomJsonData)
        return true
      }
      case '__avatar_active':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarActiveJsonData)))
        return true
      }
      case '__avatar_chat_icon':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarChatIconJsonData)))
        return true
      }
      case '__avatar_checkmark':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarCheckmarkJsonData)))
        return true
      }
      case '__avatar_question':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarQuestionJsonData)))
        return true
      }
      case '__avatar_transaction':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarTransactionJsonData)))
        return true
      }
      case '__avatar_playground':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarPlaygroundJsonData)))
        return true
      }
      case '__avatar_idle':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarIdleJsonData)))
        return true
      }
      case '__avatar_splash2':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarSplash2JsonData)))
        return true
      }
      case '__avatar_leaving':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarLeavingJsonData)))
        return true
      }
      case '__avatar_notification':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarNotificationJsonData)))
        return true
      }
      case '__avatar_sorry':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarSorryJsonData)))
        return true
      }
      case '__avatar_thank_you':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarThankYouJsonData)))
        return true
      }
      case '__avatar_voice_start':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarVoiceStartJsonData)))
        return true
      }
      case '__avatar_voice_loop':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarVoiceLoopJsonData)))
        return true
      }
      case '__avatar_voice_end':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarVoiceEndJsonData)))
        return true
      }
      case '__avatar_agent_start':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarAgentStartJsonData)))
        return true
      }
      case '__avatar_agent_end':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarAgentEndJsonData)))
        return true
      }
      case '__avatar_queue':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarQueueWaitingLoopJsonData)))
        return true
      }
      case '__avatar_agent_connecting':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarAgentConnectingLoopJsonData)))
        return true
      }
      case '__avatar_agent_connected':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarAgentConnectedLoopJsonData)))
        return true
      }
      case '__avatar_agent_disconnected':
      {
        store.dispatch('actionResponseData', JSON.parse(JSON.stringify(avatarAgentDisconnectedLoopJsonData)))
        return true
      }
      case '__shortcuts':
      {
        store.dispatch('actionResponseData', shortcutPanelJsonData)
        return true
      }
      case '__testcasemultimedium': {
        typingIndicator(true)
        store.dispatch('actionResponseData', testcaseMultiMedium)
        return true
      }
      case '__list2': {
        typingIndicator(true)
        store.dispatch('actionResponseData', list2JsonData)
        return true
      }
      case '__transcript': {
        typingIndicator(true)
        kserver.CoreAPI.handleSessionTranscript(transcriptData)
        return true
      }
      case '__no_message':
      case '__no_message_content': {
        store.dispatch('actionResponseData', noMessageContentJsonData)
        return true
      }
      default: {
        return false
      }
    }
  }

  /****************************************************/

  /* Submit Message  */

  /****************************************************/
  var sendUserMessage = (msg, callback, optionalDisplayText, optionalInputType) => {
    submitMessage(msg, callback, optionalDisplayText, optionalInputType)
  }

  var sendHiddenUserMessage = (msg, callback) => {
    let isDebugMessage = false
    if (store.getters.getDebugMode) {
      isDebugMessage = debugSubmitMessageHandler(msg)
    }
    if (!isDebugMessage) {
      // Remove Quick Replies
      removeQuickReplies()
      removeNbestBottom()

      typingIndicator(true)

      // Send Request Message to API
      kserver.CoreAPI.sendMessage(msg)
    }
    if (callback) {
      callback()
    }
  }

  var sendHiddenUserMessageWithMetaFields = (msg, metaFields, callback) => {
    let isDebugMessage = false
    if (store.getters.getDebugMode) {
      isDebugMessage = debugSubmitMessageHandler(msg)
    }
    if (!isDebugMessage) {
      // Remove Quick Replies
      removeQuickReplies()
      removeNbestBottom()

      typingIndicator(true)

      // Send Request Message to API
      kserver.CoreAPI.sendMessageWithMetaFields(msg, metaFields)
    }
    if (callback) {
      callback()
    }
  }

  var sendHiddenPostbackMessage = (buttonObj, callback) => {
    let message

    removeQuickReplies()
    removeNbestBottom()
    typingIndicator(false)
    $store.dispatch('actionIsKeywordsClicked', true)

    if (buttonObj.payload && buttonObj.payload.trim() !== '') {
      message = buttonObj.payload
    } else if (buttonObj.display_text && buttonObj.display_text.trim() !== '') {
      message = buttonObj.display_text
      optionalDisplayText = ''
    } else if (buttonObj.label && buttonObj.label.trim() !== '') {
      message = buttonObj.label
    } else {
      message = buttonObj
    }
    kserver.CoreAPI.sendMessage(message, 'POSTBACK', null, null)

    if (callback) {
      callback()
    }
  }

  var submitMessage = (msg, callback, optionalDisplayText, optionalInputType, optionalMetaFields) => {
    let isDebugMessage = false
    let callbackForTypingIndicator = null
    if (store.getters.getDebugMode) {
      isDebugMessage = debugSubmitMessageHandler(msg)
    }
    if (!isDebugMessage) {
      store.dispatch('actionSendMessage', true)
      if (optionalDisplayText && optionalDisplayText.trim() !== '') {
        store.dispatch('actionInputMessage', optionalDisplayText)
      } else {
        store.dispatch('actionInputMessage', msg)
      }
      if (!store.getters.isLiveChatStarted) {
        typingIndicatorPendingUserMessageResponse = true
        typingIndicator(true)
        callbackForTypingIndicator = () => {
          typingIndicatorPendingUserMessageResponse = false
        }
      }

      // Send Request Message to API
      kserver.CoreAPI.sendMessage(msg, optionalInputType, optionalMetaFields, callbackForTypingIndicator)

      // Remove Quick Replies
      removeQuickReplies()
      removeNbestBottom()
      closeShortcutPanel()
    }
    if (callback) {
      callback()
    }
  }

  var submitSelection = (msg, callback) => {
    let isDebugMessage = false
    if (store.getters.getDebugMode) {
      isDebugMessage = debugSubmitMessageHandler(msg)
    }
    if (!isDebugMessage) {
      if (msg && msg.length) {
        // Send Request Message to API
        kserver.CoreAPI.sendMessage(msg, 'SELECT')

        // Remove Quick Replies
        removeQuickReplies()
        removeNbestBottom()
        closeShortcutPanel()
      }
    }
    if (callback) {
      callback()
    }
  }

  var submitIntent = (intent, optionalLabel, callback) => {
    store.dispatch('actionSendMessage', true)
    if (optionalLabel && optionalLabel !== '') {
      store.dispatch('actionInputMessage', optionalLabel)
    }
    typingIndicator(true)

    // Send Intent request to API
    kserver.CoreAPI.sendIntent(intent.name, intent.user_inputs)

    // Remove Quick Replies
    removeQuickReplies()
    removeNbestBottom()
    closeShortcutPanel()
    if (callback) {
      callback()
    }
  }

  var sendRequest = (request, callback) => {
    let isDebugMessage = false
    if (store.getters.getDebugMode) {
      if (request.payload && request.payload.text) {
        const msg = request.payload.text
        isDebugMessage = debugSubmitMessageHandler(msg)
      }
    }
    if (!isDebugMessage) {
      store.dispatch('actionSendMessage', true)
      if (request.payload && request.payload.text) {
        store.dispatch('actionInputMessage', request.payload.text)
      }
      typingIndicator(true)

      // Send Request Message to API
      kserver.CoreAPI.sendRequest(request)

      // Remove Quick Replies
      removeQuickReplies()
      removeNbestBottom()
      closeShortcutPanel()
    }
    if (callback) {
      callback()
    }
  }

  var sendHiddenRequest = (request, callback) => {
    let isDebugMessage = false
    if (store.getters.getDebugMode) {
      isDebugMessage = debugSubmitMessageHandler(request)
    }
    if (!isDebugMessage) {
      // Send Request Message to API
      kserver.CoreAPI.sendRequest(request)

      // Remove Quick Replies
      removeQuickReplies()
      removeNbestBottom()
    }
    if (callback) {
      callback()
    }
  }

  var sendEnterTokenEvent = (token, callback, optionalIntent) => {
    kserver.CoreAPI.sendEnterTokenEvent(token, callback, optionalIntent)
  }

  var sendAuthPassThruEvent = (metaFieldsArray, callback, optionalIntent) => {
    kserver.CoreAPI.sendAuthPassThruEvent(metaFieldsArray, callback, optionalIntent)
  }

  var sendProcessOAuthEvent = (token, callback, optionalIntent) => {
    kserver.CoreAPI.sendProcessOAuthEvent(token, callback, optionalIntent)
  }

  var sendGenericEvent = function (type, metaFieldsArray, callback, optionalIntent) {
    kserver.CoreAPI.sendGenericEvent(type, metaFieldsArray, callback, optionalIntent)
  }

  var startPullService = () => {
    if (store.getters.getKaiSessionId && store.getters.getKaiSessionId.length > 0) {
      store.dispatch('actionPullServiceStart')
      kserver.CoreAPI.sendPullRequest()
    } else {
      // keep trying to start the pull service until there is a session_ID
      setTimeout(() => {
        Kai.Core.startPullService()
      }, store.getters.getPullServiceErrorSleepTimer * 1000)
    }
  }

  var stopPullService = () => {
    store.state.pullServiceState = false
    store.dispatch('actionPullServiceStop')
    kserver.CoreAPI.abortPullRequest()
  }

  var sendUserTypingStateToLiveChat = () => {
    kserver.CoreAPI.sendLiveChatCustomerStateEvent()
  }

  var getHistory = (callback) => {
    kserver.CoreAPI.getHistory(callback)
  }

  var logoutSession = (callback) => {
    kserver.CoreAPI.logoutSession(callback)
  }

  var sendUIEvent = (type, elementName, action, description, additionalFields) => {
    if ($store.state.enableUIEvents) {
      const json = { element_name: elementName, action: action, description: description, ...additionalFields }
      kserver.CoreAPI.sendUIEvent(type, json)
    }
  }

  var sendError = (error) => {
    if (!$store.state.debug && $store.state.enableUIEvents) {
      const json = { name: error.name, message: error.message, stack: error.stack, webview_version: `${webviewLibraryVersion.version} - build ${webviewLibraryVersion.build}` }
      kserver.CoreAPI.sendUIEvent('webview_js_error', json)
    }
  }

  Vue.config.errorHandler = (err, vm, info) => {
    // err: error trace
    // vm: component in which error occurred
    // info: Vue specific error information such as lifecycle hooks, events etc.
    if (!$store.state.debug && $store.state.enableUIEvents) {
      const json = { component_name: vm.$options._componentTag, info: info, error_message: err.message, error_stack: err.stack, webview_version: `${webviewLibraryVersion.version} - build ${webviewLibraryVersion.build}` }
      kserver.CoreAPI.sendUIEvent('vue_component_error', json)
    }
    console.error(vm.$options._componentTag, err)
  }

  /****************************************************/

  /* Button Pressed */

  /****************************************************/

  var buttonPressed = function (buttonObj, callback = () => { if (!isMobile) { document.getElementById('kai-input-prompt-area').focus() } }) {
    if (buttonObj) {
      let overrideWebviewDefaultBehavior = false
      const deeplinkConfig = ConvertKeysToUpperCase(store.getters.getDeepLinkHandleBehaviour)
      const upperButtonType = buttonObj.type.toUpperCase()

      if (deeplinkConfig && deeplinkConfig[upperButtonType] !== undefined) {
        // if we reach here it means this specific button type is condigured
        overrideWebviewDefaultBehavior = deeplinkConfig[upperButtonType]
      } else if (deeplinkConfig === true) {
        // if we reach here it means all the button types behaviour will be handled by customer
        overrideWebviewDefaultBehavior = true
      }
      if (!overrideWebviewDefaultBehavior) {
        if (upperButtonType === 'DEEPLINK') {
          deepLink(buttonObj)
        } else if (upperButtonType === 'LOCATION') {
          mapLocation()
        } else if (upperButtonType === 'DATE') {
          addDatePicker()
        } else if (upperButtonType === 'POSTBACK' || upperButtonType === 'TEXT') {
          postBackMessage(buttonObj, false)
        } else if (upperButtonType === 'TEXT_FOLLOWUP') {
          Kai.API.sendUserMessage(buttonObj.payload, false, buttonObj.question_display_text)
        } else if (upperButtonType === 'HYPERLINK') {
          window.open(buttonObj.payload, '_blank')
        } else if (upperButtonType === 'CAPI') {
          const href = new URL(window.location.href)
          if (buttonObj.payload && buttonObj.payload.length > 0) {
            // type CAPI should provide a new session ID in the payload
            href.searchParams.set('sessionID', buttonObj.payload)
          } else {
            // in case new session ID is not provided, make sure no session ID is passed in URL param
            href.searchParams.delete('sessionID')
          }
          href.searchParams.set('initMode', 'capi_redirect')
          window.open(href, '_blank')
        } else if (upperButtonType === 'INTENT') {
          if (buttonObj.intent) {
            submitIntent(buttonObj.intent, buttonObj.label)
          } else {
            submitIntent(buttonObj.payload, buttonObj.label)
          }
        } else if (upperButtonType === 'LOGIN') {
          window.open(buttonObj.payload, '_blank')
        } else if (upperButtonType === 'CALL') {
          const payload = 'tel://' + buttonObj.payload
          const win = window.open(payload, '_blank')
          win.focus()
        } else if (upperButtonType === 'WEB_SEARCH' || upperButtonType === 'WEB_SEARCH_GOOGLE' || upperButtonType === 'WEB_SEARCH_SILVERCLOUD') {
          addContentButton(buttonObj, 'kai-core-components', CustomSearch)
          removeQuickReplies()
          removeNbestBottom()
        } else if (upperButtonType === 'WEB_SEARCH_CONTENT') {
          addContentButton(buttonObj, 'kai-core-components', CustomSearchDetails)
          removeQuickReplies()
          removeNbestBottom()
        } else if (upperButtonType === 'MINIMIZE_WEBVIEW') {
          minimizeWebviewWidget()
        } else {
          deepLink(buttonObj)
        }
      } else {
        // TODO: Add more button types here default to deepLink for now
        deepLink(buttonObj)
      }
    }
    if (callback) {
      callback()
    }
  }

  /****************************************************/

  /* Post Back Message  */

  /****************************************************/

  var postBackMessage = (buttonObj, callback, optionalInputType) => {
    let message
    let optionalDisplayText
    if (buttonObj.payload && buttonObj.payload.trim() !== '') {
      message = buttonObj.payload
    } else if (buttonObj.display_text && buttonObj.display_text.trim() !== '') { // Check for quick_reply postbacks
      message = buttonObj.display_text
      optionalDisplayText = ''
    } else if (buttonObj.label && buttonObj.label.trim() !== '') {
      message = buttonObj.label
    } else {
      message = buttonObj
    }
    if (buttonObj.question_display_text && buttonObj.question_display_text.trim() !== '') {
      optionalDisplayText = buttonObj.question_display_text
    } else if (buttonObj.display_text) {
      optionalDisplayText = buttonObj.display_text
    } else if (buttonObj.label) {
      optionalDisplayText = buttonObj.label
    }
    if (optionalInputType) {
      Kai.API.sendUserMessage(message, callback, optionalDisplayText, optionalInputType)
    } else if (isApiVersionAtLeast(3.2)) {
      Kai.API.sendUserMessage(message, callback, optionalDisplayText, 'POSTBACK')
    } else {
      Kai.API.sendUserMessage(message, callback, optionalDisplayText)
    }
  }

  /****************************************************/

  /* Deep Link  */

  /****************************************************/

  var deepLink = (buttonObj) => {
    passToNativeMethod('webviewFunctionForNative:', buttonObj)
  }

  /****************************************************/

  /* Location  */

  /****************************************************/

  var locationPressed = () => {
    if (store.getters.getUseNativeGPS) {
      passToNativeMethod('webviewFunctionForNative:', { type: 'LOCATION' })
    } else {
      getLocation((position) => {
        sendLocation(position)
      })
    }
  }

  var getLocation = (callback) => {
    var options = {
      enableHighAccuracy: true,
      timeout: 15000,
      maximumAge: 0
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          callback(pos)
          store.state.defaultMapLocation = pos
        },
        (err) => {
          locationError(err)
        },
        options
      )
    } else {
      // console.log('Geolocation is not supported by this browser.')
      return false
    }
  }

  var locationError = (error) => {
    console.warn('ERROR(' + error.code + '): ' + error.message)
    typingIndicator(false)
  }

  var sendLocation = (pos) => {
    typingIndicator(true)
    const crd = pos.coords
    // var locationText = 'Location Found! Latitude ' + crd.latitude + ', longitude ' + crd.longitude
    // console.log(locationText)

    // How to handle Location data
    let locationInput = store.getters.getLocationInputFormat
    locationInput = locationInput.replace('{latitude}', crd.latitude)
    locationInput = locationInput.replace('{longitude}', crd.longitude)
    const payload = locationInput

    // Show location data
    // addMessageContent('kai-core-components', payload, TextBubble, 'kai-right', true);

    sendHiddenUserMessage(payload)
  }

  /****************************************************/

  /* Switch Typing Indicator for Keywords  */

  /****************************************************/

  var switchTypingIndicatorKeywords = (switchToTrue) => {
    if (switchToTrue) {
      typingIndicatorPendingKeywordsResponse = true
      typingIndicator(true)
    } else {
      typingIndicatorPendingKeywordsResponse = false
      typingIndicator(false)
    }
  }
  

  /****************************************************/

  /* Date Picker  */

  /****************************************************/

  var addDatePicker = () => {
    appendComponent({}, DatePicker, 'kai-core-components')
  }

  /****************************************************/

  /* Debug  */

  /****************************************************/

  var debugMode = function (toggle, callback) {
    if (toggle) {
      // Update debug mode in store.js true
      store.dispatch('actionDebugMode', true)
      handleResponse(debugDocJsonData)
      $jq('.kai-debug-container').slideDown('slow', () => {
        // Animation complete.
      })
    } else {
      // Update debug mode in store.js false
      store.dispatch('actionDebugMode', false)
      // Remove from the DON
      $jq('.kai-debug-container').slideUp('slow', () => {
        // Animation complete.
        $jq(this).remove()
      })
    }

    if (callback) {
      callback()
    }

    return false
  }

  /****************************************************/

  /* Add Message Bubble  */

  /****************************************************/

  var addMessageContent = (element, msg, component, position, showIcon, callback) => {
    // Slot obj to pass to the append component function
    const slots = {
      message: msg,
      position: position, // Left or Right
      showIcon: showIcon
    }
    // Append Dynamic Component to Container Vue component
    appendComponent(slots, component, element)
    // Update store send message
    store.dispatch('actionSendMessage', false)
    // Callback function
    if (callback) {
      callback()
    }
  }

  /****************************************************/

  /* Add Container Card  */

  /****************************************************/

  var addCardContainer = (data, element, component, showIcon, showTypingIndicator) => {
    if (showTypingIndicator) {
      switchTypingIndicatorKeywords(true)
    }
    data.showIcon = showIcon
    appendComponent(data, component, element)
    if (showTypingIndicator) { // Turn of typing indicator just in case it fails to turn off
      setTimeout(() => {
        switchTypingIndicatorKeywords(false)
      }, 500)
    }
  }

  /****************************************************/

  /* Add Content Button  */

  /****************************************************/

  var addContentButton = (data, element, component) => {
    appendComponent(data, component, element)
  }

  /****************************************************/

  /* Add / Remove Quick Replies  */

  /****************************************************/

  var addQuickReplies = (response, onCompletion) => {
    // Make sure we remove previous Quick Replies if any
    removeQuickReplies()

    // Append Dynamic Component to Container Vue component
    $jq.each(response.quick_replies, (index, value) => {
      // Text
      if (response.quick_replies[index].display_text === '👍' || response.quick_replies[index].display_text === '👎') {
        if (!response.quick_replies[index].label) {
          response.quick_replies[index].label = getThumbsImage(response.quick_replies[index].display_text, true)
        }
        response.quick_replies[index].display_text = getThumbsImage(response.quick_replies[index].display_text)
      }
      //  Label
      if (response.quick_replies[index].label === '👍' || response.quick_replies[index].label === '👎') {
        if (!response.quick_replies[index].display_text) {
          response.quick_replies[index].display_text = getThumbsImage(response.quick_replies[index].label)
        }
        response.quick_replies[index].label = getThumbsImage(response.quick_replies[index].label, true)
      }
      if (response.quick_replies[index].type === 'TEXT_FOLLOWUP' || response.quick_replies[index].style === 'FOLLOWUP') {
        response.quick_replies[index].isDynamic = true
      }
    })
    $jq('#kai-qr-bottom-bar').show()
    appendComponent(response.quick_replies, QuickReplies, 'kai-qr-bottom-bar')
  }

  var getThumbsImage = (text, useDark) => {
    let darkSuffix = ''
    if (useDark) {
      darkSuffix = '-dark'
    }
    if (text === '👍') {
      let src
      if (!$store.getters.isInlineImagesEnabled) {
        src = store.getters.getBaseImagePath + 'thumb-up' + darkSuffix + '.png'
      } else {
        src = require('@/assets/img/' + 'thumb-up' + darkSuffix + '.png')
      }
      return '<img class="thumb-icon thumb-up" ondragstart="return false;" src="' + src + '" width="20">'
    }
    if (text === '👎') {
      let src
      if (!$store.getters.isInlineImagesEnabled) {
        src = store.getters.getBaseImagePath + 'thumb-down' + darkSuffix + '.png'
      } else {
        src = require('@/assets/img/' + 'thumb-down' + darkSuffix + '.png')
      }
      return '<img class="thumb-icon thumb-down" ondragstart="return false;" src="' + src + '" width="20">'
    }
  }

  var removeQuickReplies = () => {
    $jq('#kai-qr-bottom-bar').hide()
    $jq('.quickreplies').remove()
  }

  var removeNbestBottom = () => {
    $store.dispatch('actionNbestBottomPanel', undefined)
  }

  /****************************************************/

  /* Check If CAPI Response Contains Avatar */

  /****************************************************/

  var checkIfContainsAvatar = (response) => {
    if (response && response.message_contents) {
      for (let i = 0; i < response.message_contents.length; i++) {
        if (checkIfMessageContentContainsAvatar(response.message_contents[i])) {
          return true
        }
      }
    }
    return false
  }

  var checkIfMessageContentContainsAvatar = (currentMessageContent) => {
    if (currentMessageContent && currentMessageContent.type) {
      if (currentMessageContent.type === 'EVENT') {
        // EVENT may contains message_content with Avatar so we use recursivity
        if (checkIfContainsAvatar(currentMessageContent.payload)) {
          return true
        }
      }
      const useCustomAvatar = currentMessageContent.type === 'CUSTOM' &&
        currentMessageContent.payload.type === 'AVATAR'
      if (currentMessageContent.type === 'AVATAR' || useCustomAvatar) {
        return true
      }
    }
    return false
  }

  /****************************************************/

  /* Check If CAPI Response Contains ONLY Shortcut  */

  /****************************************************/

  var checkIfContainsOnlyShortcuts = (response) => {
    let containsShortcuts = false
    if (response && response.message_contents) {
      for (let i = 0; i < response.message_contents.length; i++) {
        const isCustomerShortcuts = response.message_contents[i].type &&
          response.message_contents[i].payload &&
          response.message_contents[i].payload.type &&
          response.message_contents[i].type === 'EVENT' &&
          response.message_contents[i].payload.type === 'CUSTOMER_SHORTCUTS'
        const isEmptyEvent = response.message_contents[i].type &&
          response.message_contents[i].type === 'EVENT' &&
          (response.message_contents[i].payload === null || response.message_contents[i].payload === undefined)
        if (!isCustomerShortcuts && !isEmptyEvent) {
          // return false as soon as a message content is not a CUSTOMER_SHORTCUTS
          return false
        } else {
          containsShortcuts = true
        }
      }
    }
    return containsShortcuts
  }

  var checkIfContainsOnlyRedirect = (response) => {
    let containsRedirect = false
    if (response && response.message_contents) {
      for (let i = 0; i < response.message_contents.length; i++) {
        const isRedirect = response.message_contents[i].type &&
          response.message_contents[i].type === 'REDIRECT'
        const isEmptyEvent = response.message_contents[i].type &&
          response.message_contents[i].type === 'EVENT' &&
          (response.message_contents[i].payload === null || response.message_contents[i].payload === undefined)
        if (!isRedirect && !isEmptyEvent) {
          // return false as soon as a message content is not a REDIRECT
          return false
        } else {
          containsRedirect = true
        }
      }
    }
    return containsRedirect
  }

  /****************************************************/

  /* Check If CAPI Response Contains ONLY Livechat Typing event  */

  /****************************************************/

  var isLastMessageAgentTyping = (response) => {
    const agentTyping = false

    if (response && response.message_contents && response.message_contents.length > 0) {
      const lastResponse = response.message_contents[response.message_contents.length - 1]
      const containsTyping = lastResponse.type && lastResponse.type === 'EVENT' &&
        lastResponse.payload && lastResponse.payload.type &&
        lastResponse.payload.type === 'LIVE_CHAT_SEND_AGENT_STATE' &&
        lastResponse.payload.payload && lastResponse.payload.payload.is_typing !== undefined
      if (containsTyping) {
        return true
      }
    }
    return agentTyping
  }

  /****************************************************/

  /* Check If Event is type TEXT_STREAM_END  */

  /****************************************************/
  const endTextStream = (response) => {
    if ('message_contents' in response) {
      response.message_contents.forEach(msg => {
        if ('payload' in msg && 'payload' in msg.payload && msg.payload.payload.type === 'STREAM_END') {
          if ('data' in msg.payload.payload && 'stream_id' in msg.payload.payload.data) {
            delete store.state.pendingTextStream[msg.payload.payload.data.stream_id]
          }
        }
      })
    }
  }

  /****************************************************/

  /* Check If Event is type COMPLETE  */

  /****************************************************/
  const completeTextStream = (response) => {
    if ('message_contents' in response) {
      response.message_contents.forEach(msg => {
        if ('type' in msg && msg.type === 'CUSTOM') {
          if ('payload' in msg && msg.payload.type === 'TEXT_STREAM_COMPLETE') {
            switchTypingIndicatorKeywords(false)
          }
        }
      })
    }
  }

  /****************************************************/

  /* Handle CAPI Request  */

  /****************************************************/

  var handleCAPIRequest = (functionName, response) => {
    // console.log('handleCAPIRequest', functionName, response)
    passToNativeWebviewCAPIRequestMethod({ name: functionName, response: response })
  }

  /****************************************************/

  /* Handle CAPI Response  */

  /****************************************************/

  var handleCAPIResponse = (functionName, response) => {
    // console.log('handleCAPIResponse', functionName, response)
    passToNativeWebviewCAPIResponseMethod({ name: functionName, response: response })
  }

  /****************************************************/

  /* Handle CAPI Error  */

  /****************************************************/

  var handleCAPIError = (functionName, response) => {
    // console.log('handleCAPIError', functionName, response)
    passToNativeWebviewCAPIErrorMethod({ name: functionName, response: response })
  }

  /****************************************************/

  /* Main Engine - Handle Response Data and pass to requested component to display content in the view   */

  /****************************************************/

  var handleResponse = async function (response, optionalMessageDelayTimer, optionalDisableScrolling, optionalIsSessionTranscript) {
    try {
      const _this = this
      // Disable textbox and send button when message sent
      // console.log('handleResponse', response)
      const containsOnlyShortcuts = checkIfContainsOnlyShortcuts(response)
      const containsOnlyRedirect = checkIfContainsOnlyRedirect(response)
      if (!containsOnlyShortcuts && !containsOnlyRedirect && !optionalIsSessionTranscript && !optionalDisableScrolling && response.message_contents && response.message_contents.length > 0) {
        store.dispatch('actionRenderingStatus', 'started')
      } else if (containsOnlyShortcuts || containsOnlyRedirect || !response.message_contents) {
        store.dispatch('actionRenderingStatus', 'started-no-scroll')
      }

      updateSessionCookie()
      resetInactivityTimer()

      if (!isValidResponse(response) || response.status.code === STATUS_OK_NO_CONTENTS) {
        await store.dispatch('actionRenderingStatus', 'idle')
        await store.dispatch('actionRenderingStatus', 'finished')
        return
      }

      // Get response from Screen Reader
      accessibilityContentScreenReader(response)

      // Update session id if available from response
      if (response.context && response.context.user && response.context.user.session_id) {
        store.dispatch('actionKaiSessionId', response.context.user.session_id)
      }

      const responseContainsKaiAnswersSuggestions = checkIfResponseContainsKaiAnswersSuggestions(response)
      if (!responseContainsKaiAnswersSuggestions && response.message_contents && response.message_contents.length > 0 && !containsOnlyShortcuts && !containsOnlyRedirect) {
        removeNbestBottom()
      }

      var messageTextCounter = 0
      nextTask(_this, 0, response, optionalMessageDelayTimer, optionalDisableScrolling, optionalIsSessionTranscript, messageTextCounter)
    } catch (error) {
      sendError(error)
      console.error(error)
    }
  }

  /****************************************************/

  /* NEXT TASK AFTER MESSENGER CONTENT ENDS */

  /****************************************************/

  async function nextTask (_this, counter, response, optionalMessageDelayTimer, optionalDisableScrolling, optionalIsSessionTranscript, messageTextCounter) {
    try {
      var messageContentLength = -1
      if (response.message_contents) {
        messageContentLength = response.message_contents.length
      } else {
        // No message contents so hide typing indicator
        typingIndicator(false)
      }

      const containsQuickReplies = response.quick_replies && response.quick_replies.length > 0
      const showQuickReplies = (counter === messageContentLength - 1 || messageContentLength < 0) && containsQuickReplies
      const responseContainsAvatar = checkIfContainsAvatar(response)

      // If response contains message content
      if (messageContentLength > 0) {
        // Message Text
        if (response.message_contents[counter].type === 'TEXT') {
          if (!optionalDisableScrolling) {
            typingIndicator(true)
          }
        }
        if (counter === 0) {
          // Add Chatbot timestamp except for first message of the session or if the response contains only customer_shortcuts
          if (document.getElementById('kai-core-components').children.length > 0 && !checkIfContainsOnlyShortcuts(response) && !checkIfContainsOnlyRedirect(response)) {
            if (response.timestamp) {
              if (moment(response.timestamp).locale($store.getters.getBotLanguages.localeTimeStamp).format(store.getters.getTimeStampFormat) !== store.getters.getLastMessageReceievedEpoc) {
                // optionalTimeStamp is used for displaying chat history
                store.dispatch('actionLastMessageReceievedEpoc', moment(response.timestamp).locale($store.getters.getBotLanguages.localeTimeStamp).format(store.getters.getTimeStampFormat))
                appendComponent(response.timestamp, TimeStamp, 'kai-core-components')
              }
            } else if ((store.state.liveChatStarted === false || store.state.liveAgentConnected === true)) {
              const timeStamp = moment().format('YYYY-MM-DDTHH:mm:ssZ')
              clearTimeout(timestampTimerHolder)
              timestampTimerHolder = setTimeout(() => {
                store.dispatch('actionLastMessageReceievedEpoc', timeStamp)
                appendComponent(timeStamp, TimeStamp, 'kai-core-components')
              }, store.state.timestampDelayInSec * 1000)
            }
          }
        }

        var timer = counter === 0 ? $store.state.defaultMessageDelayTimer : 0

        if (response.message_contents[counter].type === 'PAUSE' && response.message_contents[counter].payload.duration) {
          timer = response.message_contents[counter].payload.duration
        } else if (optionalMessageDelayTimer !== undefined && optionalMessageDelayTimer !== null) {
          timer = optionalMessageDelayTimer
        }

        if (checkIfMessageContentContainsAvatar(response.message_contents[counter]) && counter === messageContentLength - 1) {
          typingIndicator(false)
        }

        // Clear input message inside the store
        store.dispatch('actionInputMessage', '')

        if (timer > 0) {
          return new Promise((resolve, reject) => {
            setTimeout(async () => {
              await renderMessageContent(_this, counter, response, optionalMessageDelayTimer, optionalDisableScrolling, optionalIsSessionTranscript, messageTextCounter)
              if (showQuickReplies) {
                addQuickReplies(response)
              }
              resolve('Next Task is completed')
            }, timer)
          })
        } else {
          await renderMessageContent(_this, counter, response, optionalMessageDelayTimer, optionalDisableScrolling, optionalIsSessionTranscript, messageTextCounter)
          if (showQuickReplies) {
            addQuickReplies(response)
          }
          return new Promise((resolve, reject) => {
            resolve('Next Task is completed')
          })
        }
      } else {
        if (showQuickReplies) {
          addQuickReplies(response)
        }
        if (!optionalDisableScrolling) {
          await store.dispatch('actionRenderingStatus', 'idle')
          await store.dispatch('actionRenderingStatus', 'finished')
        }
      }
    } catch (error) {
      sendError(error)
      console.error(error)
    }
  } // Next Task method ends

  const checkIfTopK = payload => {
    if (payload.contents.length > 0 && payload.contents[0].type === 'CUSTOM' && payload.contents[0].payload.type === 'KAI_ANSWERS_TOP_K') return true
    return false
  }

  const checkIfResponseContainsKaiAnswersSuggestions = (response) => {
    if (response && response.message_contents) {
      for (let i = 0; i < response.message_contents.length; i++) {
        const message = response.message_contents[i]
        if ('contents' in message && checkIfMessageContentIsKaiAnswersSuggestions(message)) {
          return true
        }
      }
    }
    return false
  }

  const checkIfMessageContentIsKaiAnswersSuggestions = (payload) => {
    return payload.contents.find(content => content.type === 'CUSTOM' && content.payload && content.payload.type === 'KAI_ANSWERS_SUGGESTIONS') || false
  }

  const checkIfMessageContentHasKaiAnswersKeyword = (payload) => {
    return payload.contents.find(content => content.type === 'CUSTOM' && content.payload && content.payload.type === 'KAI_ANSWERS_KEYWORD') || false
  }

  const checkIfMessageContentHasKaiAnswersRelatedQuestion = (payload) => {
    return payload.contents.find(content => content.type === 'CUSTOM' && content.payload && content.payload.type === 'KAI_ANSWERS_RELATED_QUESTION') || false
  }

  var renderMessageContent = async function (_this, counter, response, optionalMessageDelayTimer, optionalDisableScrolling, optionalIsSessionTranscript, messageTextCounter) {
    try {
      var messageContentLength = response.message_contents.length
      /****************************************************/

      /* Type = DEBUG  */

      /****************************************************/

      if (response.message_contents[counter].type === 'DEBUG') {
        // console.log('payload', response.message_contents[counter].payload)

        var slots = {
          debugContent: response.message_contents[counter].payload
        }
        appendComponent(slots, DebugMode, 'kai-core-components')
      }

      /****************************************************/

      /* Type = EVENT and payload type is CUSTOMER_SHORTCUTS  (custom shortcut panel payload) */

      /****************************************************/

      if (response.message_contents[counter].type === 'EVENT' && response.message_contents[counter].payload && response.message_contents[counter].payload.type === 'CUSTOMER_SHORTCUTS') {
        store.dispatch('actionShortcutPayload', response.message_contents[counter].payload)
      }

      /****************************************************/

      /* Type = EVENT  */

      /****************************************************/
      if (response.message_contents[counter].type === 'EVENT' && response.message_contents[counter].payload) {
        response.message_contents[counter].payload.isSessionTranscript = optionalIsSessionTranscript
        store.dispatch('actionLogEvent', response.message_contents[counter].payload)

        // add new LiveChat component when live chat is triggered and this is the first time we receive the LIVE_CHAT_NOTIFY_CUSTOMER_QUEUE_STATE event
        if (response.message_contents[counter].payload.type === 'LIVE_CHAT_NOTIFY_CUSTOMER_QUEUE_STATE') {
          store.state.liveChatStarted = true
        }
        if (response.message_contents[counter].payload.type === 'LIVE_CHAT_NOTIFY_AGENT_CONNECTED') {
          store.state.liveChatStarted = true
          store.state.liveAgentConnected = true
        }

        // add a second LiveChat component to display the LIVE_CHAT_NOTIFY_SESSION_STOPPED event only if an agent has been connected to the chat.
        // if LIVE_CHAT_NOTIFY_SESSION_STOPPED is received when user was still in the queue, then the first LiveChat component is used.
        if (response.message_contents[counter].payload.type === 'LIVE_CHAT_NOTIFY_SESSION_STOPPED' || response.message_contents[counter].payload.type === 'LIVE_CHAT_NOTIFY_NOT_AVAILABLE') {
          store.state.liveChatStarted = false
          store.state.liveAgentConnected = false
        }
        if (response.message_contents[counter].payload.type === 'LIVE_CHAT_SEND_AGENT_STATE') {
          typingIndicator(response.message_contents[counter].payload.payload.is_typing)
        }

        if (response.message_contents[counter].payload.type === 'LIVE_CHAT_NOTIFY_CUSTOM') {
          if (response.message_contents[counter].payload.message_contents || response.message_contents[counter].payload.payload) {
            appendLiveChatNotificationComponent(response.message_contents[counter].payload, LiveChatNotification, 'kai-core-components')
            // LiveChatNotification component only use the first message_content type TEXT if it exists, the other message contents need to be handle with nextTask method
            if (response.message_contents[counter].payload.message_contents && response.message_contents[counter].payload.message_contents.length > 1) {
              const filteredMessageContents = response.message_contents[counter].payload.message_contents.slice(1)
              const responseCopy = { ...response.message_contents[counter].payload, message_contents: filteredMessageContents }
              await nextTask(_this, 0, responseCopy, optionalMessageDelayTimer, true, optionalIsSessionTranscript, messageTextCounter)
            }
          }
        }

        if (response.message_contents[counter].payload.message_contents && response.message_contents[counter].payload.message_contents.length > 0 &&
          response.message_contents[counter].payload.type !== 'CUSTOMER_SHORTCUTS' && response.message_contents[counter].payload.type !== 'LIVE_CHAT_NOTIFY_CUSTOM') {
          // wait for message_contents passed inside EVENTS to be rendered before continuing rendering the main response
          await nextTask(_this, 0, response.message_contents[counter].payload, 0, true, optionalIsSessionTranscript, messageTextCounter)
        }
      }

      /****************************************************/

      /* Type = TEXT  */

      /****************************************************/

      if (response.message_contents[counter].type === 'TEXT') {
        messageTextCounter = addTextBubble(messageTextCounter, response.message_contents[counter].payload.text)
      }

      /****************************************************/

      /* Type = CARD  */

      /****************************************************/

      if (response.message_contents[counter].type === 'CARD') {
        // addCardContainer(response.message_contents[counter], 'kai-core-components', CardSingle)
        // If multiple messages don't show icon profile images after the second text bubble
        if (messageTextCounter === 0) {
          addCardContainer(response.message_contents[counter], 'kai-core-components', CardSingle, true)
        } else {
          addCardContainer(response.message_contents[counter], 'kai-core-components', CardSingle, false)
        }
        messageTextCounter++
      }

      /****************************************************/

      /* Type = MEDIUM  */

      /****************************************************/

      if (response.message_contents[counter].type === 'MEDIUM') {
        if (messageTextCounter === 0) {
          addCardContainer(response.message_contents[counter], 'kai-core-components', MediumSingle, true)
        } else {
          addCardContainer(response.message_contents[counter], 'kai-core-components', MediumSingle, false)
        }
        messageTextCounter++
      }

      /****************************************************/

      /* Type = Select  */

      /****************************************************/
      if (response.message_contents[counter].type === 'SELECT') {
        addCardContainer(response.message_contents[counter], 'kai-core-components', Select)
      }

      /****************************************************/

      /* Type = CONTAINER  */

      /****************************************************/

      // Message Container
      if (response.message_contents[counter].type === 'CONTAINER') {
        // Check for KAI_ANSWERS_SUGGESTIONS to use in NBestBottomPanel
        if (checkIfMessageContentIsKaiAnswersSuggestions(response.message_contents[counter].payload)) {
          // check if CONTAINER contains KAI_ANSWERS_RELATED_QUESTION or KAI_ANSWERS_KEYWORD
          if (checkIfMessageContentHasKaiAnswersKeyword(response.message_contents[counter].payload) || checkIfMessageContentHasKaiAnswersRelatedQuestion(response.message_contents[counter].payload)) {
            addCardContainer(response.message_contents[counter].payload, 'kai-core-components', RelatedQuestions, false, true)
          } else {
            store.dispatch('actionNbestBottomPanel', response.message_contents[counter].payload)
          }
        } else {
          // Check for mode === LIST
          if (response.message_contents[counter].payload.mode === 'LIST') {
            if (messageTextCounter === 0) {
              addCardContainer(response.message_contents[counter], 'kai-core-components', ListViewBase, true)
            } else {
              addCardContainer(response.message_contents[counter], 'kai-core-components', ListViewBase, false)
            }
            messageTextCounter++
          }

          // Check for mode === CAROUSEL
          if (response.message_contents[counter].payload.mode === 'CAROUSEL') {
            // Check if type is KAI_ANSWERS_TOP_K
            if (checkIfTopK(response.message_contents[counter].payload)) {
              addCardContainer(response.message_contents[counter], 'kai-core-components', TopKCarousel, false, true)
              // Increase card carousel value inside the store
              store.dispatch('actionCarouselCounter', '')
            } else if (response.message_contents[counter].payload.contents.length > 1) {
              // If multiple cards inside carousel
              addCardContainer(response.message_contents[counter], 'kai-core-components', Carousel)
              // Increase card carousel value inside the store
              store.dispatch('actionCarouselCounter', '')
            } else {
              // If single cards inside carousel
              if (response.message_contents[counter].payload.contents[0].type === 'CARD') {
                addCardContainer(response.message_contents[counter].payload.contents[0], 'kai-core-components', CardSingle)
              } else if (response.message_contents[counter].payload.contents[0].type === 'MEDIUM') {
                addCardContainer(response.message_contents[counter].payload.contents[0], 'kai-core-components', MediumSingle)
              } else if (response.message_contents[counter].payload.contents[0].type === 'CUSTOM') {
                addCardContainer(response.message_contents[counter].payload.contents[0], 'kai-core-components', CustomSingle)
              }
            }
          }
        }
      }

      /****************************************************/

      /* Type = CUSTOM  */

      /****************************************************/
      if (response.message_contents[counter].type === 'CUSTOM') {
        if (response.message_contents[counter].payload.type === 'WEB_SEARCH' ||
          response.message_contents[counter].payload.type === 'WEB_SEARCH_GOOGLE' ||
          response.message_contents[counter].payload.type === 'WEB_SEARCH_SILVERCLOUD') {
          addContentButton(response.message_contents[counter], 'kai-core-components', CustomSingle)
        } else if (response.message_contents[counter].payload.type === 'AVATAR') {
          if (!store.state.useAvatar) {
            // in case WV is not configured to use Avatar Lottie animation, check instead if custom Avatar payload contains an image_url for KORC.
            // if yes we update the path botWidgetIconUrl used by MessageProfileImage for static avatar.
            if (response.message_contents[counter].payload.data && response.message_contents[counter].payload.data.image_url) {
              if (!$store.getters.isInlineImagesEnabled) {
                $store.state.botWidgetIconUrl = response.message_contents[counter].payload.data.image_url
              } else {
                $store.state.botWidgetIconInlineUrl = response.message_contents[counter].payload.data.image_url
              }
            } else {
              if (!$store.getters.isInlineImagesEnabled) {
                $store.state.botWidgetIconUrl = $store.getters.getBaseImagePath + 'chat-icon.png'
              } else {
                $store.state.botWidgetIconInlineUrl = require('@/assets/img/' + 'chat-icon.png')
              }
            }
          } else if (!optionalIsSessionTranscript && !optionalDisableScrolling) {
            $store.dispatch('actionAvatarPersistentUpdate', false)
            store.dispatch('actionAvatarUpdate', response.message_contents[counter].payload)
          }
        } else if (response.message_contents[counter].payload.type.toUpperCase() === 'INACTIVITY') {
          const parsedPayload = response.message_contents[counter].payload.data
            ? response.message_contents[counter].payload.data
            : response.message_contents[counter].payload.payload
          inlinePopup(parsedPayload, 'INACTIVITY')
        } else if (response.message_contents[counter].payload.type.toUpperCase() === 'MINIMIZE') {
          const parsedPayload = response.message_contents[counter].payload.data
            ? response.message_contents[counter].payload.data
            : response.message_contents[counter].payload.payload
          inlinePopup(parsedPayload, 'MINIMIZE')
        } else if (response.message_contents[counter].payload.type.toUpperCase() === 'KAI_ANSWERS_SUCCESS') {
          const parsedPayload = { kaiAnswer: {} }
          parsedPayload.kaiAnswer = response.message_contents[counter].payload.data
            ? response.message_contents[counter].payload.data
            : response.message_contents[counter].payload.payload
          messageTextCounter = addTextBubble(messageTextCounter, parsedPayload)
        } else if (response.message_contents[counter].payload.type.toUpperCase() === 'TEXT_STREAM') {
          response.message_contents[counter].payload.position = 'kai-left'
          store.dispatch('actionAddTextStream', response.message_contents[counter].payload)
          addTextStream(response.message_contents[counter].payload)
        } else if (response.message_contents[counter].payload.type.toUpperCase() === 'GAPI_SUGGESTIONS') {
          store.dispatch('actionNbestBottomPanel', response.message_contents[counter].payload)
        } else if (response.message_contents[counter].payload.type.toUpperCase() === 'USER_TEXT') {
          addRelatedQuestionsUserText('kai-core-components', response.message_contents[counter].payload, RelatedQuestionsUserText, 'kai-right', false)
        } else {
          addCardContainer(response.message_contents[counter], 'kai-core-components', CustomSingle)
        }
      }

      /****************************************************/

      /* Type = BUTTON  (Standalone button) */

      /****************************************************/

      if (response.message_contents[counter].type === 'BUTTON') {
        typingIndicator(false)
        addContentButton(response.message_contents[counter], 'kai-core-components', ContentButton)
      }

      /****************************************************/

      /* Type = REDIRECT  */

      /****************************************************/
      // skip REDIRECT message_contents when payload is from GET_SESSION_TRANSCRIPT event
      if (response.message_contents[counter].type === 'REDIRECT') {
        if (!optionalIsSessionTranscript) {
          buttonPressed(response.message_contents[counter].payload)
        }

        if (response.message_contents[counter].payload.type === 'SOURCE_DISPLAY') {
          const event = new CustomEvent('displaySourceUrl', { detail: response.message_contents[counter].payload.payload })
          window.dispatchEvent(event)
        }

        if (response.message_contents[counter].payload.type === 'TOP_SOURCE_DISPLAY') {
          const event = new CustomEvent('displayTopSourceUrl', { detail: response.message_contents[counter].payload.payload })
          window.dispatchEvent(event)
        }
      }

      /****************************************************/

      /* Type = LINK  (Standalone link) */

      /****************************************************/

      if (response.message_contents[counter].type === 'LINK') {
        addContentButton(response.message_contents[counter], 'kai-core-components', ContentLink)
      }

      /****************************************************/

      /* Type = Avatar  */

      /****************************************************/

      if (response.message_contents[counter].type === 'AVATAR' && !optionalIsSessionTranscript) {
        $store.dispatch('actionAvatarPersistentUpdate', false)
        store.dispatch('actionAvatarUpdate', response.message_contents[counter])
      }

      /****************************************************/

      /* Type = WEB_SEARCH  (custom search engine) */

      /****************************************************/

      if (response.message_contents[counter].type === 'WEB_SEARCH' || response.message_contents[counter].type === 'WEB_SEARCH_GOOGLE' || response.message_contents[counter].type === 'WEB_SEARCH_SILVERCLOUD') {
        addContentButton(response.message_contents[counter], 'kai-core-components', CustomSearch)
      }

      /****************************************************/

      /* Type = NBEST  */

      /****************************************************/

      if (response.alternative_questions && counter === messageContentLength - 1) {
        if (response.alternative_questions.length > 0) {
          addContentButton(response.alternative_questions, 'kai-core-components', NBest)
        }
      }

      /****************************************************/

      $jq('.kai-left-wrapper').addClass('msg-row')

      // Add class to the last msg bubble
      $jq('.kai-left-wrapper').last().addClass('last-left-msg-bubble')
      if (counter === messageContentLength - 1) {
        if (!optionalDisableScrolling) {
          if (!isLastMessageAgentTyping(response)) {
            typingIndicator(false)
          }
          setTimeout(async () => {
            await store.dispatch('actionRenderingStatus', 'idle')
            await store.dispatch('actionRenderingStatus', 'finished')
          }, $store.state.defaultMessageDelayTimer)
          setTimeout(() => endTextStream(response), $store.state.defaultMessageDelayTimer)
          setTimeout(() => completeTextStream(response), $store.state.defaultMessageDelayTimer)
        }
      }

      counter++

      if (counter < messageContentLength) {
        await nextTask(_this, counter, response, optionalMessageDelayTimer, optionalDisableScrolling, optionalIsSessionTranscript, messageTextCounter)
      }
      return new Promise((resolve, reject) => {
        resolve('render message_contents completed')
      })
    } catch (error) {
      sendError(error)
      console.error(error)
    }
  }

  /****************************************************/

  /* Check CAPI Response Status is V  */

  /****************************************************/

  var isValidResponse = (response) => {
    if (response.status && response.status.code &&
      (response.status.code === STATUS_OK || response.status.code === parseInt(STATUS_OK) ||
        response.status.code === STATUS_OK_NO_CONTENTS || response.status.code === parseInt(STATUS_OK_NO_CONTENTS) ||
        response.status.code === STATUS_INVALID_REQUEST || response.status.code === parseInt(STATUS_INVALID_REQUEST) ||
        response.status.code === STATUS_AUTHENTICATION_FAILED || response.status.code === parseInt(STATUS_AUTHENTICATION_FAILED) ||
        response.status.code === STATUS_ACCESS_DENIED || response.status.code === parseInt(STATUS_ACCESS_DENIED) ||
        response.status.code === STATUS_LOGICAL_ERRORS || response.status.code === parseInt(STATUS_LOGICAL_ERRORS) ||
        response.status.code === STATUS_OTP_REQUIRED || response.status.code === parseInt(STATUS_OTP_REQUIRED) ||
        response.status.code === STATUS_INVALID_OTP_RETRY || response.status.code === parseInt(STATUS_INVALID_OTP_RETRY) ||
        response.status.code === STATUS_EXPIRED_OTP || response.status.code === parseInt(STATUS_EXPIRED_OTP) ||
        response.status.code === STATUS_TOO_MANY_OTP_FAILURES || response.status.code === parseInt(STATUS_TOO_MANY_OTP_FAILURES) ||
        response.status.code === STATUS_INTERNAL_SERVER_ERROR || response.status.code === parseInt(STATUS_INTERNAL_SERVER_ERROR) ||
        response.status.code === STATUS_SERVICE_UNAVAILABLE || response.status.code === parseInt(STATUS_SERVICE_UNAVAILABLE))) {
      return true
    } else if (response.status && response.status.code === STATUS_INVALID_SESSION_ID) {
      handleInvalidSessionID(response)
      return false
    } else {
      return false
    }
  }

  /****************************************************/

  /* Typing Icon  */

  /****************************************************/

  var typingIndicator = (toggle) => {
    if (typingIndicatorTimerHolder) {
      clearTimeout(typingIndicatorTimerHolder)
    }
    if (toggle === true) {
      store.dispatch('actionTypingIndicator', true)

      // When livechat is started, automatically remove typing indicator after 5s in case it has not been removed manually
      // (needed in case customer Live Chat system is not properly sending event when agent stopped typing)
      if ($store.getters.isLiveChatStarted) {
        typingIndicatorTimerHolder = setTimeout(() => {
          store.dispatch('actionTypingIndicator', false)
        }, 5000)
      }
    } else {
      // do not remove the typing indicator if user_message request is pending
      // or if keywords response is pending
      if (!typingIndicatorPendingUserMessageResponse && !typingIndicatorPendingKeywordsResponse) {
        store.dispatch('actionTypingIndicator', false)
      }
    }
  }

  /****************************************************/

  /* Append Component  */

  /****************************************************/

  var appendComponent = (obj, component, element, position) => {
    const ComponentClass = Vue.extend(component)
    const instance = new ComponentClass({
      propsData: { type: 'primary' }
    })

    instance.$slots.default = obj
    if (component && component.name === 'Select') {
      store.dispatch('actionSlotsDefault', obj)
    }
    instance.$mount()

    if (document.getElementById(element) != null) {
      if (position === 'bottom') {
        document.getElementById(element).before(instance.$el)
      } else {
        document.getElementById(element).appendChild(instance.$el)
      }
    }
  }

  /****************************************************/

  /* Map Location  */

  /****************************************************/

  var mapLocation = () => {
    appendComponent({}, LocationMap, 'webview-container')
  }

  /****************************************************/

  /* Idle Popup  */

  /****************************************************/

  var inlinePopup = (payload, optionalType) => {
    if (optionalType) {
      payload.popupType = optionalType
    }
    store.dispatch('actionInlinePopupContent', payload)
    store.dispatch('actionShowInlinePopup', true)
  }

  /****************************************************/

  /* Add Text Bubble  */

  /****************************************************/

  var addTextBubble = (messageTextCounter, payload) => {
    // If multiple messages don't show icon profile images after the second text bubble
    // If there is a user utterance in between response, show avatar on the next response
    if (messageTextCounter === 0 || store.state.inputMessage) {
      addMessageContent('kai-core-components', payload, TextBubble, 'kai-left', true)
    } else {
      addMessageContent('kai-core-components', payload, TextBubble, 'kai-left', false)
    }
    messageTextCounter++
    return messageTextCounter
  }

  /****************************************************/

  /* Add Live Chat Notification  */

  /****************************************************/

  var appendLiveChatNotificationComponent = (obj, component, element, position) => {
    const ComponentClass = Vue.extend(component)
    const instance = new ComponentClass({
      propsData: { type: 'primary', payload: obj }
    })
    instance.$mount()

    if (document.getElementById(element) != null) {
      if (position === 'bottom') {
        document.getElementById(element).before(instance.$el)
      } else {
        document.getElementById(element).appendChild(instance.$el)
      }
    }
  }

  var addLiveChatNotification = (payload) => {
    appendLiveChatNotificationComponent(payload, LiveChatNotification, 'kai-core-components')
  }

  /****************************************************/

  /* Add Text Stream  */

  /****************************************************/

  var appendTextStream = (obj, component, element, position) => {
    const ComponentClass = Vue.extend(component)
    const instance = new ComponentClass({
      propsData: { type: 'primary', payload: obj }
    })

    instance.$mount()

    if (document.getElementById(element) != null) {
      if (position === 'bottom') {
        document.getElementById(element).before(instance.$el)
      } else {
        document.getElementById(element).appendChild(instance.$el)
      }
    }
  }

  var addTextStream = (payload) => {
    if ((payload.data.stream_id in store.state.pendingTextStream &&
      !store.state.pendingTextStream[payload.data.stream_id].instantiated) ||
      !(payload.data && 'stream_id' in payload.data)) {
      appendTextStream(payload, TextBubble, 'kai-core-components')
    }
  }

  /****************************************************/

  /* Add Related Questions User Text  */

  /****************************************************/

  var addRelatedQuestionsUserText = (element, payload, component, position, showIcon, callback) => {
    const slots = {
      payload: payload,
      position: position, // Left or Right
      showIcon: showIcon
    }
    appendComponent(slots, component, element)
    typingIndicator(true)

    if (callback) {
      callback()
    }
  }

  /****************************************************/

  /* Multi Select  */

  /****************************************************/

  var select = () => {
    appendComponent({}, Select, 'bottombar', 'bottom')
  }

  /****************************************************/

  /* Load History  */

  /****************************************************/

  var loadHistory = () => {
    // If seesion id
    const initMode = findGetParameter('initMode')
    if (findGetParameter('sessionID')) {
      var sessionID = findGetParameter('sessionID')
      $store.state.kaiSessionId = sessionID
      if (!(initMode && initMode === 'capi_redirect')) {
        kserver.CoreAPI.getHistory()
      }
    } else if ($store.getters.getKaiSessionId !== '' && $store.getters.isSessionCookieEnabled && !(initMode && initMode === 'capi_redirect')) {
      kserver.CoreAPI.getHistory(() => {
        const openWebviewWidget = Cookies.get('k_webview_widget_opened') === 'true'
        if (openWebviewWidget) {
          $store.state.showWebviewWidget = openWebviewWidget
        }
      })
    }
  }

  var findGetParameter = (parameterName) => {
    const params = new URLSearchParams(location.search)
    let result = params.get(parameterName)
    if (!result) {
      if ($store.state.scriptSrc) {
        const scriptURL = new URL($store.state.scriptSrc)
        const scriptParams = new URLSearchParams(scriptURL.search)
        result = scriptParams.get(parameterName)
      }
    }
    return result
  }

  var generateMainContainerClass = () => {
    if (($store.state.useWidgetMode && $store.state.generateWebviewMainContainer) ||
      (document.getElementsByClassName('webview--app').length === 0 && !document.getElementById($store.state.el))) {
      if (document.getElementsByClassName('webview--app').length === 0) {
        const body = document.getElementsByTagName('body')[0]
        body.classList.add('webview--app')
        const newElem = document.createElement('div')
        newElem.setAttribute('id', $store.state.el)
        body.appendChild(newElem)
      }
    }
  }

  /****************************************************/

  /* Accessibility Content ScreenReader  */

  /****************************************************/

  var accessibilityContentScreenReader = (response) => {
    // Check config to see if screenReader is false
    if (store.getters.getScreenReader === false) {
      return false
    }

    // Reset screen array
    const screenReaderMessage = []
    const screenReaderQuickReplies = []
    let countItems = 0
    // var screenReaderCurrentDate = parseInt(moment().format('LT').replace(/\D+/g, ''));
    const screenReaderCurrentDate = moment().format('hh:mm A')

    const screenReaderLanguage = $store.getters.getBotLanguages.translations.screenReader

    //* *** Message Content Data
    if (response.message_contents !== undefined && response.message_contents !== null) {
      for (let i = 0; i < response.message_contents.length; i++) {
        // TEXT Content
        if (response.message_contents[i].type === 'TEXT') {
          screenReaderMessage.push(response.message_contents[i].payload.text)
        }
        // BUTTON Content
        if (response.message_contents[i].type === 'BUTTON') {
          screenReaderMessage.push(screenReaderLanguage.button + ': ' + response.message_contents[i].payload.label)
        }

        if (response.message_contents[i].type === 'CARD') {
          screenReaderMessage.push(screenReaderLanguage.card + ': ' + response.message_contents[i].payload.title + '. ')
          // TODO: add medium image description if available in CAPI payload
          screenReaderMessage.push(response.message_contents[i].payload.subtitle + '. ')
          if (response.message_contents[i].payload.buttons) {
            for (let k = 0; k < response.message_contents[i].payload.buttons.length; k++) {
              screenReaderMessage.push(screenReaderLanguage.button + ' ' + [k + 1] + ': ' + response.message_contents[i].payload.buttons[k].label + '. ')
            }
          }
        }

        // CAROUSEL CARD Content
        if (response.message_contents[i].type === 'CONTAINER') {
          countItems = 0
          screenReaderMessage.push(response.message_contents[i].payload.contents.length + ' ' + screenReaderLanguage.carouselCards + '. ')
          for (let j = 0; j < response.message_contents[i].payload.contents.length; j++) {
            countItems++
            screenReaderMessage.push(screenReaderLanguage.card + ' ' + [countItems] + ': ' + response.message_contents[i].payload.contents[j].payload.title + '. ')
            screenReaderMessage.push(response.message_contents[i].payload.contents[j].payload.subtitle + '. ')
            if (response.message_contents[i].payload.contents[j].payload.buttons) {
              for (let m = 0; m < response.message_contents[i].payload.contents[j].payload.buttons.length; m++) {
                screenReaderMessage.push(screenReaderLanguage.button + ' ' + [m + 1] + ': ' + response.message_contents[i].payload.contents[j].payload.buttons[m].label + '. ')
              }
            }
          }
        }

        if (response.message_contents[i].type === 'SELECT') {
          countItems = 0
          screenReaderMessage.push(response.message_contents[i].payload.options.length + ' ' + response.message_contents[i].payload.mode + ' ' + screenReaderLanguage.cards + '. ')
          for (let o = 0; o < response.message_contents[i].payload.options.length; o++) {
            countItems++
            if (response.message_contents[i].payload.options[o].payload) {
              if (response.message_contents[i].payload.options[o].payload.title) {
                screenReaderMessage.push(screenReaderLanguage.card + ' ' + [countItems] + ': ' + response.message_contents[i].payload.options[o].payload.title + '. ')
              }
              if (response.message_contents[i].payload.options[o].payload.subtitle) {
                screenReaderMessage.push(response.message_contents[i].payload.options[o].payload.subtitle + '. ')
              }
              if (response.message_contents[i].payload.options[o].payload.buttons) {
                for (let p = 0; p < response.message_contents[i].payload.options[o].payload.buttons.length; p++) {
                  screenReaderMessage.push(screenReaderLanguage.button + ' ' + [p + 1] + ': ' + response.message_contents[i].payload.options[o].payload.buttons[p].label + '. ')
                }
              }
            } else if (response.message_contents[i].payload.options[o].label) {
              screenReaderMessage.push(screenReaderLanguage.card + ' ' + [countItems] + ': ' + response.message_contents[i].payload.options[o].label + '. ')
            }
          }
        }
      }
    }

    //* *** Quick Replies Data
    if (response.quick_replies !== undefined && response.quick_replies !== null) {
      // Reset Counter
      countItems = 0
      // Post quick reply length
      screenReaderMessage.push(screenReaderLanguage.thereAre + ' ' + (response.quick_replies.length) + ' ' + screenReaderLanguage.quickReplies + '. ')

      for (let n = 0; n < response.quick_replies.length; n++) {
        // BUTTON Content
        countItems++
        var quickReplyLabel
        if (response.quick_replies[n].label && response.quick_replies[n].label.length > 0) {
          quickReplyLabel = response.quick_replies[n].label
        } else {
          quickReplyLabel = response.quick_replies[n].display_text
        }
        screenReaderMessage.push(screenReaderLanguage.quickReply + ' ' + [countItems] + ': ' + quickReplyLabel + '. ')
      }
    }

    //* *** Alternative Question Data
    if (response.alternative_questions !== undefined && response.alternative_questions !== null) {
      // Reset Counter
      countItems = 0
      screenReaderMessage.push(screenReaderLanguage.thereAre + ' ' + (response.alternative_questions.length) + ' ' + screenReaderLanguage.alternativeQuestionBottom + '. ')

      for (let l = 0; l < response.alternative_questions.length; l++) {
        // Alternative Question Content
        countItems++
        if (response.alternative_questions[l].question && response.alternative_questions[l].question.length > 0) {
          screenReaderMessage.push(screenReaderLanguage.alternativeQuestion + ' ' + [countItems] + ': ' + response.alternative_questions[l].question + '. ')
        } else {
          screenReaderMessage.push(screenReaderLanguage.alternativeQuestion + ' ' + [countItems] + ': ' + response.alternative_questions[l].display_sentence + '. ')
        }
      }
    }

    if (screenReaderMessage.length > 0) {
      setTimeout(() => {
        $jq('#virtual-assistant-content').children('div').html(screenReaderLanguage.timeSendAt + ' ' + screenReaderCurrentDate + '. ' + screenReaderLanguage.from + ' ' + screenReaderLanguage.virtualAssistantDesignation + ':<br>' + screenReaderMessage.join(' ') + ' ' + screenReaderQuickReplies.join(' '))
      }, 2000)
    }
  }

  /****************************************************/

  /* update Session cookie  */

  /****************************************************/

  var updateSessionCookie = () => {
    if ($store.getters.getKaiSessionId !== '' && $store.getters.isSessionCookieEnabled) {
      if (!$store.getters.getCookieAutoRefresh) {
        setCookieSession($store.getters.getCookieExpirationTimeInSec)
      } else {
        if (!cookieAutoRefreshTimeout) {
          setCookieSession($store.getters.getCookieExpirationTimeInSec)
          cookieAutoRefreshTimeout = setInterval(() => {
            setCookieSession($store.getters.getCookieExpirationTimeInSec)
          }, $store.getters.getCookieExpirationTimeInSec * 1000 - 100) // reset the cookie a few ms before it expired just to be safe.
        }
      }
    }
  }

  function setCookieSession (cookieExpirationTimeInSe) {
    const expiringDate = new Date(new Date().getTime() + cookieExpirationTimeInSe * 1000)
    Cookies.remove('k_session_id')
    Cookies.set('k_session_id', $store.getters.getKaiSessionId, { expires: expiringDate })
    if ($store.getters.getPullServiceState) {
      Cookies.remove('k_pull_service_state')
      Cookies.set('k_pull_service_state', $store.getters.getPullServiceState, { expires: expiringDate })
    }
  }

  var removeSessionCookie = () => {
    if ($store.getters.isSessionCookieEnabled) {
      if ($store.getters.getCookieAutoRefresh) {
        clearTimeout(cookieAutoRefreshTimeout)
      }
      Cookies.remove('k_session_id')
      Cookies.remove('k_pull_service_state')
    }
  }

  /****************************************************/

  /* Handle Invalid Session ID  */

  /****************************************************/

  var handleInvalidSessionID = (response, isAfterStartSession) => {
    typingIndicator(false)
    if (!store.state.overrideInvalidSessionIdHandling) {
      if (store.getters.getKaiSessionId === '') {
        // reach here when the user sent a request before the first Session Init event is called
        Kai.Core.addMessageContent('kai-core-components', store.getters.getBotLanguages.translations.errorMessage.invalidSessionID.sessionNotInitialized, TextBubble, 'kai-left', true)
      }

      if (store.getters.getKaiSessionId !== '') {
        // reach here when session break
        invalidSessionCounter++
        if (isAfterStartSession) {
          invalidSessionAfterStartSessionCounter++
        }
        if (invalidSessionAfterStartSessionCounter >= store.getters.getMaxInvalidSessionIdCounterAfterStartSessionEvent) {
          // if an error 440 occured just after a call to the the Start session event more than 1 (configurable)
          Kai.Core.addMessageContent('kai-core-components', store.getters.getBotLanguages.translations.errorMessage.invalidSessionID.TooManyInvalidSessionsAfterStartSessionEvent, TextBubble, 'kai-left', true)
        } else if (invalidSessionCounter >= store.getters.getMaxInvalidSessionIdCounter) {
          // if the total number of error 440 is more than 5 (configurable) times during the chat
          Kai.Core.addMessageContent('kai-core-components', store.getters.getBotLanguages.translations.errorMessage.invalidSessionID.TooManyInvalidSessions, TextBubble, 'kai-left', true)
        } else {
          // try to recover by starting the session again and upon success sending the user input back.
          const previousUserMessage = store.state.inputMessage
          kserver.CoreAPI.startSession(function (callbackData) {
            if (callbackData && callbackData.status.code === STATUS_OK) {
              Kai.API.sendUserMessage(previousUserMessage)
            }
          })
        }
      }
    } else {
      store.state.overrideInvalidSessionIdHandling(response)
    }
  }

  /****************************************************/

  /* Browser Detection  */

  /****************************************************/

  var isIE11 = !!window.MSInputMethodContext && !!document.documentMode

  if (!isIE11) {
    // Focus when users scroll window inside iframe fix keyboard freezing issue.
    document.addEventListener('keydown', function (e) {
      window.focus()
      // console.log("keydown");
    })

    document.addEventListener('touchend', function (e) {
      window.focus()
      // console.log("touchend");
    })
  }

  /****************************************************/

  /* Detect iOS devices - Where is this being used? JB   */

  /****************************************************/
  var agent = navigator.userAgent
  if (agent.indexOf('iPhone') > 0 || agent.indexOf('iPod') > 0) {
    // document.querySelector('#kai-container').addClass('ios');
    // console.log("IOS device");
    // Question slide panel styling inside Iframe
    // $jq('.info-panel, .info-panel p, .info-panel h3, .info-panel div').css('font-size','9px')

  }
  /*
           var agent = navigator.userAgent;
           if (agent.indexOf('iPhone') > 0 || agent.indexOf('iPod') > 0) {
               this._element.find('.kai-chat-content').addClass('ios');

               //Question slide panel styling inside Iframe
               //$jq('.info-panel, .info-panel p, .info-panel h3, .info-panel div').css('font-size','9px')

           }

       //Polyfill
       //Method endsWith() not supported in IE
       if (!String.prototype.endsWith) {
         String.prototype.endsWith = function(searchString, position) {
             var subjectString = this.toString();
             if (typeof position !== 'number' || !isFinite(position)
                 || Math.floor(position) !== position || position > subjectString.length) {
               position = subjectString.length;
             }
             position -= searchString.length;
             var lastIndex = subjectString.indexOf(searchString, position);
             return lastIndex !== -1 && lastIndex === position;
         };
       }
     */

  function ConvertKeysToUpperCase (obj) {
    const output = {}
    for (const i in obj) {
      if (Object.prototype.toString.apply(obj[i]) === '[object Object]') {
        output[i.toUpperCase()] = ConvertKeysToUpperCase(obj[i])
      } else if (Object.prototype.toString.apply(obj[i]) === '[object Array]') {
        output[i.toUpperCase()] = []
        output[i.toUpperCase()].push(ConvertKeysToUpperCase(obj[i][0]))
      } else {
        output[i.toUpperCase()] = obj[i]
      }
    }
    return output
  }

  function isApiVersionAtLeast (desiredVersion) {
    const apiVersion = store.state.api_version
    if (typeof desiredVersion === 'number') {
      return parseFloat(apiVersion.substring(0, 3)) >= desiredVersion
    }
    // api_version length should be at least 3 characters with 2 digits, e.g 4.1
    // but it can be also 5 characters with 3 digits, e.g 4.1.2
    let isSuperiorOrEqual = false
    let extraDigit = false
    let extraDigitCompare = false
    if (typeof apiVersion !== 'undefined') {
      if (apiVersion.length === 5 && desiredVersion.length === 5) {
        extraDigit = true
        extraDigitCompare = apiVersion[4] >= desiredVersion[4]
      } else {
        const tempDigit = 0
        if (apiVersion.length === 5) {
          extraDigit = true
          // extraDigitCompare = apiVersion[4] >= tempDigit? true: false
          extraDigitCompare = true
        } else if (desiredVersion.length === 5) {
          extraDigit = true
          extraDigitCompare = tempDigit >= desiredVersion[4]
        }
      }
      isSuperiorOrEqual = !(parseFloat(apiVersion.substring(0, 3)) < parseFloat(desiredVersion.substring(0, 3)))

      if (isSuperiorOrEqual) {
        if (extraDigit && parseFloat(apiVersion.substring(0, 3)) === parseFloat(desiredVersion.substring(0, 3))) {
          isSuperiorOrEqual = extraDigitCompare
        }
      }
    }
    return isSuperiorOrEqual
  }

  function updateConfigAndThemeVariables (response) {
    // response = {
    //   config: {
    //     "useWidgetMode": false,
    //     "useHeaderBar": false,
    //     "useSplashScreen": false,
    //   },
    //   content: {
    //     style: {
    //       'main-background-color': '#f2f2f2',
    //       'component-background-color': '#ffffff',
    //       'background-gradient-starting-color-rgb': 'rgba(255, 255, 255, 0)',
    //       'background-gradient-ending-color-rgb': 'rgba(255, 255, 255, 1)',
    //       'left-message-color': '#ffffff',
    //       'left-message-text-color': '#000000',
    //       'right-message-color': '#444A58',
    //       'right-message-text-color': '#ffffff',
    //       'text-bubble-and-button-max-width': '222px',
    //       'text-color': '#1c2029',
    //       'font-title-size': '20px',
    //       'font-sub-title-size': '14px'
    //     }
    //   }
    // }
    Cookies.remove('k_config')
    if (response.config) {
      Cookies.set('k_config', response.config)
      for (const [key, value] of Object.entries(response.config)) {
        if (store.state[key] !== undefined) {
          if (key === 'botLanguages') {
            store.state[key] = $jq.extend(true, store.state[key], value)
          } else {
            store.state[key] = value
          }
        }
      }
    }

    generateMainContainerClass()

    Cookies.remove('k_style')
    if (response.content && response.content.style) {
      Cookies.set('k_style', response.content.style)
      for (const [key, value] of Object.entries(response.content.style)) {
        var currentValue = getComputedStyle(document.getElementsByClassName('webview--app')[0]).getPropertyValue('--' + key)
        if (currentValue) {
          document.getElementsByClassName('webview--app')[0].style.setProperty('--' + key, value)
        }
      }
    }

    // check if CAPI "context" fields are passed in the config and if yes update webview store context accordingly.
    if (response && response.config && response.config.context) {
      kserver.CoreAPI.updateContext(response.config.context)
    }

    const serverConfigs = extractServerConfigsFromData(response.config)
    updateServerConfigs(serverConfigs)
  }

  const extractServerConfigsFromData = (data) => {
    if (data) {
      const serverConfigs = []
      // check if data field server_configs is upper case or lower case
      const dataConfigs = data.SERVER_CONFIGS || data.server_configs || data.serverConfigs || data.kaiServerConfigs
      const dataConfig = data.SERVER_CONFIG || data.server_config || data.serverConfig
      if (dataConfigs !== undefined && dataConfigs && dataConfigs.length > 0) {
        for (let i = 0; i < dataConfigs.length; i++) {
          serverConfigs.push(extractServerConfigFromData(dataConfigs[i]))
        }
      } else if (dataConfig !== undefined && dataConfig) {
        serverConfigs.push(extractServerConfigFromData(dataConfig))
      } else {
        serverConfigs.push(extractServerConfigFromData(data))
      }
      return serverConfigs
    }
  }

  const extractServerConfigFromData = (data) => {
    if (data) {
      const serverConfig = {}

      const serverURL = data.SERVER_URL || data.server_url || data.serverUrl
      if (serverURL !== undefined) {
        serverConfig.SERVER_URL = serverURL
      }
      const languageCode = data.LANGUAGE_CODE || data.language_code || data.languageCode
      if (languageCode !== undefined) {
        serverConfig.LANGUAGE_CODE = languageCode
      } else {
        serverConfig.LANGUAGE_CODE = 'EN'
      }

      const headerName = data.CAPI_CLIENT_SECRET_HEADER_NAME || data.capi_client_secret_header_name || data.APP_AUTH_HEADER || data.app_auth_header
      if (headerName !== undefined) {
        serverConfig.CAPI_CLIENT_SECRET_HEADER_NAME = headerName
      } else {
        serverConfig.CAPI_CLIENT_SECRET_HEADER_NAME = 'secret'
      }
      const capiClientSecretValue = data.secret || data.CAPI_CLIENT_SECRET_VALUE || data.capi_client_secret_value || data.capiClientSecretValue || data.APP_AUTH_KEY || data.app_auth_key
      if (capiClientSecretValue !== undefined) {
        serverConfig.CAPI_CLIENT_SECRET_VALUE = capiClientSecretValue
      }
      const speechRecognitionServer = data.SPEECH_RECOGNITION_SERVER || data.speech_recognition_server || data.speechRecognitionServer
      if (speechRecognitionServer !== undefined) {
        serverConfig.SPEECH_RECOGNITION_SERVER = speechRecognitionServer
      }
      // check if "ASSISTANT_NAME" and "ASSISTANT_TARGET" are passed in the data and if yes add them to request header
      const assistantName = data.ASSISTANT_NAME || data.assistant_name || data.assistantName
      if (assistantName !== undefined) {
        serverConfig.ASSISTANT_NAME = assistantName
      }
      const assistantTarget = data.ASSISTANT_TARGET || data.assistant_target || data.assistantTarget
      if (assistantTarget !== undefined) {
        serverConfig.ASSISTANT_TARGET = assistantTarget
      }
      return serverConfig
    }
  }

  const updateServerConfigs = (serverConfigs) => {
    if (serverConfigs && serverConfigs.length > 0) {
      $store.state.kaiServerConfigs = serverConfigs
      updateAssistantHeader()
    }
  }

  function updateAssistantHeader () {
    // check if "ASSISTANT_NAME" and "ASSISTANT_TARGET" are defined in ENV and if yes add them to request header
    var additionalHeaders = {}

    const serverConfig = getCurrentServerConfig()
    if (serverConfig.ASSISTANT_NAME) {
      additionalHeaders.assistant_name = serverConfig.ASSISTANT_NAME
    }
    if (serverConfig.ASSISTANT_TARGET) {
      additionalHeaders.assistant_target = serverConfig.ASSISTANT_TARGET
    }
    const headerName = serverConfig.CAPI_CLIENT_SECRET_HEADER_NAME ? serverConfig.CAPI_CLIENT_SECRET_HEADER_NAME : serverConfig.APP_AUTH_HEADER
    const headerValue = serverConfig.CAPI_CLIENT_SECRET_VALUE ? serverConfig.CAPI_CLIENT_SECRET_VALUE : serverConfig.APP_AUTH_KEY
    if (headerName && headerValue) {
      additionalHeaders[headerName] = headerValue
    }
    kserver.CoreAPI.setAdditionalRequestHeaders(additionalHeaders)
  }

  const getCurrentServerConfig = () => {
    return getServerConfigForLanguage($store.state.selectedLanguage.code)
  }

  const getServerConfigForLanguage = (languageCode) => {
    if ($store.state.kaiServerConfigs && $store.state.kaiServerConfigs.length > 0) {
      return $store.state.kaiServerConfigs.find((serverConfig) => {
        return serverConfig.LANGUAGE_CODE === languageCode
      })
    } else {
      return {}
    }
  }

  function inactivityWatcher () {
    window.addEventListener('mousemove', () => resetInactivityTimer())
    window.addEventListener('mousedown', () => resetInactivityTimer())
    window.addEventListener('touchstart', () => resetInactivityTimer())
    window.addEventListener('click', () => resetInactivityTimer())
    window.addEventListener('keydown', () => resetInactivityTimer())
    window.addEventListener('scroll', resetInactivityTimer(), true) // improved see comments
    resetInactivityTimer()
  }

  function onInactivityTimerFinished () {
    if (!$store.getters.isLiveChatStarted) {
      kserver.CoreAPI.sendIntent('kcb_timeout')
    }
  }

  function resetInactivityTimer () {
    clearTimeout(inactivityTimerHolder)
    if (store.state.inactivityTimeInSec > 0) {
      inactivityTimerHolder = setTimeout(onInactivityTimerFinished, store.state.inactivityTimeInSec * 1000)
    }
  }

  /****************************************************/

  /* AutoComplete  */

  /****************************************************/

  var autoCompleteRequest = (ajaxSettings, query) => {
    return kbaseserver.BaseAPI.autoCompleteAjaxRequest(ajaxSettings, query)
  }

  /****************************************************/

  /* Avatar Files  */

  /****************************************************/

  var getAvatarFile = async (name) => {
    if ($store.getters.isInlineAvatarEnabled) {
      // // SINGLE MODE
      //     let avatar = await import('@/avatars/' + name + '.json')
      //     return avatar
      // // SINGLE MODE
      const nameLowerCase = name.toLowerCase()
      if (nameLowerCase === 'splash1_bodymovin') {
        return splash1Bodymovin
      }
      if (nameLowerCase === 'splash2_bodymovin') {
        return splash2Bodymovin
      }
      if (nameLowerCase === 'active_bodymovin') {
        return activeBodymovin
      }
      if (nameLowerCase === 'checkmark_bodymovin') {
        return checkmarkBodymovin
      }
      if (nameLowerCase === 'idle_bodymovin') {
        return idleBodymovin
      }
      if (nameLowerCase === 'leaving_bodymovin') {
        return leavingBodymovin
      }
      if (nameLowerCase === 'notification_bodymovin') {
        return notificationBodymovin
      }
      if (nameLowerCase === 'playground_bodymovin') {
        return playgroundBodymovin
      }
      if (nameLowerCase === 'question_bodymovin') {
        return questionBodymovin
      }
      if (nameLowerCase === 'sorry_bodymovin') {
        return sorryBodymovin
      }
      if (nameLowerCase === 'thank_you_bodymovin') {
        return thankYouBodymovin
      }
      if (nameLowerCase === 'transaction_bodymovin') {
        return transactionBodymovin
      }
      if (nameLowerCase === 'voice_1start_bodymovin') {
        return voice1startBodymovin
      }
      if (nameLowerCase === 'voice_2loop_bodymovin') {
        return voice2LoopBodymovin
      }
      if (nameLowerCase === 'voice_3end_bodymovin') {
        return voice3endBodymovin
      }
      if (nameLowerCase === 'agent_start_bodymovin') {
        return agentStartBodymovin
      }
      if (nameLowerCase === 'agent_end_bodymovin') {
        return agentEndBodymovin
      }
      if (nameLowerCase === 'waiting_loop_bodymovin') {
        return queueWaitingLoopBodymovin
      }
      if (nameLowerCase === 'agent_connecting_loop_bodymovin') {
        return agentConnectingLoopBodymovin
      }
      if (nameLowerCase === 'agent_connected_persistent_bodymovin') {
        return agentConnectedLoopBodymovin
      }
      if (nameLowerCase === 'agent_disconnected_bodymovin') {
        return agentDisconnectedLoopBodymovin
      }
      if (nameLowerCase === 'chat_with_me_bodymovin') {
        return chatWithMeBodymovin
      }
      if (nameLowerCase === 'chat_with_me_start_bodymovin') {
        return chatWithMeStartBodymovin
      }
      if (nameLowerCase === 'chat_with_me_end_bodymovin') {
        return chatWithMeEndBodymovin
      }
      return name
      /// #endif
    } else {
      return $store.getters.getBaseAvatarPath + name + '.json'
    }
  }

  var updateLanguageConfig = (langConfig) => {
    store.dispatch('actionBotLanguages', langConfig)
  }
  const selectLanguage = (langCode) => {
    selectLanguageInternal(langCode)
  }

  const selectLanguageInternal = (langCode, onPageReload = false) => {
    // check language code is contained in the list of available languages
    // in the store property languageChoices array of JSON objects
    const lang = $store.getters.getLanguageChoices.find((lang) => {
      return lang.code === langCode
    })

    if (lang === undefined) {
      console.error('Language code not found in languageChoices store array')
      return
    }

    // check langCode has a corresponding server config if not console error and return
    const serverConfig = getServerConfigForLanguage(langCode)
    if (serverConfig === undefined || serverConfig === {}) {
      console.error('Language code not found in serverConfigs store array')
      return
    }

    // retrieve the corresponding language json file
    // if the language object an url use fetch to retrieve the json file
    // if not import the json file from the assets folder

    if (lang && lang.url) {
      fetch(lang.url)
        .then(response => response.json())
        .then(data => {
          finishLanguageSelection(data, lang, onPageReload)
        })
        .catch(error => {
          console.error(error)
        })
    } else {
      const data = require(`@/assets/languages/${lang.code.toLowerCase()}-lang.json`)
      finishLanguageSelection(data, lang, onPageReload)
    }
  }

  const finishLanguageSelection = async (data, lang, onPageReload) => {
    updateLanguageConfig(data)
    await $store.dispatch('actionSelectLanguage', lang)
    updateAssistantHeader()
    Cookies.set('k_selected_language', lang.code)
    await clearWebviewConversation()
    passToNativeMethod('webviewLanguageChanged', lang)
    if (!onPageReload && $store.getters.getKaiSessionId === '' && $store.getters.getStartSession === true) {
      // if the language is not changed on page reload from kaiInit, check if startSession config is true and start a new session
      kserver.CoreAPI.startSession()
    }
  }

  var clearShortcutMenu = () => {
    return new Promise((resolve, reject) => {
      if ($store.getters.getRenderingStatus === 'idle') {
        $store.dispatch('actionShortcutPayload', false).then(() => {
          resolve()
        })
      } else {
        // watch $store for renderingStatus to be idle
        const unwatch = $store.watch((state) => state.renderingStatus, (newVal, oldVal) => {
          if (newVal === 'idle') {
            $store.dispatch('actionShortcutPayload', false).then(() => {
              resolve()
              unwatch()
            })
          }
        })
      }
    })
  }

  var closeShortcutPanel = () => {
    Cookies.set('k_shortcut_panel_opened', false)
    $store.dispatch('actionDisplayShortcutPanel', false)
  }

  const minimizeWebviewWidget = () => {
    Kai.API.sendUIEvent('webview_widget_minimized', 'header_minimized_button', 'clicked', `Minimized button's function overridden: ${!!$store.state.headerBarConfig.overrideMinimizeButtonClick}`)
    if ($store.state.headerBarConfig && $store.state.headerBarConfig.overrideMinimizeButtonClick) {
      $store.state.headerBarConfig.overrideMinimizeButtonClick()
    } else {
      $store.state.showWebviewWidget = false
      Cookies.set('k_webview_widget_opened', $store.state.showWebviewWidget)
    }
  }

  const closeWebviewWidget = () => {
    Kai.API.sendUIEvent('webview_widget_closed', 'header_closed_button', 'clicked', `Close button's function overridden: ${!!$store.state.headerBarConfig.overrideCloseButtonClick}`)
    if ($store.state.headerBarConfig && $store.state.headerBarConfig.overrideCloseButtonClick) {
      $store.state.headerBarConfig.overrideCloseButtonClick()
    } else {
      Kai.API.clearWebviewConversation()
      Kai.API.logoutSession(() => { $store.state.showWebviewWidget = false }) // showWebviewWidget callback function feels slow here.
      $store.state.showWebviewWidget = false
    }
  }

  const forceAppRerender = () => {
    $store.dispatch('actionAppKeyIndex')
  }

  const clearWebviewConversation = async () => {
    forceAppRerender()
    await clearShortcutMenu()
    store.dispatch('actionKaiSessionId', '')
    $store.state.liveChatStarted = false
  }

  const reestablishWebRTCConnection = () => {
    if ($store.state.liveChatStarted) {
      const multimediaConfig = Cookies.get('k_multimedia_config')
      const webRTCStatus = Cookies.get('k_webrtc_status')
      const livechatPanelStatesSize = Cookies.get('k_livechat_panel_states_size')
      if (multimediaConfig && webRTCStatus) {
        $store.dispatch('actionMultimediaConfig', JSON.parse(multimediaConfig))
        $store.dispatch('actionWebRTCStatus', JSON.parse(webRTCStatus))
        if (livechatPanelStatesSize) {
          $store.dispatch('actionLivechatPanelStates', { ...$store.state.livechatPanelStates, size: livechatPanelStatesSize })
        }
        $store.dispatch('actionReestablishWebRTCConnection', true)
      }
    }
  }

  const displayToastMessage = (message, optionalDurationMs) => {
    const payload = {
      toast_message: true,
      duration: optionalDurationMs || 3000,
      message: message
    }

    Kai.Core.inlinePopup(payload, 'TOAST_MESSAGE')
  }
  /****************************************************/

  /* MAKE SURE TO EXPORT YOUR FUNCTIONS */

  /****************************************************/

  return {
    kaiInit,
    dispatchWebviewEvent,
    passToNativeMethod,
    passToNativeWebviewCAPIRequestMethod,
    passToNativeWebviewCAPIResponseMethod,
    passToNativeWebviewCAPIErrorMethod,
    getVersion,
    getSessionId,
    sendUserMessage,
    sendHiddenUserMessage,
    sendHiddenUserMessageWithMetaFields,
    sendHiddenPostbackMessage,
    submitMessage,
    debugSubmitMessageHandler,
    buttonPressed,
    postBackMessage,
    deepLink,
    locationPressed,
    getLocation,
    locationError,
    sendLocation,
    addMessageContent,
    addCardContainer,
    addQuickReplies,
    addLiveChatNotification,
    checkIfContainsAvatar,
    checkIfContainsOnlyShortcuts,
    checkIfContainsOnlyRedirect,
    getThumbsImage,
    addContentButton,
    DatePicker,
    addDatePicker,
    IntroScreen,
    ListViewBase,
    removeQuickReplies,
    removeNbestBottom,
    handleCAPIRequest,
    handleCAPIResponse,
    handleCAPIError,
    handleResponse,
    typingIndicator,
    appendComponent,
    mapLocation,
    inlinePopup,
    debugMode,
    loadHistory,
    select,
    accessibilityContentScreenReader,
    // Remove Misc function and add inside a separate file.
    findGetParameter,
    isValidResponse,
    sendRequest,
    sendHiddenRequest,
    sendEnterTokenEvent,
    sendAuthPassThruEvent,
    sendProcessOAuthEvent,
    sendGenericEvent,
    startPullService,
    stopPullService,
    sendUserTypingStateToLiveChat,
    sendUIEvent,
    getHistory,
    logoutSession,
    submitSelection,
    handleInvalidSessionID,
    isApiVersionAtLeast,
    updateSessionCookie,
    removeSessionCookie,
    submitIntent,
    autoCompleteRequest,
    getAvatarFile,
    updateConfigAndThemeVariables,
    updateServerConfigs,
    getCurrentServerConfig,
    updateAssistantHeader,
    updateLanguageConfig,
    selectLanguage,
    clearShortcutMenu,
    closeShortcutPanel,
    minimizeWebviewWidget,
    closeWebviewWidget,
    clearWebviewConversation,
    forceAppRerender,
    reestablishWebRTCConnection,
    displayToastMessage
  }
}()) // End Core.

// For referencing in API as Kai.API.<function> instead of depreciated Kai.Core.<function>
const API = Core

// Export Core
export default {
  Core,
  API
}
