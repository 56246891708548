<template>
  <div
    v-if="medium.medium_url !== ''"
    class="kai-card-img"
  >
    <template v-if="isImage && hyperlink">
      <a
        target="_blank"
        @click="clickHyperlink(hyperlink)"
      >
        <img
          class="backup_picture img_with_hyperlink"
          :class="{large:medium.type!='SMALL_IMAGE'}"
          ondragstart="return false;"
          :src="source"
          alt=""
          @load="imageLoaded('image')"
        >
      </a>
    </template>
    <template v-else-if="isImage">
      <img
        class="backup_picture"
        :class="{large:medium.type!='SMALL_IMAGE'}"
        ondragstart="return false;"
        :src="source"
        alt=""
        @load="imageLoaded('image')"
      >
    </template>
    <div
      v-if="isYoutubeVideo"
      id="carousel-video"
      class="kai-video"
    >
      <iframe
        id="carouselVideo"
        title="Youtube embedded video"
        tabindex="-1"
        width="100%"
        height="100%"
        :src="source"
        frameborder="0"
        allow="autoplay; encrypted-media"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        @load="imageLoaded('youtube')"
      />
    </div>
    <div
      v-if="isVimeoVideo"
      id="carousel-video"
      class="kai-video"
      playsinline
      webkit-playsinline
    >
      <iframe
        id="carouselVideo"
        tabindex="-1"
        width="100%"
        :src="source"
        frameborder="0"
        webkit-playsinline
        playsinline
        @load="imageLoaded('vimeo')"
      />
    </div>
    <div
      v-if="isMP4Video"
      id="carousel-video-mp4"
      class="kai-video"
    >
      <video
        id="carouselVideo"
        tabindex="-1"
        playsinline
        width="100%"
        height="auto"
        controls
      >
        <!-- comment :loadeddata for now as it creates an infinite loop exception when calculating card height for expand button -->
        <!-- <source :loadeddata="imageLoaded('mp4')" :src="source" type='video/mp4'>Your browser does not support the video tag. -->
        <source
          :src="source"
          type="video/mp4"
        >{{ unsupportedMessage }}
      </video>
    </div>
  </div>
</template>

<script>
import MediumMixin from './Mixin/MediumMixin'
import './styles/Medium.less'

export default {
  name: 'MediumImage',
  mixins: [
    MediumMixin
  ],
  props: {
    medium: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isImage: false,
      isYoutubeVideo: false,
      isVimeoVideo: false,
      isMP4Video: false,
      source: '',
      hyperlink: '',
      unsupportedMessage: $store.getters.getBotLanguages.translations.medium.unsupportedMessage
    }
  },
  mounted () {
    if (this.medium.medium_url) {
      this.mediumContent(this.medium.medium_url, this.medium.type, this.medium.hyperlink_url)
    } else if (this.medium.payload.medium_url) {
      this.mediumContent(this.medium.payload.medium_url, this.medium.payload.type, this.medium.payload.hyperlink_url)
    }
  },
  methods: {
    clickHyperlink () {
      Kai.Core.sendUIEvent('medium_image_hyperlink', 'hyperlink', 'clicked', `URL opened: ${this.hyperlink}`)
      Kai.Core.buttonPressed({ type: 'HYPERLINK', payload: this.hyperlink })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>
