<template>
  <div class="kai-select-container">
    <div>
      <SelectCarousel
        v-if="response.default.payload.mode === 'CAROUSEL'"
        :is-disable="isDisable"
        :response="response"
      />
      <SelectList
        v-if="response.default.payload.mode === 'LIST'"
        :is-disable="isDisable"
        :response="response"
      />
      <SelectChecklist
        v-if="response.default.payload.mode === 'CHECKLIST' && !verifyContainExtraPayload()"
        :is-disable="isDisable"
        :response="response"
      />
      <SelectRating
        v-if="response.default.payload.mode === 'RATING'"
        :response="response"
      />
    </div>
  </div>
</template>

<script>
import SelectChecklist from './SelectChecklist'
import SelectList from './SelectList'
import SelectCarousel from './SelectCarousel'
import SelectRating from './SelectRating'

import {
  store
} from '../../store/index'

export default {
  name: 'Select',
  components: {
    SelectChecklist,
    SelectCarousel,
    SelectList,
    SelectRating
  },
  data () {
    return {
      response: this.$slots,
      selectedItemsIndex: [],
      isDisable: false
    }
  },
  created () {
    store.state.chipSelectedItem = []
  },
  mounted () {
    // Set max item select
    this.$root.$refs.SelectComp = this
    store.state.chipSelectedItemMax = this.response.default.payload.max_select // Refactor can use below selectResponseData

    // Init Check for pervious component on screen
    if (store.state.perviousCompValidation === undefined) {
      store.state.perviousCompValidation = this
    } else {
      // Disable perivous component and clear tags
      store.state.perviousCompValidation.isDisable = true
      // Update latest component
      store.state.perviousCompValidation = this
    }
  },
  methods: {
    verifyContainExtraPayload () {
      for (var i = 0; i < this.response.default.payload.options.length; i++) {
        if (this.response.default.payload.options[i].payload &&
                    Object.keys(this.response.default.payload.options[i].payload).length !== 0 &&
                    this.response.default.payload.options[i].payload.constructor === Object) {
          return true
        }
      }
      return false
    }
  }
}
</script>
