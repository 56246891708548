'use strict'

// sdp helper functions
export const sdpAttributeRemove = (sdp, attr) => {
  if (typeof sdp !== 'string' || typeof attr !== 'string') {
    return sdp
  }

  // find the first m= media-line and gather all payload types we're going to kill
  var sdpLines = sdp.split('\r\n')
  for (var i = 0; i < sdpLines.length;) {
    if (sdpLines[i] === attr) {
      sdpLines.splice(i, 1)
    } else {
      ++i
    }
  }

  // return the results
  return sdpLines.join('\r\n')
}

export const sdpAttributeRemoveCrypto = (sdp) => {
  const sdpLines = sdp.split('\r\n')

  // search for the first m= line
  for (let i = 0; i < sdpLines.length; i++) {
    if (sdpLines[i].substr(0, 9) === 'a=crypto:') {
      // delete it
      sdpLines.splice(i--, 1)
    }
  }

  // rejoin
  return sdpLines.join('\r\n')
}

export const sdpAttributeRemoveCodec = (sdp) => {
  if (typeof sdp !== 'string' || sdp.length === 1) {
    return sdp
  }

  // find the first m= media-line and gather all payload types we're going to kill
  var mLineOff = -1
  var sdpLines = sdp.split('\r\n')
  var pts = []
  for (var i = 0; i < sdpLines.length; i++) {
    if (mLineOff === -1) {
      // find the first m= line
      if (sdpLines[i].substring(0, 2) === 'm=') {
        mLineOff = i
      }
    } else {
      if (sdpLines[i].substring(0, 9) === 'a=rtpmap:') {
        // check all the codecs we're trying to kill
        for (var j = 1; j < sdp.length; ++j) {
          if (sdpLines[i].search(sdp[j] + '/') !== -1) {
            // it is something we're killing, get the payload type
            var pt = sdpLines[i].substring(9)
            const off = pt.search(' ')
            if (off !== -1) {
              pts.push(pt.substring(0, off))
            }
            break
          }
        }
      } else if (sdpLines[i].substring(0, 7) === 'a=fmtp:') { // check for an associated format type (i.e. one that references a format we're deleting)
        let off = sdpLines[i].search(' apt=')
        if (off !== -1) {
          // check if it's in our list of what we're killing
          let pt = sdpLines[i].substring(off + 5)
          for (let j = 0; j < pts.length; ++j) {
            if (pts[j] === pt) {
              // it is, add this new payload type too
              pt = sdpLines[i].substring(7)
              off = pt.search(' ')
              if (off !== -1) {
                pts.push(pt.substring(0, off))
              }
              break
            }
          }
        }
      }
    }
  }

  // now remove all the payload types we gathered
  if (pts.length && mLineOff !== -1) {
    // remove them all from the media line
    for (let i = 0; i < pts.length; ++i) {
      var mpt = sdpLines[mLineOff].search(' ' + pts[i])
      if (mpt !== -1) {
        sdpLines[mLineOff] = sdpLines[mLineOff].substring(0, mpt) + sdpLines[mLineOff].substring(mpt + pt.length + 1)
      }
    }

    // make it a tad easier to search the big list
    for (let i = 0; i < pts.length; ++i) {
      pts[i] = ':' + pts[i] + ' '
    }

    // remove from all the attributes
    let i = 0
    while (i < sdpLines.length) {
      var found = false
      for (let j = 0; j < pts.length; ++j) {
        if (sdpLines[i].search(pts[j]) !== -1) {
          found = true
          break
        }
      }
      if (found) {
        sdpLines.splice(i, 1)
      } else {
        ++i
      }
    }
    return sdpLines.join('\r\n')
  }

  // just return what we got
  return sdp
}

export const sdpAttributeInsert = (sdp, attr) => {
  var sdpLines = sdp.split('\r\n')

  // search for the first m= line
  for (var i = 0; i < sdpLines.length; i++) {
    if (sdpLines[i].substring(0, 2) === 'm=') {
      // insert the attribute here after and optional i/c/b =
      i++
      while (i < sdpLines.length) {
        var l = sdpLines[i].substring(0, 2)
        if (l === 'i=' || l === 'c=' || l === 'b=') {
          i++
        } else {
          break
        }
      }
      sdpLines.splice(i, 0, 'a=' + attr)
      return sdpLines.join('\r\n')
    }
  }

  // just return what we got
  return sdp
}

export const sdpAttributeFind = (sdp, attr) => {
  var sdpLines = sdp.split('\r\n')

  // search for the first m= line
  for (var i = 0; i < sdpLines.length; i++) {
    if (sdpLines[i].length >= attr.length && sdpLines[i].substring(0, attr.length) === attr) {
      // return the value of the attribute
      return sdpLines[i].substring(attr.length)
    }
  }

  // nothing
  return null
}

export const sdpPatchUpAnswer = (sdp, bandwidth) => {
  var sdpLines = sdp.split('\r\n')

  // search for the first m= line
  for (var i = 0; i < sdpLines.length;) {
    if (sdpLines[i].substring(0, 12) === 'a=rtpmap:102') { // currently always 102 for our server for Opus
      // add ptime AFTER a=rtpmap:
      sdpLines.splice(i + 1, 0, 'a=ptime:20')
      i += 2
    } else if (sdpLines[i].substring(0, 2) === 'b=') {
      // remove this attribute
      sdpLines.splice(i, 1)
    } else if (sdpLines[i].substring(0, 2) === 'c=') {
      // possibly put bandwidth after this
      if (typeof bandwidth === 'number' && bandwidth > 0) {
        sdpLines.splice(i + 1, 0, 'b=AS:' + bandwidth)
        i++
      }
      i++
    } else if (sdpLines[i].substring(0, 11) === 'a=revation-') {
      // remove this attribute
      sdpLines.splice(i, 1)
    } else if (sdpLines[i].substring(0, 7) === 'a=dbid:') {
      // remove this attribute
      sdpLines.splice(i, 1)
    } else if (sdpLines[i].substring(0, 8) === 'm=audio ' || sdpLines[i].substring(0, 8) === 'm=video ') {
      // add ice-lite at the session-level
      sdpLines.splice(i, 0, 'a=ice-lite')
      i++

      // replace the profile
      sdpLines[i] = sdpLines[i].replace('RTP/SAVP ', 'UDP/TLS/RTP/SAVPF ')
      // sdpLines[i] = sdpLines[i].replace("RTP/SAVP ", "TCP/DTLS/RTP/SAVPF ");
      i++
    } else {
      i++
    }
  }

  // rejoin
  return sdpLines.join('\r\n')
}
