
<template>
  <div
    v-if="!hideBubble"
    ref="cardItem"
    class="kai-user-wrapper kai-row clear"
    role="none"
    :class="[Position+'-wrapper', { 'show-icon' : ShowIcon && showMessageProfileImage}, {'hidden-icon': !ShowIcon && showMessageProfileImage}]"
  >
    <div class="kai-chat-wrapper">
      <!-- Chat Message Icon -->
      <MessageProfileImage
        v-if="Position === 'kai-left' && ShowIcon && showMessageProfileImage"
        aria-hidden="true"
      />
      <!-- Chat Message -->
      <div
        class="kai-chat-row"
        :class="[{'kai-answer-shown': kaiAnswerSource}]"
        role="none"
      >
        <div
          class="kai-chat-message"
          :aria-label="ScreenReaderLabel"
          role="text"
          :class="[Position, { 'img-space' : showMessageProfileImage === true && Position === 'kai-left'}, {'kai-answer-shown': kaiAnswerSource}, {'kai-stream-event': streamId}]"
          tabindex="0"
          @contextmenu="openContextMenu($event)"
        >
          <div
            v-if="Position === 'kai-left' && !isStream"
            class="left-border"
            aria-hidden="true"
          />
          <div
            v-if="Position === 'kai-right' && !isStream"
            class="right-border"
            aria-hidden="true"
          />
          <div
            v-if="kaiAnswerSource"
            class="kai-chat-message-title"
          >
            KAI Answers:
          </div>
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="Text && Text.length > 0"
            class="kai-chat-message-text"
            aria-hidden="true"
            :style="displayFixed && Position === 'kai-left' ? 'max-height:' + mainElementMaxHeight + 'px;': ''"
            :class="{fixed: displayFixed && showExpandButton}"
            v-html="Text"
          />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="showExpandButton && Position === 'kai-left'"
            v-tap="(e) => clickExpandButton(e)"
            class="card-expand-button-wrapper"
            :class="{expanded:isExpanded}"
          >
            <img
              v-if="!isExpanded"
              aria-hidden="true"
              class="card-expand-button"
              ondragstart="return false;"
              :src="expandIconSrc"
              width="20"
            >
            <img
              v-if="isExpanded"
              aria-hidden="true"
              class="card-expand-button"
              ondragstart="return false;"
              :src="collapseIconSrc"
              width="20"
            >
          </div>
        </div>
        <div
          v-if="isStream"
          class="kai-chat-caption"
          :class="{kai_chat_no_title: !streamTitle}"
        >
          <div
            v-if="streamTitle"
            class="kai-chat-caption-title"
          >
            {{ streamTitle }}
          </div>
          <div
            ref="copyStream"
            class="kai-chat-caption-copy"
          >
            <!-- eslint-disable vue/no-v-html -->
            <svg
              width="13"
              height="15"
              fill="none"
              viewBox="0 0 13 15"
              xmlns="http://www.w3.org/2000/svg"
              @click="onClickStreamCopy()"
              v-html="getMenuItemsSVGStrings().copy"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </div>

        <div
          v-if="kaiAnswerSource"
          class="kai-chat-message-text kai-answer-source kai-gap-10"
        >
          <div class="kai-flex-wrapper kai-gap-10">
            <span>Source:</span>
            <a
              :href="kaiAnswerSource.source_url"
              target="_blank"
            >{{ kaiAnswerSource.source_title }}</a>
          </div>
          <div
            class="list-inline-button-item"
            tabindex="0"
            navLvl="1"
            navDir="vertical"
            @click="onClickSend()"
          >
            <div class="list-inline-button-text">
              Send
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="kaiAnswerSource && Position === 'kai-left'"
        class="context-menu-wrap"
      >
        <KaiAnswerContextMenu
          :message="Text"
          :kai-answer-source="kaiAnswerSource"
          :context-menu-position="contextMenuPosition"
          :is-open="showContextMenu"
          :enabled-options="contextMenuEnabledOptions"
          @open="openContextMenu"
          @close="closeContextMenu"
          @delete="onDeleteClicked"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MessageProfileImage from './MessageProfileImage'
import KaiAnswerContextMenu from './KaiAnswerContextMenu'
import TextMixin from './Mixin/TextMixin'
import CardMixin from './Mixin/CardMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import DOMPurify from 'dompurify'

import './styles/TextBubble.less'

export default {
  name: 'TextBubble',
  templateOverride: '#textBubble-override',
  components: {
    MessageProfileImage,
    KaiAnswerContextMenu
  },
  mixins: [
    TextMixin,
    CardMixin,
    ImageUtilityMixin
  ],
  props: {
    payload: {
      type: Object,
      required: false,
      default: () => (undefined)
    }
  },
  data () {
    return {
      fontSize: window.$store.getters.getTextMessageFontSize,
      isTextBubble: true,
      cardMainElement: 'kai-chat-message-text',
      // card elements that enter in consideration to calculate the main element maximum Height
      cardElements: [],
      showMessageProfileImage: false,
      showContextMenu: false,
      contextMenuPosition: {},
      contextMenuEnabledOptions: {
        edit: true,
        copy: true,
        save: true,
        delete: true,
        viewSource: true
      },
      hideBubble: false,
      copyOngoing: false
    }
  },
  computed: {
    // a computed getter
    streamId () {
      if (this.payload &&
          'data' in this.payload &&
          'stream_id' in this.payload.data) {
        return this.payload.data.stream_id
      }
      return null // Add a default return statement
    },
    streamTitle () {
      if (this.isStream && 'title' in this.payload.data) {
        return this.payload.data.title
      }
      return null // Add a default return statement
    },
    isStream () {
      return this.streamId || (this.payload && this.payload.type === 'TEXT_STREAM')
    },
    mutatedTextStream () {
      if (this.streamId && this.streamId in $store.getters.getPendingTextStream && 'content' in $store.getters.getPendingTextStream[this.streamId]) {
        return $store.getters.getPendingTextStream[this.streamId].content
      }
      return null // Add a default return statement
    },
    Text () {
      var text
      if (this.payload && this.payload.message && this.payload.message.length > 0) {
        text = this.linkify(this.payload.message)
      } else if (this.$slots.default && this.$slots.default.message && this.$slots.default.message.kaiAnswer) {
        text = this.$slots.default.message.kaiAnswer.answer
      } else if (this.$slots.default && this.$slots.default.message && this.$slots.default.message.length > 0) {
        text = this.linkify(this.$slots.default.message)
      } else if (this.isStream) {
        if (this.streamId in $store.getters.getPendingTextStream && 'content' in $store.getters.getPendingTextStream[this.streamId]) {
          $store.state.pendingTextStream[this.streamId].instantiated = true
          text = this.linkify($store.getters.getPendingTextStream[this.streamId].content)
        } else {
          text = this.linkify(this.payload.data.content)
        }
      }
      return this.lineBreak(text)
    },
    kaiAnswerSource () {
      if (this.$slots.default && this.$slots.default.message && this.$slots.default.message.kaiAnswer && this.$slots.default.message.kaiAnswer.sources) {
        // Note for now Kai Answers only provide one source so we return the fist item in source array
        return this.$slots.default.message.kaiAnswer.sources[0]
      }
      return false
    },
    Position () {
      if (this.payload && this.payload.position && this.payload.position.length > 0) {
        return this.payload.position
      } else {
        // `this` points to the vm instance
        return this.$slots.default.position
      }
    },
    ShowIcon () {
      if (this.payload) {
        if (this.payload.showIcon) {
          return true
        } else {
          return false
        }
      } else {
        // `this` points to the vm instance
        if (this.$slots.default && this.$slots.default.showIcon) {
          return this.$slots.default.showIcon
        } else {
          return false
        }
      }
    },
    ScreenReaderLabel () {
      var screenReaderCurrentDate = moment().format('hh:mm A')
      var screenReader = $store.getters.getBotLanguages.translations.screenReader
      var sender = screenReader.userDesignation + ' ' + screenReader.say

      if (this.Position === 'kai-left' || this.Position === 'kai-right') {
        if (this.Position === 'kai-left' && this.ShowIcon) {
          sender = screenReader.virtualAssistantDesignation + ' ' + screenReader.said
        }
        // return screenReaderCurrentDate + '.  ' + screenReader.sentBy + ' ' + sender + '. ' + this.Text
        return screenReader.timeSendAt + ' ' + screenReaderCurrentDate + ',  ' + sender + ': ' + this.Text
      } else {
        return ''
      }
    }
  },
  watch: {
    mutatedTextStream (newText, oldText) {
      this.text = newText
    }
  },
  mounted () {
    this.showMessageProfileImage = !$store.state.useAvatar || $store.getters.isLiveAgentConnected
  },
  methods: {
    openContextMenu (e) {
      const shouldSkipContextMenu =
      !this.kaiAnswerSource || this.Position !== 'kai-left' ||
        e.target.tagName.toLowerCase() === 'a'
      if (shouldSkipContextMenu || getSelection().toString()) {
        return
      }
      e.preventDefault()
      this.contextMenuPosition = {
        x: e.pageX || e.clientX,
        y: e.pageY || e.clientY
      }
      this.showContextMenu = true
    },
    closeContextMenu () {
      this.showContextMenu = false
      this.contextMenuPosition = { x: null, y: null }
    },
    onDeleteClicked () {
      this.hideBubble = true
    },
    onClickSend () {
      Kai.API.dispatchWebviewEvent('onKaiAnswersSend', { message: this.Text, suggestion_source: this.kaiAnswerSource })
    },
    async onClickStreamCopy () {
      if (!this.copyOngoing) {
        this.copyOngoing = true
        const svg = this.$refs.copyStream.querySelector('svg')
        const originalIcon = svg.innerHTML
        svg.innerHTML = this.getMenuItemsSVGStrings().copied
        setTimeout(() => {
          svg.innerHTML = originalIcon
          this.copyOngoing = false
        }, 1250)
        try {
          // use DOMPurify to sanitize the text before copying it to the clipboard
          // remove all the html tags and keep only the text rendered
          const div = document.createElement('div')
          div.innerHTML = DOMPurify.sanitize(this.Text)
          const textToCopy = div.textContent || div.innerText || ''
          await navigator.clipboard.writeText(textToCopy)
        } catch (error) {
          throw new Error(`Unable to copy text to clipboard: ${error.message}`)
        }
      }
    }
  }
}
</script>
