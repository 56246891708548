<template>
  <section
    v-if="$store.state.showShortCutMenu && shortcutMenuContent && !isLiveChatStarted"
    class="shortcut-container"
  >
    <div
      ref="keyboardWrapper"
      class="shortcut-wrapper"
      navDir="horizontal"
    >
      <div
        class="shortcut-content"
        :class="{shortcut_content_open:displayShortcutPanel}"
        role="region"
        :style="'max-height: ' + shortcutMaxHeight + 'px;'"
        :aria-roledescription="screenReaderTranslations.shortcutMenu"
      >
        <h1>
          <span>
            <!--  eslint-disable vue/html-self-closing -->
            <!-- eslint-disable vue/no-v-html -->
            <svg
              class="svg-heart"
              width="18px"
              height="16px"
              viewBox="0 0 18 16"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              v-html="heartLogoSvg"
            ></svg>
            <!--  eslint-enable vue/html-self-closing -->
            <!-- eslint-enable vue/no-v-html -->
            {{ shortcutMenuTitle }}
          </span>
        </h1>
        <hr>
        <div
          v-for="(shortcutItem, itemIndex) in shortcutMenuContent"
          :key="itemIndex"
          class=""
        >
          <template v-if="shortcutItem != undefined">
            <div class="shortcut-header">
              {{ shortcutItem.payload.title }}
              <span
                role="button"
                :aria-label="screenReaderTranslations.shortcutInfoButton"
                class="shortcut-info-btn kai-mouse-pointer"
                @click="shortcutInfoShow(shortcutItem.payload.title, shortcutItem.payload.subtitle)"
              ><img
                :src="infoIcon"
                alt=""
              ></span>
            </div>
            <div class="shortcut-item-wrapper">
              <div
                v-for="(shortcutList, innerItemIndex) in shortcutItem.payload.buttons"
                :key="innerItemIndex"
              >
                <div
                  class="shortcut-item kai-mouse-pointer"
                  role="button"
                  navLvl="1"
                  navDir="horizontal"
                  :aria-label="shortcutList.label"
                  :tabindex="displayShortcutPanel? '0': '-1'"
                  @click="sendShortcutItem(shortcutList)"
                >
                  {{ shortcutList.label }}
                </div>
              </div>
            </div>
            <div class="clear-it" />
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import './styles/ShortcutPanel.less'
import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import popupShortcutInfoJsonData from '../../debug/popup_shortcut_info.json'
import Cookies from 'js-cookie'

export default {
  name: 'ShortcutPanel',
  mixins: [
    KeyboardNavigationMixin,
    ImageUtilityMixin
  ],
  props: [],
  data () {
    return {
      extraStyle: 'width: 14.4px; top: calc(50% - 5.5px); left: 3px;',
      infoIcon: this.getImagePath('svg/info_icon.svg'),
      screenReaderTranslations: $store.getters.getBotLanguages.translations.screenReader,
      heartLogoSvg: this.getHeartInnerSVGString(),
      previousContent: null,
      shortcutMaxHeight: 450 // Max height of shortcut panel in px
    }
  },
  computed: { // Listen to see if store state change
    shortcutMenuContent () {
      return this.$store.getters.getShortcutPayloadContent
    },
    isLiveChatStarted () {
      return this.$store.getters.isLiveChatStarted
    },
    shortcutMenuTitle () {
      return $store.getters.getBotLanguages.translations.shortcutMenu.title
    },
    displayShortcutPanel () {
      return this.$store.getters.getDisplayShortcutPanel
    }
  },
  watch: {
    shortcutMenuContent () {
      const content = this.$store.getters.getShortcutPayloadContent
      if (!content) {
        if (this.displayShortcutPanel) {
          this.closeShortcutPanel()
        }
      } else {
        if (content !== this.previousContent) {
          content.sort(this.compareShortcutsPriority)
          this.previousContent = content
        }
      }
    },
    isLiveChatStarted () {
      if (document.querySelector('.shortcut-wrapper') !== null) {
        // TODO: hide shortcut menu button when live chat is started
      }
    },
    displayShortcutPanel () {
      if (this.displayShortcutPanel) {
        this.shortcutMaxHeight = 450
        this.$nextTick(() => {
          const shortcutTop = document.querySelector('.shortcut-container').getBoundingClientRect().top
          const bottomBarTop = document.querySelector('.kai-bottom-bar').getBoundingClientRect().top
          this.shortcutMaxHeight = Math.min(bottomBarTop - shortcutTop, 450)
          // make shortcut menu content scroll back to top when it's reopened
          document.querySelector('.shortcut-content').scrollTop = 0
        })
      }
    }
  },
  methods: {
    closeShortcutPanel () {
      Cookies.set('k_shortcut_panel_opened', !this.displayShortcutPanel)
      this.$store.dispatch('actionDisplayShortcutPanel', !this.displayShortcutPanel)
    },
    sendShortcutItem (shortcutItem) {
      Kai.Core.buttonPressed(shortcutItem)
      Kai.API.sendUIEvent(`shortcut_menu_${this.displayShortcutPanel ? 'opened' : 'closed'}`, 'shortcut_item_button', 'clicked', `Shortcut Menu ${this.shortcutPanelOpen ? 'Opened' : 'Closed'}`)
    },
    shortcutInfoShow (header, listinfo) {
      const popup = popupShortcutInfoJsonData.message_contents[0].payload.payload
      popup.title = header
      popup.subtitle = listinfo

      Kai.Core.inlinePopup(popup, 'SHORTCUT_INFO')
      Kai.API.sendUIEvent('shortcut_menu_info', 'info_button', 'clicked', popup && popup.title ? `Info displayed for "${popup.title}"` : null)
    },
    compareShortcutsPriority: (a, b) => {
      if (!a.webviewPriority && !b.webviewPriority) { return 0 }
      if (!a.webviewPriority && b.webviewPriority) { return -1 }
      if (a.webviewPriority && !b.webviewPriority) { return 1 }
      if (a.webviewPriority < b.webviewPriority) { return -1 }
      if (a.webviewPriority > b.webviewPriority) { return 1 }
      if (a.webviewPriority === b.webviewPriority) { return 0 }
      return 0
    }
  }
}
</script>
