<template>
  <div
    v-if="isActivePanel"
    ref="keyboardWrapper"
  >
    <div

      class="kai-nbest-bottom-panel"
      tabindex="0"
      navLvl="1"
      navDir="horizontal"
      @click="onTitleClick"
    >
      <div
        class="kai-nbest-bottom-panel-title-container"
      >
        <div class="kai-nbest-horizontal-bar" />
        <div class="kai-nbest-bottom-panel-title">
          {{ title }}
        </div>
      </div>
      <div

        :class="{'kai-nbest-container-open': openPanel, 'kai-nbest-off-bottom': !isScrolledToBottom, 'kai-nbest-off-top': !isScrolledToTop}"
        class="kai-nbest-bottom-panel-item-container"
      >
        <div

          ref="nbestScrollbox"
          class="kai-nbest-bottom-panel-scrollbox"
          @scroll="onScroll"
        >
          <div
            v-for="button in buttonsPayload"
            :key="button.id"
            class="kai-nbest-bottom-panel-item kai-mouse-pointer"
            role="button"
            :aria-label="button.label"
            :tabindex="openPanel ? '0' : '-1'"
            navLvl="2"
            navDir="both"
            @click="onNbestPressed(button)"
          >
            {{ button.label }}
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="button.style && button.style === 'GENERATED'"
              ref="clipImage"
              class="kai-nbest-image"
              v-html="generatedIcon"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import Cookies from 'js-cookie'

export default {
  name: 'NbestBottomPanel',
  mixins: [
    KeyboardNavigationMixin,
    ImageUtilityMixin
  ],
  data () {
    return {
      isActivePanel: false,
      openPanel: Cookies.get('k_nbest_bottom_panel_opened') !== 'false',
      isScrolledToBottom: true,
      isScrolledToTop: true,
      defaultTitle: window.$store.getters.getBotLanguages.translations.nBest.relatedQuestionsTitle,
      generatedIcon: this.getGeneratedSVGString()
    }
  },
  computed: {
    nbestBottomPanel () {
      return $store.getters.getNbestBottomPanel
    },
    buttonsPayload () {
      return this.nbestBottomPanel.contents.filter(content => content.type === 'BUTTON').map(button => button.payload)
    },
    title () {
      const kaiAnswersSuggestions = this.nbestBottomPanel.contents.find(content => content.type === 'CUSTOM' && content.payload && content.payload.type === 'KAI_ANSWERS_SUGGESTIONS')
      return (kaiAnswersSuggestions && kaiAnswersSuggestions.payload && kaiAnswersSuggestions.payload.data && kaiAnswersSuggestions.payload.data.container_title) || this.defaultTitle
    }
  },
  watch: {
    nbestBottomPanel (newVal, oldVal) {
      if (newVal !== oldVal) {
        const hasButtons = newVal && newVal.contents && newVal.contents.map(content => content.type === 'BUTTON').length > 0
        this.isActivePanel = hasButtons
        this.openPanel = hasButtons && Cookies.get('k_nbest_bottom_panel_opened') !== 'false'
        if (hasButtons) {
          this.$nextTick(() => this.onScroll())
        }
      }
    }
  },
  methods: {
    onNbestPressed (quickReply) {
      this.isActivePanel = false
      Kai.Core.buttonPressed(quickReply)
      $store.dispatch('actionNbestBottomPanel', undefined)
    },
    onScroll () {
      const nbestScrollbox = this.$refs.nbestScrollbox
      this.isScrolledToBottom = nbestScrollbox.scrollHeight - nbestScrollbox.scrollTop === nbestScrollbox.clientHeight
      this.isScrolledToTop = nbestScrollbox.scrollTop === 0
    },
    onTitleClick () {
      this.openPanel = !this.openPanel
      Kai.API.sendUIEvent(`nbest_bottom_panel_${this.openPanel ? 'opened' : 'closed'}`, 'title_button', 'clicked', `Related Information Nbest Bottom Panel ${this.openPanel ? 'Opened' : 'Closed'}`)
      Cookies.set('k_nbest_bottom_panel_opened', this.openPanel)
    }
  }
}
</script>

<style>
.kai-nbest-bottom-panel {
  bottom: var(--bottom-bar-height);
  padding: 5px 10px;
  font-size: 15px;
  width: 100%;
  z-index: 12;
  border-top: 1px solid #ececec;
  color: var(--text-color);
}

.kai-nbest-bottom-panel div,.kai-nbest-bottom-panel p,.kai-nbest-bottom-panel span {
  color: inherit;
}

.kai-nbest-bottom-panel:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}

.kai-nbest-bottom-panel-item-container {
  overflow: hidden;
  position: relative;
  max-height: 0;
  transition: all .5s ease-in-out;
  transition-property: max-height;
}

.kai-nbest-bottom-panel-item-container.kai-nbest-container-open {
  max-height: 140px;
}

.kai-nbest-bottom-panel-scrollbox {
  height:100%;
  overflow:auto;
  max-height: 140px;
}

.kai-nbest-bottom-panel-item-container.kai-nbest-off-bottom:after {
  content: '';
  height: 35px;
  width: 100%;
  background: linear-gradient(var(--background-gradient-starting-color-rgb) 0px, var(--background-gradient-ending-color-rgb));
  position: absolute;
  pointer-events: none;
  left: 0;
  bottom: 0;
}

.kai-nbest-bottom-panel-item-container.kai-nbest-off-top:before {
  content: '';
  height: 25px;
  width: 100%;
  background: linear-gradient(var(--background-gradient-ending-color-rgb) 0px, var(--background-gradient-starting-color-rgb));
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
}

.kai-nbest-bottom-panel-item {
  font-family: 'Lato', 'Avenir', Helvetica, Arial, sans-serif;
  background-color: #ccc;
  width: auto;
  padding: 6px 10px;
  border-radius: 10px;
  float: left;
  font-size: 12px;
  font-weight: bold;
  margin: 5px 10px 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 5px;
}

.kai-nbest-bottom-panel-item:hover {
  background-color: #999;
}

.kai-nbest-bottom-panel-item:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}

.kai-nbest-bottom-panel-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.kai-nbest-bottom-panel-title {
  font-family: 'Lato', 'Avenir', Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  margin: 5px 0 5px 0;
  text-align: center;
}

.kai-nbest-horizontal-bar {
  width: 30px;
  height: 3px;
  background-color: #ccc;
  border-radius: 15px;
}

.kai-nbest-image {
    width: 15px;
    height: 15px;
    position: relative;
    display: inline-flex;
    text-align: center;
    vertical-align: middle;
    color: var(--generated-icon-color);
    margin: 0;
    /* margin-bottom: 10px;*/
}

.kai-nbest-image>svg {
    height: 100%;
}
</style>
