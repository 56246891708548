<template>
  <div class="context-menu">
    <button
      data-v-3ae18178=""
      type="submit"
      class="button clear button--only-icon small secondary"
      @click="handleOpen"
    >
      <svg
        data-v-335c864d=""
        width="14"
        height="14"
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon--font more-vertical"
      >
        <path
          d="M12 7.75a1.75 1.75 0 1 1 0-3.5 1.75 1.75 0 0 1 0 3.5ZM12 13.75a1.75 1.75 0 1 1 0-3.5 1.75 1.75 0 0 1 0 3.5ZM10.25 18a1.75 1.75 0 1 0 3.5 0 1.75 1.75 0 0 0-3.5 0Z"
          fill="currentColor"
        />
      </svg>
    </button>
    <ContextMenu
      v-if="isOpen && !isCannedResponseModalOpen"
      :x="contextMenuPosition.x"
      :y="contextMenuPosition.y"
      @close="handleClose"
    >
      <div class="menu-container">
        <menu-item
          v-if="enabledOptions['edit']"
          :option="{
            icon: 'edit',
            label: 'Edit',
          }"
          variant="icon"
          @click="onEditClicked"
        />
        <menu-item
          v-if="enabledOptions['copy']"
          :option="{
            icon: 'clipboard',
            label: 'Copy',
          }"
          variant="icon"
          @click="handleCopy"
        />
        <menu-item
          v-if="enabledOptions['save']"
          :option="{
            icon: 'save',
            label: 'Save',
          }"
          variant="icon"
          @click="handleSave"
        />
        <menu-item
          v-if="enabledOptions['delete']"
          :option="{
            icon: 'delete',
            label:'Delete',
          }"
          variant="icon"
          @click="deleteSuggestion"
        />
        <hr v-if="enabledOptions['viewSource']">
        <menu-item
          v-if="enabledOptions['viewSource']"
          :option="{
            icon: 'eye-show',
            label: 'View Source',
          }"
          variant="icon"
          @click="onViewSourceClicked"
        />
      </div>
    </ContextMenu>
  </div>
</template>
<script>

import ContextMenu from './ContextMenu'
import MenuItem from './MenuItem'

export default {
  name: 'KaiAnswerContextMenu',
  components: {
    ContextMenu,
    MenuItem
  },
  props: {
    message: {
      type: String,
      required: true
    },
    kaiAnswerSource: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    enabledOptions: {
      type: Object,
      default: () => ({})
    },
    contextMenuPosition: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      isCannedResponseModalOpen: false,
      showTranslateModal: false,
      showDiscardModal: false
    }
  },
  computed: {
  },
  methods: {
    async handleCopy () {
      await this.copyTextToClipboard(this.message)
      // this.showAlert('Copied to clipboard')
      this.handleClose()
    },
    async handleSave () {
      // await copyTextToClipboard(this.plainTextContent);
      // this.showAlert('Saved successfully')
      this.handleClose()
    },
    onEditClicked (data) {
      Kai.API.dispatchWebviewEvent('onKaiAnswersEdit', { message: this.message, suggestion_source: this.kaiAnswerSource })
      this.handleClose()
    },
    onViewSourceClicked (data) {
      // TODO: implement
      this.handleClose()
    },
    deleteSuggestion () {
      this.$emit('delete')
    },
    handleOpen (e) {
      this.$emit('open', e)
    },
    handleClose (e) {
      this.$emit('close', e)
    },
    async copyTextToClipboard (text) {
      try {
        await navigator.clipboard.writeText(text)
      } catch (error) {
        throw new Error(`Unable to copy text to clipboard: ${error.message}`)
      }
    }
  }
}
</script>
<style scoped>

.menu-container {
  max-width: 13rem;
  border-radius: 0.375rem;
  background-color: var(--component-background-color);
  padding: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 8px 10px -6px;
  background-color: rgb(255 255 255 / 1);

}

.button {
    margin-bottom: 0px;
    display: inline-flex;
    height: 2.5rem;
    align-items: center;
}
.button.small {
    font-size: 0.75rem;
    font-size: var(--font-size-mini);
}
.button.clear {
    border: 1px solid #1f93ff;
    color: #1f93ff;
}
.button.clear, .button.clear:hover, .button.clear:focus {
    background-color: transparent;
}
.button.small {
    height: 2rem;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
}
.button.button--only-icon {
    width: 2.5rem;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
}
.button.clear.secondary {
    border: 1px solid #5d7592;
    color: #5d7592;
}
.button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
    border-color: transparent;
}
.button.clear.secondary {
    color: hsl(207 5.6% 31.6% / 1);
}
.button.button--only-icon.small {
    width: 2rem;
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.cursor-pointer {
    cursor: pointer;
}
.z-\[9999\] {
    z-index: 9999;
}
.fixed {
    position: fixed;
}
.cursor-pointer {
    cursor: pointer;
}
</style>
