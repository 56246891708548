<template>
  <div class="kai-card-piechart">
    <div id="chart-wrap">
      <apexcharts
        id="chart"
        ref="chart"
        :width="cardWidth"
        heigth="auto"
        type="pie"
        :options="chartOptions"
        :series="series"
        @click="onChartClickEvent"
        @mounted="onChartMountedEvent"
      />
    </div>
  </div>
</template>

<script>
import MediumMixin from './Mixin/MediumMixin'
import MediumPieChartMixin from './Mixin/MediumPieChartMixin'
import VueApexCharts from 'vue-apexcharts'
import './styles/Medium.less'

export default {
  name: 'MediumPieChartDiagram',
  components: {
    apexcharts: VueApexCharts
  },
  mixins: [
    MediumMixin,
    MediumPieChartMixin
  ],
  props: {
    medium: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      cardWidth: getComputedStyle(document.querySelector('.webview--app')).getPropertyValue('--card-item-width').slice(0, -2)
    }
  },
  computed: {
    chartOptions (options) {
      options.options.chart = {
        ...options.options.chart,
        ...{ defaultLocale: $store.getters.getBotLanguages.language, locales: [$store.getters.getBotLanguages.translations.charts] }
      }
      return options.options
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
