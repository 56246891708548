<template>
  <div
    class="live-chat-widget"
    :class="{ live_chat_open_up: isLiveChatStarted && openUp, live_chat_open_down: isLiveChatStarted && !openUp }"
  >
    <div class="live-chat-widget-inner-container">
      <div
        v-if="isLiveAgentConnected"
        class="live-chat-widget-feature-container"
      >
        <div
          v-if="multimediaConfig.attachment"
          class="live-chat-button live-chat-image live-chat-attachment-button kai-tooltip"
          tabindex="0"
          @mouseover="buttonImagesSrc.attachment = buttonImagesIcons.attachment_white"
          @mouseleave="buttonImagesSrc.attachment = buttonImagesIcons.attachment"
          @click="chooseFile()"
        >
          <img
            :src="buttonImagesSrc.attachment"
            alt=""
          >
          <span class="kai-tooltip-text">
            {{ attachmentTooltip }}
          </span>
        </div>
        <div
          v-if="multimediaConfig.voice"
          :class="{ 'live-chat-voice-button': !isAudioSharing, 'live-chat-end-call-button': isAudioSharing }"
          class="live-chat-button live-chat-image kai-tooltip"
          tabindex="0"
          @mouseover="buttonImagesSrc.voice = isAudioSharing ? buttonImagesIcons.voice_white : buttonImagesIcons.voice_white"
          @mouseleave="buttonImagesSrc.voice = isAudioSharing ? buttonImagesIcons.voice_white : buttonImagesIcons.voice"
          @click=" isAudioSharing ? endVoiceCall() : isWebRTCActive ? triggerVoiceCall(): displayVoiceConfirmation()"
        >
          <img
            :src="buttonImagesSrc.voice"
            alt=""
          >
          <span class="kai-tooltip-text">
            {{ isAudioSharing ? endCallTooltip : voiceTooltip }}
          </span>
        </div>
        <div
          v-if="isAudioSharing"
          :class="{ 'live-chat-mute-button-pressed': isMuted }"
          class="live-chat-button live-chat-image live-chat-mute-button kai-tooltip"
          tabindex="0"
          @mouseover="buttonImagesSrc.mute = isMuted ? buttonImagesIcons.mute_white : buttonImagesIcons.mute_white"
          @mouseleave="buttonImagesSrc.mute = isMuted ? buttonImagesIcons.mute_white : buttonImagesIcons.mute"
          @click="toggleMute()"
        >
          <img
            :src="buttonImagesSrc.mute"
            alt=""
          >
          <span class="kai-tooltip-text">
            {{ isMuted ? unmuteTooltip : muteTooltip }}

          </span>
        </div>
        <div
          v-if="multimediaConfig.video"
          :class="{ 'live-chat-video-button-pressed': isVideoSharing }"
          class="live-chat-button live-chat-image live-chat-video-button kai-tooltip"
          tabindex="0"
          @mouseover="buttonImagesSrc.video = isVideoSharing ? buttonImagesIcons.video_white : buttonImagesIcons.video_white"
          @mouseleave="buttonImagesSrc.video = isVideoSharing ? buttonImagesIcons.video_white : buttonImagesIcons.video"
          @click=" isVideoSharing ? endVideoSharing() : isWebRTCActive ? triggerVideoSharing() : displayVideoConfirmation()"
        >
          <img
            :src="buttonImagesSrc.video"
            alt=""
          >
          <span class="kai-tooltip-text">
            {{ isVideoSharing ? endVideoTooltip : videoTooltip }}
          </span>
        </div>
        <div
          v-if="multimediaConfig.desktopSharing"
          :class="{ 'live-chat-desktop-sharing-button-pressed': isDesktopSharing }"
          class="live-chat-button live-chat-image live-chat-desktop-sharing-button kai-tooltip"
          tabindex="0"
          @mouseover="buttonImagesSrc.desktopSharing = isDesktopSharing ? buttonImagesIcons.desktopSharing_white : buttonImagesIcons.desktopSharing_white"
          @mouseleave="buttonImagesSrc.desktopSharing = isDesktopSharing ? buttonImagesIcons.desktopSharing_white : buttonImagesIcons.desktopSharing"
          @click=" isDesktopSharing ? endDesktopSharing() : isWebRTCActive ? triggerDesktopSharing() : displayRDSConfirmation()"
        >
          <img
            :src="buttonImagesSrc.desktopSharing"
            alt=""
          >
          <span class="kai-tooltip-text">
            {{ isDesktopSharing ? endDesktopSharingTooltip : desktopSharingTooltip }}
          </span>
        </div>
        <div
          v-if="multimediaConfig.video || multimediaConfig.voice || multimediaConfig.desktopSharing"
          class="live-chat-button live-chat-image live-chat-device-settings-button kai-tooltip"
          tabindex="0"
          @mouseover="buttonImagesSrc.deviceSettings = buttonImagesIcons.deviceSettings_white"
          @mouseleave="buttonImagesSrc.deviceSettings = buttonImagesIcons.deviceSettings"
          @click="displaySettings()"
        >
          <img
            :src="buttonImagesSrc.deviceSettings"
            alt=""
          >
          <span class="kai-tooltip-text">
            {{ deviceSettingsTooltip }}
          </span>
        </div>
      </div>
      <div class="live-chat-widget-end-container">
        <div
          class="live-chat-button live-chat-image live-chat-end-button kai-tooltip"
          tabindex="0"
          @click="endLiveChatConfirmation()"
        >
          <img
            :src="buttonImagesSrc.endChat_white"
            alt=""
          >
          <span class="kai-tooltip-text">
            {{ endChatTooltip }}
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="inlinePopupOpen && isLiveChatStarted && !openUp && !isToastMessage"
      class="inline-popup-wrapper"
    />
    <live-chat-widget-panel
      v-if="isLiveChatStarted && openUp"
      :mode="livechatPanelStates.mode"
      :panel-size="livechatPanelStates.size"
      :subtitle="livechatPanelStates.subtitle"
      :description="livechatPanelStates.description"
      :confirm="livechatPanelStates.confirmFunction"
      :cancel-label="livechatPanelStates.cancelLabel"
      :confirm-label="livechatPanelStates.confirmLabel"
    />
  </div>
</template>
<script>
import Kai from '../../kai'
import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import './styles/LiveChatWidget.less'
import popupEndLivechatJsonData from '../../debug/popup_end_livechat.json'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import LiveChatButtonsMixin from './Mixin/LiveChatButtonsMixin'
import LiveChatWidgetPanel from './LiveChatWidgetPanel.vue'
import Cookies from 'js-cookie'

export default {
  name: 'LiveChatWidget',
  components: { LiveChatWidgetPanel },
  mixins: [
    KeyboardNavigationMixin,
    ImageUtilityMixin,
    LiveChatButtonsMixin
  ],
  props: {
    openUp: {
      type: Boolean
    }
  },
  computed: {
    reestablishWebRTCConnection () {
      return this.$store.getters.getReestablishWebRTCConnection
    }
  },
  watch: {
    async newCustomEvents (events) {
      if (events.length > 0) {
        for (let i = 0; i < events.length; i++) {
          await this.lastCustomEvent(events[i])
        }
      }
    },
    async reestablishWebRTCConnection () {
      if (this.reestablishWebRTCConnection) {
        this.$store.dispatch('actionReestablishWebRTCConnection', false)
        const webRTCStatus = this.$store.getters.getWebRTCStatus
        if (webRTCStatus.audioUser) {
          this.endVoiceCall()
          this.triggerVoiceCall(true)
        }
        if (webRTCStatus.videoUser) {
          this.endVideoSharing()
          this.triggerVideoSharing()
        }
        if (webRTCStatus.rdsUser) {
          this.endDesktopSharing()
          this.triggerDesktopSharing()
        }
        if (webRTCStatus.rdsAgent) {
          const auth = webRTCStatus.rdsAgent.data.auth
          const offer = await this.webrtc.rdsRemote.start(auth)
          // CAPI event to start rds
          const newPayload = {
            event_type: 'RdsUserJoin',
            data: {
              cmd: 'rds',
              sdp: offer.sdp
            }
          }
          kserver.CoreAPI.sendLiveChatCustomRequest(newPayload)
        }
        if (webRTCStatus.videoAgent) {
          const auth = webRTCStatus.videoAgent.data.auth
          const offer = await this.webrtc.videoRemote.start(auth)
          // CAPI event to start video
          const newPayload = {
            event_type: 'VideoUserJoin',
            data: {
              cmd: 'video',
              sdp: offer.sdp
            }
          }
          kserver.CoreAPI.sendLiveChatCustomRequest(newPayload)
        }
      }
    }
  },
  methods: {
    endLiveChatConfirmation () {
      // get the translated strings
      const translations = this.$store.getters.getBotLanguages.translations.inlinePopup.endLivechatConfirmation
      let popup = JSON.parse(JSON.stringify(popupEndLivechatJsonData))
      popup = (popup.message_contents[0].payload.data)
        ? popup.message_contents[0].payload.data
        : popup.message_contents[0].payload.payload
      if (translations !== undefined) {
        if (translations.title !== undefined && translations.title !== '') popup.title = translations.title
        if (translations.subtitle !== undefined && translations.subtitle !== '') popup.subtitle = translations.subtitle
        if (translations.buttons !== undefined) {
          popup.buttons.forEach(button => {
            if (button.type.toUpperCase() === 'CLOSE') button.label = translations.buttons.closeButtonLabel
            else if (button.type.toUpperCase() === 'END_LIVECHAT') button.label = translations.buttons.endChatButtonLabel
          })
        }
      }
      Kai.Core.inlinePopup(popup, 'LIVECHAT_END_SESSION_CONFIRMATION')
    },
    async lastCustomEvent (event) {
      // this if statement is to ensure that the last event is only processed once. As the LiveChatWidget.vue is loaded twice in App.vue to support both widget mode and full screen mode.
      if ((!this.openUp && $store.state.useWidgetMode) || (!$store.state.useWidgetMode && this.openUp)) {
        if (event !== undefined && event.id !== this.lastCustomEventId && !event.isSessionTranscript) {
          const payload = event.payload
          if (payload.event_type === 'AudioAgentAccepted') {
            if (payload.data && payload.data.cmd && payload.data.cmd === 'invited') {
              this.webrtc.audio.remoteSDP(payload.data.sdp)
            } else if (payload.data && payload.data.action && payload.data.action === 'stop') {
              this.webrtc.audio.stop()
            }
          }
          if (payload.event_type === 'AudioAgentJoin') {
            // if the agent is inviting the user to join the audio call, we do nothing here,
            // LiveChatNotification.vue will display the click to join audio call notification,
            // and only once user click to join will the audio call be established
          }
          if (payload.event_type === 'AudioAgentInvite') {
            // if the agent is inviting the user to join the audio call, we do nothing here,
            // LiveChatNotification.vue will display the click to join audio call notification,
            // and only once user click to join will the audio call be established

            // if user is already sharing video, we can directly accept the audio invite and trigger the audio call starting muted
            if (this.$store.getters.getWebRTCStatus.videoUser || this.$store.getters.getWebRTCStatus.rdsUser || (this.$store.getters.getWebRTCStatus.videoAgent && this.$store.getters.getWebRTCStatus.videoAgent.status) || (this.$store.getters.getWebRTCStatus.rdsAgent && this.$store.getters.getWebRTCStatus.rdsAgent.status)) {
              this.triggerVoiceCall(false, true)
              if ($store.getters.getLivechatPanelStates.size === 'big' &&
                  event.message_contents && event.message_contents[0] && event.message_contents[0].payload && event.message_contents[0].payload.text) {
                Kai.API.displayToastMessage(event.message_contents[0].payload.text)
              }
            }
          }
          if (payload.event_type === 'AudioAgentEnd') {
            this.webrtc.audio.stop()
            this.$store.dispatch('actionWebRTCStatus', {
              audioAgent: false
            })
            if (this.$store.getters.getWebRTCStatus.videoUser || this.$store.getters.getWebRTCStatus.rdsUser || (this.$store.getters.getWebRTCStatus.videoAgent && this.$store.getters.getWebRTCStatus.videoAgent.status) || (this.$store.getters.getWebRTCStatus.rdsAgent && this.$store.getters.getWebRTCStatus.rdsAgent.status)) {
              if ($store.getters.getLivechatPanelStates.size === 'big' &&
                  event.message_contents && event.message_contents[0] && event.message_contents[0].payload && event.message_contents[0].payload.text) {
                Kai.API.displayToastMessage(event.message_contents[0].payload.text)
              }
            }
          }
          if (payload.event_type === 'VideoAgentJoin' || payload.event_type === 'VideoUserJoin') {
            if (payload.data && payload.data.cmd === 'video' && payload.data.sdp && payload.data.status === '200') {
              // Is this command in response to local video offer or remote video answer?
              const remoteAuth = this.webrtc.getRevationViewAuth(payload.data.sdp)
              if (remoteAuth) {
                await this.webrtc.videoRemote.remoteSDP(payload.data.sdp)
              } else {
                if (!this.webrtc.videoLocal.active) {
                  await this.webrtc.videoLocal.start({})
                }
                await this.webrtc.videoLocal.remoteSDP(payload.data.sdp)
              }
            }
          }
          if (payload.event_type === 'VideoAgentInvite') {
            // if the agent is inviting the user to join a video call, we just store payload data here,
            // LiveChatNotification.vue will display the "click to join" video call notification,
            // and only once user click to join will the video call be established using this payload data
            if (payload.data && payload.data.auth) {
              this.$store.dispatch('actionWebRTCStatus', {
                videoAgent: {
                  status: false, // status is false because the video call is not yet established
                  data: payload.data
                }
              }).then(() => {
                // if user is already sharing video, we can directly accept the invite and trigger the remote video offer
                if (this.$store.getters.getWebRTCStatus.videoUser || this.$store.getters.getWebRTCStatus.rdsUser || (this.$store.getters.getWebRTCStatus.rdsAgent && this.$store.getters.getWebRTCStatus.rdsAgent.status)) {
                  this.triggerRemoteVideoSharing()
                }
              })
              // in case VideoAgentInvite is with action stop, it shouldn't happened
              // but it was a possibility in the code from Linklive example
            } else if (payload.data && payload.data.action && payload.data.action === 'stop') {
              this.$store.dispatch('actionWebRTCStatus', {
                videoAgent: false
              })
              this.webrtc.videoRemote.stop()
            }
          }
          if (payload.event_type === 'VideoAgentEnd') {
            if (payload.data && payload.data.action && payload.data.action === 'stop') {
              this.$store.dispatch('actionWebRTCStatus', {
                videoAgent: false
              })
              this.webrtc.videoRemote.stop()
            }
          }
          if (payload.event_type === 'RdsAgentJoin' || payload.event_type === 'RdsUserJoin') {
            if (payload.data && payload.data.cmd === 'rds' && payload.data.sdp && payload.data.status === '200') {
              // Is this command in response to local video offer or remote video answer?
              const remoteAuth = this.webrtc.getRevationViewAuth(payload.data.sdp)
              if (remoteAuth) {
                await this.webrtc.rdsRemote.remoteSDP(payload.data.sdp)
              } else {
                if (!this.webrtc.rdsLocal.active) {
                  await this.webrtc.rdsLocal.start({})
                }
                await this.webrtc.rdsLocal.remoteSDP(payload.data.sdp)
              }
            }
          }
          if (payload.event_type === 'RdsAgentInvite' || payload.event_type === 'RdsAgentEnd') {
            // if the agent is inviting the user to join a Rds call, we just store payload data here,
            // LiveChatNotification.vue will display the "click to join" rds call notification,
            // and only once user click to join will the Rds call be established using this payload data
            if (payload.data && payload.data.auth) {
              this.$store.dispatch('actionWebRTCStatus', {
                rdsAgent: {
                  status: false, // status is false because the rds call is not yet established
                  data: payload.data
                }
              }).then(() => {
                // if user is already sharing, we can directly accept the invite and trigger the remote video offer
                if (this.$store.getters.getWebRTCStatus.videoUser || this.$store.getters.getWebRTCStatus.rdsUser || (this.$store.getters.getWebRTCStatus.videoAgent && this.$store.getters.getWebRTCStatus.videoAgent.status)) {
                  this.triggerRemoteDesktopSharing()
                }
              })
            } else if (payload.data.action === 'restart') {
              // rds does not resend auth, because the sample only supports 2 participants (including the local user)
              // we can simply reuse the auth from the previous rds session
              // const auth = this.webrtc.rdsRemote.auth
              const offer = await this.webrtc.rdsRemote.start(this.webrtc.rdsRemote.auth)
              // console.warn('Restarting RDS-2', offer);
              const newPayload = {
                event_type: 'RdsUserJoin',
                data: {
                  cmd: 'rds',
                  sdp: offer.sdp
                }
              }
              kserver.CoreAPI.sendLiveChatCustomRequest(newPayload)
            } else if (payload.data.action === 'stop') {
              this.$store.dispatch('actionWebRTCStatus', {
                rdsAgent: false
              })
              this.webrtc.rdsRemote.stop()
            }
          }
          if (payload.event_type === 'FileUploadAccepted' || payload.event_type === 'FileUploadSuccess ') {
            const foundFile = this.files.find((f) => {
              return f.id === this.fileUploadId - 1
            })
            if (!foundFile) {
              console.warn(`Ignoring file upload for fileUploadId ${this.fileUploadId - 1} as it is not in the list of files to upload.`, this.files)
              return
            }
            const dummyPayload = {
              type: 'LIVE_CHAT_NOTIFY_CUSTOM',
              payload: {
                event_type: 'FileUploadInProgress',
                data: payload.data,
                file_name: foundFile.file.name,
                file_size: foundFile.file.size
              },
              event: 'LIVE_CHAT_NOTIFY_CUSTOM'
            }

            Kai.API.addLiveChatNotification(dummyPayload)
          }
          if (payload.event_type === 'FileUploadError') {
            if (payload.data.publishto.file.error) {
              console.warn(`Failed to upload file: ${payload.data.publishto.file.name}. ${payload.data.publishto.file.error}`)
            }
          }
          if (payload.event_type === 'MultimediaConfiguration') {
            const config = payload.data.config
            const voice = config.voice
            const fileTransfer = config.fileTransfer

            if (fileTransfer && fileTransfer.text && fileTransfer.text.substr(0, 4) === 'send') {
              const allowed = fileTransfer.allow ? fileTransfer.allow === 'true' : false
              const extensions = fileTransfer.extensions && fileTransfer.extensions.length > 0 ? fileTransfer.extensions.split(',') : []
              const maxMb = fileTransfer['max-mb'] ? fileTransfer['max-mb'] : 0
              this.$store.dispatch('actionAttachmentFileTypes', {
                allowed: allowed ? extensions : [],
                forbidden: allowed ? [] : extensions,
                maxFileSizeInMB: maxMb
              })
            }

            const remoteDesktop = config.remoteDesktop
            const video = config.video
            const multimediaConfig = {
              voice: voice && ((voice.text && voice.text.substr(0, 4) === 'send') || !voice.text),
              attachment: fileTransfer && fileTransfer.text && fileTransfer.text.substr(0, 4) === 'send',
              desktopSharing: remoteDesktop && remoteDesktop.text && remoteDesktop.text.substr(0, 4) === 'send',
              video: video && video.text && video.text.substr(0, 4) === 'send'
            }
            this.$store.dispatch('actionMultimediaConfig', multimediaConfig)
            Cookies.set('k_multimedia_config', JSON.stringify(multimediaConfig))
          }
          $store.dispatch('actionLastCustomEventId', event.id)
        }
      }
    }
  }
}
</script>
