<template>
  <section class="kai-row">
    <div
      ref="listview"
      class="kai-card-large-template"
      navDir="horizontal"
    >
      <div
        tabindex="0"
        navLvl="1"
        navDir="vertical"
        class="kai-card-large-container wrapper"
      >
        <section
          v-for="(item, i) in response.default.payload.contents"
          :key="i"
        >
          <div
            v-if="!item.payload.title && i!=0"
            class="list-item-separator"
          />
          <div class="item kai-card-large-template">
            <div
              class="item-wrapper"
              :class="{item_block: useInlineButton && startOnSameLine && !item.payload.medium && item.payload.subtitle}"
            >
              <div class="card-content-right">
                <Medium
                  v-if="item.payload.medium"
                  class="img-wrapper"
                  :medium="item.payload.medium"
                />
              </div>
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="item.payload.title"
                class="header grouped-list"
              >
                <div
                  class="checkmark-listview-select"
                  v-html="item.payload.title"
                />
              </div>
              <div
                class="item-text"
                v-html="subtitleToDisplay(item)"
              />
              <!-- eslint-enable vue/no-v-html -->
              <!-- use inline buttons on the same line as subtitle when there is no medium to display -->
              <div class="clear" />

              <section
                v-if="useInlineButton && startOnSameLine && !item.payload.medium"
                class="group-list-btn"
              >
                <div
                  v-for="(button, buttonIndex) in item.payload.buttons"
                  :key="buttonIndex"
                  class="list-inline-button-item"
                  tabindex="0"
                  navLvl="1"
                  navDir="vertical"
                  @click="itemButton(button)"
                >
                  <div class="list-inline-button-text">
                    {{ button.label }}
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div
            v-if="!item.payload.title && i!=0"
            class="group-last-list-item-separator"
          />
          <!-- use normal buttons -->
          <section v-if="!useInlineButton">
            <div
              v-for="(button, buttonIndex) in item.payload.buttons"
              :key="buttonIndex"
              class="button list-button kai-card-button"
              tabindex="0"
              navLvl="1"
              navDir="vertical"
              @click="itemButton(button)"
            >
              {{ button.label }}
            </div>
          </section>
          <!-- use inline buttons -->
          <div
            v-if="useInlineButton && startOnSameLine && item.payload.medium || useInlineButton && !startOnSameLine"
            class="list-inline-buttons-container"
          >
            <div
              v-for="(button, buttonIndex) in item.payload.buttons"
              :key="buttonIndex"
              class="list-inline-button-item"
              tabindex="0"
              navLvl="1"
              navDir="vertical"
              @click="itemButton(button)"
            >
              <div class="list-inline-button-text">
                {{ button.label }}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import Kai from '../../kai'
import TextMixin from './Mixin/TextMixin'
import Medium from './Medium'
import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import './styles/ListView.less'

export default {
  name: 'ListViewGrouped',
  components: {
    Medium
  },
  mixins: [
    TextMixin,
    KeyboardNavigationMixin
  ],
  props: {
    response: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      useInlineButton: window.$store.getters.isInlineButtonModeForList,
      startOnSameLine: window.$store.getters.isInlineButtonStartingOnFirstLine
    }
  },
  methods: {
    itemButton (button) {
      Kai.Core.buttonPressed(button)
    },
    subtitleToDisplay (item) {
      var text = this.linkify(item.payload.subtitle)
      return this.lineBreak(text)
    }
  }
}
</script>
