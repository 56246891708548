var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"context-menu"},[_c('button',{staticClass:"button clear button--only-icon small secondary",attrs:{"data-v-3ae18178":"","type":"submit"},on:{"click":_vm.handleOpen}},[_c('svg',{staticClass:"icon icon--font more-vertical",attrs:{"data-v-335c864d":"","width":"14","height":"14","fill":"none","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12 7.75a1.75 1.75 0 1 1 0-3.5 1.75 1.75 0 0 1 0 3.5ZM12 13.75a1.75 1.75 0 1 1 0-3.5 1.75 1.75 0 0 1 0 3.5ZM10.25 18a1.75 1.75 0 1 0 3.5 0 1.75 1.75 0 0 0-3.5 0Z","fill":"currentColor"}})])]),(_vm.isOpen && !_vm.isCannedResponseModalOpen)?_c('ContextMenu',{attrs:{"x":_vm.contextMenuPosition.x,"y":_vm.contextMenuPosition.y},on:{"close":_vm.handleClose}},[_c('div',{staticClass:"menu-container"},[(_vm.enabledOptions['edit'])?_c('menu-item',{attrs:{"option":{
          icon: 'edit',
          label: 'Edit',
        },"variant":"icon"},on:{"click":_vm.onEditClicked}}):_vm._e(),(_vm.enabledOptions['copy'])?_c('menu-item',{attrs:{"option":{
          icon: 'clipboard',
          label: 'Copy',
        },"variant":"icon"},on:{"click":_vm.handleCopy}}):_vm._e(),(_vm.enabledOptions['save'])?_c('menu-item',{attrs:{"option":{
          icon: 'save',
          label: 'Save',
        },"variant":"icon"},on:{"click":_vm.handleSave}}):_vm._e(),(_vm.enabledOptions['delete'])?_c('menu-item',{attrs:{"option":{
          icon: 'delete',
          label:'Delete',
        },"variant":"icon"},on:{"click":_vm.deleteSuggestion}}):_vm._e(),(_vm.enabledOptions['viewSource'])?_c('hr'):_vm._e(),(_vm.enabledOptions['viewSource'])?_c('menu-item',{attrs:{"option":{
          icon: 'eye-show',
          label: 'View Source',
        },"variant":"icon"},on:{"click":_vm.onViewSourceClicked}}):_vm._e()],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }