<template>
  <section :id="'carousel'+ carouselCounter">
    <div class="top-k-source">
      <div class="top-k-source-title-container">
        <span class="top-k-source-title">{{ sourceTitle }}</span>
      </div>
      <div class="top-k-kai-card-carousel-wrapper">
        <div
          ref="navLeft"
          class="top-k-kai-card-carousel-nav-left-container"
          navDir="horizontal"
          tabindex="0"
          role="button"
          :aria-label="screenReaderTranslations.carouselArrowLeft"
          :disabled="enableRTL ? atEndOfList : atHeadOfList"
          :class="{hover: hoverLeft && !mobile}"
          @click="moveCarouselToItem(currentIndex + scrollDirection)"
          @mouseover="hoverLeft = true"
          @mouseleave="hoverLeft = false"
        >
          <img
            :src="arrowIcon"
            class="top-k-kai-card-carousel-nav-left"
            aria-hidden="true"
            draggable="false"
            :disabled="enableRTL ? atEndOfList : atHeadOfList"
          >
        </div>
        <div
          v-pan="onPan"
          v-panend="onPanEnd"
          class="top-k-kai-card-carousel"
        >
          <div class="top-k-kai-card-carousel-overflow-container">
            <div
              ref="carousel"
              class="top-k-kai-card-wrapper top-k-kai-card-carousel-transform"
              role="region"
              :aria-roledescription="screenReaderTranslations.inACarousel"
              :aria-label="ScreenReaderLabel"
              navDir="horizontal"
              :style="transformCarousel"
              :class="{transition: !onPanStarted,isFinishAnimate: isScrollFreeEase}"
            >
              <TopKCard
                v-for="(item, index) in response.default.payload.contents"
                :id="'top-k-kai-card-carousel' + index"
                :key="index"
                ref="item"
                :item="item"
                :index="index"
              />
            </div>
          </div>
        </div>
        <div
          ref="navRight"
          class="top-k-kai-card-carousel-nav-right-container"
          navDir="horizontal"
          tabindex="0"
          role="button"
          :aria-label="screenReaderTranslations.carouselArrowRight"
          :disabled="enableRTL ? atHeadOfList : atEndOfList"
          :class="{hover: hoverRight && !mobile}"
          @click="moveCarouselToItem(currentIndex - scrollDirection)"
          @mouseover="hoverRight = true"
          @mouseleave="hoverRight = false"
        >
          <img
            :src="arrowIcon"
            class="top-k-kai-card-carousel-nav-right"
            aria-hidden="true"
            draggable="false"
            :disabled="enableRTL ? atHeadOfList : atEndOfList"
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import {
  isMobile
} from 'mobile-device-detect'
import TopKCard from './TopKCard'
import CarouselMixin from './Mixin/CarouselMixin'
import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import './styles/TopKCardCarousel.less'

// Code below is coming from QuickReplies.vue

Vue.directive('pan', {
  bind: function (el, binding) {
    if (typeof binding.value === 'function') {
      const mc = new Hammer(el)
      mc.get('pan').set({
        direction: Hammer.DIRECTION_HORIZONTAL
      })
      mc.on('pan', binding.value)
    }
  }
})

Vue.directive('panend', {
  bind: function (el, binding) {
    if (typeof binding.value === 'function') {
      const mc = new Hammer(el)
      mc.get('pan').set({
        direction: Hammer.DIRECTION_HORIZONTAL
      })
      mc.on('panend', binding.value)
    }
  }
})

Vue.directive('tap', {
  bind: function (el, binding) {
    if (typeof binding.value === 'function') {
      const mc = new Hammer(el)
      mc.on('tap', binding.value)
    }
  }
})

export default {
  name: 'TopKCarousel',
  components: {
    TopKCard
  },
  mixins: [
    CarouselMixin,
    KeyboardNavigationMixin,
    ImageUtilityMixin
  ],
  data () {
    return {
      response: this.$slots,
      carouselCounter: window.$store.getters.getCarouselCounter,
      currentOffset: 0,
      currentIndex: 0,
      paginationFactor: 320, // pagination factor = carousel_card width + margin right
      hoverLeft: false,
      hoverRight: false,
      mobile: isMobile,
      forceUpdate: false,
      currentCardMode: '',
      referenceOffset: 0,
      onPanStarted: false, // flag used to disable css animation during the pan gesture.
      isScrollAnimate: true,
      isScrollFreeEase: true,
      containSmallImage: false,
      screenReaderTranslations: $store.getters.getBotLanguages.translations.screenReader,
      arrowIcon: this.getImagePath('svg/Arrow_carousel.svg'),
      enableRTL: $store.state.enableRTL
    }
  },
  computed: {
    cardItems () {
      return this.response.default.payload.contents
    },
    ScreenReaderLabel () {
      var screenReaderCurrentDate = moment().format('hh:mm A')
      var screenReader = $store.getters.getBotLanguages.translations.screenReader
      var sender = screenReader.virtualAssistantDesignation + ' ' + screenReader.displayed
      var cardLabel = this.response.default.payload.contents.length + ' ' + screenReader.card
      if (this.response.default.payload.contents.length > 1) {
        cardLabel = this.response.default.payload.contents.length + ' ' + screenReader.cards
      }
      return screenReader.timeSendAt + ' ' + screenReaderCurrentDate + ',  ' + sender + ': ' + cardLabel
    },
    sourceTitle () {
      return this.response.default.payload.contents[0].payload.data.container_title === 'Source:' ? 'Sources' : null
    },
    atEndOfList () {
      return (this.cardItems.length - 1) === this.currentIndex
    },
    atHeadOfList () {
      return this.currentIndex === 0
    },
    displaySource () {
      return $store.state.displaySource
    }
  },
  watch: {
    displaySource () {
      this.$nextTick(() => {
        this.moveCarouselToItem(this.currentIndex)
      })
    }
  },
  mounted () {
    this.isScrollAnimate = true
    this.isScrollFreeEase = true
  },
  created () {
    window.addEventListener('resize', this.onResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    // Override moveCarouselToItem from CarouselMixin
    moveCarouselToItem (index) {
      const direction = this.currentIndex > index ? 'left' : 'right'
      this.toggleOffCardExpand()
      const gapBetweenCards = 15

      let totalWidthToAdd = 0

      if (this.currentIndex === index) {
        this.currentOffset = 0
        for (let i = 0; i < this.currentIndex; i++) {
          totalWidthToAdd += (this.$refs.item[i].$el.clientWidth + gapBetweenCards)
        }
      } else {
        if (direction === 'right') {
          for (let i = this.currentIndex; i < index; i++) {
            totalWidthToAdd += (this.$refs.item[i].$el.clientWidth + gapBetweenCards)
          }
        } else {
          for (let i = this.currentIndex; i > index; i--) {
            if (!this.atHeadOfList) { totalWidthToAdd += (this.$refs.item[i - 1].$el.clientWidth + gapBetweenCards) }
          }
        }
      }

      const widthToAdd = direction === 'right' ? -totalWidthToAdd : totalWidthToAdd
      if (index >= 0 && index < this.cardItems.length) {
        this.currentOffset += widthToAdd
        this.currentIndex = index
        // update referenceOffset value as it need to stay up to date for the next time user initiate a pan gesture
        this.referenceOffset = this.currentOffset
        this.updateItemsFocusability()
        this.traceCurrentItem('click_arrow')
      }
    },
    onResize (e) {
      this.moveCarouselToItem(this.currentIndex)
    }
  }
}
</script>

<style scoped>

.top-k-kai-card-carousel-transform {
    transform: var(--carousel_translate);
}

.top-k-kai-card-carousel-nav-left-container {
  height: 25px;
  width: 25px;
  margin: 0px 0 0 5px;
}
.top-k-kai-card-carousel-nav-right-container {
  height: 25px;
  width: 25px;
  margin: 0 5px 0 0;
}

.top-k-kai-card-carousel-nav-left {
  height: 15px;
  width: 10px;
}

.top-k-kai-card-carousel-nav-right {
  height: 15px;
  width: 10px;
}
</style>
