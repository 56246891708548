<template>
  <section class="card-single-wrapper">
    <div class="kai-row" />
    <div
      class="kai-card-wraper kai-card-single"
    >
      <MessageProfileImage
        v-if="ShowIcon && showMessageProfileImage"
        aria-hidden="true"
      />
      <CardItem
        v-if="response.default.type === 'CARD'"
        :item="response.default"
        :index="0"
        tabindex="0"
      />
    </div>
  </section>
</template>

<script>
import CardItem from './CardItem'
import MessageProfileImage from './MessageProfileImage'

export default {
  name: 'CardSingle',
  components: {
    CardItem,
    MessageProfileImage
  },
  data () {
    return {
      response: this.$slots,
      showMessageProfileImage: false
    }
  },
  computed: {
    ShowIcon () {
      if (this.payload) {
        if (this.payload.showIcon) {
          return true
        } else {
          return false
        }
      } else {
        // `this` points to the vm instance
        if (this.$slots.default && this.$slots.default.showIcon) {
          return this.$slots.default.showIcon
        } else {
          return false
        }
      }
    }
  },
  mounted () {
    this.showMessageProfileImage = !$store.state.useAvatar || $store.getters.isLiveAgentConnected
  }
}
</script>
<style scoped>
.kai-card-single {
  margin-bottom: 35px;
}
</style>
