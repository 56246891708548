<template>
  <section>
    <section
      v-if="ShowIcon && showMessageProfileImage"
      class="kai-row"
    >
      <MessageProfileImage aria-hidden="true" />
    </section>
    <section class="kai-row">
      <div
        ref="listview"
        class="kai-card-large-template"
        navDir="horizontal"
      >
        <CardLargeItem
          v-for="(item, i) in response.default.payload.contents"
          :key="i"
          class="kai-card-large-container wrapper"
          :item="item"
          :index="i"
          :is-grouped-list="useGroupedListMode"
          isref="item"
        />
      </div>
    </section>
  </section>
</template>

<script>
import CardLargeItem from './CardLargeItem'
import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import MessageProfileImage from './MessageProfileImage'
import './styles/ListView.less'

export default {
  name: 'ListView',
  components: {
    CardLargeItem,
    MessageProfileImage
  },
  mixins: [
    KeyboardNavigationMixin
  ],
  props: {
    response: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      useGroupedListMode: window.$store.getters.getGroupedListMode,
      showMessageProfileImage: false
    }
  },
  computed: {
    ShowIcon () {
      if (this.payload) {
        if (this.payload.showIcon) {
          return true
        } else {
          return false
        }
      } else {
        // `this` points to the vm instance
        return this.response.default.showIcon
      }
    }
  },
  mounted () {
    this.showMessageProfileImage = !$store.state.useAvatar || $store.getters.isLiveAgentConnected
  }
}

</script>
