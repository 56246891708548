<template>
  <div
    ref="cardItem"
    class="kai-card-large-item"
    :aria-label="ScreenReaderLabel"
    :class="{kai_card_button_active_border: isSelect && this.$parent.selectedItemsIndex.includes(index), adaptive:isAdaptiveItemMode && !itemWithFixedHeight, fixed:displayFixed, disabled: isSelect && this.$parent.isDisable}"
  >
    <div class="item kai-card-large-template">
      <div class="card-content-left">
        <div
          class="item-wrapper"
          :class="{item_block: useInlineButton && startOnSameLine && !item.payload.medium && item.payload.subtitle}"
        >
          <div class="card-content-right">
            <Medium
              v-if="item.payload.medium"
              class="img-wrapper"
              :medium="item.payload.medium"
            />
          </div>
          <div class="header">
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="item.payload.title"
              class="checkmark-listview-select"
              v-html="item.payload.title"
            />
            <div
              v-else
              class="checkmark-listview-select"
              v-html="item.value"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="item-text"
            :style="displayFixed ? 'max-height:' + mainElementMaxHeight + 'px;': ''"
            :class="{fixed: displayFixed && showExpandButton}"
            v-html="subtitleToDisplay(item)"
          />
          <!-- eslint-enable vue/no-v-html -->
          <!-- use inline buttons on the same line as subtitle when there is no medium to display -->
          <section
            v-if="useInlineButton && startOnSameLine && !item.payload.medium"
            class="no-medium-btn-wrap"
            tabindex="0"
            navLvl="1"
            navDir="vertical"
          >
            <div
              v-for="(button, buttonIndex) in item.payload.buttons"
              :key="buttonIndex"
              class="edit-btn list-inline-button-item"
              tabindex="0"
              navLvl="1"
              navDir="vertical"
              @click="itemButton(button)"
            >
              <div class="list-inline-button-text">
                {{ button.label }}
              </div>
            </div>
          </section>
          <!-- use select inline buttons on the same line as subtitle when there is no medium to display -->
          <section v-if="isSelect && useInlineButton && startOnSameLine && !item.payload.medium">
            <div
              class="list-inline-button-item"
              tabindex="0"
              navLvl="1"
              navDir="vertical"
              :class="{ kai_card_button_active: this.$parent.selectedItemsIndex.includes(index), disabled: this.$parent.isDisable, fixed: displayFixed}"
              @click="(e) => this.$parent.selectBtn(e, item, index)"
            >
              <div
                v-if="item.label"
                class="list-inline-button-text"
              >
                {{ item.label }}
              </div>
              <!-- fallback -->
              <div
                v-else
                class="list-inline-button-text"
              >
                {{ fallbackButtonText }}
              </div>
            </div>
          </section>
        </div>
      </div>

      <!--
<div class="card-content-right">
    <Medium v-if="item.payload.medium" class="img-wrapper" :medium="item.payload.medium"></Medium>
</div>
-->
      <div
        v-if="showExpandButton"
        tabindex="0"
        navLvl="1"
        navDir="vertical"
        role="button"
        :aria-label="expandButtonLabel"
        class="card-expand-button-wrapper"
        :class="{expanded:isExpanded}"
        @click="(e) => clickExpandButton(e, index)"
      >
        <img
          v-if="!isExpanded"
          aria-hidden="true"
          class="card-expand-button"
          ondragstart="return false;"
          :src="expandIconSrc"
          width="20"
        >
        <img
          v-if="isExpanded"
          aria-hidden="true"
          class="card-expand-button"
          ondragstart="return false;"
          :src="collapseIconSrc"
          width="20"
        >
      </div>
    </div>

    <!-- use normal select buttons -->
    <div
      v-if="isSelect && !useInlineButton"
      tabindex="0"
      navLvl="1"
      navDir="vertical"
      class="button list-button kai-card-button"
      :class="{ kai_card_button_active: this.$parent.selectedItemsIndex.includes(index), disabled: this.$parent.isDisable, fixed: displayFixed}"
      @click="(e) => this.$parent.selectBtn(e, item, index)"
    >
      {{ item.label }}
    </div>

    <!-- use inline select buttons -->
    <div
      v-if="isSelect && useInlineButton && startOnSameLine && item.payload.medium || useInlineButton && !startOnSameLine"
      class="list-inline-buttons-container"
      :class="{fixed: displayFixed}"
    >
      <div
        tabindex="0"
        navLvl="1"
        navDir="vertical"
        class="list-inline-button-item"
        :class="{ kai_card_button_active: this.$parent.selectedItemsIndex.includes(index), disabled: this.$parent.isDisable, fixed: displayFixed}"
        @click="(e) => this.$parent.selectBtn(e, item, index)"
      >
        <div
          v-if="item.label"
          class="list-inline-button-text"
        >
          {{ item.label }}
        </div>
        <!-- fallback -->
        <div
          v-else
          class="list-inline-button-text"
        >
          {{ fallbackButtonText }}
        </div>
      </div>
    </div>

    <!-- use normal buttons -->
    <section
      v-if="!isSelect && !useInlineButton"
      class="block-button-container"
      :class="{fixed: displayFixed}"
    >
      <div
        v-for="(button, buttonIndex) in item.payload.buttons"
        :key="buttonIndex"
        tabindex="0"
        navLvl="1"
        navDir="vertical"
        role="button"
        :aria-label="button.label"
        class="button list-button kai-card-button"
        @click="itemButton(button)"
      >
        {{ button.label }}
      </div>
    </section>
    <!-- use inline buttons -->
    <div
      v-if="!isSelect && useInlineButton && startOnSameLine && item.payload.medium || useInlineButton && !startOnSameLine"
      class="list-inline-buttons-container"
      :class="{fixed: displayFixed}"
    >
      <div
        v-for="(button, buttonIndex) in item.payload.buttons"
        :key="buttonIndex"
        tabindex="0"
        navLvl="1"
        navDir="vertical"
        role="button"
        :aria-label="button.label"
        class="list-inline-button-item"
        @click="itemButton(button)"
      >
        <div class="list-inline-button-text">
          {{ button.label }}
        </div>
      </div>
    </div>
    <div
      v-if="isSelect && this.$parent.isDisable"
      class="kai-card-disabled-overlay"
    />
  </div>
</template>

<script>
import Kai from '../../kai'
import CardMixin from './Mixin/CardMixin'
import TextMixin from './Mixin/TextMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import Medium from './Medium'
import './styles/ListView.less'
import {
  store
} from '../../store/index'
export default {
  name: 'CardLargeItem',
  components: {
    Medium
  },
  mixins: [
    TextMixin,
    CardMixin,
    ImageUtilityMixin
  ],
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isSelect: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      selectedItemsIndex: [],
      useInlineButton: store.getters.isInlineButtonModeForList,
      startOnSameLine: store.getters.isInlineButtonStartingOnFirstLine,
      isLargeCard: true,
      cardMainElement: 'item-text',
      // card elements that enter in consideration to calculate the main element maximum Height
      cardElements: [
        {
          className: 'list-inline-buttons-container',
          substract: true
        },
        {
          className: 'block-button-container',
          substract: true
        },
        {
          className: 'kai-card-large-template',
          substract: true
        },
        {
          className: 'item-text',
          substract: false
        }
      ],
      fallbackButtonText: store.getters.getBotLanguages.translations.selectTemplate.fallbackListSubmitButton
    }
  },
  computed: {
    ScreenReaderLabel () {
      var screenReader = $store.getters.getBotLanguages.translations.screenReader
      return screenReader.card + ' ' + (this.index + 1) + ': ' + this.item.payload.title
    },
    expandButtonLabel () {
      if (this.isExpanded) {
        return $store.getters.getBotLanguages.translations.screenReader.collapse
      } else {
        return $store.getters.getBotLanguages.translations.screenReader.expand
      }
    }
  },
  methods: {
    itemButton (button) {
      Kai.Core.buttonPressed(button)
    },
    subtitleToDisplay (item) {
      var text = this.linkify(item.payload.subtitle)
      return this.lineBreak(text)
    }
  }
}
</script>
