<template>
  <div class="context-menu">
    <!-- <button
      data-v-3ae18178=""
      type="submit"
      class="button clear button--only-icon small secondary"
      @click="handleOpen"
    >
      <svg
        data-v-335c864d=""
        width="14"
        height="14"
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon--font more-vertical"
      >
        <path
          d="M12 7.75a1.75 1.75 0 1 1 0-3.5 1.75 1.75 0 0 1 0 3.5ZM12 13.75a1.75 1.75 0 1 1 0-3.5 1.75 1.75 0 0 1 0 3.5ZM10.25 18a1.75 1.75 0 1 0 3.5 0 1.75 1.75 0 0 0-3.5 0Z"
          fill="currentColor"
        />
      </svg>
    </button> -->
    <ContextMenu
      v-if="isOpen"
      :x="contextMenuPosition.x"
      :y="contextMenuPosition.y"
      @close="handleClose"
    >
      <div
        class="menu-container"
        :style="{ 'max-width': contextMenuPosition.maxWidth + 'px', 'max-height': contextMenuPosition.maxHeight + 'px'}"
      >
        <menu-item
          v-if="enabledOptions['copy']"
          :option="{
            icon: 'copy',
            label: '<b>' + answerPrefix + '</b> ' + message,
          }"
          variant="message"
          @click="handleCopy"
        />
      </div>
    </ContextMenu>
  </div>
</template>
<script>

import ContextMenu from './ContextMenu'
import MenuItem from './MenuItem'
import DOMPurify from 'dompurify'

export default {
  name: 'KaiAnswerContextMenu',
  components: {
    ContextMenu,
    MenuItem
  },
  props: {
    message: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    enabledOptions: {
      type: Object,
      default: () => ({})
    },
    contextMenuPosition: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      isCannedResponseModalOpen: false,
      showTranslateModal: false,
      showDiscardModal: false
    }
  },
  computed: {
    answerPrefix () {
      return $store.getters.getBotLanguages.translations.relatedQuestions.answerPrefix
    }
  },
  methods: {
    async handleCopy () {
      await this.copyTextToClipboard(this.message)
      // this.showAlert('Copied to clipboard')
      // this.handleClose()
    },
    handleOpen (e) {
      this.$emit('open', e)
    },
    handleClose (e) {
      this.$emit('close', e)
    },
    async copyTextToClipboard (text) {
      try {
        // use DOMPurify to sanitize the text before copying it to the clipboard
        // remove all the html tags and keep only the text rendered
        const div = document.createElement('div')
        div.innerHTML = DOMPurify.sanitize(text)
        const textToCopy = div.textContent || div.innerText || ''
        await navigator.clipboard.writeText(textToCopy)
      } catch (error) {
        throw new Error(`Unable to copy text to clipboard: ${error.message}`)
      }
    }
  }
}
</script>
<style scoped>

.menu-container {
  max-width: 300px;
  border: 1px solid var(--playground-1-color);
  background-color: var(--component-background-color);
  padding: 0.25rem;
  box-shadow: var(--shadow);
  background-color: rgb(255 255 255 / 1);
  position: relative;
  overflow: hidden;
}

.menu-container {
  overflow: auto;
}

.button {
    margin-bottom: 0px;
    display: inline-flex;
    height: 2.5rem;
    align-items: center;
}
.button.small {
    font-size: 0.75rem;
    font-size: var(--font-size-mini);
}
.button.clear {
    border: 1px solid #1f93ff;
    color: #1f93ff;
}
.button.clear, .button.clear:hover, .button.clear:focus {
    background-color: transparent;
}
.button.small {
    height: 2rem;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
}
.button.button--only-icon {
    width: 2.5rem;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
}
.button.clear.secondary {
    border: 1px solid #5d7592;
    color: #5d7592;
}
.button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
    border-color: transparent;
}
.button.clear.secondary {
    color: hsl(207 5.6% 31.6% / 1);
}
.button.button--only-icon.small {
    width: 2rem;
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.z-\[9999\] {
    z-index: 9999;
}
.fixed {
    position: fixed;
}
</style>
