<template>
  <div>
    <label
      class="radio-form-control"
      :for="`radioselect_${language.code}`"
    >
      <input
        :id="`radioselect_${language.code}`"
        type="radio"
        :value="language.code"
        name="language"
        :checked="language.code === $store.getters.getSelectedLanguage.code"
        @click="onClickRadio(language)"
      >
      {{ language.name }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'RadioButton',
  props: {
    language: {
      type: Object,
      required: true
    },
    onClickRadio: {
      type: Function,
      required: true
    }
  }
}
</script>

<style scoped>
.radio-form-control {
  font-family: system-ui, sans-serif;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  padding-top: 5px;
}

input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: var(--primary-action-color);;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: var(--primary-action-color);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

.form-control + .form-control {
  margin-top: 1em;
}
</style>
