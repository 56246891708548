'use strict'

export class WebRTCDevices {
  constructor () {
    var micDevices = null
    var spkDevices = null
    var cameraDevices = null

    // returns null if not yet complete, returns array (possibly empty) if complete
    this.getMicDevices = () => {
      return micDevices
    }

    this.getSpkDevices = () => {
      return spkDevices
    }

    this.getCameraDevices = () => {
      return cameraDevices
    }

    // returns a promise that resolves to an array of devices
    // will prompt user for permission if needed
    this.getDevices = async () => {
      return new Promise((resolve) => {
        const doQuery = () => {
          navigator.mediaDevices.enumerateDevices()
            .then((di) => {
              gotDevices(di)
              resolve(di)
            })
            .catch(() => {
              gotNoDevices()
              resolve([])
            })
        }

        // might need a first-time access request prompt
        console.log('requesting access to local audio')
        navigator.mediaDevices.getUserMedia({
          audio: {},
          video: {}
        }).then(function (stream) {
          stream.getTracks().forEach(function (track) {
            track.stop()
          })
          doQuery()
        })
      })
    }

    const gotDevices = (di) => {
      micDevices = []
      spkDevices = []
      cameraDevices = []

      for (var i = 0; i !== di.length; ++i) {
        if (di[i].kind === 'audioinput') {
          micDevices.push({ id: di[i].deviceId, label: di[i].label || 'Microphone ' + (micDevices.length + 1) })
        } else if (di[i].kind === 'audiooutput') {
          spkDevices.push({ id: di[i].deviceId, label: di[i].label || 'Speaker ' + (spkDevices.length + 1) })
        } else if (di[i].kind === 'videoinput') {
          cameraDevices.push({ id: di[i].deviceId, label: di[i].label || 'Camera ' + (cameraDevices.length + 1) })
        }
      }
    }

    const gotNoDevices = () => {
      micDevices = []
      spkDevices = []
      cameraDevices = []
      console.warn('No devices found.')
    }
  }
}
