<template>
  <section>
    <div
      id="kai-qr-bottom-bar-container"
      navDir="horizontal"
      :class="{live_chat_open:isLiveChatStarted, video_expand_bar_open: showExpandBar}"
    >
      <div id="kai-qr-bottom-bar" />
      <!-- Quick Replies Dynamic load -->
      <NbestBottomPanel />
      <ShortcutPanel :class="{shortcut_widget:useWidgetMode}" />
    </div>
  </section>
</template>
<script>

import NbestBottomPanel from './NbestBottomPanel'
import ShortcutPanel from './ShortcutPanel'

export default {
  name: 'QuickRepliesBar',
  components: {
    NbestBottomPanel,
    ShortcutPanel
  },
  computed: {
    isLiveChatStarted () {
      return this.$store.getters.isLiveChatStarted
    },
    useWidgetMode () {
      return $store.state.useWidgetMode
    },
    showExpandBar () {
      return this.isLiveChatStarted && (this.$store.getters.getWebRTCStatus.videoUser ||
        (this.$store.getters.getWebRTCStatus.videoAgent && this.$store.getters.getWebRTCStatus.videoAgent.status) || this.$store.getters.getWebRTCStatus.rdsUser ||
        (this.$store.getters.getWebRTCStatus.rdsAgent && this.$store.getters.getWebRTCStatus.rdsAgent.status)) && this.$store.getters.getLivechatPanelStates.size !== 'big'
    }
  }
}
</script>

<style>

#kai-qr-bottom-bar-container {
  position: absolute;
  bottom: var(--bottom-bar-height);
  background-color: #fff;
  width: 100%;
  z-index: 12;
  -webkit-box-shadow:  0px -5px 9px 0px rgba(0,0,0,0.075);
  box-shadow: 0px -5px 9px 0px rgba(0,0,0,0.075);
  max-height: calc(100% - var(--bottom-bar-height) - var(--header-bar-height));
  height: auto;
  overflow: hidden;
}

@media only screen and (min-height: 750px) and (min-width: 435px) {
  .webview_container_widget #kai-qr-bottom-bar-container {
    max-height: calc(680px - var(--bottom-bar-height) - var(--header-bar-height));
  }

}

#kai-qr-bottom-bar {
  display: none;
}

@media screen and (max-width: 435px) {
  .webview_container_widget #kai-qr-bottom-bar-container.live_chat_open{
    bottom: calc(var(--bottom-bar-height) + var(--live-chat-widget-height));
    transition: var(--transition-bottom-bar);
    transition-property: bottom;
  }
}
#kai-qr-bottom-bar-container.live_chat_open:not(.webview_container_widget #kai-qr-bottom-bar-container.live_chat_open){
  bottom: calc(var(--bottom-bar-height) + var(--live-chat-widget-height));
  transition: var(--transition-bottom-bar);
}

#kai-qr-bottom-bar-container.video_expand_bar_open{
  bottom: calc(var(--bottom-bar-height) + var(--live-chat-expand-bar-height));
  transition: var(--transition-bottom-bar);
}

#kai-qr-bottom-bar-container.video_expand_bar_open:not(.webview_container_widget #kai-qr-bottom-bar-container.video_expand_bar_open){
  bottom: calc(var(--bottom-bar-height) + var(--live-chat-expand-bar-height));
  transition: var(--transition-bottom-bar);
}

#kai-qr-bottom-bar-container.live_chat_open.video_expand_bar_open:not(.webview_container_widget #kai-qr-bottom-bar-container.live_chat_open.video_expand_bar_open){
  bottom: calc(var(--bottom-bar-height) + var(--live-chat-widget-height) + var(--live-chat-expand-bar-height));
  transition: var(--transition-bottom-bar);
}

#kai-qr-bottom-bar:focus{
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}

.active {
    transition: max-height 0.25s ease-in;
}

#kai-qr-bottom-bar .kai-card-carousel-wrapper {
        margin: 10px 0 10px 0;
}

#kai-qr-bottom-bar .kai-card-wraper {
      margin-left: 40px;
}

#kai-qr-bottom-bar .kai-card-wraper.scrollDisabled {
    margin-left: 10px;
}

@media screen and (max-width: 435px) {
}

@media screen and (min-width: 435px) {
  .webview_container_widget .notif-chip {
      bottom: 114px;
      width: 385px;
  }
}
</style>
