
/****************************************************/

/* Store Actions  */

/****************************************************/
const actionKaiSessionId = ({ commit }, payload) => {
  commit('mutateKaiSessionId', payload)
}

const actionDebugMode = ({ commit }, payload) => {
  commit('mutateDebugMode', payload)
}

const actionInputMessage = ({ commit }, payload) => {
  commit('mutateInputMessage', payload)
}

const actionSendMessage = ({ commit }, payload) => {
  commit('mutateSendMessage', payload)
}

const actionSendSelection = ({ commit }, payload) => {
  commit('mutateSendSelection', payload)
}

const actionResponseData = ({ commit }, payload) => {
  commit('mutateResponseData', payload)
}

const actionRenderingStatus = ({ commit }, payload) => {
  commit('mutateRenderingStatus', payload)
}

const actionTypingIndicator = ({ commit }, payload) => {
  commit('mutateTypingIndicator', payload)
}

const actionLastMessageReceievedEpoc = ({ commit }, payload) => {
  commit('mutateLastMessageReceievedEpoc', payload)
}

const actionCarouselCounter = ({ commit }, payload) => {
  commit('mutateCarouselCounter', payload)
}

const actionPullServiceStart = ({ commit }) => {
  commit('mutatePullServiceStatus', true)
}

const actionPullServiceStop = ({ commit }) => {
  commit('mutatePullServiceStatus', false)
}

const actionRequestHeaders = ({ commit }, payload) => {
  commit('mutateRequestHeaders', payload)
}

const actionUserTyping = ({ commit }, payload) => {
  commit('mutateUserTyping', payload)
}

const actionAvatarUpdate = ({ commit }, payload) => {
  commit('mutateAvatar', payload)
}

const actionAvatarPersistentUpdate = ({ commit }, payload) => {
  commit('mutateAvatarPersistent', payload)
}

const actionAvatarTitle = ({ commit }, payload) => {
  commit('mutateAvatarTitle', payload)
}

const actionShortcutPayload = ({ commit }, payload) => {
  commit('mutateShortcutPayload', payload)
}
const actionDisplayShortcutPanel = ({ commit }, payload) => {
  commit('mutateDisplayShortcutPanel', payload)
}
const actionShowInlinePopup = ({ commit }, isVisible) => {
  commit('mutateShowInlinePopup', isVisible)
}

const actionInlinePopupContent = ({ commit }, payload) => {
  commit('mutateInlinePopupContent', payload)
}

const actionBotLanguages = ({ commit }, payload) => {
  commit('mutateBotLanguages', payload)
}

const actionSlotsDefault = ({ commit }, payload) => {
  commit('mutateSlotsDefault', payload)
}

const actionSpliceSelectedItemsIndex = ({ commit }, payload) => {
  commit('mutateSpliceSelectedItemsIndex', payload)
}

const actionPushSelectedItemsIndex = ({ commit }, payload) => {
  commit('mutatePushSelectedItemsIndex', payload)
}

const actionEmptySelectedItemsIndex = ({ commit }) => {
  commit('mutateEmptySelectedItemsIndex')
}

const actionUseAvatar = ({ commit }, payload) => {
  commit('mutateUseAvatar', payload)
}

const actionUseHeaderBar = ({ commit }, payload) => {
  commit('mutateUseHeaderBar', payload)
}

const actionUseWidgetAnimation = ({ commit }, payload) => {
  commit('mutateUseWidgetAnimation', payload)
}

const actionAppKeyIndex = ({ commit }) => {
  commit('mutateAppKeyIndex')
}

const actionSelectLanguage = ({ commit }, payload) => {
  commit('mutateSelectLanguage', payload)
}

const actionWebRTCStatus = ({ commit }, payload) => {
  commit('mutateWebRTCStatus', payload)
}
// isMuted: false,
//     isVideoSharing: false,
//     isDesktopSharing: false,

const actionIsMuted = ({ commit }, payload) => {
  commit('mutateIsMuted', payload)
}

const actionIsAudioSharing = ({ commit }, payload) => {
  commit('mutateIsAudioSharing', payload)
}

const actionIsVideoSharing = ({ commit }, payload) => {
  commit('mutateIsVideoSharing', payload)
}

const actionIsDesktopSharing = ({ commit }, payload) => {
  commit('mutateIsDesktopSharing', payload)
}

const actionMultimediaConfig = ({ commit }, payload) => {
  commit('mutateMultimediaConfig', payload)
}

const actionAttachmentFileTypes = ({ commit }, payload) => {
  commit('mutateAttachmentFileTypes', payload)
}

const actionLivechatCurrentMainVideo = ({ commit }, payload) => {
  commit('mutateLivechatCurrentMainVideo', payload)
}

const actionLogEvent = ({ commit }, payload) => {
  commit('mutateLogEvent', payload)
}

const actionLastCustomEventId = ({ commit }, payload) => {
  commit('mutateLastCustomEventId', payload)
}

const actionReestablishWebRTCConnection = ({ commit }, payload) => {
  commit('mutateReestablishWebRTCConnection', payload)
}

const actionLivechatPanelStates = ({ commit }, payload) => {
  commit('mutateLivechatPanelStates', payload)
}

const actionAddTextStream = ({ commit }, payload) => {
  commit('mutateAddTextStream', payload)
}

const actionNbestBottomPanel = ({ commit }, payload) => {
  commit('mutateNbestBottomPanel', payload)
}

const actionShowAvatarTransition = ({ commit }, payload) => {
  commit('mutateShowAvatarTransition', payload)
}

const actionIsKeywordsClicked = ({ commit }, payload) => {
  commit('mutateIsKeywordsClicked', payload)
}
/****************************************************/

/* Export Actions  */

/****************************************************/

export default {
  actionKaiSessionId,
  actionDebugMode,
  actionInputMessage,
  actionSendMessage,
  actionSendSelection,
  actionResponseData,
  actionRenderingStatus,
  actionTypingIndicator,
  actionLastMessageReceievedEpoc,
  actionCarouselCounter,
  actionPullServiceStart,
  actionPullServiceStop,
  actionRequestHeaders,
  actionUserTyping,
  actionAvatarUpdate,
  actionAvatarPersistentUpdate,
  actionAvatarTitle,
  actionShortcutPayload,
  actionDisplayShortcutPanel,
  actionShowInlinePopup,
  actionInlinePopupContent,
  actionBotLanguages,
  actionSlotsDefault,
  actionSpliceSelectedItemsIndex,
  actionPushSelectedItemsIndex,
  actionEmptySelectedItemsIndex,
  actionUseAvatar,
  actionUseHeaderBar,
  actionUseWidgetAnimation,
  actionAppKeyIndex,
  actionSelectLanguage,
  actionWebRTCStatus,
  actionIsMuted,
  actionIsAudioSharing,
  actionIsVideoSharing,
  actionIsDesktopSharing,
  actionMultimediaConfig,
  actionAttachmentFileTypes,
  actionLivechatCurrentMainVideo,
  actionLogEvent,
  actionLastCustomEventId,
  actionReestablishWebRTCConnection,
  actionLivechatPanelStates,
  actionAddTextStream,
  actionNbestBottomPanel,
  actionShowAvatarTransition,
  actionIsKeywordsClicked
}
