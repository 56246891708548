<template lang="en">
<section id="header-bar">
<div class="webview-header-wrapper" :class="{small:!useAvatar || !useHeaderBar}" navDir="horizontal">
  <div class="header-bar-top" v-if="enableLanguageSelectionUI">
    <div class="header-bar-buttons-wrapper">
      <div
        v-if="useWidgetMode && enableCloseButton"
        role="button"
        :aria-label="screenReaderTranslations.minimize + ' ' + screenReaderTranslations.virtualAssistantDesignation"
        tabindex="0"
        navLvl="1"
        navDir="horizontal"
        class="close-webview-language-select"
        :class="{align_right: alignIconRight}"
        v-on:click="this.closeWebviewWidget"
        ref="keyboardWrapper"
      >
        <span aria-hidden="true" class="c-icon icon-close-webview-language-select" :style="'background-image: url(' + this.closeIcon + ')'">
        </span>
      </div>
      <div
        v-if="useWidgetMode && enableMinimizeButton"
        role="button"
        :aria-label="screenReaderTranslations.minimize + ' ' + screenReaderTranslations.virtualAssistantDesignation"
        tabindex="0"
        navLvl="1"
        navDir="horizontal"
        class="minimize-webview-language-select"
        :class="{align_right: alignIconRight}"
        v-on:click="this.onMinimizeClick"
        ref="keyboardWrapper"
      >
        <span aria-hidden="true" class="c-icon icon-minimize-webview-language-select" :style="'background-image: url(' + this.minimizeIcon + ')'">
        </span>
      </div>
    </div>
    <div v-if="!useWidgetMode"></div>
    <div class="language-select-container" v-on:click="this.openLanguageModal">
      <div class="language-select-text">{{ selectedLanguage.code }}</div>
      <div class="language-select-icon" :style="'background-image: url(' + this.languageSelectIcon + ')'"></div>
    </div>
  </div>
  <div class="webview-header" v-show="useHeaderBar" :class="{avatarOpen: displayAvatarTitle}">
    <div class="header-bar-buttons-wrapper no_select_language">
      <div
        v-if="useWidgetMode && !enableLanguageSelectionUI && enableCloseButton"
        role="button"
        :aria-label="screenReaderTranslations.minimize + ' ' + screenReaderTranslations.virtualAssistantDesignation"
        tabindex="0"
        navLvl="1"
        navDir="horizontal"
        class="close-webview"
        :class="{align_right: alignIconRight, no_avatar: !useAvatar}"
        v-on:click="this.closeWebviewWidget"
        ref="keyboardWrapper"
      >
        <span aria-hidden="true" class="c-icon icon-close-webview" :style="'background-image: url(' + this.closeIcon + ')'">
        </span>
      </div>
      <div
        v-if="useWidgetMode && !enableLanguageSelectionUI && enableMinimizeButton"
        role="button"
        :aria-label="screenReaderTranslations.minimize + ' ' + screenReaderTranslations.virtualAssistantDesignation"
        tabindex="0"
        navLvl="1"
        navDir="horizontal"
        class="minimize-webview"
        :class="{align_right: alignIconRight, no_avatar: !useAvatar}"
        v-on:click="this.onMinimizeClick"
        ref="keyboardWrapper"
      >
        <span aria-hidden="true" class="c-icon icon-minimize-webview" :style="'background-image: url(' + this.minimizeIcon + ')'">
        </span>
      </div>
    </div>
    <Avatar v-if="showAvatar"></Avatar>
    <div v-if="!useAvatar" class="webview-title">{{title}}</div>
  </div>
  <AvatarTitle></AvatarTitle>
  <div v-show="useHeaderBar || enableLanguageSelectionUI || displayAvatarTitle" class="webview-header-bottom-padding"></div>
</div>
</section>
</template>

<script>

import Avatar from './Avatar'
import AvatarTitle from './AvatarTitle.vue'
import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import './styles/HeaderBar.less'

export default {
  name: 'HeaderBar',
  components: {
    Avatar,
    AvatarTitle
  },
  mixins: [
    KeyboardNavigationMixin,
    ImageUtilityMixin
  ],
  data () {
    return {
      response: this.$slots,
      minimizeIcon: this.getImagePath('minimize.png'),
      closeIcon: this.getImagePath('close_x.png'),
      languageSelectIcon: this.getImagePath('language.svg'),
      title: $store.getters.getBotLanguages.translations.header.title,
      screenReaderTranslations: $store.getters.getBotLanguages.translations.screenReader
    }
  },
  computed: {
    useHeaderBar () {
      return $store.state.useHeaderBar
    },
    useWidgetMode () {
      return $store.state.useWidgetMode
    },
    useAvatar: function () {
      return $store.state.useAvatar
    },
    showAvatar () {
      return !$store.getters.isSplashScreenEnabled
    },
    alignIconRight () {
      return $store.state.headerBarConfig && $store.state.headerBarConfig.minimizeButtonAlignRight
    },
    displayAvatarTitle () {
      return $store.getters.getAvatarTitle && $store.getters.getAvatarTitle.length > 0
    },
    selectedLanguage () {
      return $store.getters.getSelectedLanguage
    },
    enableLanguageSelectionUI () {
      return $store.state.enableLanguageSelectionUI
    },
    enableMinimizeButton () {
      return $store.state.enableMinimizeButton
    },
    enableCloseButton () {
      return $store.state.enableCloseButton
    }
  },
  watch: {
    useAvatar: function () {
      return $store.state.useAvatar
    },
    showAvatar () {
      return !$store.getters.isSplashScreenEnabled
    }
  },
  methods: {
    onMinimizeClick () {
      if ($store.state.enableMinimizeIntent) {
        kserver.CoreAPI.sendIntent('kcb_minimize')
      } else {
        Kai.API.minimizeWebviewWidget()
      }
    },
    closeWebviewWidget () {
      Kai.API.closeWebviewWidget()
    },
    openLanguageModal () {
      const buttons = [
        {
          type: 'CANCEL',
          label: 'Cancel'
        },
        {
          type: 'CONFIRM_LANGUAGE_SELECT',
          label: 'Confirm'
        }
      ]

      const payload = {
        title: 'Change Language',
        language_selection: true,
        buttons: buttons
      }

      Kai.Core.inlinePopup(payload, 'LANGUAGE_SELECTION')
    },
    closeModal () {
      this.$store.dispatch('actionShowInlinePopup', false)
    }
  }
}
</script>
