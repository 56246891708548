<template>
  <div
    :class="variant === 'message' ? 'kai-menu-message': 'menu'"
    role="button"
    @click.stop="handleDefaultClick()"
  >
    <svg
      v-if="variant === 'default' || variant === 'icon'"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        v-if="iconSource"
        :key="iconSource"
        :d="iconSource"
        fill="currentColor"
      />
    </svg>

    <p
      v-if="variant === 'default' || variant === 'icon'"
      class="menu-label overflow-hidden whitespace-nowrap text-ellipsis"
    >
      {{ option.label }}
    </p>
    <!-- eslint-disable vue/no-v-html -->
    <p
      v-if="variant === 'message'"
      ref="message"
      class="menu-label"
      v-html="option.label"
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'

export default {
  mixins: [ImageUtilityMixin],
  props: {
    option: {
      type: Object,
      default: () => {}
    },
    variant: {
      type: String,
      default: 'default'
    }
  },
  computed: {
    iconSource () {
      // To support icons with multiple paths
      if (this.option.icon === 'edit') {
        return this.getMenuItemsSVGStrings().edit
      }
      if (this.option.icon === 'clipboard') {
        return this.getMenuItemsSVGStrings().clipboard
      }
      if (this.option.icon === 'save') {
        return this.getMenuItemsSVGStrings().save
      }
      if (this.option.icon === 'delete') {
        return this.getMenuItemsSVGStrings().delete
      }
      if (this.option.icon === 'eye-show') {
        return this.getMenuItemsSVGStrings().eyeShow
      }
      if (this.option.icon === 'copy') {
        return this.getMenuItemsSVGStrings().copy
      }
      return false
    },
    copiedIconSource () {
      return this.getMenuItemsSVGStrings().copied
    }
  },
  mounted () {
    if (this.variant === 'message' && this.option.icon === 'copy') {
      this.$nextTick(() => {
        // we need to create the SVG element dynamically with regular javascript instead of Vue template
        // as we want the SVG icon for message variant to be inserted inlined inside the p tag and this same p tag needs to
        // use a v-html directive to render the potential HTML content of the message

        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
        svg.setAttribute('width', '13')
        svg.setAttribute('height', '15')
        svg.setAttribute('fill', 'none')
        svg.setAttribute('viewBox', '0 0 13 15')
        svg.addEventListener('click', this.handleMessageIconClick)
        svg.innerHTML = this.iconSource
        this.$refs.message.appendChild(svg)
      })
    }
  },
  methods: {
    handleDefaultClick () {
      if (this.variant === 'default' || this.variant === 'icon') {
        this.$emit('click')
      }
    },
    handleMessageIconClick () {
      if (this.variant === 'message') {
        const svg = this.$refs.message.querySelector('svg')
        const originalIcon = svg.innerHTML
        svg.innerHTML = this.copiedIconSource
        setTimeout(() => {
          svg.innerHTML = originalIcon
        }, 1250)
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped>
.menu, .kai-menu-message {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow: hidden;
    border-radius: 0.125rem;
    padding: 0.25rem;
}

.menu {
    cursor: pointer;
}

.menu:hover {
    color: var(--hover-text-color);
    background-color: var(--hover-color);
}

p {
    margin-bottom: 0.5rem;
    margin-bottom: var(--space-small);
    font-size: inherit;
    line-height: 1.65;
    text-rendering: optimizeLegibility;
}

p {
    font-size: 0.875rem;
    word-spacing: 0.12em;
}
.overflow-hidden {
    overflow: hidden;
}
.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.menu .menu-label, .kai-menu-message .menu-label{
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    flex-shrink: 0;
    font-size: 0.75rem;
    line-height: 1rem;
}

.kai-menu-message .menu-label {
    flex-shrink: initial;
}

</style>

<style>

.kai-menu-message .menu-label svg {
    cursor: pointer;
    cursor: hand;
    margin-left: 0.5rem;
    color: var(--playground-3-color);
}
.kai-menu-message .menu-label svg:hover {
  color: var(--secondary--action-color);
}
</style>
