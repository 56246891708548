<template>
  <section style="height:100%;">
    <CardItem
      v-if="item.type === 'CARD'"
      :item="item"
      :index="index"
      :contain-small-image="containSmallImage"
    />
    <Custom
      v-else-if="item.type === 'CUSTOM'"
      :item="item.payload"
      :index="index"
    />
    <MediumSingle
      v-else-if="item.type === 'MEDIUM'"
      :medium="item"
      :index="index"
    />
  </section>
</template>

<script>
import CardItem from './CardItem'
import Custom from './Custom'
import MediumSingle from './MediumSingle'

export default {
  name: 'Card',
  components: {
    CardItem,
    Custom,
    MediumSingle
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    containSmallImage: {
      type: Boolean,
      default: false
    }
  }
}
</script>
