<template>
  <div
    id="live-chat-expand-bar"
    :class="{ live_chat_open_up: showExpandBar && isLiveChatStarted && openUp,
              live_chat_open_down: showExpandBar && isLiveChatStarted && !openUp,
              live_chat_panel_small: livechatPanelStates.size === 'small'}"
    @click="expandPanel"
  >
    <div
      class="live-chat-expand-container"
      tabindex="0"
      navDir="horizontal"
    >
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="live-chat-expand-icon"
        v-html="maximizeIcon"
      />
      <!-- eslint-enable vue/no-v-html -->
      <span class="live-chat-expand-text">{{ expandText }}</span>
    </div>
  </div>
</template>

<script>

import ImageUtilityMixin from './Mixin/ImageUtilityMixin'

export default {
  name: 'LiveChatExpandBar',
  mixins: [
    ImageUtilityMixin
  ],
  props: {
    openUp: {
      type: Boolean
    }
  },
  data () {
    return {
    }
  },
  computed: {
    isLiveChatStarted () {
      return this.$store.getters.isLiveChatStarted
    },
    useWidgetMode () {
      return $store.state.useWidgetMode
    },
    livechatPanelStates () {
      if (!$store.getters.getLivechatPanelStates) {
        $store.state.livechatPanelStates = {
          size: 'minimized',
          mode: 'voice',
          subtitle: null,
          description: null,
          cancelLabel: $store.getters.getBotLanguages.translations.liveChatMessages.panelCancelLabel,
          confirmLabel: null,
          localAudioRefs: [],
          localVideoRefs: [],
          remoteVideoRefs: [],
          localRDSRefs: [],
          remoteRDSRefs: [],
          confirmFunction: () => {}
        }
      }
      return $store.getters.getLivechatPanelStates
    },
    showExpandBar () {
      return this.isLiveChatStarted && (this.$store.getters.getWebRTCStatus.videoUser ||
        (this.$store.getters.getWebRTCStatus.videoAgent && this.$store.getters.getWebRTCStatus.videoAgent.status) || this.$store.getters.getWebRTCStatus.rdsUser ||
        (this.$store.getters.getWebRTCStatus.rdsAgent && this.$store.getters.getWebRTCStatus.rdsAgent.status)) && this.$store.getters.getLivechatPanelStates.size !== 'big'
    },
    maximizeIcon () {
      return this.getLiveChatSVGStrings().maximize
    },
    expandText () {
      return this.$store.getters.getBotLanguages.translations.liveChatMessages.expandText
    }
  },
  methods: {
    expandPanel () {
      this.$store.dispatch('actionLivechatPanelStates', { ...$store.state.livechatPanelStates, size: 'big' })
    }
  }
}
</script>

<style>
    #live-chat-expand-bar {
        position: absolute;
        width: 100%;
        height: 0;
        background-color: var(--primary-action-color);
        z-index: 100;
    }

    #live-chat-expand-bar.live_chat_open_up {
        height: var(--live-chat-expand-bar-height);
        bottom: calc(var(--bottom-bar-height) + var(--live-chat-widget-height));
        transition: var(--transition-bottom-bar);
        transition-property: height;
        transition-delay: 1s;
    }

    #live-chat-expand-bar.live_chat_open_down {
        height: var(--live-chat-expand-bar-height);
        bottom: calc(var(--bottom-bar-height));
        transition: var(--transition-bottom-bar);
        transition-property: height;
        transition-delay: 0.5s;
    }

    @media screen and (max-width: 435px) {
      #live-chat-expand-bar.live_chat_open_down {
          bottom: calc(var(--bottom-bar-height) + var(--live-chat-widget-height));
      }
    }

    .live-chat-expand-container {
        display: none;
        align-items: center;
        padding: 0 5px;
        cursor: pointer;
    }

    #live-chat-expand-bar.live_chat_open_down .live-chat-expand-container {
        display: flex;
    }

    #live-chat-expand-bar.live_chat_open_up,
    #live-chat-expand-bar.live_chat_open_up .live-chat-expand-container {
        display: none;
    }

    #live-chat-expand-bar.live_chat_open_up:not(.live_chat_panel_small),
    #live-chat-expand-bar.live_chat_open_up:not(.live_chat_panel_small) .live-chat-expand-container {
        display: flex;
    }

    .live-chat-expand-container:focus {
        outline: none;
        box-shadow: 0 0 5px 3px var(--highlight-color);
    }

    .live-chat-expand-icon {
        cursor: pointer;
        color: var(--live-chat-logo-color);
    }

    .live-chat-expand-text {
        color: var(--right-message-text-color);
        font-weight: bold;
    }
</style>
