<template>
  <section
    class="kai-card-medium"
    navDir="vertical"
  >
    <MediumPieChartDiagram
      v-if="medium && medium.payload && medium.payload.type == 'PIECHART'"
      :medium="medium.payload"
      @piechartLoaded="mediumLoaded"
      @selectedItem="selectedPiechartItem"
    />
    <MediumPieChartDiagram
      v-else-if="medium && medium.type && medium.type == 'PIECHART'"
      :medium="medium"
      @piechartLoaded="mediumLoaded"
      @selectedItem="selectedPiechartItem"
    />
    <MediumImage
      v-else-if="medium
        && (medium.payload
          || (medium.medium_url && medium.medium_url !== ''))"
      :medium="medium"
      @imageLoaded="mediumLoaded"
    />
  </section>
</template>

<script>
import MediumPieChartDiagram from './MediumPieChartDiagram'
import MediumImage from './MediumImage'

export default {
  name: 'Medium',
  components: {
    MediumImage,
    MediumPieChartDiagram
  },
  props: {
    medium: {
      type: Object,
      required: true
    },
    isExpanded: Boolean
  },
  methods: {
    mediumLoaded (optionalChartRef) {
      this.$emit('mediumLoaded')
      if (optionalChartRef) {
        this.$emit('piechartMedium', optionalChartRef)
      }
    },
    selectedPiechartItem (index) {
      this.$emit('selectedPiechartItem', index)
    }
  }
}
</script>
