'use strict'

import { sdpAttributeRemoveCrypto, sdpAttributeInsert, sdpPatchUpAnswer } from './sdp-utils.js'

export class VideoBidirectional {
  constructor (type, location) {
    this.active = false
    this.window = new AutoWindow()
    this.localStream = undefined
    this.remoteStream = undefined
    this.peerConnection = undefined
    this.bidirectional = false
    this.type = type // rds or video
    this.location = location // local or remote
    this.auth = undefined
    var deviceCamera

    this.start = (auth) => {
      return new Promise((resolve) => {
        try {
          console.log(`Creating ${this.location} video peer connection`)
          this.peerConnection = new RTCPeerConnection({ iceServers: [] })
          this.active = true
        } catch (e) {
          console.log(`Failed to create PeerConnection, exception. ${e.message}`)
          closeStream(this)
          return
        }

        this.peerConnection.ontrack = async (e) => {
          if (this.bidirectional || (this.location === 'remote')) {
            if (this.window.elements) {
              this.window.elements.forEach((element) => {
                element.srcObject = e.streams[0]
              })
            }
          }
          if (this.location === 'remote') {
            console.log('Remote video stream added.')

            this.remoteStream = e.streams[0]
            // Need setTimeout before applying constraints. For some reasons, async/await does not work.

            if ($store.state.livechatCurrentMainVideo === undefined) {
              if (this.type === 'rds') {
                $store.state.livechatCurrentMainVideo = 'remote_desktop'
              } else {
                $store.state.livechatCurrentMainVideo = 'remote_video'
              }
            }
          }
        }

        if (this.type === 'video' && this.location === 'local') {
          console.log('Requesting access to local video.')
          this.window.self_preview = true

          navigator.mediaDevices.getUserMedia({
            audio: false,
            video: getCameraConstraints.call(this)
          })
            .then(async (stream) => {
              const handleMediaSuccess = localVideoMediaSuccess.bind(this)
              const offer = await handleMediaSuccess(stream)
              resolve(offer)
            })
            .catch((err) => {
              console.warn('Failed to get access to local media.', err)
              this.stop()
              resolve(null)
            })
        } else if (this.type === 'rds' && this.location === 'local') {
          console.log('Requesting access to desktop video.')
          navigator.mediaDevices.getDisplayMedia({ video: true })
            .then(async (stream) => {
              const handleMediaSuccess = localVideoMediaSuccess.bind(this)
              const offer = await handleMediaSuccess(stream)
              resolve(offer)
            })
            .catch((err) => {
              console.warn('Failed to get access to local media.', err)
              this.stop()
              resolve(null)
            })
        } else if (this.location === 'remote') {
          console.log(`Sending offer to peer (${this.location} remote).`)
          this.auth = auth
          this.peerConnection.addTransceiver('video')
          this.peerConnection.createOffer()
            .then(async (offer) => {
              offer.sdp = sdpAttributeInsert(offer.sdp, 'ice-lite')
              await this.peerConnection.setLocalDescription(offer)
              offer.sdp = sdpAttributeInsert(offer.sdp, `revation-view-auth:${this.auth}`)
              resolve(offer)
            })
            .catch((err) => {
              console.warn('Failed to create offer.', err)
              closeStream(this)
              resolve(null)
            })
        } else {
          console.warn(`Unknown video type. Expected type == [rds|video] and location == [local|remote], received type =='${this.type}' and location == '${this.location}'`)
          resolve(null)
        }
      })
    }

    this.changeCamera = (device) => {
      if (this.type === 'video' && this.location === 'local') {
        if (deviceCamera !== device) {
          deviceCamera = device
          if (this.localStream && this.peerConnection) {
            navigator.mediaDevices.getUserMedia({
              audio: false,
              video: getCameraConstraints.call(this)
            })
              .then(changeStream.bind(this))
              .catch((err) => {
                console.warn('Failed to get access to local media.', err)
                this.stop()
              })
          }
        }
      }
    }

    this.stop = () => {
      if (this.active) {
        if (this.localStream) {
          this.localStream.getTracks().forEach(function (track) {
            track.stop()
          })
          this.localStream = null
        }
        if (this.peerConnection) {
          this.peerConnection.close()
          this.peerConnection = null
        }
        if (this.window.elements) {
          this.window.elements.forEach((element) => {
            element.srcObject = null
          })
        }
        this.active = false
      }
    }

    this.remoteSDP = (content) => {
      return new Promise((resolve, reject) => {
        if (this.type === 'video' && this.location === 'local') {
          content = sdpPatchUpAnswer(content, 256)
        } else if (this.type === 'video' && this.location === 'remote') {
          content = sdpPatchUpAnswer(content)
        }

        this.peerConnection.setRemoteDescription({ type: 'answer', sdp: content })
          .then(() => {
            resolve('ok')
          })
          .catch((err) => {
            reject(new Error('Remote video description problem', err))
          })
      })
    }

    this.muteLocal = (muted) => {
      var isMuted = false
      if (this.localStream) {
        this.localStream.getTracks().forEach(function (t) {
          if (t.kind === 'video') {
            if (typeof muted === 'boolean') {
              t.enabled = !muted
            }
            isMuted = !t.enabled
          }
        })
      }
      return isMuted
    }

    const getCameraConstraints = () => {
      const constraints = {
        width: $store.state.webRTCConstraints.thumbnailVideo.idealWidth,
        height: $store.state.webRTCConstraints.thumbnailVideo.idealHeight,
        frameRate: { min: 1, ideal: 24, max: 30 }
      }

      if (deviceCamera) {
        constraints.deviceId = { exact: deviceCamera }
      }
      return constraints
    }

    const changeStream = (stream) => {
      const track = stream.getVideoTracks()[0]
      var sender = this.peerConnection.getSenders().find(function (s) {
        return s.track.kind === track.kind
      })
      sender.replaceTrack(track)

      if (this.window.elements) {
        this.window.elements.forEach((element) => {
          element.srcObject = stream
        })
      }
      this.remoteStream = stream
    }

    const localVideoMediaSuccess = (stream) => {
      return new Promise((resolve) => {
        console.log('User has granted access to local video.')
        if (this.window.elements) {
          this.window.elements.forEach((element) => {
            element.srcObject = stream
          })
        }

        this.localStream = stream
        const tracks = this.localStream.getTracks()
        tracks.forEach((track) => {
          this.peerConnection.addTrack(
            track,
            this.localStream
          )
        })

        this.peerConnection.createOffer({
          offerToReceiveAudio: false,
          offerToReceiveVideo: true
        })
          .then((offer) => {
            offer.sdp = sdpAttributeInsert(offer.sdp, 'ice-lite')
            this.peerConnection.setLocalDescription(offer)
              .then(() => {
                offer.sdp = sdpAttributeRemoveCrypto(offer.sdp)
                offer.sdp = sdpAttributeInsert(offer.sdp, this.bidirectional ? 'revation-sender:sendrecv' : 'revation-sender')
                resolve(offer)
              })
              .catch((err) => {
                console.warn('Local video description problem.', err)
                this.stop()
                resolve(null)
              })
          })
          .catch((err) => {
            console.warn('Create offer failed.', err)
            this.stop()
            resolve(null)
          })
      })
    }

    var closeStream = () => {
      this.active = false
      if (this.window.elements) {
        // any videos or shares
        this.window.elements.forEach((element) => {
          element.style.display = 'none'
        })
      }
      if (this.localStream) {
        if (this.peerConnection && this.peerConnection.removeStream) {
          this.peerConnection.removeStream(this.localStream)
        }
        this.stopTracks(this.localStream)
        this.localStream.onended = null
        this.localStream = null
      }
      if (this.remoteStream) {
        if (this.peerConnection && this.peerConnection.removeStream) {
          this.peerConnection.removeStream(this.remoteStream)
        }
        this.stopTracks(this.remoteStream)
        this.remoteStream.onended = null
        this.remoteStream = null
      }
      if (this.peerConnection) {
        this.peerConnection.close()
        this.peerConnection = null
      }
    }
  }
}

class AutoWindow {
  constructor () {
    this.elements = null
    this.div = null
    this.minimized = false
    this.self_preview = false
    this.calc_x = 0
    this.calc_y = 0
    this.calc_w = 0
    this.calc_h = 0

    this.fitToSize = (x, y, w, h) => {
      this.calc_x = x
      this.calc_y = y
      this.calc_w = w
      this.calc_h = h
      // if we knew the video dimensions, we would reduce the dimensions to match the aspect ratio
    }

    this.deflate = (p) => {
      this.calc_x += p
      this.calc_y += p
      this.calc_w -= p * 2
      this.calc_h -= p * 2
    }
  }
}
