<template>
  <div
    class="live-chat-widget-panel"
    :class="{ small_panel: panelSize === 'small', big_panel: panelSize === 'big'}"
  >
    <div
      class="live-chat-panel-header"
      :class="{ small_panel_header: panelSize !== 'big'}"
    >
      <div
        v-if="panelSize === 'big'"
        tabindex="0"
        class="live-chat-minimize-section"
        @click="minimizePanel()"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="live-chat-panel-minimize-button"
          v-html="minimizeIcon"
        />
        <!-- eslint-enable vue/no-v-html -->
        <span class="live-chat-panel-title">{{ minimizeText }}</span>
      </div>
    </div>

    <div class="live-chat-panel-inner-container">
      <div class="live-chat-panel-webrtc-container">
        <audio
          ref="localAudio"
          class="localAudio"
        />
        <div class="live-chat-panel-item live-chat-panel-thumbnail-container">
          <div
            v-show="webRTCStatus.rdsUser"
            class="live-chat-panel-webrtc-item live-chat-panel-local-desktop"
          >
            <figure class="figure">
              <video
                ref="localRDS"
                autoplay="autoplay"
                muted="false"
                controlsList="nodownload noremoteplayback"
                class="localDesktop border border-2"
              />
              <figcaption class="figure-caption text-center">
                {{ caption.rdsUserCaption }}
              </figcaption>
            </figure>
          </div>
          <div
            v-show="webRTCStatus.videoUser"
            class="live-chat-panel-webrtc-item live-chat-panel-local-video"
          >
            <figure class="figure">
              <video
                ref="localVideo"
                autoplay="autoplay"
                muted="false"
                controlsList="nodownload noremoteplayback"
                class="localVideo border border-2"
              />
              <figcaption class="figure-caption text-center">
                {{ caption.videoUserCaption }}
              </figcaption>
            </figure>
          </div>
          <div
            v-show="webRTCStatus.videoAgent"
            class="live-chat-panel-webrtc-item live-chat-panel-remote-video"
            :class="{ main_video: mainVideo === 'remote_video'}"
            @click="selectMainVideo($event, 'remote_video')"
          >
            <figure class="figure">
              <video
                ref="remoteVideo"
                autoplay="autoplay"
                muted="false"
                :controls="mainVideo === 'remote_video'? true: false"
                controlsList="nodownload noremoteplayback"
                class="remoteVideo border border-2"
              />
              <figcaption class="figure-caption text-center">
                {{ caption.videoAgentCaption }}
              </figcaption>
            </figure>
          </div>
          <!-- <div
          class="live-chat-panel-webrtc-item live-chat-panel-remote-avatar"
          :style="'background-image: url(' + remoteVideoIcon + '); background-repeat: no-repeat; background-position: center center;'"
        >
        </div> -->
          <div
            v-show="webRTCStatus.rdsAgent"
            class="live-chat-panel-webrtc-item live-chat-panel-remote-desktop"
            :class="{ main_video: mainVideo === 'remote_desktop'}"
            @click="selectMainVideo($event, 'remote_desktop')"
          >
            <figure class="figure">
              <video
                ref="remoteRDS"
                autoplay="autoplay"
                muted="false"
                :controls="mainVideo === 'remote_desktop'? true: false"
                controlsList="nodownload noremoteplayback"
                class="remoteDesktop border border-2"
              />
              <figcaption class="figure-caption text-center">
                {{ caption.rdsAgentCaption }}
              </figcaption>
            </figure>
          </div>
        </div>
        <div
          class="live-chat-panel-webrtc-item live-chat-panel-avatar"
          :style="'background-image: url(' + remoteVideoIcon + '); background-repeat: no-repeat; background-position: center center;'"
        />
      </div>
      <div
        v-if="panelSize ==='small' && mode !== 'settings'"
        class="live-chat-panel-image"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="mode === 'rds'"
          class="svg-wrapper"
          v-html="rdsIcon"
        />
        <div
          v-else-if="mode === 'video'"
          class="svg-wrapper"
          v-html="videoIcon"
        />
        <div
          v-else-if="mode === 'voice'"
          class="svg-wrapper"
          v-html="voiceIcon"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>
      <div
        v-if="panelSize ==='small' && mode !== 'settings'"
        class="live-chat-panel-text"
      >
        <span class="live-chat-panel-subtitle  ">
          {{ subtitle }}
        </span>
        <span class="live-chat-panel-description">
          {{ description }}
        </span>
      </div>
      <div
        v-if="mode === 'settings' && panelSize ==='small'"
        class="live-chat-panel-settings"
      >
        <div
          v-if="panelSize ==='small'"
          class="live-chat-panel-text"
        >
          <span class="live-chat-panel-subtitle">
            {{ $store.getters.getBotLanguages.translations.liveChatMessages.settingsPanelTitle }}
          </span>
        </div>
        <div class="live-chat-panel-settings-item">
          <span class="live-chat-panel-settings-label">
            {{ $store.getters.getBotLanguages.translations.liveChatMessages.settingsPanelMicrophoneLabel }}
          </span>
          <div class="live-chat-panel-settings-select">
            <div
              class="live-chat-panel-settings-selected-value"
              tabindex="0"
              @click="toggleMicrophoneSelect()"
            >
              {{ selectedMicrophone }}
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="microphoneSelectOpen"
                class="live-chat-panel-settings-select-arrow"
                v-html="maximizeIcon"
              />
              <div
                v-else
                class="live-chat-panel-settings-select-arrow"
                v-html="minimizeIcon"
              />
              <!-- eslint-enable vue/no-v-html -->
            </div>
            <ul
              v-if="microphoneSelectOpen"
              class="live-chat-panel-settings-select-options"
            >
              <li
                v-for="microphone in microphones"
                :key="microphone.label"
                :value="microphone.deviceId"
                @click="selectMicrophoneItem(microphone)"
              >
                {{ microphone.label }}
              </li>
            </ul>
          </div>
        </div>
        <div class="live-chat-panel-settings-item">
          <span class="live-chat-panel-settings-label">
            {{ $store.getters.getBotLanguages.translations.liveChatMessages.settingsPanelSpeakerLabel }}
          </span>
          <div class="live-chat-panel-settings-select">
            <div
              class="live-chat-panel-settings-selected-value"
              tabindex="0"
              @click="toggleSpeakerSelect()"
            >
              {{ selectedSpeaker }}
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="speakerSelectOpen"
                class="live-chat-panel-settings-select-arrow"
                v-html="maximizeIcon"
              />
              <div
                v-else
                class="live-chat-panel-settings-select-arrow"
                v-html="minimizeIcon"
              />
              <!-- eslint-enable vue/no-v-html -->
            </div>
            <ul
              v-if="speakerSelectOpen"
              class="live-chat-panel-settings-select-options"
            >
              <li
                v-for="speaker in speakers"
                :key="speaker.label"
                :value="speaker.deviceId"
                @click="selectSpeakerItem(speaker)"
              >
                {{ speaker.label }}
              </li>
            </ul>
          </div>
        </div>
        <div class="live-chat-panel-settings-item">
          <span class="live-chat-panel-settings-label">
            {{ $store.getters.getBotLanguages.translations.liveChatMessages.settingsPanelCameraLabel }}
          </span>
          <div class="live-chat-panel-settings-select">
            <div
              class="live-chat-panel-settings-selected-value"
              tabindex="0"
              @click="toggleCameraSelect()"
            >
              {{ selectedCamera }}
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="cameraSelectOpen"
                class="live-chat-panel-settings-select-arrow"
                v-html="maximizeIcon"
              />
              <div
                v-else
                class="live-chat-panel-settings-select-arrow"
                v-html="minimizeIcon"
              />
              <!-- eslint-enable vue/no-v-html -->
            </div>
            <ul
              v-if="cameraSelectOpen"
              class="live-chat-panel-settings-select-options"
            >
              <li
                v-for="camera in cameras"
                :key="camera.label"
                :value="camera.deviceId"
                @click="selectCameraItem(camera)"
              >
                {{ camera.label }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        v-if="mode === 'settings' && panelSize ==='small'"
        class="live-chat-panel-buttons-container"
      >
        <div
          class="kai-card-button kai-card-outline-button"
          tabindex="0"
          @click="populateDeviceSelectors()"
        >
          {{ $store.getters.getBotLanguages.translations.liveChatMessages.settingsPanelRefreshLabel }}
        </div>
        <div
          class="kai-card-button"
          tabindex="0"
          @click="minimizePanel()"
        >
          {{ $store.getters.getBotLanguages.translations.liveChatMessages.settingsPanelCloseLabel }}
        </div>
      </div>
      <div
        v-if="panelSize ==='small' && mode !== 'settings'"
        class="live-chat-panel-buttons-container"
      >
        <div
          class="kai-card-button kai-card-outline-button"
          tabindex="0"
          @click="minimizePanel()"
        >
          {{ cancelLabel }}
        </div>
        <div
          class="kai-card-button"
          tabindex="0"
          @click="confirm()"
        >
          {{ confirmLabel }}
        </div>
      </div>
    </div>
    <live-chat-video-widget v-if="panelSize === 'big'" />
    <InlinePopup
      v-if="panelSize === 'big' && useWidgetMode"
      class="inline_popup_widget"
    />
  </div>
</template>
<script>

import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import LiveChatVideoWidget from './LiveChatVideoWidget.vue'
import LiveChatButtonsMixin from './Mixin/LiveChatButtonsMixin'
import InlinePopup from './InlinePopup'

export default {
  name: 'LiveChatWidget',
  components: { LiveChatVideoWidget, InlinePopup },
  mixins: [
    KeyboardNavigationMixin,
    ImageUtilityMixin,
    LiveChatButtonsMixin
  ],
  props: {
    panelSize: {
      type: String,
      default: 'minimized'
    },
    mode: {
      type: String,
      default: 'voice'
    },
    subtitle: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    cancelLabel: {
      type: String,
      default: 'Cancel'
    },
    confirmLabel: {
      type: String,
      default: 'Start Voice'
    },
    confirm: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      remoteVideoIcon: this.getImagePath('svg/agent_avatar_large.svg'),
      // mainVideo: 'remote_video'
      microphoneSelectOpen: false,
      speakerSelectOpen: false,
      cameraSelectOpen: false
    }
  },
  computed: {
    iconSVGString () {
      return this.getLiveChatSVGStrings()
    },
    voiceIcon () {
      return this.iconSVGString.phone
    },
    videoIcon () {
      return this.iconSVGString.video
    },
    rdsIcon () {
      return this.iconSVGString.desktop
    },
    minimizeIcon () {
      return this.iconSVGString.minimize
    },
    maximizeIcon () {
      return this.iconSVGString.maximize
    },
    minimizeText () {
      return this.$store.getters.getBotLanguages.translations.liveChatMessages.minimizeText
    },
    currentMode () {
      return this.mode
    },
    caption () {
      return {
        rdsUserCaption: this.$store.getters.getBotLanguages.translations.liveChatMessages.rdsUserCaption,
        videoUserCaption: this.$store.getters.getBotLanguages.translations.liveChatMessages.videoUserCaption,
        videoAgentCaption: this.$store.getters.getBotLanguages.translations.liveChatMessages.videoAgentCaption,
        rdsAgentCaption: this.$store.getters.getBotLanguages.translations.liveChatMessages.rdsAgentCaption
      }
    },
    mainVideo: {
      get () {
        return this.$store.state.livechatCurrentMainVideo
      },
      set (newMainVideo) {
        return newMainVideo
      }
    },
    useWidgetMode () {
      return $store.state.useWidgetMode
    }
  },
  watch: {
    currentMode (newMode) {
      if (newMode === 'settings') {
        this.populateDeviceSelectors()
      }
    },
    webRTCStatus: {
      handler (newValue, oldValue) {
        if (this.livechatPanelStates.size === 'big') {
          // if neither agent or user are sharing rds or video, minimize the panel
          if (!this.webRTCStatus.rdsUser && !this.webRTCStatus.rdsAgent && !this.webRTCStatus.videoUser && !this.webRTCStatus.videoAgent) {
            this.$nextTick(() => {
              $store.dispatch('actionLivechatPanelStates', {
                ...this.livechatPanelStates,
                size: 'minimized'
              })
            })
          }
          if (this.webRTCStatus.rdsAgent && !this.webRTCStatus.videoAgent) {
            this.selectMainVideo(null, 'remote_desktop')
          } else if (this.webRTCStatus.videoAgent && !this.webRTCStatus.rdsAgent) {
            this.selectMainVideo(null, 'remote_video')
          }
        }
      },
      deep: true
    }
  },
  created () {
    this.$nextTick(() => {
      if (this.$parent.livechatPanelStates.localAudioRefs && this.$parent.livechatPanelStates.localAudioRefs.length > 1) {
        this.$refs.localAudio.srcObject = this.$parent.livechatPanelStates.localAudioRefs[0].srcObject
        this.$refs.localVideo.srcObject = this.$parent.livechatPanelStates.localVideoRefs[0].srcObject
        this.$refs.remoteVideo.srcObject = this.$parent.livechatPanelStates.remoteVideoRefs[0].srcObject
        this.$refs.localRDS.srcObject = this.$parent.livechatPanelStates.localRDSRefs[0].srcObject
        this.$refs.remoteRDS.srcObject = this.$parent.livechatPanelStates.remoteRDSRefs[0].srcObject
      }
      this.$parent.livechatPanelStates.localAudioRefs.push(this.$refs.localAudio)
      this.$parent.livechatPanelStates.localVideoRefs.push(this.$refs.localVideo)
      this.$parent.livechatPanelStates.remoteVideoRefs.push(this.$refs.remoteVideo)
      this.$parent.livechatPanelStates.localRDSRefs.push(this.$refs.localRDS)
      this.$parent.livechatPanelStates.remoteRDSRefs.push(this.$refs.remoteRDS)
    })
  },
  methods: {
    minimizePanel () {
      this.$store.dispatch('actionLivechatPanelStates', { ...$store.state.livechatPanelStates, size: 'minimized' })
    },
    selectMainVideo (event, video) {
      if (event) {
        event.preventDefault()
      }

      this.$store.dispatch('actionLivechatCurrentMainVideo', video)

      // if (video == 'remote_desktop') {
      //   if ($store.state.webrtc.rdsRemote.remoteStream != undefined) {
      //     $store.state.webrtc.rdsRemote.remoteStream.getVideoTracks()[0].applyConstraints(mainConstraints)
      //   }
      //   if ($store.state.webrtc.videoRemote.remoteStream != undefined) {
      //     $store.state.webrtc.videoRemote.remoteStream.getVideoTracks()[0].applyConstraints(thumbnailConstraints)
      //   }
      // } else {
      //   if ($store.state.webrtc.rdsRemote.remoteStream != undefined) {
      //     $store.state.webrtc.rdsRemote.remoteStream.getVideoTracks()[0].applyConstraints(thumbnailConstraints)
      //   }

      //   if ($store.state.webrtc.videoRemote.remoteStream != undefined) {
      //     $store.state.webrtc.videoRemote.remoteStream.getVideoTracks()[0].applyConstraints(mainConstraints)
      //   }
      // }

      this.mainVideo = video
    },
    toggleMicrophoneSelect () {
      this.microphoneSelectOpen = !this.microphoneSelectOpen
      this.speakerSelectOpen = false
      this.cameraSelectOpen = false
    },
    selectMicrophoneItem (microphone) {
      this.selectedMicrophone = microphone.label
      this.changeMicrophone(microphone)
      this.microphoneSelectOpen = false
    },
    toggleSpeakerSelect () {
      this.speakerSelectOpen = !this.speakerSelectOpen
      this.microphoneSelectOpen = false
      this.cameraSelectOpen = false
    },
    selectSpeakerItem (speaker) {
      this.selectedSpeaker = speaker.label
      this.changeSpeaker(speaker)
      this.speakerSelectOpen = false
    },
    toggleCameraSelect () {
      this.cameraSelectOpen = !this.cameraSelectOpen
      this.microphoneSelectOpen = false
      this.speakerSelectOpen = false
    },
    selectCameraItem (camera) {
      this.selectedCamera = camera.label
      this.changeCamera(camera)
      this.cameraSelectOpen = false
    }
  }
}
</script>
