<template>
  <!-- <section style="height:100%;" class="top-k-card-container"> -->
  <TopKCardItem
    :item="item"
    :index="index"
  />
  <!-- </section> -->
</template>

<script>
import TopKCardItem from './TopKCardItem'

export default {
  name: 'TopKCard',
  components: {
    TopKCardItem
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: false,
      default: 0
    }
  }
}
</script>
