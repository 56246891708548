<template>
  <div>
    <ListView
      v-if="!useGroupedListMode"
      :response="response"
    />
    <ListViewGrouped
      v-if="useGroupedListMode"
      :response="response"
    />
  </div>
</template>

<script>
import ListView from './ListView'
import ListViewGrouped from './ListViewGrouped'
export default {
  name: 'ListViewBase',
  components: {
    ListView,
    ListViewGrouped
  },
  data () {
    return {
      useGroupedListMode: window.$store.getters.getGroupedListMode,
      response: this.$slots
    }
  }
}
</script>
