import { render, staticRenderFns } from "./KaiAnswersBottomBar.vue?vue&type=template&id=6c9e17c1&scoped=true"
var script = {}
import style0 from "./styles/KaiAnswersBottomBar.less?vue&type=style&index=0&id=6c9e17c1&prod&lang=less&scoped=true&external"
import style1 from "./KaiAnswersBottomBar.vue?vue&type=style&index=1&id=6c9e17c1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c9e17c1",
  null
  
)

export default component.exports