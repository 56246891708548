<template>
  <section
    v-if="inlinePopupOpen"
    class="inline-popup-container"
  >
    <div
      ref="keyboardWrapper"
      class="inline-popup-wrapper"
      :class="{ 'inline-popup-open': inlinePopupOpen , 'inline-popup-toast': isToastMessage}"
    >
      <div
        class="inline-popup-content kai-modal-dialog"
        :class="{ inline_popup_content_open: inlinePopupOpen }"
        role="region"
      >
        <div
          v-if="!isToastMessage"
          class="kai-modal-content"
        >
          <div
            v-if="popupContent && popupContent.title"
            class="kai-modal-header kai-inline-modal-header"
          >
            <h4 class="kai-modal-title">
              {{ popupContent.title }}
            </h4>
            <button
              type="button"
              class="close"
              @click="popupContent.popupType && popupContent.popupType === 'MINIMIZE' ? buttonEvent('MINIMIZE_WEBVIEW'): buttonEvent('CLOSE')"
            >
              &times;
            </button>
          </div>
          <div class="kai-modal-body">
            <div
              id="kai_inline_modal_content_wrapper"
              class="kai-inline-kai-modal-content-wrapper"
            >
              <div
                v-if="popupContent && popupContent.medium"
                id="kai_inline_modal_image_content_wrapper"
                class="kai-inline-modal-image-content-wrapper"
              >
                <MediumSmall :medium="popupContent.medium" />
              </div>
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="popupContent && popupContent.subtitle"
                id="kai_inline_modal_content"
                class="kai-inline-kai-modal-content"
                v-html="popupContent.subtitle"
              />
              <!-- eslint-enable vue/no-v-html -->
              <div
                v-if="popupContent && popupContent.language_selection"
                class="radio-button-container"
              >
                <template v-for="(language, index) in languageChoices">
                  <RadioButton
                    :key="index"
                    :language="language"
                    :on-click-radio="languageRadioOnChange"
                  />
                </template>
              </div>
            </div>
          </div>
          <div
            v-if="popupContent && popupContent.buttons"
            id="kai_inline_modal_footer"
            class="kai-modal-footer kai-inline-modal-footer kai-card-button-container"
          >
            <template v-for="(button, index) in popupContent.buttons">
              <button
                v-if="!button.payload || button.payload == ''"
                :id="`action_button_${index}`"
                :key="index"
                type="button"
                :aria-label="button.label"
                class="kai-card-button"
                :class="{
                  'kai-card-outline-button': isInOutlinedList(button.type)
                }"
                @click="buttonEvent(button.type, button.content)"
              >
                {{ button.label }}
              </button>
              <!-- eslint-disable vue/no-v-html -->
              <button
                v-else
                :id="`action_button_${index}`"
                :key="index"
                class="kai-card-button"
                role="button"
                :aria-label="button.label"
                @click="itemButton(button.payload && button.payload.type ? button.payload: button)"
                v-html="button.label"
              />
              <!-- eslint-enable vue/no-v-html -->
            </template>
          </div>
        </div>
        <div
          v-else-if="isToastMessage"
          class="kai-modal-content"
          :class="{'kai-modal-content-show': showToastMessage}"
        >
          <div
            class="kai-toast-message"
          >
            {{ popupContent.message }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Kai from '../../kai'
import MediumSmall from './MediumSmall'
import RadioButton from './RadioButton.vue'
import './styles/InlinePopup.less'
import LiveChatButtonsMixin from './Mixin/LiveChatButtonsMixin'

export default {
  name: 'InlinePopup',
  components: {
    MediumSmall,
    RadioButton
  },
  mixins: [LiveChatButtonsMixin],
  data () {
    return {
      outlineButtonTypeList: ['CANCEL', 'CLOSE', 'CUSTOM_CANCEL'],
      selectedRadioLanguageObj: $store.getters.getSelectedLanguage,
      showToastMessage: false
    }
  },
  computed: {
    // Listen to see if store state change
    inlinePopupOpen () {
      return $store.getters.getShowInlinePopup
    },
    popupContent () {
      return $store.getters.getInlinePopupContent
    },
    languageChoices () {
      return $store.getters.getLanguageChoices
    },
    isToastMessage () {
      return this.popupContent && this.popupContent.toast_message
    }

  },
  watch: {
    popupContent (newVal) {
      if (newVal && newVal.toast_message) {
        setTimeout(() => {
          this.showToastMessage = true // flag to change opacity for fade in animation
          const durationMs = newVal.duration || 8000
          setTimeout(() => {
            this.showToastMessage = false
            setTimeout(() => this.closeModal(), 500) // 500ms to match duration of fade out animation
          }, durationMs)
        }, 100)
      }
    }
  },
  methods: {
    buttonEvent (eventName, eventData) {
      switch (eventName.toUpperCase()) {
        case 'CANCEL':
        case 'CLOSE':
        case 'CUSTOM_CANCEL': {
          this.selectedRadioLanguageObj = $store.getters.getSelectedLanguage
          this.closeModal()
          Kai.API.sendUIEvent(`popup${this.popupContent.popupType ? '_' + this.popupContent.popupType.toLowerCase() : ''}`, `${eventName.toLowerCase()}_button`, 'clicked', this.popupContent && this.popupContent.title ? `Popup for "${this.popupContent.title}"` : null)
          break
        }
        case 'INTENT': {
          Kai.Core.submitIntent(eventData)
          this.closeModal()
          break
        }
        case 'END_LIVECHAT': {
          kserver.CoreAPI.sendLiveChatEndSessionEvent()
          this.endWebRTC()
          this.closeModal()
          break
        }
        case 'MESSAGE':
        case 'TEXT': {
          Kai.Core.sendHiddenUserMessage(eventData)
          this.closeModal()
          break
        }
        case 'MINIMIZE_WEBVIEW': {
          this.closeModal()
          Kai.API.minimizeWebviewWidget()
          break
        }
        case 'CONFIRM_LANGUAGE_SELECT': {
          this.closeModal()
          Kai.API.selectLanguage(this.selectedRadioLanguageObj.code)
          break
        }
        default: {
          Kai.API.sendUIEvent(`popup${this.popupContent.popupType ? '_' + this.popupContent.popupType.toLowerCase() : ''}`, 'default_button', 'clicked', this.popupContent && this.popupContent.title ? `Popup for "${this.popupContent.title}"` : null)
          this.closeModal()
        }
      }
    },
    closeModal () {
      this.$store.dispatch('actionShowInlinePopup', false)
    },
    itemButton (payload) {
      Kai.Core.buttonPressed(payload)
      this.closeModal()
    },
    isInOutlinedList (buttonType) {
      return (
        this.outlineButtonTypeList.filter(x => x === buttonType).length >= 1
      )
    },
    languageRadioOnChange (languageSelected) {
      this.selectedRadioLanguageObj = languageSelected
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.kai-inline-modal-image-content-wrapper ::v-deep .medium-small-wrapper {
  float: none;
  margin-left: 0;
  margin-top: 0;
  width: fit-content;
}

/* make sure that the image is small */
.kai-inline-modal-image-content-wrapper ::v-deep .medium-small-wrapper img {
  max-width: 120px;
  max-height: 90px;
  width: auto;
  height: auto;
}

.radio-button-container {
  padding: 10px 30px;
}
</style>
