<template>
  <section
    id="kai-container"
    class="kai_container"
    :class="{
      avatarHeaderBar:useHeaderBar && useAvatar,
      smallHeaderBar:useHeaderBar && !useAvatar,
      avatarTitleDisplayed:isAvatarTitleDisplayed,
      languageSelection: enableLanguageSelectionUI}"
    :style="{'bottom': bottomHeight + 'px'}"
  >
    <ScreenReader />
    <div
      class="kai-inner-container"
      :class="{'kai-avatar-container':useAvatar, 'kai-no-avatar-container': !useAvatar, 'language-select-header': useHeaderBar && enableLanguageSelectionUI && !useAvatar}"
    >
      <!-- Append component to the of the dom -->
      <div id="kai-component-top" />
      <!-- Append core component -->
      <div id="kai-core-components" />

      <TypingIndicator
        v-if="triggerTypingIndicator"
        class="kai-typing-indicator"
      />
      <!-- Append component after content -->
      <div
        id="kai-component-default"
        class="clear"
        :style="'height:' + containerHeight + 'px;'"
      />
      <!-- Append component to the bottom of the dom  -->
      <div id="kai-component-bottom" />
    </div>
  </section>
</template>
<script>
import Kai from '../../kai'
import TextBubble from './TextBubble'
import TypingIndicator from './TypingIndicator'
import ScreenReader from './ScreenReader'
import moment from 'moment'
import './styles/Container.less'
import smoothscroll from 'smoothscroll-polyfill'
import { detect } from 'detect-browser'
import { isEmpty } from 'lodash'

export default {
  name: 'Container',
  filters: {
    moment (date) {
      return moment(date).format('Do MMMM., h:mm a')
    }
  },
  components: {
    TypingIndicator,
    ScreenReader
  },
  data () {
    return {
      controls: {
        showTypingIndicator: false
      },
      checkedNames: [],
      containerHeight: 800,
      bottomHeight: 0,
      responsesQueue: [],
      isRenderingNextInQueue: false,
      autoScrollPending: false,
      resizeDefaultComponentPending: false,
      previousScrollDistance: 0,
      scrollCounter: 0
    }
  },
  computed: { // Listen to see if store state change
    processMessage () {
      return this.$store.getters.getInputMessage
    },
    processResponse () {
      return this.$store.getters.getResponseData
    },
    onRenderingStatusUpdate () {
      return this.$store.getters.getRenderingStatus
    },
    triggerTypingIndicator () {
      return this.$store.getters.getTypingIndicator
    },
    useHeaderBar () {
      return $store.state.useHeaderBar
    },
    useAvatar () {
      return $store.state.useAvatar
    },
    isAvatarTitleDisplayed () {
      return $store.getters.getAvatarTitle && $store.getters.getAvatarTitle.length > 0
    },
    useWidgetMode () {
      return $store.state.useWidgetMode
    },
    enableLanguageSelectionUI () {
      return $store.state.enableLanguageSelectionUI
    },
    isLiveAgentConnected () {
      return this.$store.getters.isLiveAgentConnected
    },
    isLiveChatStarted () {
      return this.$store.getters.isLiveChatStarted
    },
    isKeywordsClicked() {
      return this.$store.getters.isKeywordsClicked
    }
  },
  watch: { // Run method if store state change
    processMessage (message) {
      if (message) {
        // Wait for actionRenderingStatus to be set back to 'idle' before dispatching again the status 'started' to make sure the Vue mutation is triggered.
        // (This is needed in case a previous User Message has been sent and the status is already 'started' because the server hasn't replied yet.
        // Typically happened when connection timeout and the user keep sending message)
        $store.dispatch('actionRenderingStatus', 'idle').then(() =>
          $store.dispatch('actionRenderingStatus', 'started').then(() => setTimeout(() => Kai.Core.addMessageContent('kai-core-components', message, TextBubble, 'kai-right'), 100)))
      }
    },
    processResponse (response) {
      if (response) {
        this.responsesQueue.push(response)
        this.renderResponse()
        $store.state.responseData = undefined
      }
    },
    triggerTypingIndicator (newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        if (this.isLiveAgentConnected || this.isKeywordsClicked) {
          this.performAutoScroll()
          $store.dispatch('actionIsKeywordsClicked', false)
        }
      }
    },
    async onRenderingStatusUpdate (status) {
      try {
        await this.calculateBottomHeight()
      } catch (error) {
        console.error('onRenderingStatusUpdate - An error occurred:', error)
        // Retry the operation
        if (this.retryCount < 3) {
          this.retryCount++
          this.calculateBottomHeight()
        }
      }
      // when rendering start the WV should scroll to display the latest message at the top of the chat window.
      // to allow the latest message to reach the top of the chat window, the blank container "kai-component-default" height is adjusted.
      if (status === 'started') {
        if (this.isLiveChatStarted || this.isLiveAgentConnected) {
          this.resizeDefaultComponent()
        } else {
          this.containerHeight = this.$el.offsetHeight
        }
        if (!this.isRenderingNextInQueue) {
          this.addStartMarker()
          this.performAutoScroll()
        }
      }
      // when rendering is finished the blank container "kai-component-default" height is reduce to fit the remaining space on the screen and not let the user scroll down on an empty window.
      if (status === 'finished') {
        this.resizeDefaultComponent()
        if (!this.isRenderingNextInQueue) {
          if (this.isLiveChatStarted || this.isLiveAgentConnected) {
            this.performAutoScroll()
          }
        }
        // remove first response in queue when rendering finished
        this.responsesQueue.splice(0, 1)
        this.$nextTick(() => {
          if (this.responsesQueue.length > 0) {
            this.isRenderingNextInQueue = true
            this.renderResponse()
          } else {
            this.isRenderingNextInQueue = false
          }
        })
        $store.dispatch('actionRenderingStatus', 'idle')
      }
    },
    autoScrollPending (newVal) {
      if (!newVal) {
        const _this = this
        this.$nextTick(() => {
          if (_this.resizeDefaultComponentPending) {
            _this.resizeDefaultComponent()
          }
        })
      }
    }
  },
  async mounted () {
    // kick off the polyfill!
    const browser = detect()
    if (browser && (browser.name === 'safari' || browser.name === 'ios')) {
      if ((browser.name === 'safari' && browser && browser.version.split('.')[0] < 16) || browser.name === 'ios') {
        window.__forceSmoothScrollPolyfill__ = true
      }
    }
    smoothscroll.polyfill()

    // Check for Mobile
    this.$nextTick(() => {
      window.addEventListener('load', () => {
        this.windowWidth = window.innerWidth
        if (this.windowWidth <= 960) {
          $jq('#kai-container').addClass('mobile')
        }
      })
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
        if (this.windowWidth <= 960) {
          $jq('#kai-container').addClass('mobile')
        } else {
          $jq('#kai-container').removeClass('mobile')
        }
      })
    })
    if ($store.state.locationAPIKey && $store.state.locationAPIKey.length > 0 && $store.getters.getAppKeyIndex === 0) {
      const googleMapScript = document.createElement('script')
      const source = `https://maps.google.com/maps/api/js?key=${$store.state.locationAPIKey}${
        ($store.getters.getBotLanguages.region !== undefined ? '&region=' + $store.getters.getBotLanguages.region : '')
      }${($store.getters.getBotLanguages.language !== undefined ? '&language=' + $store.getters.getBotLanguages.language : '')
      }&libraries=places&callback=Function.prototype`
      googleMapScript.setAttribute('src', source)
      document.head.appendChild(googleMapScript)
    }
    try {
      await this.calculateBottomHeight()
      this.resizeDefaultComponent()
    } catch (error) {
      console.error('mounted - An error occurred:', error)
      // Retry the operation
      if (this.retryCount < 3) {
        this.retryCount++
        this.calculateBottomHeight()
        this.resizeDefaultComponent()
      }
    }
    this.observeQuickReplyHeight()
  },
  methods: {
    moment,
    calculateBottomHeight () {
      var _this = this
      return new Promise((resolve, reject) => {
        _this.$nextTick(() => {
          try {
            var bottomBarHeight = 0
            var bottomBarElt

            if ($store.state.useKaiAnswersBottomBar) {
              bottomBarElt = document.querySelector('#webview-container #kai-answers-bottombar')
            } else {
              bottomBarElt = document.querySelector('#webview-container #bottombar')
            }

            if (bottomBarElt) {
              bottomBarHeight = bottomBarElt.getBoundingClientRect().height
            }

            var quickReplyElt = document.querySelector('#kai-qr-bottom-bar-container')
            var quickReplyHeight = 0

            if (quickReplyElt) {
              quickReplyHeight = quickReplyElt.getBoundingClientRect().height
            }

            _this.bottomHeight = bottomBarHeight + quickReplyHeight
            resolve(_this.bottomHeight)
          } catch (error) {
            console.error('calculateBottomHeight - An error occurred:', error)
            reject(error)
          }
        })
      })
    },
    observeQuickReplyHeight () {
      const element = document.querySelector('#kai-qr-bottom-bar-container')

      if (!element) {
        console.error('Element #kai-qr-bottom-bar-container not found')
        return
      }

      const resizeObserver = new ResizeObserver(async () => {
        try {
          await this.calculateBottomHeight()
        } catch (error) {
          console.error('observeQuickReplyHeight - An error occurred:', error)
          // Retry the operation
          if (this.retryCount < 3) {
            this.retryCount++
            this.calculateBottomHeight()
          }
        }
      })

      this.$nextTick(() => {
        resizeObserver.observe(element)
      })

      // Cleanup
      this.$once('hook:beforeDestroy', () => {
        resizeObserver.unobserve(element)
      })
    },
    resizeDefaultComponent () {
      // if autoScrollPending is true, it means the autoScroll is still in progress, we need to wait for it to finish before resizing the default component
      if (!this.autoScrollPending || this.isLiveChatStarted || this.isLiveAgentConnected) {
        this.resizeDefaultComponentPending = false
        this.$nextTick(() => {
          var defComp = this.$el.querySelector('#kai-component-default')
          var currentHeight = defComp.offsetHeight
          var defCompRect = defComp.getBoundingClientRect()
          var kaiContainerRect = this.$el.getBoundingClientRect()
          var qrBottomBarRect = document.querySelector('#kai-qr-bottom-bar-container').getBoundingClientRect()
          const isQrBottomBarVisible = qrBottomBarRect.top > 0
          const qrBottomBarHeight = qrBottomBarRect.top - defCompRect.top
          const kaiContainerHeight = kaiContainerRect.bottom - defCompRect.top

          const minHeight = isQrBottomBarVisible ? qrBottomBarHeight : kaiContainerHeight
          const adjustedHeight = Math.min(currentHeight, minHeight)

          this.containerHeight = Math.max(0, adjustedHeight)
        })
      } else {
        this.resizeDefaultComponentPending = true
      }
    },
    renderResponse () {
      if ((this.$store.getters.getRenderingStatus !== 'started' && this.responsesQueue.length > 0) ||
        (this.$store.getters.getRenderingStatus === 'started' && this.responsesQueue.length === 1)) {
        if ('source' in this.responsesQueue[0] && this.responsesQueue[0].source === 'pullRequest') {
          Kai.Core.handleResponse(this.responsesQueue[0], 0, false)
        } else {
          Kai.Core.handleResponse(this.responsesQueue[0])
        }
      }
    },
    performAutoScroll () {
      var _this = this
      _this.autoScrollPending = true
      let isPendingTextStream = false

      if (!isEmpty($store.getters.getPendingTextStream)) {
        for (const key in $store.getters.getPendingTextStream) {
          if ($store.getters.getPendingTextStream[key].instantiated) {
            isPendingTextStream = true
          }
        }
      }

      setTimeout(() => {
        const startElements = _this.$el.querySelectorAll('#kai-core-components .kai-component-start')
        // get last one
        var lastElement = startElements[startElements.length - 1]
        const ignoreStartElement = _this.isLastCoreComponentElement(lastElement)
        if (ignoreStartElement) {
          if (startElements.length > 1) {
            lastElement = startElements[startElements.length - 2]
          }
        }
        if (lastElement !== undefined && lastElement !== null && !isPendingTextStream && !_this.isLiveAgentConnected) {
          lastElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
          requestAnimationFrame(_this.checkScroll)
        } else if (lastElement !== undefined && lastElement !== null && _this.isLiveAgentConnected) {
          var defComp = this.$el.querySelector('#kai-component-default').getBoundingClientRect()
          var qrBottomBarRect = document.querySelector('#kai-qr-bottom-bar-container').getBoundingClientRect()
          if (defComp.top > qrBottomBarRect.top) {
            _this.$el.querySelector('#kai-component-default').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
          }
        }
      }, $store.state.defaultMessageDelayTimer)
    },
    checkScroll () {
      const container = document.querySelector('.kai_container')
      const startElements = this.$el.querySelectorAll('#kai-core-components .kai-component-start')
      // get last one
      const lastElement = startElements[startElements.length - 1]
      if (!lastElement) {
        return
      }
      const containerTop = container.getBoundingClientRect().top
      const lastElementTop = lastElement.getBoundingClientRect().top
      const distance = lastElementTop - containerTop
      if (distance < 50 || this.scrollCounter === 10) {
        // The container is close to the last element, stop checking
        this.autoScrollPending = false
        this.previousScrollDistance = 0
        this.scrollCounter = 0
      } else if (distance === this.previousScrollDistance && this.scrollCounter < 10) {
        // The container is not close to the last element, but it hasn't moved either, check again in 100ms
        this.scrollCounter++
        setTimeout(this.checkScroll, 200)
      } else {
        // The container is not close to the last element, check again on the next frame
        this.previousScrollDistance = distance
        requestAnimationFrame(this.checkScroll)
      }
    },
    addStartMarker () {
      const startMarker = document.createElement('div')
      startMarker.classList.add('kai-component-start')
      this.$el.querySelector('#kai-core-components').appendChild(startMarker)
    },
    isLastCoreComponentElement (element) {
      // find index of element among children of _this.$el.querySelectorAll('#kai-core-components)
      const children = this.$el.querySelector('#kai-core-components').children
      const index = Array.prototype.indexOf.call(children, element)

      if (index === children.length - 1) {
        return true
      } else {
        if (index === children.length - 2) {
          const nextElement = children[index + 1]
          if (nextElement.classList.contains('kai-time-stamp')) {
            return true
          }
        }
        return false
      }
    }
  }
}
</script>
