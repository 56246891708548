<template>
  <section
    v-if="$store.state.showShortCutMenu && shortcutMenuContent && !isLiveChatStarted"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="shortcut-menu-button"
      :class="{shortcut_menu_open:displayShortcutPanel}"
      @click="onShortcutPanelMenuButtonClicked()"
      v-html="getHamburgerMenuSVGString()"
    />
    <!-- eslint-enable vue/no-v-html -->
  </section>
</template>

<script>
import './styles/ShortcutPanel.less'
import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import Cookies from 'js-cookie'

export default {
  name: 'ShortcutPanel',
  mixins: [
    KeyboardNavigationMixin,
    ImageUtilityMixin
  ],
  props: [],
  data () {
    return {
      shortcutPanelOpen: false,
      screenReaderTranslations: $store.getters.getBotLanguages.translations.screenReader,
      previousContent: null
    }
  },
  computed: { // Listen to see if store state change
    shortcutMenuContent () {
      return this.$store.getters.getShortcutPayloadContent
    },
    isLiveChatStarted () {
      return this.$store.getters.isLiveChatStarted
    },
    displayShortcutPanel () {
      return this.$store.getters.getDisplayShortcutPanel
    }
  },
  methods: {
    onShortcutPanelMenuButtonClicked () {
      this.toggleShortcutPanel()
      Kai.API.sendUIEvent(`shortcut_menu_${this.displayShortcutPanel ? 'opened' : 'closed'}`, 'hamburger_menu_button', 'clicked', `Shortcut Menu ${this.displayShortcutPanel ? 'Opened' : 'Closed'}`)
    },
    toggleShortcutPanel () {
      Cookies.set('k_shortcut_panel_opened', !this.displayShortcutPanel)
      this.$store.dispatch('actionDisplayShortcutPanel', !this.displayShortcutPanel)
    }
  }
}
</script>
