<template>
  <!-- Modal -->
  <!-- Location picker Modal -->
  <div
    id="locationPickerModal"
    class="kai-modal fade"
    role="dialog"
  >
    <div class="kai-modal-dialog">
      <div class="kai-modal-content">
        <div class="kai-modal-header">
          <h4 class="kai-modal-title">
            {{ translations.locationPicker.headerTitle }}
          </h4>
          <button
            type="button"
            class="close"
            @click="closeModal()"
          >
            &times;
          </button>
        </div>
        <div class="kai-modal-body">
          <div
            class="form-horizontal"
            style="width:100%;"
          >
            <div class="form-group">
              <label class="col-sm-2 control-label">{{ translations.locationPicker.inputLabel }}:</label>

              <div class="col-sm-12">
                <input
                  id="us3-address"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div
              id="mapLocationPicker"
              style="width: 100%; height: 400px;"
            />
            <input
              id="us3-lat"
              type="text"
              class="form-control"
              style="display:none;"
            >
            <input
              id="us3-lon"
              type="text"
              class="form-control"
              style="display:none;"
            >
            <div class="clearfix">
&nbsp;
            </div>
            <div class="clearfix" />
          </div>
        </div>
        <div class="kai-modal-footer">
          <button
            id="validatePosition"
            type="button"
            class="btn btn-outline-secondary"
            @click="selectPosition()"
          >
            {{ translations.locationPicker.sendButton }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Kai from '../../kai'
import { store } from '../../store/index'

export default {
  name: 'LocationMap',
  data () {
    return {
      translations: store.getters.getBotLanguages.translations, // Import language json
      myDefaultLocation: store.getters.getDefaultMapLocation,
      selectLocation: {
        coords: {
          latitude: null,
          longitude: null
        }
      }
    }
  },
  mounted () {
    require('../../js/vendors/locationpicker.jquery.js')
    var _this = this
    $jq(document).ready(function () {
      $jq('#locationPickerModal').addClass('show')
      $jq('#locationPickerModal').css('display', 'block')
      $jq('.webview--app').addClass('modal-open')
      $jq('#mapLocationPicker').locationpicker({
        enableAutocomplete: true,
        enableAutocompleteBlur: true,
        location: {
          latitude: _this.myDefaultLocation.coords.latitude,
          longitude: _this.myDefaultLocation.coords.longitude
        },
        radius: 1,
        zoom: _this.myDefaultLocation.zoom,
        inputBinding: {
          latitudeInput: $jq('#us3-lat'),
          longitudeInput: $jq('#us3-lon'),
          locationNameInput: $jq('#us3-address')
        },
        onchanged: (currentLocation, radius, isMarkerDropped) => {
          _this.selectLocation.coords.latitude = currentLocation.latitude
          _this.selectLocation.coords.longitude = currentLocation.longitude
        }
      })
    })
  },
  methods: {
    selectPosition () {
      if (this.selectLocation.coords.latitude) {
        Kai.Core.sendLocation(this.selectLocation)
      } else {
        Kai.Core.sendLocation(this.myDefaultLocation)
      }
      this.closeModal()
    },
    closeModal: () => {
      $jq('#locationPickerModal').removeClass('show')
      $jq('#locationPickerModal').css('display', 'none')
      $jq('.webview--app').removeClass('modal-open')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>
