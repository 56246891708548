<template>
  <div class="kai-user-wrapper kai-row clear">
    <div class="kai-chat-wrapper">
      <div class="kai-chat-row">
        <div class="kai-chat-message">
          <div class="kai-chat-message-text">
            <!-- eslint-disable vue/no-v-html -->
            <a
              :href="response.default.payload.payload"
              target="__blank"
              v-html="response.default.payload.label"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './styles/TextBubble.less'

export default {
  name: 'Link',
  templateOverride: '#textBubble-override',
  data () {
    return {
      response: this.$slots
    }
  }
}
</script>
