<template>
  <div class="kai-card-text-container">
    <!-- TITLE -->
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="medium.chart_data.title"
      class="kai-medium-title"
      v-html="medium.chart_data.title"
    />
    <!-- eslint-enable vue/no-v-html -->
    <div class="kai-medium-legend-container">
      <div
        v-for="(item, index) in medium.chart_data.items"
        :key="index"
        class="kai-medium-legend-item"
        @click="toggleSeries(item.name, index)"
      >
        <div class="kai-medium-legend-left">
          <div
            v-if="options"
            class="kai-medium-legend-color"
            :style="{background: options.colors[index]}"
            :class="{active: selectedItem==index}"
          />
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="kai-medium-legend-text"
            v-html="item.name"
          />
          <!-- eslint-enable vue/no-v-html -->
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="kai-medium-legend-right"
          v-html="item.label"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </div>
  </div>
</template>

<script>
import MediumMixin from './Mixin/MediumMixin'
import MediumPieChartMixin from './Mixin/MediumPieChartMixin'
import './styles/Medium.less'

export default {
  name: 'MediumPieChartLegend',
  mixins: [
    MediumMixin,
    MediumPieChartMixin
  ],
  props: {
    medium: {
      type: Object,
      required: true
    }
  },
  computed: {
    selectedItem () {
      return this.$parent.selectedPieChartItem
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
