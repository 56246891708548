<template>
  <section>
    <div class="kai-row" />
    <MessageProfileImage
      v-if="ShowIcon && showMessageProfileImage"
      aria-hidden="true"
    />
    <div
      v-if="response.default && response.default.type === 'MEDIUM'"
      class="kai-card-wraper"
      tabindex="0"
    >
      <div class="kai-card-item kai-card-medium-item">
        <Medium
          :medium="response.default"
          :is-expanded="true"
          @piechartMedium="updateChartReference"
          @selectedPiechartItem="updateSelectedPiechartItem"
        />
        <div class="kai-card-subtitle">
          <MediumPieChartLegend
            v-if="response.default.payload && response.default.payload.type && response.default.payload.type == 'PIECHART'"
            :medium="response.default.payload"
          />
        </div>
      </div>
    </div>
    <div v-else-if="medium && medium.type === 'MEDIUM'">
      <div class="kai-card-item kai-card-medium-item">
        <Medium
          :medium="medium"
          :is-expanded="true"
          @piechartMedium="updateChartReference"
          @selectedPiechartItem="updateSelectedPiechartItem"
        />
        <div class="kai-card-subtitle">
          <MediumPieChartLegend
            v-if="medium.payload && medium.payload.type && medium.payload.type == 'PIECHART'"
            :medium="medium.payload"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Medium from './Medium'
import MediumPieChartLegend from './MediumPieChartLegend'
import MediumPieChartMixin from './Mixin/MediumPieChartMixin'
import MessageProfileImage from './MessageProfileImage'

export default {
  name: 'MediumSingle',
  components: {
    Medium,
    MediumPieChartLegend,
    MessageProfileImage
  },
  mixins: [
    MediumPieChartMixin
  ],
  props: {
    medium: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      response: this.$slots,
      showMessageProfileImage: false
    }
  },
  computed: {
    ShowIcon () {
      if (this.payload) {
        if (this.payload.showIcon) {
          return true
        } else {
          return false
        }
      } else {
        // `this` points to the vm instance
        if (this.$slots.default && this.$slots.default.showIcon) {
          return this.$slots.default.showIcon
        } else {
          return false
        }
      }
    }
  },
  mounted () {
    this.showMessageProfileImage = !$store.state.useAvatar || $store.getters.isLiveAgentConnected
  },
  methods: {
    updateChartReference (optionalChartRef) {
      if (optionalChartRef) {
        this.$refs.chartRef = optionalChartRef
      }
    }
  }
}
</script>
