<template>
  <div class="kai-screen-intro">
    <div class="kai-circle" />
    <h1
      class="introScreenTitle"
      placeholder="placeholder"
    >
      {{ translations.introScreen.introTitle }}
    </h1>
    <p
      class="introScreenMsg"
      placeholder="placeholder"
    >
      {{ translations.introScreen.introMessage }}
    </p>
  </div>
</template>

<script>
import { store } from '../../store/index'
import './styles/IntroScreen.less'

export default {
  name: 'IntroScreen',
  data () {
    return {
      translations: store.getters.getBotLanguages.translations,
      placeholder: store.getters.getBotLanguages.translations.introScreen.introMessage
    }
  }
}
</script>
