'use strict'

import { AudioBidirectional } from './webrtc-audio.js'
import { VideoBidirectional } from './webrtc-video.js'
import { WebRTCDevices } from './webrtc-devices.js'

/* global MediaController */

export const webrtc = {
  ok: typeof RTCSessionDescription !== 'undefined' && RTCPeerConnection && navigator && navigator.mediaDevices && navigator.mediaDevices.getUserMedia,

  audio: new AudioBidirectional(),
  videoRemote: new VideoBidirectional('video', 'remote'),
  rdsRemote: new VideoBidirectional('rds', 'remote'),
  videoLocal: new VideoBidirectional('video', 'local'),
  rdsLocal: new VideoBidirectional('rds', 'local'),
  devices: new WebRTCDevices(),

  init: function (audioElements, localVideoElements, remoteVideoElements, localDesktopElements, remoteDesktopElements) {
    this.audio.elements = audioElements
    if (window.MediaController) {
      this.audio.elements.map(function (element) {
        element.controller = new MediaController()
      })
    }
    if (localVideoElements) {
      this.videoLocal.window.elements = localVideoElements
    };
    if (remoteVideoElements) {
      this.videoRemote.window.elements = remoteVideoElements
    };
    if (localDesktopElements) {
      this.rdsLocal.window.elements = localDesktopElements
    };
    if (remoteDesktopElements) {
      this.rdsRemote.window.elements = remoteDesktopElements
    };
  },

  videoBidirectional: function (isBidirectional) {
    if (typeof isBidirectional === 'boolean') {
      this.videoLocal.bidirectional = isBidirectional
    }
    return this.videoLocal.bidirectional
  },

  setVol: function (vol) {
    if (this.audio.element) {
      this.audio.element.volume = vol
    }
  },

  stopTracks: function (s) {
    if (typeof s.getTracks === 'function') {
      // current way
      var tracks = s.getTracks()
      for (var i = 0; i < tracks.length; ++i) {
        tracks[i].stop()
      }
    } else if (typeof s.stop === 'function') {
      // deprecated way
      s.stop()
    }
  },

  getRevationViewAuth: function (sdp) {
    let auth = ''

    const index = sdp.indexOf('revation-view-auth')
    if (index !== -1) {
      const index2 = sdp.indexOf(':', index)
      const index3 = sdp.indexOf('\r', index2)
      if (index3 !== -1) {
        auth = sdp.substring(index2 + 1, index3)
      }
    }

    return auth
  },

  closeAll: function () {
    this.audio.stop()
    this.videoLocal.stop()
    this.rdsLocal.stop()
    this.videoRemote.stop()
    this.rdsRemote.stop()
  }
}

if (!webrtc.ok) {
  console.log('Browser does not appear to be WebRTC-capable')
}
