<template>
  <div
    class="kai-bottom-bar"
    :class="{live_chat_open_up:this.$parent.isLiveChatStarted && !this.$parent.useWidgetMode, live_chat_open_down:this.$parent.isLiveChatStarted && this.$parent.useWidgetMode}"
  >
    <ChipPanelQbar
      v-if="this.$parent.chipItemLength > 0"
      :response="this.$parent.response"
    />
    <ShortcutButton v-if="this.$parent.chipItemLength === 0" />
    <transition name="recording-fade">
      <div
        v-if="!this.$parent.speechRecognitionStarted && this.$parent.chipItemLength === 0"
        class="kai-textarea"
        :class="{widget:this.$parent.useWidgetMode, shortcut_panel_available: $parent.shortcutMenuAvailable}"
      >
        <form
          autocomplete="off"
          class="kai-input-prompt-wrapper"
        >
          <input
            id="kai-input-prompt-area"
            ref="kaiInputPromptArea"
            v-model="$parent.userRequest.message"
            class="kai-input-prompt"
            tabindex="0"
            type="text"
            autocomplete="new-password"
            :dir="$store.state.enableRTL ? 'rtl' : 'auto'"
            :placeholder="this.$parent.inputPlaceHolder"
            :aria-label="this.$parent.inputPlaceHolder"
            @keyup="$parent.inputMessageChange"
            @keydown.enter="$parent.sendMessage"
          >
        </form>
      </div>
    </transition>

    <transition name="recording-translate">
      <div
        v-if="this.$parent.speechRecognitionStarted"
        class="speech-recording"
      >
        <img
          :src="this.$parent.micIconRed"
          alt=""
        >
      </div>
    </transition>

    <span
      v-if="this.$parent.speechRecognitionStarted"
      class="recording-timer"
    >
      {{ this.$parent.timer }}
    </span>

    <div
      v-if="!$parent.useSpeechRecognition || this.$parent.speechRecognitionUrl === undefined || this.$parent.userRequest.message !== '' || this.$parent.chipItemLength > 0"
      class="kai-send-wrapper"
      :class="{widget:this.$parent.useWidgetMode}"
    >
      <!-- v-on:click="sendMessage" -->
      <button
        class="kai-send-button"
        :aria-label="this.$parent.translations.sendMessageButton"
        tabindex="0"
        @click="$parent.sendMessage"
      >
        <img
          :src="this.$parent.sendIcon"
          alt=""
        >
      </button>
    </div>
    <div
      v-else
      class="kai-send-wrapper"
      :class="{widget:this.$parent.useWidgetMode, speech:this.$parent.speechRecognitionStarted}"
    >
      <button
        v-longpress="this.$parent.startSpeechRecognition"
        v-longpressup="this.$parent.stopRecording"
        v-tap="this.$parent.showSpeechHint"
        v-panend="this.$parent.stopRecording"
        class="kai-send-button"
        :aria-label="this.$parent.translations.screenReader.speechRecognitionButton"
        tabindex="0"
      >
        <img
          :src="this.$parent.micIcon"
          alt=""
        >
      </button>
    </div>
  </div>
</template>
<script>

import ChipPanelQbar from './ChipPanelQbar'
import ShortcutButton from './ShortcutButton'

export default {
  name: 'InnerBottomBar',
  components: {
    ChipPanelQbar,
    ShortcutButton
  }
}
</script>

<style lang="less" src='./styles/BottomBar.less' scoped>
</style>
