<template>
  <div
    v-if="displayTimeStamp"
    class="kai-time-stamp"
  >
    <div class="brand-profile-name">
      <span class="kai-quick-timestamp">{{ processTimeStamp }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: '',
  data () {
    return {
      timeStampOptionalValue: this.$slots,
      timeStampFormat: window.$store.getters.getTimeStampFormat,
      displayTimeStamp: false
    }
  },
  computed: {
    processTimeStamp () {
      if (this.timeStampOptionalValue.default && this.timeStampOptionalValue.default.length > 0) {
        return moment(this.timeStampOptionalValue.default).locale($store.getters.getBotLanguages.localeTimeStamp).format(this.timeStampFormat)
      } else {
        return moment().locale($store.getters.getBotLanguages.localeTimeStamp).format(this.timeStampFormat)
      }
    }
  },
  mounted () {
    this.displayTimeStamp = !this.isPreviousComponentTimeStamp()
  },
  methods: {
    moment,
    isPreviousComponentTimeStamp () {
      const element = document.querySelector('#kai-core-components')
      const childrenArray = Array.from(element.children)
      const reversedChildren = childrenArray.reverse()

      const lastChild = reversedChildren.find(child => !child.classList.contains('kai-component-start'))
      if (lastChild && lastChild.classList.contains('kai-time-stamp')) {
        return true
      }
      return false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.kai-time-stamp {
    clear: both;
}

.kai-time-stamp .brand-profile-name {
    color: #BB0826;
    margin-left: 0px;
    margin-bottom: 5px;
    font-size: 12px;
    text-align: center;
}

.kai-time-stamp .kai-quick-timestamp {
    font-size: var(--timestamp-font-size);
    font-weight: 500px;
    color: #666666;
    text-transform: uppercase;
    padding: 0 5px;
}
</style>
