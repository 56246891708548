<template>
  <div id="related-questions-container-float">
    <div
      class="related-questions-inner-float"
      :style="componentWidth"
    >
      <div class="keywords-title" :style="marginBottomTitle">
        <span v-show="showRelatedQuestions && keywords.length > 0">{{ keywordsTitle }}</span>
        <button
          class="related-questions-title-btn kai-tooltip"
          :class="showRelatedQuestionsArrow"
          @click="toggleRelatedQuestions"
          @tap="toggleRelatedQuestions"
        >
          <img :src="arrowIcon">
          <span class="kai-tooltip-text">
            {{ showRelatedQuestions ? collapseLabel: expandLabel }}
          </span>
        </button>
      </div>
      <div class="keywords-container" v-show="showRelatedQuestions && keywords.length > 0">
        <div class="keyword-list-container">
          <span
            v-for="(keyword, index) in keywords"
            :key="index"
            class="keyword-btn"
          >
            <span class="keyword-remove-text">{{ keyword.payload.data.keyword }}</span>
            <img 
              :src="closeIcon" 
              @click="removeKeyword(keyword.payload.buttons[0])"
              class="keyword-remove-icon" 
              alt="Close Icon" 
              width="10" 
              height="10"
            >
          </span>
        </div>
        <div class="suggested-keywords-container" v-if="suggestedKeywords.length > 0">
          {{ suggestedKeywordsTitle }}
          <span
            v-for="(keyword, index) in suggestedKeywords"
            :key="index"
            class="suggested-keyword"
            @click="clickSuggestedKeyword(keyword.payload.buttons[0])"
          >
            {{ keyword.payload.data.keyword }}
          </span>
        </div>
      </div>
      <div class="related-questions-title">
        {{ showRelatedQuestions ? relatedQuestionsTitle : title  }}
      </div>
      <div
        v-for="(rc, index) in relatedQuestions"
        v-show="showRelatedQuestions"
        :key="index"
        :ref="'relatedQuestion' + index"
        :class="{ 'main-border': index === 0 }"
        class="related-questions-inner-item"
        @mouseenter="openRelatedAnswer($event, index, 'relatedQuestion' + index)"
        @mouseleave="closeRelatedAnswer(index)"
      >
        <div
          class="related-questions-text"
          @click="onClick(rc.payload.buttons[0])"
        >
          {{ rc.payload.data.question }}
        </div>
        <RelatedQuestionsOverlay
          v-if="!hideRelatedAnswer"
          :key="index"
          :message="rc.payload.data.answer"
          :context-menu-position="relatedAnswerPosition"
          :is-open="showRelatedAnswer[index]"
          :enabled-options="relatedAnswerEnabledOptions"
          @open="openRelatedAnswer(e, index)"
          @close="closeRelatedAnswer(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Kai from '../../kai'
import RelatedQuestionsOverlay from './RelatedQuestionsOverlay'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import Cookies from 'js-cookie'
import './styles/relatedQuestions.less'

export default {
  name: 'RelatedQuestions',
  components: {
    RelatedQuestionsOverlay
  },
  mixins: [
    ImageUtilityMixin
  ],
  data () {
    return {
      response: this.$slots,
      showRelatedQuestions: Cookies.get('k_related_question_opened') !== 'false',
      arrowIcon: this.getImagePath('svg/collapse_arrow.svg'),
      relatedAnswerPosition: {},
      showRelatedAnswer: [],
      relatedAnswerEnabledOptions: {
        copy: true
      },
      collapseLabel: $store.getters.getBotLanguages.translations.screenReader.collapse,
      expandLabel: $store.getters.getBotLanguages.translations.screenReader.expand,
      closeIcon: this.getImagePath('close-gray.png'),
      hideRelatedAnswer: false
    }
  },
  computed: {
    showRelatedQuestionsArrow () {
      if (this.showRelatedQuestions) {
        return 'up-arrow'
      } else {
        return 'down-arrow'
      }
    },
    componentWidth () {
      if (this.response.default.length === 0) {
        return { width: 'auto' }
      } else {
        return { width: '100%' }
      }
    },
    marginBottomTitle () {
      if (this.showRelatedQuestions) return { 'margin-bottom': '10px'}
    },
    title () {
      const suggestion = this.response.default.contents.find(content => content.type === 'CUSTOM' && content.payload && content.payload.type === 'KAI_ANSWERS_SUGGESTIONS')
      return suggestion ? suggestion.payload.data.container_title : ''
    },
    keywordsTitle () {
      const suggestion = this.response.default.contents.find(content => content.type === 'CUSTOM' && content.payload && content.payload.type === 'KAI_ANSWERS_SUGGESTIONS')
      return suggestion ? suggestion.payload.data.keywords_title : ''
    },
    suggestedKeywordsTitle () {
      const suggestion = this.response.default.contents.find(content => content.type === 'CUSTOM' && content.payload && content.payload.type === 'KAI_ANSWERS_SUGGESTIONS')
      return suggestion ? suggestion.payload.data.suggested_keywords_title : ''
    },
    relatedQuestionsTitle () {
      const suggestion = this.response.default.contents.find(content => content.type === 'CUSTOM' && content.payload && content.payload.type === 'KAI_ANSWERS_SUGGESTIONS')
      return suggestion ? suggestion.payload.data.related_questions_title : ''
    },
    relatedQuestions () {
      return this.response.default.contents.filter(content => content.type === 'CUSTOM' && content.payload && content.payload.type === 'KAI_ANSWERS_RELATED_QUESTION')
    },
    keywords() {
      return this.response.default.contents.filter(content => content.type === 'CUSTOM' && content.payload && content.payload.type === 'KAI_ANSWERS_KEYWORD')
    },
    suggestedKeywords() {
      return this.response.default.contents.filter(content => content.type === 'CUSTOM' && content.payload && content.payload.type === 'KAI_ANSWERS_SUGGESTED_KEYWORD')
    }
  },
  mounted () {
    this.showRelatedAnswer = Array(this.relatedQuestions.length).fill(false)
    this.disableRelatedAnswer()
  },
  methods: {
    onClick (button) {
      this.disableRelatedAnswer()
      Kai.Core.buttonPressed(button)
    },
    openRelatedAnswer (e, index, itemRef) {
      if (this.showRelatedAnswer[index]) return
      e.preventDefault()
      // get itemRef bounding rect
      const itemRect = this.$refs[itemRef][0].getBoundingClientRect()
      const mainContainer = document.getElementById('kai-container')

      // calculate the distance between itemRect bottom and mainContainer bottom
      const distanceToBottom = mainContainer.getBoundingClientRect().bottom - itemRect.bottom

      this.relatedAnswerPosition = {
        x: itemRect.left + 10,
        y: itemRect.top + itemRect.height * 0.66,
        maxWidth: itemRect.width - 20,
        maxHeight: distanceToBottom + itemRect.height * 0.34
      }
      this.showRelatedAnswer[index] = true
    },
    closeRelatedAnswer (index) {
      this.showRelatedAnswer[index] = false
      this.relatedAnswerPosition = { x: null, y: null, maxWidth: null }
    },
    toggleRelatedQuestions () {
      this.showRelatedQuestions = !this.showRelatedQuestions
      Cookies.set('k_related_question_opened', this.showRelatedQuestions)
    },
    clickSuggestedKeyword (button) {
      Kai.Core.sendHiddenPostbackMessage(button)
    },
    removeKeyword (button) {
      Kai.Core.sendHiddenPostbackMessage(button)
    },
    disableRelatedAnswer () {
      this.hideRelatedAnswer = true
      setTimeout(() => {
        // deactivate related questions overlay for 2s to prevent auto scrolling issue:
        // when user clicks on a related question, the overlay will be closed and the page will scroll to the clicked question
        this.hideRelatedAnswer = false
        this.$forceUpdate()
      }, 2000)
    }
  }
}
</script>
