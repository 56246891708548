module.exports = {
  data () {
    return {
      imageLoadSuccessHolder: false,
      imageLoadErrorHolder: false,
      isLogoCached: false,
      cachedLogoURL: false,
      logoPath: false
    }
  },
  methods: {
    loadLogoImage (context, startDelay) {
      const container = document.getElementsByClassName('lottie')[0]
      context.anim.addEventListener('DOMLoaded', () => {
        const imageElements = container.lastChild.getElementsByTagName('image')
        let imageReplaced = false
        for (let i = 0; i < imageElements.length; i++) {
          const element = imageElements[i]
          if (element.getAttribute('width') === '300px') {
            imageReplaced = true
            this.handleImageLoad(context, element, startDelay)
          } else {
            imageReplaced = true
          }
        }
        if (!imageReplaced) {
          this.$nextTick(() => context.anim.play())
          context.removePreviousAnimation()
        }
        context.runningAnimation = true
      }, true)
    },
    handleImageLoad (context, element, startDelay) {
      this.imageLoadSuccessHolder = () => this.onImageLoadSuccess(context, element, startDelay)
      element.addEventListener('load', this.imageLoadSuccessHolder, true)
      if (this.$store.getters.isLiveAgentConnected) {
        this.$nextTick(() => {
          const template = document.createElement('template')
          template.innerHTML = this.getAgentSVGString()
          element.parentElement.replaceChildren(template.content)
          context.onImageLoadSuccess(context, element)
        })
      }
      if (!this.$store.getters.isInlineAvatarEnabled) {
        this.$nextTick(() => {
          element.setAttribute('href', this.logoPath)
        })
      }
      if (this.$store.getters.isInlineImagesEnabled || this.isLogoCached) {
        this.$nextTick(() => {
          this.imageLoadSuccessHolder()
        })
      }
      this.imageLoadErrorHolder = () => context.onImageLoadError(context, element, startDelay)
      element.addEventListener('error', this.imageLoadErrorHolder, true)
    },
    onImageLoadSuccess (context, element, startDelay) {
      element.removeEventListener('load', this.imageLoadSuccessHolder, true)
      if (context.isFirstAnimation && startDelay) {
        setTimeout(() => {
          this.$nextTick(() => context.anim.play())
        }, startDelay * 1000)
      } else {
        this.$nextTick(() => context.anim.play())
      }
      if (context.removePreviousAnimation) {
        context.removePreviousAnimation()
      }
    },
    onImageLoadError (context, element, startDelay) {
      element.removeEventListener('error', this.imageLoadErrorHolder, true)
      this.$nextTick(() => {
        element.setAttribute('href', this.$store.getters.getBotLogoBackupUrl)
        if (context.isFirstAnimation && startDelay) {
          setTimeout(() => {
            this.$nextTick(() => context.anim.play())
          }, startDelay * 1000)
        } else {
          this.$nextTick(() => context.anim.play())
        }
      })
    },
    async prepareAssets (avatarObj) {
      const asset = avatarObj.animationData ? avatarObj.animationData.assets.filter(asset => asset.id === 'image_0')[0] : {}
      try {
        asset.p = require('@/assets/img/' + this.$store.getters.getBotLogoInlineUrl)
      } catch (error) {
        asset.p = this.$store.getters.getBotLogoInlineUrl
      }
      try {
        asset.p = this.$store.getters.getBotLogoUrl
      } catch (error) {
        asset.p = this.$store.getters.getBotLogoUrl
      }
      asset.e = true
      if (!this.isLogoCached || asset.p !== this.cachedLogoURL) {
        this.isLogoCached = await this.checkImageCached(asset.p)
        if (this.isLogoCached) {
          this.cachedLogoURL = asset.p
        }
      }
      if (!this.$store.getters.isInlineAvatarEnabled) {
        this.logoPath = asset.p
      }
    },
    async checkImageCached (url, waitTimeMs = 40) {
      const ac = new AbortController()
      const cachePromise = fetch(url, {
        signal: ac.signal
      })
        .then(() => true)
        .catch(() => false)
      setTimeout(() => ac.abort(), waitTimeMs)
      return cachePromise
    }
  }
}
