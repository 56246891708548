<template>
  <div
    class="theme-app"
    :class="{show:showThemeChanger}"
  >
    <div
      v-if="webviewIsReady"
      :key="componentKeyIndex"
    >
      <div
        v-show="showWidget"
        id="webview-container"
        class="webview-container"
        role="main"
        :aria-roledescription="screenReaderTranslations.virtualAssistantDesignation + ' ' + screenReaderTranslations.chatArea"
        :class="{
          webview_container_widget: useWidgetMode,
          align_left: alignWidgetLeft,
          webview_rtl_support: enableRTL,
          live_chat_open: isLiveChatStarted,
          avatar_transition: showAvatarTransition
        }"
      >
        <SplashScreen v-if="showSplashScreen && showWidget" />
        <HeaderBar v-if="!showSplashScreen && showWidget" />
        <Container v-show="!showSplashScreen" />
        <LiveChatExpandBar :open-up="!(useWidgetMode && showWidget && isLiveChatStarted)" />
        <QuickRepliesBar />
        <BottomBar v-if="!showSplashScreen" />
        <LiveChatWidget
          :open-up="true"
        />
        <InlinePopup :class="{inline_popup_widget:useWidgetMode}" />
      </div>
      <PoweredBySnippet
        v-if="useWidgetMode && showWidget"
        class="powered_by_kasisto"
        :class="{live_chat_open:isLiveChatStarted}"
      />
      <LiveChatWidget
        v-if="useWidgetMode && showWidget"
        :open-up="false"
      />
      <live-chat-widget-panel
        v-if="useWidgetMode && showWidget && isLiveChatStarted"
        :mode="livechatPanelStates.mode"
        :panel-size="livechatPanelStates.size"
        :subtitle="livechatPanelStates.subtitle"
        :description="livechatPanelStates.description"
        :confirm="livechatPanelStates.confirmFunction"
        :cancel-label="livechatPanelStates.cancelLabel"
        :confirm-label="livechatPanelStates.confirmLabel"
      />
      <div navDir="horizontal">
        <GreetingBubble
          v-if="showGreetings && !showWidget"
          :is-greeting-closed="isGreetingClosed"
          @closeGreeting="isGreetingClosed = true"
        />
        <div
          v-show="!showWidget"
          ref="keyboardWrapper"
          class="webview_widget_launcher"
          role="button"
          :aria-label="screenReaderTranslations.open + ' ' + screenReaderTranslations.virtualAssistantDesignation"
          tabindex="0"
          navLvl="1"
          navDir="horizontal"
          @click="toggleWidgetDisplay"
        >
          <StartupWidgetAnimation v-if="showGreetings && showAnimatedWidget && !showWidget" />
          <img
            v-else
            aria-hidden="true"
            class="webview_widget_icon"
            ondragstart="return false;"
            :src="widgetIconSrc"
          >
        </div>
      </div>
    </div>
    <transition
      name="theme-editor-slide-fade"
      @enter="moveWidget"
      @after-leave="moveWidget"
    >
      <div
        v-if="showThemeChanger"
        class="theme-editor"
        :class="{show:showThemeChanger}"
      >
        <ThemeChanger />
      </div>
    </transition>
  </div>
</template>

<script>
import Container from './core/components/Container'
import BottomBar from './core/components/BottomBar'
import ThemeChanger from './core/components/ThemeChanger'
import HeaderBar from './core/components/HeaderBar'
import InlinePopup from './core/components/InlinePopup'
import StartupWidgetAnimation from './core/components/StartupWidgetAvatar'
import GreetingBubble from './core/components/GreetingBubble'
import QuickRepliesBar from './core/components/QuickRepliesBar'
import LiveChatWidget from './core/components/LiveChatWidget'
import LiveChatWidgetPanel from './core/components/LiveChatWidgetPanel'
import LiveChatExpandBar from './core/components/LiveChatExpandBar'
import SplashScreen from './core/components/SplashScreen'
import PoweredBySnippet from './core/components/PoweredBySnippet'
import KeyboardNavigationMixin from './core/components/Mixin/KeyboardNavigationMixin'
import Cookies from 'js-cookie'

export default {
  name: 'App',
  components: {
    Container,
    BottomBar,
    ThemeChanger,
    HeaderBar,
    QuickRepliesBar,
    SplashScreen,
    PoweredBySnippet,
    LiveChatWidget,
    InlinePopup,
    StartupWidgetAnimation,
    GreetingBubble,
    LiveChatExpandBar,
    LiveChatWidgetPanel
  },
  mixins: [
    KeyboardNavigationMixin
  ],
  data () {
    return {
      showSplashScreen: false,
      alignWidgetLeft: false,
      screenReaderTranslations: $store.getters.getBotLanguages.translations.screenReader,
      imageLoadErrorHolder: false,
      isGreetingClosed: false,
      widgetIconSrc: undefined
    }
  },
  computed: {
    webviewIsReady () {
      return $store.state.webviewReady
    },
    useWidgetMode () {
      return $store.state.useWidgetMode
    },
    showThemeChanger () {
      return $store.state.showThemeChanger
    },
    showWidget () {
      const show = $store.state.showWebviewWidget || !this.useWidgetMode
      if (!show) {
        this.isStartupWidgetDisplayed()
      }
      return show
    },
    isSplashScreenEnabled () {
      return $store.getters.isSplashScreenEnabled
    },
    enableRTL () {
      return $store.state.enableRTL
    },
    isLiveChatStarted () {
      return $store.getters.isLiveChatStarted
    },
    livechatPanelStates () {
      if (!$store.getters.getLivechatPanelStates) {
        $store.state.livechatPanelStates = {
          size: 'minimized',
          mode: 'voice',
          subtitle: null,
          description: null,
          cancelLabel: $store.getters.getBotLanguages.translations.liveChatMessages.panelCancelLabel,
          confirmLabel: null,
          localAudioRefs: [],
          localVideoRefs: [],
          remoteVideoRefs: [],
          localRDSRefs: [],
          remoteRDSRefs: [],
          confirmFunction: () => {}
        }
      }
      return $store.getters.getLivechatPanelStates
    },
    showAnimatedWidget () {
      return $store.state.useWidgetAnimation
    },
    showGreetings () {
      return this.useWidgetMode && this.webviewIsReady && !this.isGreetingClosed
    },
    componentKeyIndex () {
      return $store.getters.getAppKeyIndex
    },
    isInlineImagesEnabled () {
      return $store.getters.isInlineImagesEnabled
    },
    widgetIconInlineUrl () {
      return $store.getters.getWidgetIconInlineUrl
    },
    widgetIconUrl () {
      return $store.getters.getWidgetIconUrl
    },
    showAvatarTransition () {
      return $store.state.showAvatarTransition
    }
  },
  watch: {
    showWidget () {
      this.checkSplashScreenCookie()
    },
    isSplashScreenEnabled () {
      this.showSplashScreen = $store.getters.isSplashScreenEnabled
    },
    enableRTL () {
      this.applyFontSizeFactor()
    },
    isGreetingClosed (newValue) {
      if (newValue) {
        this.$nextTick(() => {
          const widgetLauncherIcon = document.getElementsByClassName('webview_widget_icon')[0]
          if (widgetLauncherIcon) {
            widgetLauncherIcon.setAttribute('src', this.widgetIconSrc)
          }
        })
      }
    },
    showAnimatedWidget () {
      if (!$store.state.useWidgetAnimation) {
        this.$nextTick(() => {
          const widgetLauncherIcon = document.getElementsByClassName('webview_widget_icon')[0]
          if (widgetLauncherIcon) {
            widgetLauncherIcon.setAttribute('src', this.widgetIconSrc)
          }
        })
        return $store.state.useWidgetAnimation
      }
    },
    isInlineImagesEnabled () {
      this.updateWidgetIconSrc()
    },
    widgetIconInlineUrl () {
      this.updateWidgetIconSrc()
    },
    widgetIconUrl () {
      this.updateWidgetIconSrc()
    }

  },
  mounted () {
    this.checkSplashScreenCookie()
    this.applyFontSizeFactor()
    setTimeout(() => {
      this.updateWidgetIconSrc()
    }, 500)
  },
  methods: {
    toggleWidgetDisplay () {
      $store.state.showWebviewWidget = !$store.state.showWebviewWidget
      Kai.API.sendUIEvent('webview_widget_opened', 'webview_widget_chat_icon', 'clicked', 'Webview Widget Opened')
      Cookies.set('k_webview_widget_opened', $store.state.showWebviewWidget)
      this.isGreetingClosed = true
      if ($store.getters.getKaiSessionId === '' && $store.getters.getStartSession === true) {
        kserver.CoreAPI.startSession()
      }
    },
    moveWidget () {
      this.alignWidgetLeft = this.showThemeChanger
    },
    onImageLoadError (context, element) {
      // if an issue happened just try one more time to run the animation with backup logo
      element.removeEventListener('error', this.imageLoadErrorHolder)
      this.$nextTick(() => {
        element.setAttribute('src', $store.getters.getBotWidgetIconBackupUrl)
      })
    },
    checkSplashScreenCookie () {
      if ($store.getters.isSplashScreenEnabled) {
        const skipSplashScreen = Cookies.get('k_splashscreen_opened')

        if (!skipSplashScreen) {
          this.showSplashScreen = true
        } else {
          $store.state.useSplashScreen = false
          this.showSplashScreen = false
        }
      } else {
        $store.state.useSplashScreen = false
        this.showSplashScreen = false
      }
    },
    applyFontSizeFactor () {
      if (document.getElementsByClassName('webview--app').length > 0) {
        const initialFactor = parseInt(getComputedStyle(document.getElementsByClassName('webview--app')[0]).getPropertyValue('--font-size-factor').trim())
        if ($store.state.enableRTL) {
          document.getElementsByClassName('webview--app')[0].style.setProperty('--font-size-factor', initialFactor * $store.state.RTLFontSizeFactor)
        } else {
          document.getElementsByClassName('webview--app')[0].style.setProperty('--font-size-factor', initialFactor)
        }
      }
    },
    isStartupWidgetDisplayed () {
      if ($store.state.webviewReady && this.useWidgetMode && !$store.state.showWebviewWidget) {
        // Reeach here the webview widget is not displayed and the webview_widget_launcher icon is displayed instead
        this.$nextTick(() => {
          try {
            Kai.API.sendUIEvent('webview_startup_widget_displayed', 'webview_widget_chat_icon', 'displayed', 'Webview Widget Closed')
          } catch (error) {
            console.error(error)
          }
        })
      }
    },
    updateWidgetIconSrc () {
      if ($store.getters.isInlineImagesEnabled) {
        try {
          this.widgetIconSrc = require('@/assets/img/' + $store.getters.getBotWidgetIconInlineUrl)
        } catch (error) {
          this.widgetIconSrc = $store.getters.getBotWidgetIconInlineUrl
        }
      } else {
        this.widgetIconSrc = $store.state.botWidgetIconUrl
      }
    }
  }
}
</script>

<style>

@font-face {
font-family: 'Lato';
src: local("Lato"), url('~@/assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
}

body {
    margin: 0;
}

#webview-container, .webview--app .dateCalendar-module, .live-chat-widget, .live-chat-widget-panel {
    font-family: 'Lato', 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    box-shadow: 2px 2px 20px 0px rgb(0 0 0 / 15%);
}

.webview_container_widget{
  position: fixed;
  right: 30px;
  bottom: 60px;
  z-index: 100002;
  background: #fff;
  border-radius: 20px;
  height: calc(100% - var(--bottom-bar-height));
  width: 385px;
  overflow: hidden;
}
.webview_container_widget.align_left{
  left:30px;
}

.webview-container{
  height:  calc(100% - var(--bottom-bar-height));
}

@media only screen and (min-height: 750px) and (min-width: 435px) {
  .webview_container_widget {
    height: 680px;
  }

}

@media screen and (max-width: 435px) {
    .webview_container_widget {
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        border-radius: 0;
        height: 100%;
        width: 100%;
    }
    .webview_container_widget.align_left{
      left:0px;
    }
}

@media screen and (min-width: 435px) {
  .webview_container_widget.live_chat_open{
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    transition: var(--transition-live-chat-widget);
    transition-property: z-index;
    transition-delay: 1s;
    z-index: 100001;
  }
}

.webview_widget_launcher {
    z-index: 100001;
    position: absolute;
    right: 40px;
    bottom: 70px;
    cursor: pointer;
}

.webview_widget_launcher:focus{
  outline: none;
  /* box-shadow: 0 0 5px 3px var(--highlight-color); */
  /* border-radius: 40px; */
}

.webview_widget_launcher .webview_widget_icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    float: left;
}

.theme-app {
    background-color: #F4F4F4;

    transition: all .5s ease;
}

.theme-app.show {
    right: 300px;
    transition: all .5s ease;
}

@media screen and (max-width: 480px) {
  .__themeChanger {
   display: none;
  }

  .debug-box img {
    width: 30%;
 }
}

.theme-editor {
    position: absolute;
    top: 0;
    width: 0;
    right: 0;
    bottom: 0;
}

.theme-editor.show {
    width: 300px;
}

.theme-editor-slide-fade-enter-active {
    transition: all .5s ease;
}

.theme-editor-slide-fade-leave-active {
    transition: all .5s ease;
}

.theme-editor-slide-fade-enter,
.theme-editor-slide-fade-leave-to {
    transform: translateX(300px);
}

.powered_by_kasisto{
    position: fixed;
    right: 155.5px;
    bottom: 33px;
    z-index: 100002;
}

.powered_by_kasisto.live_chat_open{
  z-index: 100000;
  transition: all 0.5s ease-in-out;
  transition-property: bottom, display;
  bottom:66px;
}
</style>
