<template>
  <div
    ref="cardItem"
    :aria-label="ScreenReaderLabel"
    navLvl="0"
    navDir="horizontal"
    class="kai-card-item"
    :class="{kai_card_button_active_border: isSelect && this.$parent.selectedItemsIndex.includes(index), adaptive:isAdaptiveItemMode && !itemWithFixedHeight, fixed:displayFixed}"
  >
    <Medium
      v-if="item.payload.medium && !containSmallImage"
      :medium="item.payload.medium"
      :is-expanded="isExpanded"
      @mediumLoaded="calculateMainElementMaxHeight"
      @piechartMedium="updateChartReference"
      @selectedPiechartItem="updateSelectedPiechartItem"
    />

    <div
      class="kai-card-item-footer"
      :class="hasSmallImageClass"
    >
      <div
        navDir="horizontal"
        class="kai-card-text-container"
      >
        <div>
          <MediumSmall
            v-if="item.payload.medium && containSmallImage"
            :medium="item.payload.medium"
          />
          <!-- TITLE -->
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="item.payload.title"
            class="kai-card-title"
            :class="{gauge:showGauge}"
            v-html="item.payload.title"
          />
          <div
            v-else
            class="kai-card-title"
            v-html="item.value"
          />
          <!-- eslint-enable vue/no-v-html -->
          <!-- SUB TITLE -->
          <div
            v-if="item.payload.subtitle"
            class="kai-card-subtitle"
            :style="displayFixed ? 'max-height:' + mainElementMaxHeight + 'px;': ''"
            :class="{fixed: displayFixed && showExpandButton, gauge:showGauge || showBar}"
          >
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="showBar"
              :class="convertHtmlData(subtitleToDisplay)"
              v-html="subtitleToDisplay"
            />
            <!-- eslint-enable vue/no-v-html -->
            <MediumPieChartLegend
              v-if="item.payload.medium && item.payload.medium.type && item.payload.medium.type == 'PIECHART'"
              :medium="item.payload.medium"
            />
            <MediumGauge
              v-if="showGauge"
              :gauge="subtitleToDisplay"
            />
            <MediumBarChart
              v-if="showBar"
              :bar-chart="subtitleToDisplay"
            />
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="!showBar"
              :class="convertHtmlData(subtitleToDisplay)"
              v-html="subtitleToDisplay"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </div>
        <div
          v-if="showExpandButton"
          navLvl="1"
          navDir="vertical"
          role="button"
          :aria-label="expandButtonLabel"
          class="card-expand-button-wrapper"
          :class="{expanded:isExpanded}"
          @click="(e) => clickExpandButton(e, index)"
        >
          <img
            v-if="!isExpanded"
            aria-hidden="true"
            class="card-expand-button"
            ondragstart="return false;"
            :src="expandIconSrc"
            width="20"
          >
          <img
            v-if="isExpanded"
            aria-hidden="true"
            class="card-expand-button"
            ondragstart="return false;"
            :src="collapseIconSrc"
            width="20"
          >
        </div>
      </div>
      <div
        v-if="isSelect"
        class="kai-card-button-container"
        :class="{fixed: displayFixed}"
      >
        <!-- CARD BUTTON FOR MULTISELECT-->
        <div
          v-if="item.label"
          navLvl="1"
          navDir="vertical"
          role="button"
          :aria-label="item.label"
          class="kai-card-button"
          :class="{ kai_card_button_active: this.$parent.selectedItemsIndex.includes(index), disabled: this.$parent.isDisable}"
          @click="(e) => this.$parent.selectBtn(e, item, index)"
        >
          {{ item.label }}
        </div>
        <div
          v-else
          navLvl="1"
          navDir="vertical"
          role="button"
          :aria-label="fallbackButtonText"
          class="kai-card-button"
          :class="{ kai_card_button_active: this.$parent.selectedItemsIndex.includes(index), disabled: this.$parent.isDisable}"
          @click="(e) => this.$parent.selectBtn(e, item, index)"
        >
          {{ fallbackButtonText }}
        </div>
      </div>
      <div
        v-if="!isSelect"
        class="kai-card-button-container"
        :class="{fixed: displayFixed}"
      >
        <!-- CARD BUTTON -->
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-for="(button, buttonIndex) in validButtons(item.payload.buttons)"
          :key="buttonIndex"
          navLvl="1"
          navDir="vertical"
          class="kai-card-button"
          role="button"
          :aria-label="button.label"
          @click="(e) => itemButton(e, button)"
          v-html="button.label"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </div>
    <div
      v-if="isSelect && $parent.isDisable"
      class="kai-card-disabled-overlay"
    />
  </div>
</template>

<script>
import CardMixin from './Mixin/CardMixin'
import TextMixin from './Mixin/TextMixin'
import MediumPieChartMixin from './Mixin/MediumPieChartMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import Medium from './Medium'
import MediumGauge from './MediumGauge'
import MediumBarChart from './MediumBarChart'
import MediumSmall from './MediumSmall'
import MediumPieChartLegend from './MediumPieChartLegend'
import {
  store
} from '../../store/index'
export default {
  name: 'CardItem',
  components: {
    Medium,
    MediumSmall,
    MediumGauge,
    MediumBarChart,
    MediumPieChartLegend
  },
  mixins: [
    CardMixin,
    TextMixin,
    MediumPieChartMixin,
    ImageUtilityMixin
  ],
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isSelect: {
      type: Boolean,
      default: false
    },
    containSmallImage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cardMainElement: 'kai-card-subtitle',
      // card elements that enter in consideration to calculate the main element maximum Height
      cardElements: [
        {
          className: 'kai-card-medium',
          substract: true
        },
        // the legend container is now part of the cardMainElement and shouldn't be included directly in the calculation
        // {
        //   className: 'kai-medium-legend-container',
        //   substract: false
        // },
        {
          className: 'kai-card-title',
          substract: true
        },
        {
          className: 'kai-card-button-container',
          substract: true
        }
      ],
      fallbackButtonText: store.getters.getBotLanguages.translations.selectTemplate.fallbackListSubmitButton,
      showGauge: false,
      showBar: false
    }
  },
  computed: {
    // a computed getter
    subtitleToDisplay () {
      var text = this.linkify(this.item.payload.subtitle)
      return this.lineBreak(text)
    },
    ScreenReaderLabel () {
      var screenReader = $store.getters.getBotLanguages.translations.screenReader
      return screenReader.card + ' ' + (this.index + 1) + ': ' + this.item.payload.title
    },
    expandButtonLabel () {
      if (this.isExpanded) {
        return $store.getters.getBotLanguages.translations.screenReader.collapse
      } else {
        return $store.getters.getBotLanguages.translations.screenReader.expand
      }
    },
    hasSmallImageClass () {
      if (this.item.payload.medium && this.containSmallImage) {
        return 'has-small-image'
      } else {
        return ''
      }
    }
  },
  methods: {

    convertHtmlData (elString) {
      const htmlObject = document.createElement('div')
      htmlObject.innerHTML = elString
      if (typeof htmlObject.childNodes[0].tagName === 'string') {
        if (htmlObject.childNodes[0].hasAttribute('id') && htmlObject.childNodes[0].getAttribute('id') === 'kcb_credit_score') {
          // Show gauge chart
          this.showGauge = true
        }
        if (htmlObject.childNodes[0].hasAttribute('id') && htmlObject.childNodes[0].getAttribute('id') === 'kcb_budget') {
          // Show bar chart
          this.showBar = true
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
   /* the 300px is the min width of the .kai-card-title,
    which gave the min-width to the card. This way the min-width is kept. */
  .kai-card-item {
    min-width: var(--card-item-width);
  }

    /* the 58px is the width of the small image  */
  .kai-card-item-footer.has-small-image .kai-card-title {
    min-width: unset;
    width: calc(100% - 58px);
  }
</style>
