<template>
  <section class="kai-row">
    <div
      ref="listview"
      class="kai-card-large-template"
      navDir="horizontal"
    >
      <CardLargeItem
        v-for="(item, i) in response.default.payload.options"
        :key="i"
        ref="item"
        tabindex="0"
        nav-lvl="1"
        nav-dir="vertical"
        class="kai-card-large-container wrapper"
        :item="item"
        :index="i"
        :is-select="true"
      />
    </div>
    <!--List view multi select template end-->
  </section>
</template>

<script>
import TextBubble from './TextBubble'
import CardLargeItem from './CardLargeItem'
import SelectMixin from './Mixin/SelectMixin'
import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import './styles/SelectList.less'
export default {
  name: 'SelectList',
  components: {
    CardLargeItem
  },
  mixins: [
    SelectMixin,
    KeyboardNavigationMixin
  ],
  props: {
    response: {
      type: Object,
      required: true
    },
    isDisable: Boolean
  },
  data () {
    return {
      // isDisable: false,
      selectedItemsIndex: this.$parent.selectedItemsIndex,
      // selectedItemsIndex: [],
      validationMessage: '',
      textBubble: TextBubble,
      ishideSubmitContent: false
    }
  },
  created () {
    this.hideSubmitContent() // Mixin
    this.$root.$refs.SelectListComp = this
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>
