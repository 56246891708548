<template>
  <div v-if="messageText">
    <div class="livechat-notification kai-row">
      <div class="livechat-notification-container">
        <div
          aria-hidden="true"
          class="left-border"
        />
        <div class="livechat-notification-message-container">
          <div class="livechat-notification-message">
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="messageText.payload.text" />
            <!-- eslint-enable vue/no-v-html -->
            <a
              v-if="mode === 'agentInvite' && !isEventObsolete"
              class="livechat-video-link"
              @click="displayLiveChatPanel"
            >
              {{ clickToJoin }}
            </a>
          </div>
          <img
            v-if="mode === 'agentInvite' || mode === 'join'"
            :src="inviteImgSrc"
          >
          <img
            v-else-if="mode === 'end'"
            :src="endImgSrc"
          >
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="mode == 'agentInvite' && !isEventObsolete">
    <div class="livechat-notification kai-row">
      <div class="livechat-notification-container">
        <div
          aria-hidden="true"
          class="left-border"
        />
        <div class="livechat-notification-message-container">
          <div class="livechat-notification-message">
            <a
              v-if="mode === 'agentInvite' && !isEventObsolete"
              class="livechat-video-link"
              @click="displayLiveChatPanel"
            >
              {{ clickToJoin }}
            </a>
          </div>
          <img
            v-if="mode === 'agentInvite' || mode === 'join'"
            :src="inviteImgSrc"
          >
          <img
            v-else-if="mode === 'end'"
            :src="endImgSrc"
          >
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="mode === 'fileUploadRequest'">
    <div class="livechat-notification kai-row">
      <div
        class="livechat-notification-container livechat-link"
        @click="downloadFile(payload.payload.data.file)"
      >
        <div class="livechat-notification-message-container">
          <div class="livechat-notification-message">
            {{ downloadFileLabel }}:
            <a class="livechat-link">
              {{ payload.payload.data.file.name }}
            </a>
          </div>
          <img :src="buttonImagesIcons.notif_download">
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="mode === 'fileUploadInProgress'">
    <div class="livechat-notification kai-row">
      <div class="livechat-notification-container">
        <div class="livechat-notification-message-container">
          <div class="livechat-notification-message">
            <div>{{ uploadFileLabel }}: {{ payload.payload.file_name }}</div>
            <div
              v-if="fileUploadStatus === 'done'"
              class="livechat-video-link livechat-link-disabled"
            >
              {{ payload.payload.file_size + ' ' + fileUploadedLabel }}
            </div>
          </div>
          <img
            v-if="fileUploadStatus === 'done'"
            :src="buttonImagesIcons.notif_upload_done"
          >
          <img
            v-else-if="fileUploadStatus === 'failed'"
            :src="buttonImagesIcons.notif_upload_failed"
          >
          <img
            v-else
            :src="buttonImagesIcons.notif_upload_inprogress"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import TextMixin from './Mixin/TextMixin'
import LiveChatButtonsMixin from './Mixin/LiveChatButtonsMixin'
import './styles/LiveChatNotification.less'

export default {
  name: 'LiveChatNotification',
  mixins: [
    ImageUtilityMixin,
    TextMixin,
    LiveChatButtonsMixin
  ],
  props: {
    payload: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      clickToJoin: $store.getters.getBotLanguages.translations.liveChatMessages.clickToJoin,
      fileUploadedLabel: $store.getters.getBotLanguages.translations.liveChatMessages.fileUploaded,
      uploadFileLabel: $store.getters.getBotLanguages.translations.liveChatMessages.uploadFile,
      downloadFileLabel: $store.getters.getBotLanguages.translations.liveChatMessages.downloadFile,
      fileUploadStatus: null
    }
  },
  computed: {
    getEvents () {
      return $store.getters.getEvents
    },
    eventType () {
      if (this.payload && this.payload.payload && this.payload.payload.event_type) {
        return this.payload.payload.event_type
      } else {
        return ''
      }
    },
    mode () {
      if (this.eventType === 'VideoAgentInvite' || this.eventType === 'AudioAgentInvite' || this.eventType === 'RdsAgentInvite') {
        return 'agentInvite'
      } else if (this.eventType.indexOf('Invite') > -1 || this.eventType.indexOf('Join') > -1) {
        return 'join'
      } else if (this.eventType.indexOf('End') > -1 && this.eventType.indexOf('EndAccepted') < 0) {
        return 'end'
      } else if (this.eventType === 'FileUploadRequest') {
        return 'fileUploadRequest'
      } else if (this.eventType === 'FileUploadInProgress') {
        // internal webview event to display File name being uploaded
        return 'fileUploadInProgress'
      } else {
        return 'message'
      }
    },
    inviteImgSrc () {
      if (this.eventType.startsWith('Video')) {
        return this.buttonImagesIcons.notif_video
      } else if (this.eventType.startsWith('Rds')) {
        return this.buttonImagesIcons.notif_rds
      }
      return this.buttonImagesIcons.notif_phone
    },
    endImgSrc () {
      if (this.eventType.startsWith('Video')) {
        return this.buttonImagesIcons.notif_video_stop
      } else if (this.eventType.startsWith('Rds')) {
        return this.buttonImagesIcons.notif_rds_stop
      }
      return this.buttonImagesIcons.notif_phone_stop
    },
    firstMessageContent () {
      if ('message_contents' in this.payload) {
        return this.payload.message_contents[0]
      } else {
        return undefined
      }
    },
    messageText () {
      const messageText = this.firstMessageContent
      if (messageText && messageText.type === 'TEXT') {
        if (messageText.payload && messageText.payload.text) {
          messageText.payload.text = this.linkify(messageText.payload.text)
          messageText.payload.text = this.lineBreak(messageText.payload.text)
        }
      }
      return messageText
    },
    isEventObsolete () {
      if (this.payload.type === 'LIVE_CHAT_NOTIFY_CUSTOM' && this.payload.id >= 0) {
        const events = this.getEvents
        let result = events.filter(e => e.id > this.payload.id).some(e => e.type === 'LIVE_CHAT_NOTIFY_SESSION_STOPPED' ||
          e.type === 'LIVE_CHAT_NOTIFY_NOT_AVAILABLE')
        if (result) {
          return true
        }
        result = events.filter(e => e.id > this.payload.id).some(e => e.type === 'LIVE_CHAT_NOTIFY_CUSTOM' &&
          e.payload && (e.payload.event_type === 'AudioAgentEnd' || e.payload.event_type === 'VideoAgentEnd' || e.payload.event_type === 'RdsAgentEnd'))
        if (result) {
          return true
        }
      }
      return false
    }
  },
  created () {
    if (this.mode === 'fileUploadInProgress') {
      this.fileUploadStatus = 'inprogress'
      this.processFileUpload(this.payload.payload.data).then(() => {
        this.fileUploadStatus = 'done'
      }).catch(() => {
        this.fileUploadStatus = 'failed'
      })
    }
  },
  methods: {
    async displayLiveChatPanel () {
      if (this.eventType === 'AudioAgentInvite') {
        this.triggerVoiceCall()
      } else {
        if (this.eventType === 'VideoAgentInvite') {
          this.triggerRemoteVideoSharing()
        }
        if (this.eventType === 'RdsAgentInvite') {
          this.triggerRemoteDesktopSharing()
        }
        $store.dispatch('actionLivechatPanelStates', { ...$store.state.livechatPanelStates, size: 'big' })
      }
    }
  }
}
</script>
