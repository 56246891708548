<template>
  <section :id="'carousel'+carouselCounter">
    <div class="kai-card-carousel-wrapper">
      <div
        ref="navLeft"
        class="kai-card-carousel-nav-left-container"
        navDir="horizontal"
        tabindex="0"
        role="button"
        :aria-label="screenReaderTranslations.carouselArrowLeft"
        :disabled="enableRTL ? atEndOfList : atHeadOfList"
        :class="{hover: hoverLeft && !mobile}"
        @click="moveCarouselToItem(currentIndex + scrollDirection)"
        @mouseover="hoverLeft = true"
        @mouseleave="hoverLeft = false"
      >
        <img
          :src="arrowIcon"
          class="kai-card-carousel-nav-left"
          aria-hidden="true"
          draggable="false"
          :disabled="enableRTL ? atEndOfList : atHeadOfList"
        >
      </div>
      <div
        v-pan="onPan"
        class="kai-card-carousel"
      >
        <div class="kai-card-carousel-overflow-container kai-card-select-carousel">
          <div
            ref="carousel"
            class="kai-card-wraper kai-card-carousel-transform"
            role="region"
            :aria-roledescription="screenReaderTranslations.inACarousel"
            :aria-label="ScreenReaderLabel"
            navDir="horizontal"
            :style="transformCarousel"
            :class="{transition: !onPanStarted}"
          >
            <CardItem
              v-for="(item, index) in response.default.payload.options"
              :id="'kai-card-carousel' + index"
              :key="index"
              ref="item"
              :item="item"
              :index="index"
              :is-select="true"
            />
          </div>
        </div>
      </div>
      <div
        ref="navRight"
        class="kai-card-carousel-nav-right-container"
        navDir="horizontal"
        tabindex="0"
        role="button"
        :aria-label="screenReaderTranslations.carouselArrowRight"
        :disabled="enableRTL ? atHeadOfList : atEndOfList"
        :class="{hover: hoverRight && !mobile}"
        @click="moveCarouselToItem(currentIndex - scrollDirection)"
        @mouseover="hoverRight = true"
        @mouseleave="hoverRight = false"
      >
        <img
          :src="arrowIcon"
          class="kai-card-carousel-nav-right"
          aria-hidden="true"
          draggable="false"
          :disabled="enableRTL ? atHeadOfList : atEndOfList"
        >
      </div>
    </div>
    <div class="kai-card-carousel-pager">
      <div
        v-for="(item, index) in response.default.payload.options"
        :key="index"
        aria-hidden="true"
        class="kai-card-carousel-pager-item"
        :class="{active:index == currentIndex}"
        @click="moveCarouselToItem(index)"
      >
        <a
          tabindex="-1"
          href=""
          data-slide-index="0"
          class="bx-pager-link"
        >index</a>
      </div>
    </div>
  </section>
</template>

<script>
import {
  isMobile
} from 'mobile-device-detect'
import TextBubble from './TextBubble'
import CardItem from './CardItem'
import CarouselMixin from './Mixin/CarouselMixin'
import CardMixin from './Mixin/CardMixin'
import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import SelectMixin from './Mixin/SelectMixin'
import './styles/SelectCarousel.less'
import {
  store
} from '../../store/index'
/*
Vue.directive('pan', {
  bind: function (el, binding) {
    if (typeof binding.value === 'function') {
      const mc = new Hammer(el)
      mc.get('pan').set({
        direction: Hammer.DIRECTION_HORIZONTAL
      })
      mc.on('pan', binding.value)
    }
  }
})

Vue.directive('tap', {
  bind: function (el, binding) {
    if (typeof binding.value === 'function') {
      const mc = new Hammer(el)
      mc.on('tap', binding.value)
    }
  }
})
*/

export default {
  name: 'SelectCarousel',
  components: {
    CardItem
  },
  mixins: [
    CarouselMixin,
    CardMixin,
    SelectMixin,
    KeyboardNavigationMixin,
    ImageUtilityMixin
  ],
  props: {
    response: {
      type: Object,
      required: true
    },
    isDisable: Boolean
  },
  data () {
    return {
      carouselCounter: store.getters.getCarouselCounter,
      selectedItemsIndex: this.$parent.selectedItemsIndex,
      // selectedItemsIndex:[],
      currentOffset: 0,
      currentIndex: 0,
      hoverLeft: false,
      hoverRight: false,
      mobile: isMobile,
      forceUpdate: false,
      currentCardMode: '',
      referenceOffset: 0,
      onPanStarted: false, // flag used to disable css animation during the pan gesture.
      // isDisable: this.isDisable,
      validationMessage: '',
      textBubble: TextBubble,
      ishideSubmitContent: false,
      screenReaderTranslations: $store.getters.getBotLanguages.translations.screenReader,
      arrowIcon: this.getImagePath('svg/Arrow_carousel.svg'),
      enableRTL: $store.state.enableRTL
    }
  },
  computed: {
    ScreenReaderLabel () {
      var screenReaderCurrentDate = moment().format('hh:mm A')
      var screenReader = $store.getters.getBotLanguages.translations.screenReader
      var sender = screenReader.virtualAssistantDesignation + ' ' + screenReader.displayed
      var cardLabel = this.response.default.payload.options.length + ' ' + screenReader.card
      if (this.response.default.payload.options.length > 1) {
        cardLabel = this.response.default.payload.options.length + ' ' + screenReader.cards
      }
      return screenReader.timeSendAt + ' ' + screenReaderCurrentDate + ',  ' + sender + ': ' + cardLabel
    }
  },
  created () {
    this.hideSubmitContent() // Mixin
    this.$root.$refs.SelectCarouselComp = this
  }
}
</script>

<style scoped>
.kai-card-carousel-transform {
    transform: var(--carousel_translate);
}
</style>
