<template>
  <section :id="'carousel'+ carouselCounter">
    <div class="kai-card-carousel-wrapper">
      <div
        ref="navLeft"
        class="kai-card-carousel-nav-left-container"
        navDir="horizontal"
        tabindex="0"
        role="button"
        :aria-label="screenReaderTranslations.carouselArrowLeft"
        :disabled="enableRTL ? atEndOfList : atHeadOfList"
        :class="{hover: hoverLeft && !mobile}"
        @click="moveCarouselToItem(currentIndex + scrollDirection)"
        @mouseover="hoverLeft = true"
        @mouseleave="hoverLeft = false"
      >
        <img
          :src="arrowIcon"
          class="kai-card-carousel-nav-left"
          aria-hidden="true"
          draggable="false"
          :disabled="enableRTL ? atEndOfList : atHeadOfList"
        >
      </div>
      <div
        v-pan="onPan"
        v-panend="onPanEnd"
        class="kai-card-carousel"
      >
        <div class="kai-card-carousel-overflow-container">
          <div
            ref="carousel"
            class="kai-card-wraper kai-card-carousel-transform"
            role="region"
            :aria-roledescription="screenReaderTranslations.inACarousel"
            :aria-label="ScreenReaderLabel"
            navDir="horizontal"
            :style="transformCarousel"
            :class="{transition: !onPanStarted,isFinishAnimate: isScrollFreeEase}"
          >
            <Card
              v-for="(item, index) in response.default.payload.contents"
              :id="'kai-card-carousel' + index"
              :key="index"
              ref="item"
              :item="item"
              :index="index"
              :contain-small-image="containSmallImage"
            />
          </div>
        </div>
      </div>
      <div
        ref="navRight"
        class="kai-card-carousel-nav-right-container"
        navDir="horizontal"
        tabindex="0"
        role="button"
        :aria-label="screenReaderTranslations.carouselArrowRight"
        :disabled="enableRTL ? atHeadOfList : atEndOfList"
        :class="{hover: hoverRight && !mobile}"
        @click="moveCarouselToItem(currentIndex - scrollDirection)"
        @mouseover="hoverRight = true"
        @mouseleave="hoverRight = false"
      >
        <img
          :src="arrowIcon"
          class="kai-card-carousel-nav-right"
          aria-hidden="true"
          draggable="false"
          :disabled="enableRTL ? atHeadOfList : atEndOfList"
        >
      </div>
    </div>
    <div class="kai-card-carousel-pager">
      <div
        v-for="(item, index) in response.default.payload.contents"
        :key="index"
        aria-hidden="true"
        class="kai-card-carousel-pager-item"
        :class="{active:index == currentIndex}"
        @click="moveCarouselToItem(index)"
      >
        <a
          tabindex="-1"
          href=""
          data-slide-index="0"
          class="bx-pager-link"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import {
  isMobile
} from 'mobile-device-detect'
import Card from './Card'
import CarouselMixin from './Mixin/CarouselMixin'
import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import './styles/CardCarousel.less'

// Code below is coming from QuickReplies.vue

Vue.directive('pan', {
  bind: function (el, binding) {
    if (typeof binding.value === 'function') {
      const mc = new Hammer(el)
      mc.get('pan').set({
        direction: Hammer.DIRECTION_HORIZONTAL
      })
      mc.on('pan', binding.value)
    }
  }
})

Vue.directive('panend', {
  bind: function (el, binding) {
    if (typeof binding.value === 'function') {
      const mc = new Hammer(el)
      mc.get('pan').set({
        direction: Hammer.DIRECTION_HORIZONTAL
      })
      mc.on('panend', binding.value)
    }
  }
})

Vue.directive('tap', {
  bind: function (el, binding) {
    if (typeof binding.value === 'function') {
      const mc = new Hammer(el)
      mc.on('tap', binding.value)
    }
  }
})

export default {
  name: 'Carousel',
  components: {
    Card
  },
  mixins: [
    CarouselMixin,
    KeyboardNavigationMixin,
    ImageUtilityMixin
  ],
  data () {
    return {
      response: this.$slots,
      carouselCounter: window.$store.getters.getCarouselCounter,
      currentOffset: 0,
      currentIndex: 0,
      paginationFactor: 320, // pagination factor = carousel_card width + margin right
      hoverLeft: false,
      hoverRight: false,
      mobile: isMobile,
      forceUpdate: false,
      currentCardMode: '',
      referenceOffset: 0,
      onPanStarted: false, // flag used to disable css animation during the pan gesture.
      isScrollAnimate: true,
      isScrollFreeEase: true,
      containSmallImage: false,
      screenReaderTranslations: $store.getters.getBotLanguages.translations.screenReader,
      arrowIcon: this.getImagePath('svg/Arrow_carousel.svg'),
      enableRTL: $store.state.enableRTL
    }
  },
  computed: {
    cardItems () {
      return this.response.default.payload.contents
    },
    ScreenReaderLabel () {
      var screenReaderCurrentDate = moment().format('hh:mm A')
      var screenReader = $store.getters.getBotLanguages.translations.screenReader
      var sender = screenReader.virtualAssistantDesignation + ' ' + screenReader.displayed
      var cardLabel = this.response.default.payload.contents.length + ' ' + screenReader.card
      if (this.response.default.payload.contents.length > 1) {
        cardLabel = this.response.default.payload.contents.length + ' ' + screenReader.cards
      }
      return screenReader.timeSendAt + ' ' + screenReaderCurrentDate + ',  ' + sender + ': ' + cardLabel
    }
  },
  mounted () {
    this.isScrollAnimate = true
    this.isScrollFreeEase = true
    this.checkIfContainsSmallImage(this.response)
  },
  methods: {
    checkIfContainsSmallImage (response) {
      for (const card of response.default.payload.contents) {
        // If no medium in the payload
        if (card.payload.medium === undefined) {
          this.containSmallImage = true
          return false
        }
        // If at least one medium type is SMALL_IMAGE
        if (card.payload.medium.type === 'SMALL_IMAGE') {
          this.containSmallImage = true
          return false
        }
      }
    }
  }
}
</script>

<style scoped>

.kai-card-carousel-transform {
    transform: var(--carousel_translate);
}

.kai-card-carousel-nav-left-container {
  margin-left: 15px;
}
.kai-card-carousel-nav-right-container {
  margin-right: 15px;
}
</style>
