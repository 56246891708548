<template>
  <section>
    <div
      ref="cardItem"
      class="kai-card-item"
      :class="{adaptive:isAdaptiveItemMode && !itemWithFixedHeight, fixed:displayFixed}"
    >
      <Medium
        :medium="item.data.medium"
        :is-expanded="isExpanded"
        @mediumLoaded="calculateMainElementMaxHeight()"
      />
      <div class="kai-card-item-footer">
        <div class="kai-card-text-container">
          <!-- TITLE -->
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="item.data.title"
            class="kai-card-title"
            v-html="item.data.title"
          />
          <!-- SUB TITLE -->
          <div
            v-if="item.data.subtitle"
            class="kai-card-subtitle"
            :style="displayFixed ? 'max-height:' + mainElementMaxHeight + 'px;': ''"
            :class="{fixed: displayFixed && showExpandButton}"
            v-html="subtitleToDisplay(item)"
          />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="showExpandButton"
            v-tap="(e) => clickExpandButton(e, index)"
            class="card-expand-button-wrapper"
            :class="{expanded:isExpanded}"
          >
            <img
              v-if="!isExpanded"
              aria-hidden="true"
              class="card-expand-button"
              ondragstart="return false;"
              :src="expandIconSrc"
              width="20"
            >
            <img
              v-if="isExpanded"
              aria-hidden="true"
              class="card-expand-button"
              ondragstart="return false;"
              :src="collapseIconSrc"
              width="20"
            >
          </div>
        </div>
        <div
          class="kai-card-button-container"
          :class="{fixed: displayFixed}"
        >
          <!-- CARD BUTTON -->
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-for="(button, buttonIndex) in validButtons(item.data.buttons)"
            :key="buttonIndex"
            v-tap="(e) => itemButton(e, button)"
            class="kai-card-button"
            v-html="button.label"
          />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CardMixin from './Mixin/CardMixin'
import Medium from './Medium'
import TextMixin from './Mixin/TextMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import './styles/CustomKIMCard.less'

export default {
  name: 'CustomKIMCard',
  components: {
    Medium
  },
  mixins: [
    CardMixin,
    TextMixin,
    ImageUtilityMixin
  ],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    // a computed getter
    subtitleToDisplay (item) {
      var text = this.linkify(this.item.data.subtitle)
      return this.lineBreak(text)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
</style>
