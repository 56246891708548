<template>
  <div>
    <MediumLineChart
      v-if="chartData.type === 'LINECHART'"
      :chart-data="chartData"
    />
  </div>
</template>

<script>
import MediumLineChart from './MediumLineChart'

export default {
  name: 'Chart',
  components: {
    MediumLineChart
  },
  mixins: [

  ],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      chartData: this.$props.item.data

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
</style>
