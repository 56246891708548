<template>
  <div
    class="kai-button-template kai-clear"
  >
    <div
      v-if="!response.default.length && response.default.payload && response.default.payload.label"
      class="kai-content-button kai-card-button kai-button"
      tabindex="0"
      @click="itemButton(response.default.payload)"
    >
      {{ response.default.payload.label }}
    </div>
  </div>
</template>

<script>
import Kai from '../../kai'
import './styles/ContentButton.less'

export default {
  name: 'ContentButton',
  data () {
    return {
      response: this.$slots
    }
  },
  methods: {
    itemButton (payload) {
      // Submit POSTBACK
      // console.log('itemButton payload', payload)
      Kai.Core.buttonPressed(payload)
    }
  }
}
</script>
