import { render, staticRenderFns } from "./SelectList.vue?vue&type=template&id=5921bbf7&scoped=true"
import script from "./SelectList.vue?vue&type=script&lang=js"
export * from "./SelectList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5921bbf7",
  null
  
)

export default component.exports