<template>
  <div
    class="live-chat-video-widget"
  >
    <div class="live-chat-video-widget-inner-container">
      <div class="live-chat-video-widget-feature-container">
        <div
          v-if="multimediaConfig.voice"
          :class="{'live-chat-voice-button': !isAudioSharing, 'live-chat-end-call-button': isAudioSharing}"
          class="live-chat-button live-chat-image kai-tooltip"
          tabindex="0"
          @mouseover="buttonImagesSrc.voice = isAudioSharing ? buttonImagesIcons.voice_white: buttonImagesIcons.voice_white"
          @mouseleave="buttonImagesSrc.voice = isAudioSharing? buttonImagesIcons.voice_white: buttonImagesIcons.voice"
          @click=" isAudioSharing ? endVoiceCall() : triggerVoiceCall(false)"
        >
          <img
            :src="buttonImagesSrc.voice"
            alt=""
          >
          <span class="kai-tooltip-text">
            {{ isAudioSharing ? endCallTooltip: voiceTooltip }}
          </span>
        </div>
        <div
          v-if="isAudioSharing"
          :class="{'live-chat-mute-button-pressed': isMuted}"
          class="live-chat-button live-chat-image live-chat-mute-button kai-tooltip"
          tabindex="0"
          @mouseover="buttonImagesSrc.mute = isMuted ? buttonImagesIcons.mute_white: buttonImagesIcons.mute_white"
          @mouseleave="buttonImagesSrc.mute = isMuted? buttonImagesIcons.mute_white: buttonImagesIcons.mute"
          @click="toggleMute()"
        >
          <img
            :src="buttonImagesSrc.mute"
            alt=""
          >
          <span class="kai-tooltip-text">
            {{ isMuted ? unmuteTooltip: muteTooltip }}

          </span>
        </div>
        <div
          v-if="multimediaConfig.video"
          :class="{'live-chat-video-button-pressed': isVideoSharing}"
          class="live-chat-button live-chat-image live-chat-video-button kai-tooltip"
          tabindex="0"
          @mouseover="buttonImagesSrc.video = isVideoSharing ? buttonImagesIcons.video_white: buttonImagesIcons.video_white"
          @mouseleave="buttonImagesSrc.video = isVideoSharing? buttonImagesIcons.video_white: buttonImagesIcons.video"
          @click=" isVideoSharing ? endVideoSharing() : triggerVideoSharing()"
        >
          <img
            :src="buttonImagesSrc.video"
            alt=""
          >
          <span class="kai-tooltip-text">
            {{ isVideoSharing ? endVideoTooltip: videoTooltip }}
          </span>
        </div>
        <div
          v-if="multimediaConfig.desktopSharing"
          :class="{'live-chat-desktop-sharing-button-pressed': isDesktopSharing}"
          class="live-chat-button live-chat-image live-chat-desktop-sharing-button kai-tooltip"
          tabindex="0"
          @mouseover="buttonImagesSrc.desktopSharing = isDesktopSharing ? buttonImagesIcons.desktopSharing_white: buttonImagesIcons.desktopSharing_white"
          @mouseleave="buttonImagesSrc.desktopSharing = isDesktopSharing? buttonImagesIcons.desktopSharing_white: buttonImagesIcons.desktopSharing"
          @click=" isDesktopSharing ? endDesktopSharing() : triggerDesktopSharing()"
        >
          <img
            :src="buttonImagesSrc.desktopSharing"
            alt=""
          >
          <span class="kai-tooltip-text">
            {{ isDesktopSharing ? endDesktopSharingTooltip: desktopSharingTooltip }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import './styles/LiveChatVideoWidget.less'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import LiveChatButtonsMixin from './Mixin/LiveChatButtonsMixin'

export default {
  name: 'LiveChatVideoWidget',
  mixins: [
    KeyboardNavigationMixin,
    ImageUtilityMixin,
    LiveChatButtonsMixin
  ],
  computed: {},
  methods: {}
}
</script>
