<template>
  <section class="kai-rating-binary-container">
    <div
      class="kai-rating-binary-wrapper"
      tabindex="0"
      navDir="horizontal"
    >
      <div
        v-for="(item, i) in ratingItems"
        :key="i"
        class="kai-quick-reply-item"
        tabindex="-1"
        navLvl="1"
        navDir="horizontal"
        @click="submitRating($event, item, i)"
      >
        <div
          v-if="isDisable"
          class="kai-card-disabled-overlay"
        />
        <div class="kai-quick-reply-label">
          <!-- eslint-disable vue/no-v-html -->
          <div
            :data-value="item.value"
            class="kai-quick-reply-label"
            v-html="item.label"
          />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SelectRatingMixin from './Mixin/SelectRatingMixin'

export default {
  name: 'RatingBinary',
  components: {

  },
  mixins: [
    SelectRatingMixin
  ],
  props: {
    response: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isDisable: false
    }
  },
  computed: {
    ratingItems () {
      var items = this.response.default.payload.options
      for (var i in items) {
        if (Kai.API.getThumbsImage(items[i].label)) {
          items[i].label = Kai.API.getThumbsImage(items[i].label)
        }
      }
      return items
    }
  }
}
</script>

<style>

 .kai-rating-binary-container {
    padding: 0px 0 0 50px;
 }

 .kai-avatar-container .kai-rating-binary-container {
    padding-left: 25px
 }

 .kai-rating-binary-wrapper {
    padding: 10px 0 20px 0;
    display: table;
    justify-content: flex-start;
    clear: both;
 }

 .selected-item {
     background-color:var(--highlight-color);
     border-radius: 50%;
 }

 .kai-rating-binary-wrapper:focus, .kai-quick-reply-item:focus {
    outline: none;
    box-shadow: 0 0 5px 3px var(--highlight-color);
}

</style>
