<template>
  <div class="related_questions_user_text_container">
    <text-bubble :payload="textBubblePayload">
    </text-bubble>
    <div 
      class="related_questions_user_text_title_container"
    >
      <div v-html="$slots.default.payload.data.title">
      </div>
    </div>
  </div>
</template>

<script>
import TextBubble from './TextBubble'

export default {
  name: 'RelatedQuestionsUserText',
  components: {
    TextBubble
  },
  computed: {
    textBubblePayload() {
      return {
        message: this.$slots.default.payload.data.content,
        position: this.$slots.default.position
      }
    }
  },
  methods: {
    convertHtmlData(elString) {
      const htmlObject = document.createElement('div')
      htmlObject.innerHTML = elString
    }
  }
}
</script>
<style scoped>
  .related_questions_user_text_title_container > div {
    float: right;
    padding: 0 30px 10px 20px;
    font-size: var(--related-questions-text-font-size);
  }
</style>