<template>
  <div
    class="avatar-title-container"
    :class="{open:displayAvatarTitle}"
  >
    <div
      ref="titleContainer"
      class="avatar-title-inner-container"
      :class="{marquee_shown:animate}"
    >
      <!-- eslint-disable vue/no-v-html -->
      <div
        ref="title"
        class="avatar-title-banner"
        :class="{marquee:animate}"
        :style="animationDuration"
        v-html="avatarTitle"
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>
  </div>
</template>
<script>

import './styles/AvatarTitle.less'

export default {
  name: 'AvatarTitle',
  data () {
    return {
      forceUpdate: false,
      defaultDurationTime: 15
    }
  },
  computed: {
    displayAvatarTitle () {
      return this.$store.getters.getAvatarTitle && this.$store.getters.getAvatarTitle.length > 0
    },
    avatarTitle () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.forceUpdate = true
      this.$forceUpdate()
      return this.$store.getters.getAvatarTitle
    },
    animate () {
      // eslint-disable-next-line no-unused-vars
      const dummy = !this.forceUpdate
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.forceUpdate = false
      if (this.$refs.titleContainer && this.$refs.title) {
        return this.$refs.titleContainer.clientWidth < this.$refs.title.clientWidth
      } else {
        return false
      }
    },
    dynamicDuration () {
      let duration = this.defaultDurationTime
      if (this.$refs.titleContainer && this.$refs.title && this.$refs.titleContainer.clientWidth > this.$refs.title.clientWidth) {
        duration = duration + duration * (this.$refs.title.clientWidth - this.$refs.titleContainer.clientWidth) / this.$refs.title.clientWidth
      }
      return duration
    },
    animationDuration () {
      return { '--avatar-title-duration': this.dynamicDuration.toString() + 's' }
    },
    useHeaderBar () {
      return $store.state.useHeaderBar
    },
    useWidgetMode () {
      return $store.state.useWidgetMode
    }
  },
  mounted () {
    // we need to force update when view is just mounted to calculate itemsWidth, maxTranslateX based on the actual size of each quick reply item
    this.forceUpdate = true
    this.$forceUpdate()
  }
}
</script>
