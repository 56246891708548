const { webrtc } = require('../../../js/webRTC/webrtc')
const Cookies = require('js-cookie')

module.exports = {
  data () {
    return {
      endChatTooltip: $store.getters.getBotLanguages.translations.liveChatMessages.endChatTooltip,
      attachmentTooltip: $store.getters.getBotLanguages.translations.liveChatMessages.attachmentTooltip,
      voiceTooltip: $store.getters.getBotLanguages.translations.liveChatMessages.voiceTooltip,
      endCallTooltip: $store.getters.getBotLanguages.translations.liveChatMessages.endCallTooltip,
      muteTooltip: $store.getters.getBotLanguages.translations.liveChatMessages.muteTooltip,
      unmuteTooltip: $store.getters.getBotLanguages.translations.liveChatMessages.unmuteTooltip,
      videoTooltip: $store.getters.getBotLanguages.translations.liveChatMessages.videoTooltip,
      endVideoTooltip: $store.getters.getBotLanguages.translations.liveChatMessages.endVideoTooltip,
      desktopSharingTooltip: $store.getters.getBotLanguages.translations.liveChatMessages.desktopSharingTooltip,
      endDesktopSharingTooltip: $store.getters.getBotLanguages.translations.liveChatMessages.endDesktopSharingTooltip,
      deviceSettingsTooltip: $store.getters.getBotLanguages.translations.liveChatMessages.deviceSettingsTooltip,
      selectedMicrophone: '',
      selectedSpeaker: '',
      selectedCamera: '',
      microphones: [],
      speakers: [],
      cameras: [],
      percentComplete: 0,
      isChoosingFile: false
    }
  },
  watch: {
    isLiveChatStarted (newVal, oldVal) {
      // reset the livechat widget when the chat ends
      if (oldVal && !newVal) {
        if (this.livechatCustomEvents && this.livechatCustomEvents.length > 0 && !this.livechatCustomEvents.slice(-1)[0].isSessionTranscript) {
          this.endWebRTC()
        }
      }
    }
  },
  computed: {
    isLiveChatStarted () {
      return $store.getters.isLiveChatStarted
    },
    isLiveAgentConnected () {
      return $store.getters.isLiveAgentConnected
    },
    multimediaConfig () {
      return $store.getters.getMultimediaConfig
    },
    webRTCStatus () {
      return $store.getters.getWebRTCStatus
    },
    isWebRTCActive () {
      return (this.webRTCStatus.audioUser || this.webRTCStatus.audioAgent || this.webRTCStatus.videoUser ||
        (this.webRTCStatus.videoAgent && this.webRTCStatus.videoAgent.status) || this.webRTCStatus.rdsUser ||
        (this.webRTCStatus.rdsAgent && this.webRTCStatus.rdsAgent.status))
    },
    isMutedLocal () {
      return this.webrtc.audio.isMutedLocal()
    },
    buttonImagesIcons () {
      return {
        attachment: this.getImagePath('svg/paper_clip.svg'),
        attachment_white: this.getImagePath('svg/paper_clip_white.svg'),
        voice: this.getImagePath('svg/phone.svg'),
        voice_white: this.getImagePath('svg/phone_white.svg'),
        mute: this.getImagePath('svg/microphone_mute.svg'),
        mute_white: this.getImagePath('svg/microphone_mute_white.svg'),
        video: this.getImagePath('svg/camera.svg'),
        video_white: this.getImagePath('svg/camera_white.svg'),
        desktopSharing: this.getImagePath('svg/screen.svg'),
        desktopSharing_white: this.getImagePath('svg/screen_white.svg'),
        deviceSettings: this.getImagePath('svg/gear.svg'),
        deviceSettings_white: this.getImagePath('svg/gear_white.svg'),
        notif_phone: this.getImagePath('svg/phone_notif.svg'),
        notif_phone_stop: this.getImagePath('svg/phone_stop_notif.svg'),
        notif_video: this.getImagePath('svg/video_notif.svg'),
        notif_video_stop: this.getImagePath('svg/video_stop_notif.svg'),
        notif_rds: this.getImagePath('svg/rds_notif.svg'),
        notif_rds_stop: this.getImagePath('svg/rds_stop_notif.svg'),
        notif_upload_inprogress: this.getImagePath('file_loading.gif'),
        notif_upload_done: this.getImagePath('svg/upload_done_notif.svg'),
        notif_upload_failed: this.getImagePath('svg/upload_failed_notif.svg'),
        notif_download: this.getImagePath('svg/download_notif.svg'),
        endChat: this.getImagePath('svg/end_chat.svg'),
        endChat_white: this.getImagePath('svg/end_chat_white.svg')
      }
    },
    buttonImagesSrc () {
      if (!$store.getters.getLivechatCurrentImagesSrc) {
        $store.state.livechatCurrentImagesSrc = {
          attachment: this.getImagePath('svg/paper_clip.svg'),
          attachment_white: this.getImagePath('svg/paper_clip_white.svg'),
          voice: this.getImagePath('svg/phone.svg'),
          voice_white: this.getImagePath('svg/phone_white.svg'),
          mute: this.getImagePath('svg/microphone_mute.svg'),
          mute_white: this.getImagePath('svg/microphone_mute_white.svg'),
          video: this.getImagePath('svg/camera.svg'),
          video_white: this.getImagePath('svg/camera_white.svg'),
          desktopSharing: this.getImagePath('svg/screen.svg'),
          desktopSharing_white: this.getImagePath('svg/screen_white.svg'),
          deviceSettings: this.getImagePath('svg/gear.svg'),
          deviceSettings_white: this.getImagePath('svg/gear_white.svg'),
          endChat: this.getImagePath('svg/end_chat.svg'),
          endChat_white: this.getImagePath('svg/end_chat_white.svg')
        }
      }
      return $store.getters.getLivechatCurrentImagesSrc
    },
    webrtc () {
      if (!$store.getters.getWebrtc) {
        $store.state.webrtc = webrtc
        this.webrtc.init(this.livechatPanelStates.localAudioRefs, this.livechatPanelStates.localVideoRefs, this.livechatPanelStates.remoteVideoRefs, this.livechatPanelStates.localRDSRefs, this.livechatPanelStates.remoteRDSRefs)
      }
      return $store.getters.getWebrtc
    },
    audioInputSelect () {
      return $store.getters.getAudioInputSelect
    },
    isMuted () {
      return $store.getters.getIsMuted
    },
    isAudioSharing () {
      return $store.getters.getIsAudioSharing
    },
    isVideoSharing () {
      return $store.getters.getIsVideoSharing
    },
    isDesktopSharing () {
      return $store.getters.getIsDesktopSharing
    },
    livechatPanelStates () {
      if (!$store.getters.getLivechatPanelStates) {
        $store.state.livechatPanelStates = {
          size: 'minimized',
          mode: 'voice',
          subtitle: null,
          description: null,
          cancelLabel: $store.getters.getBotLanguages.translations.liveChatMessages.panelCancelLabel,
          confirmLabel: null,
          localAudioRefs: [],
          localVideoRefs: [],
          remoteVideoRefs: [],
          localRDSRefs: [],
          remoteRDSRefs: [],
          confirmFunction: () => {}
        }
      }
      return $store.getters.getLivechatPanelStates
    },
    // fileUploadId: 0,
    // files: [],
    fileUploadId () {
      return $store.getters.getFileUploadId
    },
    files () {
      return $store.getters.getFiles
    },
    inlinePopupOpen () {
      return $store.getters.getShowInlinePopup
    },
    popupContent () {
      return $store.getters.getInlinePopupContent
    },
    isToastMessage () {
      return this.popupContent && this.popupContent.toast_message
    },
    livechatCustomEvents () {
      return $store.getters.getLivechatCustomEvents
    },
    lastCustomEventId () {
      return $store.getters.getLastCustomEventId
    },
    newCustomEvents () {
    // find all events that are not session transcripts and have an id greater than the lastCustomEventId
      return this.livechatCustomEvents.filter((event) => {
        return event.id > this.lastCustomEventId && !event.isSessionTranscript
      })
    }
  },
  methods: {
    displayVoiceConfirmation () {
      $store.dispatch('actionLivechatPanelStates', {
        ...this.livechatPanelStates,
        size: 'small',
        mode: 'voice',
        subtitle: $store.getters.getBotLanguages.translations.liveChatMessages.voicePanelSubtitle,
        description: $store.getters.getBotLanguages.translations.liveChatMessages.voicePanelDescription,
        cancelLabel: $store.getters.getBotLanguages.translations.liveChatMessages.panelCancelLabel,
        confirmLabel: $store.getters.getBotLanguages.translations.liveChatMessages.voicePanelConfirmLabel,
        confirmFunction: this.triggerVoiceCall
      })
    },
    triggerVoiceCall (optionalMinimizeLiveChatPanel = true, optionalStartMuted = false) {
      // in case agent already send Video/Rds Invite:
      this.triggerRemoteVideoSharing()
      this.triggerRemoteDesktopSharing()
      this.inviteAudio(optionalStartMuted)

      $store.dispatch('actionWebRTCStatus', {
        audioUser: true
      })

      $store.dispatch('actionIsAudioSharing', true)

      this.buttonImagesSrc.voice = this.buttonImagesIcons.voice_white
      if (optionalMinimizeLiveChatPanel) {
        // hide confirmation panel when user clicks on confirm button
        $store.dispatch('actionLivechatPanelStates', {
          ...this.livechatPanelStates,
          size: 'minimized'
        })
      }
    },
    endVoiceCall () {
      this.webrtc.audio.stop()
      $store.dispatch('actionWebRTCStatus', {
        audioUser: false
      })
      $store.dispatch('actionIsAudioSharing', false)
      this.buttonImagesSrc.voice = this.buttonImagesIcons.voice
      const payload = {
        event_type: 'AudioUserEnd',
        data: {
          cmd: 'reinvite',
          sdp: 0
        }
      }
      kserver.CoreAPI.sendLiveChatCustomRequest(payload)
    },
    async populateDeviceSelectors () {
      this.microphones = []
      this.speakers = []
      this.cameras = []
      const devices = await this.webrtc.devices.getDevices(false)
      for (const device of devices) {
        if (device.kind === 'audioinput') {
          this.microphones.push({ label: device.label, deviceId: device.deviceId })
        } else if (device.kind === 'audiooutput') {
          this.speakers.push({ label: device.label, deviceId: device.deviceId })
        } else if (device.kind === 'videoinput') {
          this.cameras.push({ label: device.label, deviceId: device.deviceId })
        }
      }
      // retrieve previously selected devices from cookies
      const selectedMicrophone = Cookies.get('k_selectedMicrophone')
      const selectedSpeaker = Cookies.get('k_selectedSpeaker')
      const selectedCamera = Cookies.get('k_selectedCamera')
      // set the selected devices in the selectors
      if (selectedMicrophone) {
        this.selectedMicrophone = JSON.parse(selectedMicrophone).label
      } else {
        this.selectedMicrophone = this.microphones[0].label
      }
      if (selectedSpeaker) {
        this.selectedSpeaker = JSON.parse(selectedSpeaker).label
      } else {
        this.selectedSpeaker = this.speakers[0].label
      }
      if (selectedCamera) {
        this.selectedCamera = JSON.parse(selectedCamera).label
      } else {
        this.selectedCamera = this.cameras[0].label
      }
    },
    encodeHtml (str) {
      var p = document.createElement('p')
      p.textContent = str
      return p.innerHTML
    },
    displayVideoConfirmation () {
      $store.dispatch('actionLivechatPanelStates', {
        ...this.livechatPanelStates,
        size: 'small',
        mode: 'video',
        subtitle: $store.getters.getBotLanguages.translations.liveChatMessages.videoPanelSubtitle,
        description: $store.getters.getBotLanguages.translations.liveChatMessages.videoPanelDescription,
        cancelLabel: $store.getters.getBotLanguages.translations.liveChatMessages.panelCancelLabel,
        confirmLabel: $store.getters.getBotLanguages.translations.liveChatMessages.videoPanelConfirmLabel,
        confirmFunction: this.triggerVideoSharing
      })
    },
    async triggerVideoSharing () {
      // in case agent already send Video/Rds Invite:
      this.triggerRemoteVideoSharing()
      this.triggerRemoteDesktopSharing()
      const offer = await this.webrtc.videoLocal.start({})
      $store.dispatch('actionWebRTCStatus', {
        videoUser: true
      })
      $store.dispatch('actionIsVideoSharing', true)
      this.buttonImagesSrc.video = this.buttonImagesIcons.video_white
      // hide confirmation panel when user clicks on confirm button
      $store.dispatch('actionLivechatPanelStates', {
        ...this.livechatPanelStates,
        size: 'big'
      })

      // CAPI event to start video
      const payload = {
        event_type: 'VideoUserInvite',
        data: {
          cmd: 'video',
          sdp: offer.sdp
        }
      }
      kserver.CoreAPI.sendLiveChatCustomRequest(payload)
    },
    async endVideoSharing () {
      this.webrtc.videoLocal.stop()
      $store.dispatch('actionWebRTCStatus', {
        videoUser: false
      })
      $store.dispatch('actionIsVideoSharing', false)
      this.buttonImagesSrc.video = this.buttonImagesIcons.video
      const payload = {
        event_type: 'VideoUserEnd',
        data: {
          cmd: 'video',
          sdp: 0
        }
      }
      kserver.CoreAPI.sendLiveChatCustomRequest(payload)
    },
    async triggerRemoteVideoSharing () {
      const videoAgent = $store.getters.getWebRTCStatus.videoAgent
      if (!videoAgent.status && videoAgent.data && videoAgent.data.auth) {
        const auth = videoAgent.data.auth
        const offer = await this.webrtc.videoRemote.start(auth)

        $store.dispatch('actionWebRTCStatus', {
          videoAgent: {
            ...videoAgent,
            status: true
          }
        })
        // CAPI event to start video
        const newPayload = {
          event_type: 'VideoUserJoin',
          data: {
            cmd: 'video',
            sdp: offer.sdp
          }
        }
        kserver.CoreAPI.sendLiveChatCustomRequest(newPayload)
      }
    },
    displayRDSConfirmation () {
      $store.dispatch('actionLivechatPanelStates', {
        ...this.livechatPanelStates,
        size: 'small',
        mode: 'rds',
        subtitle: $store.getters.getBotLanguages.translations.liveChatMessages.desktopPanelSubtitle,
        description: $store.getters.getBotLanguages.translations.liveChatMessages.desktopPanelDescription,
        cancelLabel: $store.getters.getBotLanguages.translations.liveChatMessages.panelCancelLabel,
        confirmLabel: $store.getters.getBotLanguages.translations.liveChatMessages.desktopPanelConfirmLabel,
        confirmFunction: this.triggerDesktopSharing.bind(this, true)
      })
    },
    async triggerDesktopSharing (optionalMinimizeLiveChatPanel = false) {
      // in case agent already send Video/Rds Invite:
      this.triggerRemoteVideoSharing()
      this.triggerRemoteDesktopSharing()
      const offer = await this.webrtc.rdsLocal.start({})
      if (offer && offer.sdp) {
        $store.dispatch('actionWebRTCStatus', {
          rdsUser: true
        })
        $store.dispatch('actionIsDesktopSharing', true)
        this.buttonImagesSrc.desktopSharing = this.buttonImagesIcons.desktopSharing_white
        // hide confirmation panel when user clicks on confirm button
        if (optionalMinimizeLiveChatPanel) {
          $store.dispatch('actionLivechatPanelStates', {
            ...this.livechatPanelStates,
            size: 'minimized'
          })
        }
        const payload = {
          event_type: 'RdsUserInvite',
          data: {
            cmd: 'rds',
            sdp: offer.sdp
          }
        }
        kserver.CoreAPI.sendLiveChatCustomRequest(payload)
      }
    },
    async endDesktopSharing () {
      this.webrtc.rdsLocal.stop()
      $store.dispatch('actionWebRTCStatus', {
        rdsUser: false
      })
      $store.dispatch('actionIsDesktopSharing', false)
      this.buttonImagesSrc.desktopSharing = this.buttonImagesIcons.desktopSharing
      const payload = {
        event_type: 'RdsUserEnd',
        data: {
          cmd: 'rds',
          sdp: 0
        }
      }
      kserver.CoreAPI.sendLiveChatCustomRequest(payload)
    },
    async triggerRemoteDesktopSharing () {
      const rdsAgent = $store.getters.getWebRTCStatus.rdsAgent
      if (!rdsAgent.status && rdsAgent.data && rdsAgent.data.auth) {
        const auth = rdsAgent.data.auth
        const offer = await this.webrtc.rdsRemote.start(auth)
        $store.dispatch('actionWebRTCStatus', {
          rdsAgent: {
            ...rdsAgent,
            status: true
          }
        })
        // CAPI event to start rds
        const newPayload = {
          event_type: 'RdsUserJoin',
          data: {
            cmd: 'rds',
            sdp: offer.sdp
          }
        }
        kserver.CoreAPI.sendLiveChatCustomRequest(newPayload)
      }
    },
    async toggleMute () {
      await this.webrtc.audio.toggleMuteLocal()
      this.updateMuteDisplay()
    },
    async mute () {
      await this.webrtc.audio.muteLocal(true)
      this.updateMuteDisplay()
    },
    endWebRTC () {
      if (this.webRTCStatus.audioUser) {
        this.webrtc.audio.stop()
      }
      if (this.webRTCStatus.videoUser) {
        this.webrtc.videoLocal.stop()
      }
      if (this.webRTCStatus.rdsUser) {
        this.webrtc.rdsLocal.stop()
      }
      // reset webRTCStatus and button images
      this.resetLiveChatWidget()
      /// ///
    },
    chooseFile () {
      // make sure chooseFile can be executed only once at a time
      if (!this.isChoosingFile) {
        this.isChoosingFile = true
        const input = document.createElement('input')
        input.setAttribute('id', this.fileUploadId)
        input.setAttribute('type', 'file')
        input.style.opacity = 0
        input.addEventListener('change', (evt) => {
          this.uploadFile(evt)
        })
        this.$nextTick(() => input.click())
        setTimeout(() => {
          this.isChoosingFile = false
        }, 1000)
      }
    },
    uploadFile (evt) {
      // NOTE: limiting to one file for simplicity
      const file = evt.target.files[0]

      if ((file && typeof file.size === 'undefined') || file.size === 0) {
        alert('Please select a file to upload.')
        return
      }

      // const fileExtension = file.name.lastIndexOf('.') > 0 ? file.name.substring(file.name.lastIndexOf('.')) : '';
      // const allowedFileTypes = $store.getters.getAttachmentFileTypes;
      // if (allowedFileTypes)
      //   if (allowedFileTypes.allowed.length > 0 && !allowedFileTypes.allowed.includes(fileExtension)) {
      //     alert('The file type is not allowed.');
      //     return;
      //   }

      //   if (allowedFileTypes ) {
      //     if (allowedFileTypes.forbidden.length > 0 && allowedFileTypes.forbidden.includes(fileExtension)) {
      //       alert('The file type is not allowed.');
      //       return;
      //   }
      // }
      // if (file.size * 1000000 > allowedFileTypes.maxFileSizeInMB) {
      //   alert(`Files must be ${allowedFileTypes.maxFileSizeInMB}MB or less.`);
      //   return;
      // }

      $store.state.files.push({ id: this.fileUploadId, file: file })

      // CAPI Event to send FileUpload and get the file upload URI. NOTE: For some reason, we are not getting the URI from KAI.
      const payload = {
        event_type: 'FileUploadRequest',
        data: {
          cmd: 'file-upload',
          file: {
            name: file.name,
            size: file.size,
            id: this.fileUploadId
          }
        }
      }
      kserver.CoreAPI.sendLiveChatCustomRequest(payload)

      $store.state.fileUploadId++

      // // test to display the attached file if it's an image in a preview element
      // if (file.type.startsWith("image/")) {
      //   let reader = new FileReader()
      //   reader.onload = () => {
      //       let url = reader.result
      //       let img = document.createElement("img")
      //       img.src = url
      //       img.style.width = "150px"
      //       img.style.height = "100px"
      //       img.style.objectFit = "contain"
      //       img.style.objectPosition = "center"
      //       document.body.append(img)
      //   }
      //   reader.readAsDataURL( evt.target.files[0] )
      // } else if (file.type.startsWith("video/")) {
      //   // display a thumbnail with an image taken from the video
      //   let video = document.createElement("video")
      //   video.src = URL.createObjectURL(file)
      //   video.style.width = "150px"
      //   video.style.height = "100px"
      //   video.style.objectFit = "contain"
      //   video.style.objectPosition = "center"
      //   video.addEventListener("loadedmetadata", () => {
      //       video.currentTime = 1
      //   })
      //   video.addEventListener("seeked", () => {
      //       let canvas = document.createElement("canvas")
      //       canvas.width = video.videoWidth
      //       canvas.height = video.videoHeight
      //       canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height)
      //       let img = document.createElement("img")
      //       img.src = canvas.toDataURL()
      //       img.style.width = "150px"
      //       img.style.height = "100px"
      //       img.style.objectFit = "contain"
      //       img.style.objectPosition = "center"
      //       document.body.append(img)
      //   })
      //   document.body.append(video)
      // } else if (file.type.startsWith("application/pdf")) {
      //   let reader = new FileReader()
      //   reader.onload = () => {
      //       let url = reader.result
      //       let pdf = document.createElement("embed")
      //       pdf.src = url
      //       pdf.style.width = "150px"
      //       pdf.style.height = "100px"
      //       pdf.style.objectFit = "contain"
      //       pdf.style.objectPosition = "center"
      //       document.body.append(pdf)
      //   }
      //   reader.readAsDataURL( evt.target.files[0] )
      // } else {
      //   // display a default thumbnail for other file types
      //   let img = document.createElement("img")
      //   img.src = "https://via.placeholder.com/150x100"
      //   img.style.width = "150px"
      //   img.style.height = "100px"
      //   img.style.objectFit = "contain"
      //   img.style.objectPosition = "center"
      //   document.body.append(img)
      // }
    },
    build_path (...args) {
      return args.map((part, i) => {
        if (i === 0) {
          return part.trim().replace(/[/]*$/g, '')
        } else {
          return part.trim().replace(/(^[/]||[/]$)/g, '')
        }
      }).filter(x => x.length).join('/')
    },

    changeMicrophone (device) {
      try {
        this.webrtc.audio.changeMic(device.deviceId)
        Cookies.set('k_selectedMicrophone', device)
      } catch (err) {
        console.error('Error changing audioInput', err)
      }
    },

    changeSpeaker (device) {
      try {
        this.webrtc.audio.changeSpk(device.deviceId)
        Cookies.set('k_selectedSpeaker', device)
      } catch (err) {
        console.error('Error changing audioOutput', err)
      }
    },
    changeCamera (device) {
      try {
        this.webrtc.videoLocal.changeCamera(device.deviceId)
        Cookies.set('k_selectedCamera', device)
      } catch (err) {
        console.error('Error changing camera', err)
      }
    },
    updateMuteDisplay () {
      $store.dispatch('actionIsMuted', this.webrtc.audio.isMutedLocal())
      $store.dispatch('actionWebRTCStatus', {
        mute: this.webrtc.audio.isMutedLocal()
      })
    },

    inviteAudio (optionalStartMuted = false) {
      this.webrtc.audio.start()
        .then((sdp) => {
          console.log('webrtc.audio.start() success')
          if (optionalStartMuted) {
            // force the audio to be muted
            this.mute()
          } else {
            this.updateMuteDisplay()
          }
          // volumeRange.value = document.getElementById('localAudio').volume;

          const payload = {
            event_type: 'AudioUserInvite',
            data: {
              cmd: 'reinvite',
              sdp: sdp
            }
          }
          kserver.CoreAPI.sendLiveChatCustomRequest(payload)
        })
    },
    resetLiveChatWidget () {
      $store.dispatch('actionWebRTCStatus', {
        audioUser: false,
        audioAgent: false,
        mute: false,
        videoUser: false,
        videoAgent: false,
        rdsUser: false,
        rdsAgent: false
      })
      $store.dispatch('actionMultimediaConfig', {
        audio: false,
        video: false,
        desktopSharing: false,
        attachment: false
      })
      $store.dispatch('actionLivechatPanelStates', {
        ...this.livechatPanelStates,
        size: 'minimized'
      })
      $store.state.isAudioSharing = false
      $store.state.isVideoSharing = false
      $store.state.isDesktopSharing = false
      $store.state.isMuted = false
      $store.state.livechatCurrentImagesSrc = undefined
      $store.state.linkLiveStates = null
    },
    async displaySettings () {
      $store.dispatch('actionLivechatPanelStates', {
        ...this.livechatPanelStates,
        size: 'small',
        mode: 'settings',
        subtitle: null,
        description: null,
        confirmLabel: null,
        confirmFunction: () => {}
      })
    },

    processFileUpload (data) {
      return new Promise((resolve, reject) => {
        console.log('id type', typeof data.publishto.file.id, data.publishto.file.id)
        const foundFile = this.files.find((f) => {
          // return f.id === parseInt(data.publishto.file.id, 10);
          return f.id === this.fileUploadId - 1
        })
        if (!foundFile) {
          throw new Error(`Ignoring file upload for fileId ${data.publishto.file.id} as it is not in the list of files to upload.`)
        }
        const uploadUri = this.build_path(`https://${data.publishto.file.host}`, data.publishto.file.uri)

        // let xhr = new XMLHttpRequest();
        // xhr.open('POST', uploadUri, true);
        // xhr.setRequestHeader('Accept', 'application/json');

        // // Add an event listener for the 'progress' event
        // xhr.upload.addEventListener('progress', function(event) {
        //   if (event.lengthComputable) {
        //     this.percentComplete = event.loaded / event.total * 100;
        //     console.log(percentComplete + '% uploaded');
        //   }
        // });

        // xhr.onreadystatechange = function() {
        //   if (xhr.readyState == 4 && xhr.status == 200) {
        //     resolve(true);
        //     // remove from list of files
        //     this.files = this.files.filter((f) => {
        //         return f.id !== foundFile.id;
        //     });
        //   } else if (xhr.readyState == 4 && xhr.status != 200) {
        //     reject('Error uploading file', xhr.status);
        //   }

        // };

        // xhr.send(foundFile.file);

        fetch(uploadUri, {
          method: 'POST',
          headers: {
            Accept: 'application/json'
          },
          mode: 'no-cors',
          body: foundFile.file
        })
        //   .then(response => {
        //     // NOTE: because we are using no-cors response is opauqe
        //     const reader = response.body.getReader();
        //     const contentLength = +response.headers.get('Content-Length');
        //     let receivedLength = 0;
        //     reader.read().then(function process({done, value}) {
        //       if (done) {
        //         resolve(true);
        //         // remove from list of files
        //         $store.state.files = this.files.filter((f) => {
        //             return f.id !== foundFile.id;
        //         });

        //         // notify CAPI that the file has been uploaded
        //         // CAPI Event to send FileUpload and get the file upload URI. NOTE: For some reason, we are not getting the URI from KAI.
        //         const payload = {
        //           "event_type": "FileUploadSuccess",
        //             "data": {
        //               "file": {
        //                 "name":foundFile.file.name,
        //                 "size": foundFile.file.size,
        //                 "id": foundFile.id
        //               }
        //             }
        //         }
        //         kserver.CoreAPI.sendLiveChatCustomRequest(payload)
        //         return;
        //       }

        //       receivedLength += value.length;
        //       const percentComplete = receivedLength / contentLength * 100;
        //       console.log(percentComplete + '% uploaded');

        //       return reader.read().then(process);
        //     });

        // })
          .then(response => {
            // NOTE: because we are using no-cors response is opauqe

            resolve(true)
            // remove from list of files
            $store.state.files = this.files.filter((f) => {
              return f.id !== foundFile.id
            })

            // notify CAPI that the file has been uploaded
            // CAPI Event to send FileUpload and get the file upload URI. NOTE: For some reason, we are not getting the URI from KAI.
            const payload = {
              event_type: 'FileUploadSuccess',
              data: {
                file: {
                  name: foundFile.file.name,
                  size: foundFile.file.size,
                  id: foundFile.id
                }
              }
            }
            kserver.CoreAPI.sendLiveChatCustomRequest(payload)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    downloadFile (file) {
      const a = document.createElement('a')
      a.href = 'https://' + file.host + file.uri
      a.download = file.name
      a.click()
    }
  }
}
