<template>
  <section
    ref="listview"
    class="kai-select-rating"
  >
    <RatingBinary
      v-if="response.default.payload.input_type === 'RATING_BINARY'"
      :response="response"
    />
    <RatingMultiple
      v-if="response.default.payload.input_type === 'RATING_5' || response.default.payload.input_type === 'RATING_10'"
      :response="response"
    />
  </section>
</template>

<script>

import RatingBinary from './RatingBinary'
import RatingMultiple from './RatingMultiple'
import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'

export default {
  name: 'SelectRating',
  components: {
    RatingBinary,
    RatingMultiple
  },
  mixins: [
    KeyboardNavigationMixin
  ],
  props: {
    response: {
      type: Object,
      required: true
    }
  }
}
</script>
