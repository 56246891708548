<template>
  <Custom
    v-if="response.default.type === 'CUSTOM'"
    :item="response.default.payload"
    :is-single="true"
  />
</template>

<script>
import Custom from './Custom'

export default {
  name: 'CustomSingle',
  components: {
    Custom
  },
  data () {
    return {
      response: this.$slots
    }
  }
}
</script>
