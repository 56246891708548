<template>
  <section>
    <div class="medium-small-wrapper">
      <template v-if="medium && medium.hyperlink_url">
        <a
          target="_blank"
          :href="medium.hyperlink_url"
        >
          <img
            class="backup_picture img_with_hyperlink"
            ondragstart="return false;"
            :src="medium.medium_url"
            alt=""
          >
        </a>
      </template>
      <template v-else-if="medium">
        <img
          class="backup_picture"
          ondragstart="return false;"
          :src="medium.medium_url"
          alt=""
        >
      </template>
    </div>
  </section>
</template>

<script>

export default {
  name: 'MediumSmall',
  props: {
    medium: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
  .medium-small-wrapper {
    /* height: 58px; */
    width: 58px;
    float: right;
    shape-outside: content-box;
    margin: 10px;
    margin-top: 15px;
  }
  .kai-card-item img {
    width: 100%;
    min-height: 0;
}

</style>
