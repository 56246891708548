'use strict'

import { sdpAttributeRemoveCrypto, sdpAttributeInsert, sdpPatchUpAnswer } from './sdp-utils.js'

export class AudioBidirectional {
  constructor () {
    this.active = false
    this.elements = null
    this.peerConnection = null
    this.localStream = null
    var deviceMic = null
    var deviceSpk = null

    // initiate two way audio
    this.start = () => {
      return new Promise((resolve) => {
        this.active = true

        console.log('Creating audio peer connection.')
        this.peerConnection = new RTCPeerConnection(null, {
          optional: []
        })

        this.peerConnection.ontrack = (e) => {
          if (this.elements) {
            this.elements.forEach((element) => {
              element.srcObject = e.streams[0]
              try {
                if (deviceSpk) {
                  element.setSinkId(deviceSpk)
                }
              } catch (error) {
                console.log('Failed to set sink id. This feature is not available in all browsers.', error)
              }
              element.play()
            })
          }
        }

        console.log('Requesting access to local audio.')
        navigator.mediaDevices.getUserMedia({
          audio: { deviceId: deviceMic ? { exact: deviceMic } : undefined },
          video: false
        })
          .then((stream) => {
            console.log('User has granted access to local audio.')

            this.localStream = stream
            const tracks = this.localStream.getTracks()
            tracks.forEach((track) => {
              this.peerConnection.addTrack(track, this.localStream)
            })

            console.log('Creating audio offer.')
            return this.peerConnection.createOffer({
              offerToReceiveAudio: 1,
              offerToReceiveVideo: 0,
              voiceActivityDetection: false
            })
          })
          .then(async (offer) => {
            // console.log('Audio offer:', offer);
            offer.sdp = sdpAttributeRemoveCrypto(offer.sdp)
            offer.sdp = sdpAttributeInsert(offer.sdp, 'ice-lite')
            await this.peerConnection.setLocalDescription(offer)
            resolve(offer.sdp)
          })
          .catch((error) => {
            console.warn('Failed to get access to local media.', error)
            this.stop()
            resolve(null)
          })
      })
    }

    this.changeMic = (device) => {
      if (deviceMic !== device) {
        deviceMic = device
        if (this.localStream && this.peerConnection) {
          navigator.mediaDevices.getUserMedia({
            audio: { deviceId: deviceMic ? { exact: deviceMic } : undefined },
            video: false
          })
            .then((stream) => {
              const track = stream.getAudioTracks()[0]
              var sender = this.peerConnection.getSenders().find(function (s) {
                return s.track.kind === track.kind
              })
              sender.replaceTrack(track)
            })
            .catch((error) => {
              console.warn('Failed to change mic.', error)
              this.stop()
            })
        }
      }
    }

    this.changeSpk = (device) => {
      if (deviceSpk !== device) {
        deviceSpk = device
        if (this.elements) {
          this.elements.forEach((element) => {
            try {
              if (deviceSpk) {
                element.setSinkId(deviceSpk)
              }
            } catch (error) {
              console.log('Failed to set sink id. This feature is not available in all browsers.', error)
            }
          })
        }
      }
    }

    this.stop = () => {
      console.log('Stop audio.')
      if (this.localStream) {
        this.localStream.getTracks().forEach((track) => {
          track.stop()
        })
        this.localStream = null
      }
      if (this.peerConnection) {
        this.peerConnection.close()
        this.peerConnection = null
      }
      if (this.elements) {
        this.elements.forEach((element) => {
          element.srcObject = null
        })
      }
      this.active = false
    }

    this.sendDTMF = (digits) => {
      let dtmfSender = null
      if (this.peerConnection && this.peerConnection.getSenders().length > 0) {
        const firstSender = this.peerConnection.getSenders()[0]
        if (firstSender) {
          dtmfSender = firstSender.dtmf
        }
      }
      // Firefox doesn't support canInsertDTMF method
      if (dtmfSender && ((dtmfSender.canInsertDTMF === undefined) || (dtmfSender.canInsertDTMF))) {
        console.log(`Send DTMF: ${digits}`)
        dtmfSender.insertDTMF(digits, 100, 70)
      }
    }

    this.remoteSDP = (content) => {
      return new Promise((resolve, reject) => {
        content = sdpPatchUpAnswer(content)
        this.peerConnection.setRemoteDescription({ type: 'answer', sdp: content })
          .then(() => {
            resolve('ok')
          })
          .catch((err) => {
            reject(new Error('Remote audio description problem', err))
          })
      })
    }

    this.muteLocal = (muted) => {
      return muteStream(this.peerConnection, muted)
    }

    this.isMutedLocal = () => {
      if (this.peerConnection) {
        var sender = this.peerConnection.getSenders().find((s) => {
          return s.track.kind === 'audio'
        })
        if (sender) {
          return !sender.track.enabled
        }
      }
      return false
    }

    this.toggleMuteLocal = () => {
      return muteStream(this.peerConnection, !this.isMutedLocal())
    }

    var muteStream = (pc, muted) => {
      var isMutedLocal = false
      if (pc) {
        var sender = pc.getSenders().find(function (s) {
          return s.track.kind === 'audio'
        })
        if (sender) {
          sender.track.enabled = !muted
          isMutedLocal = !muted
        }
      }
      return isMutedLocal
    }
  }
}
